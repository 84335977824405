import { useEffect } from 'react'

import { renderFloorImage } from 'src/pages/MapStudio/controllers/renderFloorImage'
import { renderFloorLayers } from 'src/pages/MapStudio/controllers/renderFloorLayers'
import { useMap } from 'src/modules/map'

const layersToHide = [
  'poi-scalerank4-l15',
  'poi-scalerank4-l1',
  'poi-scalerank3',
  'road-label-small',
  'building',
]

export const usePlan = props => {
  const { building, floor, floorAnglesMarkers, setFloorAnglesMarkers } = props
  const { map, flyTo, showDefaultCenter } = useMap()

  useEffect(() => {
    if (!map || !floor || !building) return false

    const coords = {
      lng: building.coordinate.longitude,
      lat: building.coordinate.latitude,
    }

    if (floor.layers?.length) {
      renderFloorLayers({
        floor,
        currentMap: map,
        isJBuilding: building.jibestream_id,
      })
    } else if (floor.coordinates) {
      renderFloorImage(floor, map, floorAnglesMarkers, setFloorAnglesMarkers)
    } else {
      // No layers, no image, nothing to do here
      return
    }

    if (!showDefaultCenter) {
      const zoom = map.getZoom()
      flyTo(coords, { zoom: zoom < 18 ? 18 : zoom })
    }

    // To avoid unwanted overlap from streets-v10 layers, some layers are hidden when an indoor map is shown
    layersToHide.forEach(layerId =>
      map.setLayoutProperty(layerId, 'visibility', 'none')
    )

    return () => {
      if (!map || !map?.getStyle()) return false
      // Restore previously hidden layers
      layersToHide.forEach(layerId =>
        map.setLayoutProperty(layerId, 'visibility', 'visible')
      )
    }
  }, [
    building,
    floor,
    floorAnglesMarkers,
    setFloorAnglesMarkers,
    map,
    flyTo,
    showDefaultCenter,
  ])
}
