import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { useParams, useHistory } from 'react-router-dom'

import { DataTable } from 'src/components/DataTable'

import { PageWithHeader, PageContentWrapper } from 'src/pages/PageTemplate'

import { Loader, Card, Button } from 'src/ui'
import { formatTime } from 'src/utils/formatDateTime'

import { Link } from 'src/components/Link'
import { useBeaconsImport } from 'src/hooks/useBeaconsImport'
import { AddBeaconsImportsModal } from 'src/components/BeaconsImports'
import { BeaconsImportModal } from 'src/components/BeaconsImports/BeaconsImportModal'
import { StyledButtonLink } from 'src/components/ButtonLink'
import { useApplications } from 'src/modules/applications'

export const BeaconsImports = () => {
  const { applicationId } = useApplications()
  const history = useHistory()
  const { beaconsImportId, buildingId } = useParams()
  const beaconsImportHook = useBeaconsImport()
  const { get: fetchImports, getImport } = beaconsImportHook

  if (!applicationId) {
    history.replace('/applications')
  }
  const [beaconsImports, setBeaconsImports] = useState([])
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(20)
  const [currentPageCount, setCurrentPageCount] = useState()
  const [beaconsImport, setBeaconsImport] = useState()
  const [isImportsLoading, setIsImportsLoading] = useState(true)

  const [isAddCsv, setIsAddCsv] = useState()
  const [isBeaconsImportsOpen, setIsBeaconsImportsOpen] = useState()

  useEffect(() => {
    if (beaconsImportId) {
      const load = async () => {
        const request = await getImport(beaconsImportId)
        if (request.ok) {
          setBeaconsImport(request?.json.beacon_import)
          setIsBeaconsImportsOpen(true)
        }
      }

      load()
    }
    setIsBeaconsImportsOpen(beaconsImportId)
  }, [beaconsImportId, getImport])

  const fetchData = useCallback(
    async ({ pageIndex, pageSize }) => {
      setIsImportsLoading(true)
      setCurrentPageIndex(pageIndex)
      setCurrentPageSize(pageSize)
      const result = await fetchImports({
        page: pageIndex,
        size: pageSize,
      })

      setCurrentPageCount(result.headers.get('X-Pager-Total-Pages') || 0)
      setBeaconsImports(result.json.beacon_imports || [])
      setIsImportsLoading(false)
    },
    [fetchImports]
  )

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Building',
        accessor: 'building_id',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Has headers',
        accessor: 'headers',
        Cell: ({ row: { original } }) => (
          <TableActionsCell>{original.headers ? 'yes' : 'no'}</TableActionsCell>
        ),
      },
      {
        Header: 'Added on',
        accessor: x => formatTime(x.created_at),
      },
      {
        id: 'actions',
        Header: 'Link to import details',
        Cell: ({ row: { original } }) => (
          <TableActionsCell>
            {original.id && (
              <Link
                title="Show on Map"
                onClick={() => {
                  setIsBeaconsImportsOpen(true)
                }}
                to={`/${
                  buildingId ? `buildings/${buildingId}/` : ''
                }beacons/imports/${original.id}`}
              >
                Open details
              </Link>
            )}
          </TableActionsCell>
        ),
      },
    ],
    [buildingId]
  )

  return (
    <PageWithHeader>
      <PageContentWrapper style={{ textAlign: 'center' }}>
        <Card fullSize>
          <DataTable
            pagination
            currentPageIndex={currentPageIndex}
            currentPageSize={currentPageSize}
            pageCount={+currentPageCount}
            fetchData={fetchData}
            columns={columns}
            rows={beaconsImports}
            actions={
              <>
                <Button color="primary" onClick={() => setIsAddCsv(true)}>
                  Upload Beacons CSV
                </Button>
                <StyledButtonLink
                  color="primary"
                  to={`/${buildingId ? `buildings/${buildingId}/` : ''}beacons`}
                >
                  {t('Go to Beacons')}
                </StyledButtonLink>
              </>
            }
          />
        </Card>
      </PageContentWrapper>
      {isImportsLoading && <Loader />}
      {isAddCsv && (
        <AddBeaconsImportsModal
          beaconsImports={beaconsImports}
          beaconsImportHook={beaconsImportHook}
          onClose={() => {
            setIsAddCsv(false)
          }}
        />
      )}

      <BeaconsImportModal
        isBeaconsImportsOpen={isBeaconsImportsOpen && beaconsImport}
        beaconsImport={beaconsImport}
        beaconsImportHook={beaconsImportHook}
        onClose={() => {
          setIsBeaconsImportsOpen(false)
          setBeaconsImport(false)
          history.push(
            `/${buildingId ? `buildings/${buildingId}/` : ''}beacons/imports`
          )
        }}
      />
    </PageWithHeader>
  )
}

const TableActionsCell = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: fit-content;
  column-gap: 12px;
  margin: auto;

  > * {
    cursor: pointer;
  }
`
