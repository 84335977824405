import React from 'react'
import { PageWithHeader, PageContentWrapper } from 'src/pages/PageTemplate'
import styled from 'styled-components/macro'
import { useHistory } from 'react-router-dom'

import { Card } from 'src/ui/Card'
import { Link as LinkComponent } from 'src/components/Link'

const NavTemplateComponent = ({ children, className, menuLinks = [] }) => {
  const history = useHistory()

  return (
    <PageWithHeader className={className}>
      <PageContentWrapper>
        <CardContainer fullSize>
          <NavLinksWrapper>
            {menuLinks.map(link => (
              <Link
                key={link.to}
                to={link.to}
                active={(history.location.pathname === link.to)?.toString()}
              >
                {link.title}
              </Link>
            ))}
          </NavLinksWrapper>
          {children}
        </CardContainer>
      </PageContentWrapper>
    </PageWithHeader>
  )
}

export const NavTemplate = styled(NavTemplateComponent)``

const CardContainer = styled(Card)`
  display: grid;
  grid-template-columns: minmax(250px, 300px) 1fr;
  width: 100%;
  height: 100%;
  padding: 41px 32px 22px;
  @media (max-width: 768px) {
    padding: 16px;
  }
`

const NavLinksWrapper = styled.nav`
  display: flex;
  position: relative;
  width: 155px;
  flex-flow: column;
  align-items: end;
`

const Link = styled(LinkComponent)`
  position: relative;
  display: block;
  padding-bottom: 15px;
  margin-bottom: 25px;
  letter-spacing: -0.5px;
  text-decoration: none;
  font-family: 'Biko';
  font-size: 18px;

  &:visited {
    color: #9dafbd;
  }

  ${props =>
    props.active === 'true'
      ? `
    color: #444951;
    border-bottom: 3px solid #5468FF;
     &:visited {
       color: #444951;
     }
    &:after{
      content: "";
      display: block;
      position: absolute;
      right: -40px;
      top: 4px;
      width: 10px;
      height: 10px;
      border-right: 2px solid #444951;
      border-bottom: 2px solid #444951; 
      transform: rotate(-45deg);
    }
  `
      : `
    border-bottom: 3px solid transparent;
    color: #9dafbd;    
  `}
`
