import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'

import { api } from 'src/modules/api'
import { parallelRequests } from 'src/utils/api'

export function useBuildingPois() {
  const { floorId, buildingId } = useParams()
  const [isLoading, setIsLoading] = useState(false)

  const get = useCallback(async () => {
    setIsLoading(true)

    const requestUrl = `buildings/${buildingId}/floors/${floorId}/pois?page[size]=100`
    const res = await api.get(requestUrl)
    const totalPages = res.headers.get('X-Pager-Total-Pages')

    let result = res.ok ? res?.json.building_pois : []

    if (totalPages > 1) {
      const data = await parallelRequests(requestUrl, totalPages - 1)
      result = result.concat(data)
    }

    setIsLoading(false)

    return result
  }, [floorId, buildingId])

  const create = useCallback(
    async data => {
      setIsLoading(true)
      const res = await api.post(
        `buildings/${buildingId}/floors/${floorId}/pois`,
        data
      )

      setIsLoading(false)

      return res
    },
    [floorId, buildingId]
  )

  const update = useCallback(async (poiId, data) => {
    setIsLoading(true)
    const res = await api.put(`pois/${poiId}`, data)
    setIsLoading(false)

    return res
  }, [])

  const getPoi = useCallback(async poiId => {
    setIsLoading(true)
    const res = await api.get(`pois/${poiId}`)
    setIsLoading(false)

    return res.ok ? res?.json.building_poi : []
  }, [])

  const remove = useCallback(async poiId => {
    setIsLoading(true)
    const res = await api.del(`pois/${poiId}`)
    setIsLoading(false)

    return res
  }, [])

  return {
    isLoading,
    get,
    create,
    update,
    remove,
    getPoi,
  }
}
