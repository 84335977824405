import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { Form, FormSpy } from 'react-final-form'

import { Modal } from 'src/components/Modal'
import { SelectField } from 'src/components/SelectField'
import { TextField } from 'src/components/TextField'
import { Alert as AlertComponent } from 'src/components/Alert'
import { Button } from 'src/ui'
import { createUpload } from 'src/modules/directLoad'
import { templateOptions } from 'src/pages/buildings/Engagements/components/defaults'

import { ReactComponent as PhoneImageComponent } from 'src/assets/images/svg/phone.svg'
import { ColorPicker } from 'src/components/ColorPicker'
import { useAbility } from 'src/hooks/useAbility'
import { ImageUploaderFieldUI } from 'src/ui/form/ImageUploaderFieldUI'

const formInitials = {
  template: 'template_1',
  encoding_type: 'qr_code',
}

export const CreateCouponModal = props => {
  const { onClose, handleCreate, trigger, setCurrentItem } = props
  const {
    engagement: { update: canUpdate },
  } = useAbility()

  const [logo, setLogo] = useState()
  const [logoPreview, setLogoPreview] = useState()
  const [background, setBackground] = useState()
  const [backgroundPreview, setBackgroundPreview] = useState()
  const [activeTemplate, setActiveTemplate] = useState()
  const [buttonFontColor, setButtonFontColor] = useState()
  const [buttonBgColor, setButtonBgColor] = useState()

  const [initialValues, setInitialValues] = useState(formInitials)

  const hasLogo = !/template_2|template_4/.test(activeTemplate)
  const hasEncodingType = /template_3|template_4/.test(activeTemplate)
  const hasIdentifier = /template_3|template_4/.test(activeTemplate)
  const hasButton = /template_5/.test(activeTemplate)

  useEffect(() => {
    if (!hasLogo) {
      setLogoPreview(false)
      setLogo('')
    }
  }, [activeTemplate, hasLogo])

  useEffect(() => {
    if (trigger) {
      const activity = trigger.activity || trigger.activity_attributes
      const coupon = activity.coupon || activity.coupon_attributes

      const editTrigger = {
        id: trigger.id,
        action_name: activity.name,
        event_type: trigger.event_type,
        template: coupon.template,
        name: coupon.name,
        title: coupon.title,
        description: coupon.description,
        encoding_type: coupon.encoding_type,
        unique_identifier_number: coupon.unique_identifier_number,
        identifier_number: coupon.identifier_number,
        button_label: coupon.button_label,
        button_link: coupon.button_link,
        logo: coupon.logo,
        image: coupon.image,
      }
      setInitialValues(editTrigger)
      if (coupon.logo && !coupon.logo.url) {
        setLogo(coupon.logo)
      }
      if (coupon.image && !coupon.image.url) {
        setBackground(coupon.image)
      }

      setBackgroundPreview(coupon.imagePreview || coupon.image?.small)

      setLogoPreview(coupon.logoPreview || coupon.logo?.url)

      if (coupon.button_font_color) {
        setButtonFontColor(coupon.button_font_color)
      }
      if (coupon.button_background_color) {
        setButtonBgColor(coupon.button_background_color)
      }
    }
  }, [trigger])

  async function onDrop(file, updateProgressCallback, completeCallback) {
    const upload = await createUpload(
      file,
      updateProgressCallback,
      completeCallback
    )

    return upload.signed_id
  }

  async function onLogoDrop(file, updateProgressCallback, completeCallback) {
    const result = await onDrop(file, updateProgressCallback, completeCallback)
    setLogo(result)
  }

  async function onBackgroundDrop(
    file,
    updateProgressCallback,
    completeCallback
  ) {
    const result = await onDrop(file, updateProgressCallback, completeCallback)
    setBackground(result)
  }

  function onLogoRemove() {
    setLogo('')
    setLogoPreview(false)
  }

  function onBackgroundRemove() {
    setBackground('')
    setBackgroundPreview(false)
  }

  const handleSave = async values => {
    if (!canUpdate) return

    if (/template_5/.test(values.template)) {
      values.button_font_color = buttonFontColor || '#ffffff'
      values.button_background_color = buttonBgColor || '#5468ff'
    }

    const newTrigger = {
      id: values.id,
      event_type: values.event_type,
      activity_attributes: {
        name: values.action_name,
        scheme: 'coupon',
        coupon_attributes: {
          template: values.template,
          name: values.name,
          title: values.title,
          description: values.description,
          encoding_type: values.encoding_type,

          logoPreview,

          imagePreview: backgroundPreview,
          identifier_number: values.identifier_number,

          unique_identifier_number: values.unique_identifier_number,
          button_label: values.button_label,
          button_link: values.button_link,
          button_font_color: values.button_font_color,
          button_background_color: values.button_background_color,
        },
      },
    }

    if (logo) {
      newTrigger.activity_attributes.coupon_attributes.logo = logo
    }

    if (/template_2|template_4/.test(values.template)) {
      delete newTrigger.activity_attributes.coupon_attributes.logo
    }

    if (background) {
      newTrigger.activity_attributes.coupon_attributes.image = background
    }

    await setCurrentItem(newTrigger)
    handleCreate(newTrigger)
  }

  const validateForm = values => {
    setActiveTemplate(values.template)
    const errors = {}
    if (!values.name) {
      errors.name = 'Name Required'
    }
    if (!values.title) {
      errors.title = 'Title Required'
    }
    if (!values.action_name) {
      errors.action_name = 'Action name Required'
    }

    if (/template_3|template_4/.test(values.template)) {
      if (!values.unique_identifier_number) {
        errors.unique_identifier_number = 'Unique identifier number Required'
      }
      if (!values.identifier_number) {
        errors.identifier_number = 'Identifier number Required'
      }
    }
    if (/template_5/.test(values.template)) {
      if (!values.button_label) {
        errors.button_label = 'Button Label required'
      }

      if (!values.button_link) {
        errors.button_link = 'Button Label required'
      }
    }

    const activity = trigger?.activity || trigger?.activity_attributes
    const coupon = activity?.coupon || activity?.coupon_attributes

    if (
      !/template_2|template_4/.test(values.template) &&
      !coupon?.logo &&
      !logo
    ) {
      errors.logo = 'Logo required'
    }

    if (!background && !coupon?.image) {
      errors.background = 'Background required'
    }

    return errors
  }

  const handleChangeButtonColor = color => {
    setButtonFontColor(color)
  }
  const handleChangeButtonBgColor = color => {
    setButtonBgColor(color)
  }

  return (
    <Modal onClose={() => onClose()}>
      <ModalContainer>
        <HeaderTitle>Create Coupon</HeaderTitle>
        <Form
          onSubmit={handleSave}
          initialValues={initialValues}
          validate={validateForm}
          render={({ handleSubmit, values, errors }) => (
            <form onSubmit={handleSubmit} style={{ textAlign: 'center' }}>
              <FormColumns>
                <Grid>
                  <Column>
                    <TextField
                      fullWidth
                      label="Name"
                      name="name"
                      placeholder="Offer"
                      maxLength={12}
                    />
                    <TextField
                      fullWidth
                      label="Title"
                      name="title"
                      placeholder="50% Discount"
                      maxLength={50}
                    />
                    <TextField
                      fullWidth
                      label="Description"
                      name="description"
                      placeholder="Enjoy 50% discount during the fashion week from Zara and Mango shops"
                      textarea
                      type="textarea"
                    />
                    <TextField
                      fullWidth
                      label="Action name"
                      name="action_name"
                      placeholder="Coupon Testing"
                      maxLength={255}
                    />
                    <ImageUploaderFieldUI
                      name="background"
                      label="Background Image"
                      error={errors.background}
                      uploaderProps={{
                        photo: background,
                        onDrop: onBackgroundDrop,
                        onRemove: onBackgroundRemove,
                        onUploadedPhoto: setBackgroundPreview,
                      }}
                    />
                  </Column>
                  <Column>
                    <SelectField
                      fullWidth
                      isMulti={false}
                      isSearchable={false}
                      options={templateOptions}
                      label="Template"
                      name="template"
                    />
                    {hasEncodingType && (
                      <SelectField
                        fullWidth
                        isMulti={false}
                        isSearchable={false}
                        options={[
                          { label: 'Qr code', value: 'qr_code' },
                          { label: 'Bar code', value: 'code_128' },
                        ]}
                        label="Encoding type"
                        name="encoding_type"
                      />
                    )}
                    {hasLogo && (
                      <ImageUploaderFieldUI
                        name="logo"
                        label="Logo"
                        error={errors.logo}
                        uploaderProps={{
                          photo: logo,
                          onDrop: onLogoDrop,
                          onRemove: onLogoRemove,
                          onUploadedPhoto: setLogoPreview,
                        }}
                      />
                    )}
                    {hasIdentifier && (
                      <>
                        <TextField
                          fullWidth
                          label="Unique identifier number"
                          name="unique_identifier_number"
                          placeholder="1wj23e"
                          maxLength={255}
                        />
                        <TextField
                          fullWidth
                          label="Identifier number"
                          name="identifier_number"
                          placeholder="32w2ql"
                          maxLength={255}
                        />
                      </>
                    )}
                    {hasButton && (
                      <>
                        <TextField
                          fullWidth
                          label="Button label"
                          name="button_label"
                          placeholder="Press me"
                          maxLength={30}
                        />
                        <TextField
                          fullWidth
                          label="Button link"
                          name="button_link"
                          placeholder="https://link.me"
                        />
                        <FormField>
                          <span>Button Font Color</span>
                          <ColorPicker
                            onChange={handleChangeButtonColor}
                            color={values.button_font_color || buttonFontColor}
                          />
                        </FormField>
                        <FormField>
                          <span>Button Background Color</span>
                          <ColorPicker
                            onChange={handleChangeButtonBgColor}
                            color={
                              values.button_background_color ||
                              buttonBgColor ||
                              '#5468ff'
                            }
                          />
                        </FormField>
                      </>
                    )}
                  </Column>
                </Grid>

                <PhoneContainer>
                  <Phone>
                    <PhoneImageComponentStyled />
                    <Rows>
                      <Row>
                        <Background url={backgroundPreview} />
                        {hasLogo && <Logo url={logoPreview} />}
                        {values.name && <Name>{values.name}</Name>}
                      </Row>
                      <RowDetails>
                        {values.title && <Title>{values.title}</Title>}
                        {values.description && (
                          <Description>{values.description}</Description>
                        )}
                        {/template_5/.test(values.template) && (
                          <StyledButton
                            href={values.button_link}
                            background={
                              values.button_background_color || buttonBgColor
                            }
                            color={values.button_font_color || buttonFontColor}
                          >
                            {values.button_label}
                          </StyledButton>
                        )}
                      </RowDetails>
                    </Rows>
                  </Phone>
                </PhoneContainer>
              </FormColumns>
              <FormSpy
                subscription={{
                  submitting: true,
                  pristine: true,
                  valid: true,
                  dirtySinceLastSubmit: true,
                  hasValidationErrors: true,
                  submitErrors: true,
                  error: true,
                  errors: true,
                  submitFailed: true,
                }}
              >
                {({ submitErrors }) => {
                  const baseError = submitErrors?.message

                  return (
                    <>
                      {!!baseError && (
                        <Alert type="error" title={t(baseError)} />
                      )}
                      <ActionWrapper>
                        <Cancel
                          color="secondary"
                          size="lg"
                          onClick={() => onClose()}
                        >
                          {t('Cancel')}
                        </Cancel>

                        <Button
                          disabled={!canUpdate}
                          color="primary"
                          size="lg"
                          onClick={handleSubmit}
                        >
                          {t('Next')}
                        </Button>
                      </ActionWrapper>
                    </>
                  )
                }}
              </FormSpy>
            </form>
          )}
        />
      </ModalContainer>
    </Modal>
  )
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`
const FormColumns = styled(Grid)`
  grid-template-columns: 1.1fr 1fr;
`

const Rows = styled.div`
  display: grid;
  position: relative;
  grid-template-rows: 200px 1fr;
  height: 100%;
  padding: 20px;
`

const Row = styled.div`
  position: relative;
`
const RowDetails = styled.div`
  padding-right: 5px;
`

const Column = styled.div`
  padding-right: 30px;
`

const HeaderTitle = styled.h1`
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.5px;
  color: #344356;
  margin: 0 20px 20px 0;
`

const ModalContainer = styled.div`
  padding: 30px;
  background-color: white;
  border-radius: 5px;
  font-family: 'Biko';
  textarea,
  select,
  input {
    width: 280px;
    padding-left: 0;
    font-size: 12px;
    line-height: 12px;
    border: none;
    border-bottom: 1px solid #e2ebffcf;
    border-radius: 0;
    &:focus {
      outline: none;
      border-bottom-color: #5468ff;
    }
  }
  label {
    margin-bottom: 0;
    margin-left: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    color: #344356;
  }
`

const StyledButton = styled.a`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0 10px;
  height: 34px;
  width: 168px;
  font-size: 13px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  text-decoration: none;
  background-color: #5468ff;
  color: white;

  ${props =>
    props.background &&
    `
      background-color: ${props.background};
  `}
  ${props =>
    props.color &&
    ` 
     color: ${props.color};
  `}
`

const ActionWrapper = styled.div`
  display: flex;
  margin-top: 20px;
`

const PhoneContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
`
const PhoneImageComponentStyled = styled(PhoneImageComponent)`
  position: absolute;
  left: 0;
`
const Phone = styled.div`
  position: relative;
  width: 285.96px;
  height: 576.05px;
`
const Background = styled.div`
  position: absolute;
  bottom: 0;
  height: 90%;
  width: 100%;
  background-color: #e2ebff;
  background-size: cover;
  background-position: center;
  ${props =>
    props.url &&
    `
      background-image: url("${props.url}") !important;
      background-color: transparent !important;
  `}
`
const Logo = styled(Background)`
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 60px;
  height: 60px;
  background-color: whitesmoke;
`

const Title = styled.div`
  position: relative;
  margin-top: 20px;
  text-align: right;
  font-weight: bold;
  font-size: 24px;
  word-break: break-word;
`
const Description = styled.div`
  position: relative;
  text-align: right;
  line-height: 23px;
  margin-top: 15px;
  max-height: 210px;
  overflow: auto;
  word-break: break-word;
`
const Name = styled.div`
  position: absolute;
  bottom: 25px;
  left: 70px;
  font-size: 24px;
  font-weight: bold;
  color: white;
  text-shadow: 0 0 11px #9aaccf;
  -webkit-text-stroke: 1px black;
  word-break: break-word;
`

const FormField = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    color: #344356;
  }
`
const Cancel = styled(Button)`
  margin-right: 18px;
`

const Alert = styled(AlertComponent)`
  background: white;
  > div:first-of-type {
    background: white;
    color: #ff6355;
  }
`
