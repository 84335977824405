export function reduceRoutes(routes, routeGeojson) {
  const neighbors = routes.reduce((hash, point) => {
    hash[point.point_id] = JSON.parse(JSON.stringify(point.neighbors))

    return hash
  }, {})

  const multiLine = routeGeojson?.features?.find(
    item => item.geometry.type === 'MultiLineString'
  )

  // get lines with 1 connection
  let lineIndex = 0

  const updateGeojson = (currentPoint, startPointId) => {
    const hasThisPoint = multiLine.properties.msNeighbors.findIndex(
      neighborsItem =>
        neighborsItem.findIndex(
          item => item.point === currentPoint.point_id
        ) !== -1 &&
        neighborsItem.findIndex(item => item.point === startPointId) !== -1
    )

    //  check on duplicated lines/Neighbors
    if (hasThisPoint !== -1) {
      return false
    }

    multiLine.properties.msNeighbors.push([
      {
        line: lineIndex,
        point: startPointId,
      },
      {
        line: lineIndex,
        point: currentPoint.point_id,
      },
    ])

    lineIndex++

    const secondPoint = routes.find(point => point.point_id === startPointId)

    const lineCoordinates = [
      [secondPoint.longitude, secondPoint.latitude],
      [currentPoint.longitude, currentPoint.latitude],
    ]

    multiLine.geometry.coordinates.push(lineCoordinates)
  }

  // Filter points where we have neighbors (means this is possible lines)
  const withNeighbors = {}
  Object.keys(neighbors).forEach(pointC => {
    if (neighbors[pointC].length) {
      withNeighbors[pointC] = neighbors[pointC]
    }
  })

  const multiLinePoints = {}

  Object.keys(withNeighbors).forEach(startPointId => {
    const endLinePoints = withNeighbors[startPointId]

    endLinePoints.forEach(endPointId => {
      const startEndPoint = `${endPointId}${startPointId}`
      const endStartPoint = `${startPointId}${endPointId}`

      if (!multiLinePoints[startEndPoint] && !multiLinePoints[endStartPoint]) {
        multiLinePoints[startEndPoint] = [endPointId, startPointId]
        const currentPoint = routes.find(point => point.point_id === endPointId)
        updateGeojson(currentPoint, startPointId)
      }
    })
  })


  routes.forEach(point => {
    routeGeojson.features.push({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [point.longitude, point.latitude],
      },
      properties: {
        id: point.point_id,
        kind: point.kind,
        connecting: point.neighbors?.length > 1,
        hasNeighbors: Boolean(point.neighbors?.length),
        neighbors: point.neighbors,

        pointCoordinates: [point.longitude, point.latitude].toString() + point.point_id,
      },
    })
  })

  return routeGeojson
}
