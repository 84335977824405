import React, {
  useCallback,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import StaticMode from '@mapbox/mapbox-gl-draw-static-mode'

const MapContext = createContext()
let mapInitiated = false

export function MapProvider(props) {
  const [map, setMap] = useState()
  const [mapDraw, setMapDraw] = useState()
  const [mapZoom, setMapZoom] = useState(0)
  const [isMapLoaded, setIsMapLoaded] = useState(false)
  const [mapContainer, setMapContainer] = useState()
  const [geocodingClient, setGeocodingClient] = useState()

  const [search, setSearch] = useState(null)
  const [searchElement, setSearchElement] = useState(null)

  const [showMarkers, setShowMarkers] = useState([])
  const [showSearchMarker, setShowSearchMarker] = useState(false)

  const [address, setAddress] = useState('')
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [navigation] = useState(new window.mapboxgl.NavigationControl())
  const [mapFullscreen] = useState(new window.mapboxgl.FullscreenControl())
  const [mapStyle] = useState('phpmax/cjgau24wv95cc2sqrbm0ugfak')

  const [defaultCenter, setDefaultCenter] = useState()

  useEffect(() => {
    if (mapInitiated || !mapContainer?.current) return

    mapInitiated = true

    const currentMap = new window.mapboxgl.Map({
      container: mapContainer.current,
      maxZoom: 25,
      pitch: 30,
      zoom: 2,
      style: `mapbox://styles/${mapStyle}`,
      language: 'en-EN',
    })

    currentMap.easeTo({
      padding: { left: 100, right: 100, top: 100, bottom: 100 },
    })
    currentMap.on('load', () => {
      const client = new MapboxGeocoder({
        accessToken: window.mapboxgl.accessToken,
        mapboxgl: window.mapboxgl,
        language: 'en-EN',
      })

      const { modes } = MapboxDraw
      modes.static = StaticMode

      // mapDraw is initially exist only in develop mode with auto page reload
      const draw = !mapDraw
        ? new MapboxDraw({
            displayControlsDefault: false,
            modes: {
              ...modes,
            },
            userProperties: true,
            clickBuffer: 10,
            touchBuffer: 10,
          })
        : mapDraw

      currentMap.addControl(draw)
      draw.changeMode('static', {})
      currentMap.addControl(mapFullscreen, 'bottom-right')

      currentMap.addControl(navigation, 'bottom-right')

      setSearchElement(client.onAdd(currentMap))
      setGeocodingClient(client)
      setMap(currentMap)
      setMapDraw(draw)
      setIsMapLoaded(true)
    })

    currentMap.on('zoom', ({ target }) =>
      setMapZoom(target.getZoom().toFixed(0))
    )

    return () => {
      mapInitiated = false
      currentMap.off('load')
      currentMap.off('zoom')
      currentMap.remove()
      setMap(null)
    }
  }, [mapContainer])

  const flyTo = useCallback(
    (coords, options = {}) => {
      if (defaultCenter) return false

      if (coords.lng && coords.lat) {
        map.flyTo({
          center: [coords.lng, coords.lat],
          zoom: options.zoom || 16,
          speed: 2, // speed of flying
          curve: 1, // curve of zoom
        })
      }
    },
    [map, defaultCenter]
  )

  const contextValue = {
    map,
    navigation,
    mapFullscreen,
    mapDraw,
    mapZoom,
    mapContainer,
    mapStyle,
    setMapContainer,
    isMapLoaded,

    setGeocodingClient,
    geocodingClient,

    setSearch,
    search,
    searchElement,

    setShowSearchMarker,
    showSearchMarker,
    setShowMarkers,
    showMarkers,

    setAddress,
    address,

    setLatitude,
    latitude,
    setLongitude,
    longitude,

    flyTo,
    defaultCenter,
    setDefaultCenter,
  }

  return (
    <MapContext.Provider value={contextValue}>
      {props.children}
    </MapContext.Provider>
  )
}

export function useMap() {
  return useContext(MapContext)
}
