import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import Select from 'react-select'
import { toast } from 'react-toastify'
import styled from 'styled-components/macro'

import { Modal } from 'src/components/Modal'

import { Button, Loader } from 'src/ui'
import { Table, TBodyTR, TD, TH } from 'src/components/DataTable'
import {
  Actions,
  ActionWrapper,
} from 'src/components/BeaconsImports/AddBeaconsImportsModal'
import { Alert } from 'src/components/Alert'

import { customSelectStyles } from 'src/ui/form/SelectInput'

const headersOptions = [
  {
    label: 'Floor ID',
    value: 'building_floor_id',
  },
  {
    label: 'Zone ID',
    value: 'zone_id',
  },
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'UUID',
    value: 'uuid',
  },
  {
    label: 'Major',
    value: 'major',
  },
  {
    label: 'Minor',
    value: 'minor',
  },
  {
    label: 'Latitude',
    value: 'latitude',
  },
  {
    label: 'Longitude',
    value: 'longitude',
  },
]

export const BeaconsImportModal = ({
  onClose,
  beaconsImportHook,
  beaconsImport,
  isBeaconsImportsOpen,
}) => {
  const { beaconsImportId } = useParams()

  const {
    getImport,
    update: updateImport,
    isLoading: isImportLoading,
  } = beaconsImportHook

  const [isLoaded, setIsLoaded] = useState(false)
  const [currentImport, setCurrentImport] = useState()
  const [hasDuplicates, setHasDuplicates] = useState()
  const [hasHeaders, setHasHeaders] = useState()
  const [hasAllMandatorySelected, setHasAllMandatorySelected] = useState(false)

  const [statusInterval, setStatusInterval] = useState(false)

  const [importStatus, setImportStatus] = useState()
  const [sourceMap, setSourceMap] = useState([])

  const handleNext = async () => {
    const updatedImport = {}

    updatedImport.headers = hasHeaders
    updatedImport.sourcemap = {}

    sourceMap.forEach((key, index) => {
      updatedImport.sourcemap[key] = index
    })

    const request = await updateImport(beaconsImportId, updatedImport)

    if (request) await requestImport()
  }

  const requestImport = useCallback(async () => {
    const request = await getImport(beaconsImportId)
    if (request?.ok && request?.json) {
      setImportStatus(request.json.beacon_import.status)
      setCurrentImport(request.json.beacon_import)
    } else {
      toast.error(
        request?.json?.error?.message ||
          'Something went wrong try again or reload the page.'
      )
    }
  }, [beaconsImportId, getImport])

  const cleanStatusInterval = useCallback(() => {
    if (importStatus && /complete|parsed|failed|pending/.test(importStatus)) {
      setIsLoaded(true)
      clearInterval(statusInterval)
      setStatusInterval(false)
    }
  }, [importStatus, statusInterval])

  const checkImportStatus = useCallback(() => {
    if (isBeaconsImportsOpen) {
      if (
        /created|preparing|parsing|processing/.test(importStatus) &&
        !statusInterval
      ) {
        const interval = setInterval(async () => {
          cleanStatusInterval()
          await requestImport()
        }, 1500)
        setStatusInterval(interval)
      }
      cleanStatusInterval()
    }
  }, [
    cleanStatusInterval,
    importStatus,
    isBeaconsImportsOpen,
    requestImport,
    statusInterval,
  ])

  useEffect(() => {
    checkImportStatus()
  }, [checkImportStatus, importStatus])

  const loadBeaconsImport = useCallback(async () => {
    if (beaconsImport && isBeaconsImportsOpen) {
      setCurrentImport(beaconsImport)
      setImportStatus(beaconsImport.status)
    }
  }, [isBeaconsImportsOpen, beaconsImport])

  useEffect(() => {
    loadBeaconsImport()
  }, [beaconsImport, loadBeaconsImport])

  const clearStatus = useCallback(() => {
    clearInterval(statusInterval)
  }, [statusInterval])

  useEffect(() => {
    if (!isBeaconsImportsOpen) {
      clearStatus()
    }
  }, [clearStatus, isBeaconsImportsOpen])

  const isHeadersRequired = Boolean(
    /parsed|pending/.test(importStatus) &&
      +beaconsImportId === currentImport?.id
  )

  const handleHasHeaders = () => {
    setHasHeaders(!hasHeaders)
  }

  const handleSourceMapSelect = (key, index) => {
    sourceMap[index] = key
    const duplicates = sourceMap.filter(
      item => sourceMap.filter(subItem => subItem === item)?.length > 1
    )

    if (duplicates.length > 0) {
      setHasDuplicates(true)
    } else {
      setHasDuplicates(false)
      setSourceMap(false)
      setSourceMap(sourceMap)
    }
    setHasAllMandatorySelected(
      sourceMap?.filter(source => /name|uuid|major|minor/.test(source))
        .length === 4
    )
  }

  const isInProcess = /preparing|queued|processing|pending|parsing/.test(
    importStatus
  )

  const isComplete = /complete/.test(importStatus)

  const hasErrors = Boolean(isComplete && currentImport?.import_errors?.length)

  const sortedHeaders =
    isComplete &&
    currentImport?.sourcemap &&
    Object.entries(currentImport?.sourcemap)
      .sort(([, a], [, b]) => a - b)
      .map(item => item[0].charAt(0).toUpperCase() + item[0].slice(1))

  const saveDisabled = useMemo(
    () => hasDuplicates || !hasAllMandatorySelected,
    [hasAllMandatorySelected, hasDuplicates]
  )

  if (!isBeaconsImportsOpen) {
    return false
  }

  return (
    <Modal onClose={onClose}>
      <ModalContainer>
        <AddABeacon>Beacons Import</AddABeacon>
        <Proceed>
          The import is{' '}
          {importStatus === 'complete' ? 'complete successfully' : importStatus}
        </Proceed>
        <Content>
          {(!isLoaded || isInProcess || isImportLoading) && (
            <Loader backdrop={false} />
          )}
          {isHeadersRequired && currentImport.first_rows?.length && (
            <>
              <Label>
                <input
                  type="checkbox"
                  defaultChecked={hasHeaders}
                  onChange={handleHasHeaders}
                />
                Does the file contains the header?
              </Label>

              <TableStyled>
                <table>
                  {hasHeaders && (
                    <thead>
                      <tr>
                        {currentImport.first_rows[0]?.map((item, index) => (
                          <TH key={`header-${item + index}`}>{item}</TH>
                        ))}
                      </tr>
                    </thead>
                  )}

                  <tbody>
                    <TBodyTR>
                      {currentImport.first_rows[1]?.map((item, index) => (
                        <TD key={`cell-${item + index}`}>{item}</TD>
                      ))}
                    </TBodyTR>
                    {!isComplete && (
                      <TBodyTR>
                        {currentImport.first_rows[1]?.map((item, index) => (
                          <TD key={`select-${item + index}`}>
                            <Select
                              styles={customSelectStyles}
                              options={headersOptions}
                              onChange={selected =>
                                handleSourceMapSelect(selected.value, index)
                              }
                            >
                              {item}
                            </Select>
                          </TD>
                        ))}
                      </TBodyTR>
                    )}
                  </tbody>
                </table>
              </TableStyled>
              <p>*Mandatory fields are: Name, UUID, Minor, Major</p>
            </>
          )}
          {hasDuplicates && (
            <AlertStyled
              type="error"
              title="You have selected identical columns name"
            />
          )}
          {hasErrors && (
            <>
              <TableStyled>
                <table>
                  {isComplete && (
                    <thead>
                      <tr>
                        {sortedHeaders.map(header => (
                          <TH>{header}</TH>
                        ))}
                      </tr>
                    </thead>
                  )}

                  <tbody>
                    <TBodyTR>
                      {currentImport.first_rows[1]?.map((item, index) => (
                        <TD key={`cell-${item + index}`}>{item}</TD>
                      ))}
                    </TBodyTR>
                    {!isComplete && (
                      <TBodyTR>
                        {currentImport.first_rows[1]?.map((item, index) => (
                          <TD key={`select-${item + index}`}>
                            <Select
                              styles={customSelectStyles}
                              options={headersOptions}
                              onChange={selected =>
                                handleSourceMapSelect(selected.value, index)
                              }
                            >
                              {item}
                            </Select>
                          </TD>
                        ))}
                      </TBodyTR>
                    )}
                  </tbody>
                </table>
              </TableStyled>
            </>
          )}
          {hasErrors && (
            <div>
              <TableStyled>
                <table>
                  <thead>
                    <tr>
                      <TH>Line in CSV</TH>
                      <TH>Errors details</TH>
                    </tr>
                  </thead>
                  <tbody>
                    {currentImport.import_errors.map(error => {
                      return (
                        <TBodyTR key={error.id}>
                          <TD>{error.row + 1}</TD>
                          {Object.keys(error.messages).map(key => {
                            return (
                              <TD key={key + error.id}>
                                <strong>{key}</strong>
                                {error.messages[key].map(item => (
                                  <div>{item}</div>
                                ))}
                              </TD>
                            )
                          })}
                        </TBodyTR>
                      )
                    })}
                  </tbody>
                </table>
              </TableStyled>
            </div>
          )}
        </Content>
        <ActionWrapper>
          <Actions>
            <Button color="secondary" bold size="lg" onClick={onClose}>
              {t('Close')}
            </Button>
            {isHeadersRequired && (
              <Button
                color="primary"
                bold
                size="lg"
                onClick={handleNext}
                disabled={saveDisabled}
              >
                {t('Save Import')}
              </Button>
            )}
          </Actions>
        </ActionWrapper>
      </ModalContainer>
    </Modal>
  )
}

const Content = styled.div`
  position: relative;
  min-height: 100px;
  width: 100%;
`
const ModalContainer = styled.div`
  position: relative;
  padding: 30px;
  background-color: white;
  border-radius: 5px;
  min-width: 80vw;
`
const AddABeacon = styled.p`
  padding: 0;
  font-size: 18px;
  line-height: 14px;
  font-weight: 600;
  color: #344356;
`

const TableStyled = styled(Table)`
  height: max-content;
  tr th {
    text-align: left;
  }
`
const AlertStyled = styled(Alert)`
  margin-top: 16px;
`

const Proceed = styled.p`
  padding: 0;
  font-size: 14px;
  line-height: 14px;
`

const Label = styled.label`
  cursor: pointer;
  input {
    margin-right: 10px;
  }
`
