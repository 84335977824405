import { useCallback, useState } from 'react'

import { api } from 'src/modules/api'

export function usePlans() {
  const [isLoading, setIsLoading] = useState(true)
  const [plans, setPlans] = useState([])

  const get = useCallback(async () => {
    setIsLoading(true)
    const res = await api.get('plans')
    res.ok && setPlans(res.json.plans)
    setIsLoading(false)

    return res
  }, [])

  return {
    isLoading,
    get,
    plans,
  }
}
