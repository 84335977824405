import { useCallback } from 'react'
import { useMapStudio } from 'src/pages/MapStudio/controllers/MapStudioController'

export const UseVirtualNeighbors = () => {
const {
  currentRoute,
  activeTool,
  setCurrentRoute
} = useMapStudio()


  const linkExistedLineEndPoint = useCallback(props => {
    const { currentPoint, lastPoint } = props

    const isTwoWay = activeTool.value === 'twoWay'

    const updatedNeighbors = currentRoute.virtualNeighbors.map(point => {
      const currentPointId = currentPoint.properties.id
      const lastPointId = lastPoint.id

      if (!lastPointId) {
        console.log(
          'something happens (missed second point neighbor): ',
          lastPoint,
          currentPoint,
        )
      }
      if (!isTwoWay) {
        if (
          point.point_id === currentPointId
        ) {
          console.log(
            `1 Clean point: ${point.point_id} from the neighbors`,
          )

          return {
            ...point,

            neighbors: point.neighbors.filter(item => item !== lastPointId),
          }
        }

        if (point.point_id === lastPointId && !point.neighbors?.includes(currentPointId)) {
          console.log(
            `The start point: ${point.point_id} has the new neighbor ${currentPointId}`,
          )

          return {
            ...point,
            neighbors: [...point.neighbors, currentPointId],
          }
        }
      } else if (isTwoWay) {
        if (currentPointId === point.point_id) {
          console.log(
            `2 The point: ${point.point_id} has the new neighbor ${lastPointId}`,
          )

          return {
            ...point,
            neighbors: [...point.neighbors, lastPointId],
          }
        }

        if (
          lastPointId === point.point_id &&
          !point.neighbors.includes(currentPointId)
        ) {
          console.log(
            `3 The point: ${point.point_id} has the new neighbor ${currentPointId}`,
          )

          return {
            ...point,
            neighbors: [...point.neighbors, currentPointId],
          }
        }
      }

      return point
    })
    currentRoute.virtualNeighbors = [...updatedNeighbors]
    setCurrentRoute({...currentRoute})
  }, [activeTool.value, currentRoute])

  const linkVirtualEndLinePoint = useCallback(props => {
    const { lastPoint, currentMsNeighbors } = props
    const isTwoWay = activeTool.value === 'twoWay'
    const lastPointId = lastPoint.id;

    const updatedPoints = currentRoute.virtualNeighbors.map(point => {
      const startPointId = currentMsNeighbors[0].point
      const endPointId = currentMsNeighbors[1]?.point

      if (!endPointId) {
        console.log(
          'something happens (missed second point neighbor): ',
          lastPoint,
          currentMsNeighbors,
        )
      }

      // Added neighbor for the first point
      if (startPointId === point.point_id && !point.neighbors?.includes(lastPointId)) {
        console.log(
          `4 The point: ${point.point_id} has the new neighbor ${lastPointId}`,
        )

        return {
          ...point,
          neighbors: [...point.neighbors, lastPointId],
        }
      }

      if (isTwoWay) {
        if (startPointId === point.point_id) {
          console.log(
            `The point: ${point.point_id} has the new neighbor ${lastPointId}`,
          )

          return {
            ...point,
            neighbors: [...point.neighbors, lastPointId],
          }
        }

        if (
          endPointId === point.point_id &&
          !point.neighbors.includes(startPointId)
        ) {
          console.log(
            `5 The point: ${point.point_id} has the new neighbor ${startPointId}`,
          )

          return {
            ...point,
            neighbors: [...point.neighbors, startPointId],
          }
        }
      }

      return point
    })

    currentRoute.virtualNeighbors = [...updatedPoints]

    setCurrentRoute({...currentRoute})
    console.log('Virtual End Point linked, neighbors:', currentRoute.virtualNeighbors)
  }, [activeTool.value, currentRoute])

  const addNewVirtualPoint = useCallback(props => {
    const { currentPoint, currentMsNeighbors, lastPoint } = props
    const pointId = currentPoint.properties.id
    const isTwoWay = activeTool.value === 'twoWay'


    const neighbors = isTwoWay ? currentMsNeighbors
      .filter(item => item.point !== pointId)
      .map(item => item.point) : [];

    const newPoint = {
      point_id: pointId,
      kind:
        currentPoint.properties.kind ||
        currentPoint.geometry.type.toLocaleLowerCase(),
      latitude: currentPoint.geometry.coordinates[1],
      longitude: currentPoint.geometry.coordinates[0],
      neighbors,
    }

    currentRoute.virtualNeighbors = [...currentRoute.virtualNeighbors, newPoint]

    console.log('Neighbors before link two points', currentRoute.virtualNeighbors)

    if (currentMsNeighbors.length > 1) {
      console.table('Update neighbors for both points, start and end')
      linkVirtualEndLinePoint({
        lastPoint,
        currentRoute,
        currentMsNeighbors,
      })
    }
  }, [activeTool.value, currentRoute, linkVirtualEndLinePoint])

  const updateVirtualPointProperty = useCallback(props => {
    const { pointId, valueToUpdate, propertyName } = props

    const updatedPoints = currentRoute.virtualNeighbors.map(point => {
      if (point.point_id === pointId) {
        return {
          ...point,
          [propertyName]: valueToUpdate,
        }
      }

      return point
    })

    currentRoute.virtualNeighbors = updatedPoints

    console.log(
      `The point: ${pointId} property ${propertyName} has been updated with ${valueToUpdate}`,
    )
  }, [currentRoute])





  const updateNeighborsAfterLineBreak = useCallback(props => {
    const {
      newLineIndex,
      dividedLineIndex,
      lastPoint,
      msNeighbors,
    } = props

    const startLineIndex = 0
    const endLineIndex = 1

    // check line direction
    const dividedLineStartPointId =
      msNeighbors[dividedLineIndex][startLineIndex].point

    const dividedLineEndPointId = msNeighbors[newLineIndex][endLineIndex].point

    const dividedPointId = lastPoint.id

    const dividedLineStartPoint = currentRoute.virtualNeighbors.find(
      point => point.point_id === dividedLineStartPointId,
    )
    const dividedLineEndPoint = currentRoute.virtualNeighbors.find(
      point => point.point_id === dividedLineEndPointId,
    )

    const isStartToEnd = dividedLineStartPoint.neighbors.includes(
      dividedLineEndPointId,
    )
    const isEndToStart = dividedLineEndPoint.neighbors.includes(
      dividedLineStartPointId,
    )

    const isTwoWay = isStartToEnd && isEndToStart
    const isTwoWayMode = activeTool.value === 'twoWay'

    let updatedVirtualNeighbors = []

    const pointFromSecondDividedLine =
      isTwoWayMode && msNeighbors?.[newLineIndex - 1]?.[startLineIndex]?.point

    console.log('Before update', currentRoute.virtualNeighbors)

    // update points for one/two way direction
    updatedVirtualNeighbors = currentRoute.virtualNeighbors.map(
      (point) => {

        if (
          ![
            dividedLineStartPointId,
            dividedLineEndPointId,
            dividedPointId,
          ].includes(point.point_id)
        ) {
          return point
        }

        const updatedPoint = { ...point }
        const endIndex = point.neighbors.indexOf(dividedLineEndPointId)
        const startIndex = point.neighbors.indexOf(dividedLineStartPointId)

        const hasStartPointFromSecondDividedLine = point.neighbors.indexOf(
          dividedPointId,
        )
        let pointNeighbors = new Array(...updatedPoint.neighbors)

        switch (point.point_id) {
          case dividedLineStartPointId:
            if (endIndex > -1) {
              // remove connection from old points
              pointNeighbors = pointNeighbors.filter((item, pointIndex) => pointIndex !== endIndex)
              // console.log('Removed from old points:', point.point_id, pointNeighbors.toString())
            }

            // add connection to old points
            if (isStartToEnd || isTwoWay) {
              pointNeighbors.push(dividedPointId)
              // console.log('Add connection to old points:', point.point_id, dividedPointId)
            }

            break
          // check the connection for final point
          case dividedPointId:
            // console.log(`Line: ${point.point_id} connected with ${dividedLineEndPointId}`)

            // line has one way direction
            pointNeighbors.push(dividedLineEndPointId)

            if (isTwoWay) {
              pointNeighbors.push(dividedLineStartPointId)
              // console.log(`Line: ${point.point_id} connected with ${dividedLineStartPoint}`);
            }

            break
          case dividedLineEndPointId:
            if (startIndex > -1) {
              // remove connection from old points
              pointNeighbors.splice(startIndex, 1)
            }

            // add connection to old points
            if (isEndToStart || isTwoWay) {
              pointNeighbors.push(dividedPointId)
            }
            break
          case pointFromSecondDividedLine:
            // connection for the start divided point
            if (hasStartPointFromSecondDividedLine === -1 && isTwoWayMode) {
              pointNeighbors.push(dividedPointId)
            }
            break
          default:
            break
        }
        updatedPoint.neighbors = [...pointNeighbors]

        return updatedPoint
      },
    )

    currentRoute.virtualNeighbors = [...updatedVirtualNeighbors]

    setCurrentRoute({...currentRoute})
    console.log('Line Update FINAL:', currentRoute.virtualNeighbors);
  }, [activeTool.value, currentRoute])

  const cleanUpEmptyNeighbors = props => {
    const { pointId, filtered } = props
    const readyForRemove = !filtered.some(point =>
      point.neighbors.includes(pointId),
    )
    if (readyForRemove) {
      removePoints({ pointId, currentRoute, filtered, forced: true })
    }
  }

  const cleanUpEmptyPoints = props => {
    const { filtered } = props

    if (!filtered.length) {
      return filtered
    }

    let pointsToRemove = []
    filtered.forEach(point => {
      if (!point.neighbors?.length) {
        const readyForRemove = !filtered.some(item =>
          item.neighbors.includes(point.point_id),
        )

        if (readyForRemove) {
          pointsToRemove.push(point.point_id)
        }
      }
    })

    let filteredLast = []
    if (pointsToRemove.length) {
      pointsToRemove.forEach(pointId => {
        filteredLast = removePoints({
          pointId,
          currentRoute,
          filtered: filteredLast.length ? filteredLast : filtered,
        })
      })

      // console.log('clean repeated', filteredLast)

      cleanUpEmptyPoints({
        filtered: filteredLast,
        currentRoute,
      })
      pointsToRemove = []

      return filteredLast
    }

    return filtered
  }

  const removePoints = props => {
    const { pointId, forced, filtered } = props
    let newNeighbors = filtered.filter(item => item.point_id !== pointId)

    console.log(`Removed point: ${pointId}`)

    if (!forced) {
      newNeighbors = newNeighbors.map((item) => {
        const point = { ...item }
        const pointIndex = point.neighbors.indexOf(pointId)
        if (pointIndex > -1) {
          const newPointNeighbors = new Array(...point.neighbors)
          newPointNeighbors.splice(pointIndex, 1)

          point.neighbors = newPointNeighbors

          if (!point.neighbors.length) {
            cleanUpEmptyNeighbors({
              pointId: point.point_id,
              filtered: newNeighbors,
              currentRoute,
            })
          }
        }

        return point
      })
    }

    return newNeighbors
  }

  const removeVirtualPoint = useCallback(props => {
    const { pointId } = props

    const pointIsExist = currentRoute.virtualNeighbors.some(
      point => point.point_id === pointId,
    )

    if (!pointIsExist) {
      return
    }

    const filtered = removePoints({
      ...props,
      filtered: currentRoute.virtualNeighbors,
    })

    const cleanedPoints = cleanUpEmptyPoints({ filtered, currentRoute })

    const finalFiltered = [
      ...(cleanedPoints.length < filtered.length ? cleanedPoints : filtered),
    ]

    currentRoute.virtualNeighbors = finalFiltered
  }, [cleanUpEmptyPoints, currentRoute, removePoints])

  return {
    addNewVirtualPoint,
    removeVirtualPoint,
    linkVirtualEndLinePoint,
    updateVirtualPointProperty,
    updateNeighborsAfterLineBreak,
    linkExistedLineEndPoint,
  }
}

export default UseVirtualNeighbors
