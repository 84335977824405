// Where the indoor layers will be inserted.

const beforeLayerId = 'marine-label-sm-ln'

export const renderFloorImage = (
  floor,
  currentMap,
  floorAnglesMarkers,
  setFloorAnglesMarkers,
  beforeLayer
) => {
  let markers

  if (floorAnglesMarkers) {
    markers = floorAnglesMarkers
  } else {
    markers = floor.coordinates.map(coordinate => {
      return new window.mapboxgl.Marker({
        draggable: true,
      })
        .setLngLat([coordinate.longitude, coordinate.latitude])
        .addTo(currentMap)
    })

    setFloorAnglesMarkers(markers)
  }

  const geoJsonCoordinates = floor.coordinates.map(item => [
    +item.longitude,
    +item.latitude,
  ])

  const onDragEnd = async () => {
    if (floor?.photo?.url) {
      const coordinates = markers.map(item => {
        const coords = item.getLngLat()

        return [+coords.lng, +coords.lat]
      })

      const src = currentMap.getSource('nearmotion-floor-plan-image')

      src.coordinates = coordinates
      src.prepare()
      src.load()
    }
  }

  if (!currentMap.getSource('nearmotion-floor-plan-image')) {
    if (floor?.photo?.url) {
      currentMap.addSource('nearmotion-floor-plan-image', {
        type: 'image',
        url: floor.photo.url,
        coordinates: [...geoJsonCoordinates],
      })

      currentMap.addLayer(
        {
          id: 'nearmotion-floor-plan-overlay',
          source: 'nearmotion-floor-plan-image',
          type: 'raster',
          paint: {
            'raster-opacity': 1,
            'raster-hue-rotate': 100,
          },
        },
        !beforeLayer ? beforeLayerId : false
      )
    }
    markers.forEach(item => item.on('dragend', () => onDragEnd()))
  }
}
