import { useState, useEffect } from 'react'
import jmap from 'jmap.js'
import { toast } from 'react-toastify'
import { getCenter } from 'src/utils/coordinates'
import { transform as fromCssToJS } from 'src/utils/fromCssToJS'

const getJBuildingCenter = bbox =>
  getCenter([
    {
      longitude: bbox[0],
      latitude: bbox[1],
    },
    {
      longitude: bbox[2],
      latitude: bbox[3],
    },
  ])

const getType = layerType => {
  const type = layerType.toLocaleLowerCase()
  if (/polygon/.test(type)) {
    return 'fill'
  }
  if (/line/.test(type)) {
    return 'line'
  }

  return 'circle'
}

const reduceJBuildingFloors = ({
  buildingStyles = [],
  floorsFromJ = [],
  floorsLayersPositions = {},
}) => {
  const mapStyles = props => {
    const { layerName, layerType } = props

    if (/CustomArtLayer-6|Background/.test(layerName)) {
      return {
        type: layerType,
        'fill-color': 'transparent',
      }
    }

    const styles = {
      type: layerType,
    }
    const stylesObject = buildingStyles[layerName]

    Object.keys(stylesObject).forEach(key => {
      if (!styles[key]) {
        const styleValue =
          stylesObject[key] === 'none' ? 'transparent' : stylesObject[key]

        if (key === 'fill') {
          styles[`${layerType}-color`] = styleValue
        }

        if (key === 'opacity') {
          styles[`${layerType}-opacity`] = styleValue
        }

        if (key === 'stroke' && layerType !== 'line') {
          styles[
            layerType === 'fill'
              ? 'fill-outline-color'
              : `${layerType}-stroke-color`
          ] = styleValue
        }

        if (key === 'strokeWidth' && /circle|line/.test(layerType)) {
          styles[
            `${
              layerType === 'circle'
                ? `${layerType}-stroke-width`
                : 'line-gap-width'
            }`
          ] = styleValue
        }
      }
    })

    return styles
  }

  return floorsFromJ.map((floor, index) => {
    const { geojson } = floor.map

    const layerData = []
    const layerTypes = []

    geojson.features.forEach(feature => {
      const layerName = feature.properties.featureType
      const layerType = feature.geometry.type

      const existLayer = layerData.find(item => item.layerName === layerName)

      const existLayerType = layerTypes.find(item => item === layerType)

      if (!existLayerType) {
        layerTypes.push(layerType)
      }
      if (!existLayer) {
        layerData.push({
          type: 'FeatureCollection',
          layerType,
          layerName,
          features: [feature],
        })
      } else {
        existLayer.features.push(feature)
      }
    })

    return {
      shortName: floor.shortName,
      name: floor.name,
      id: floor.id,
      position: floorsFromJ.length - index,
      layers: floorsLayersPositions[floor.id]
        .map(layerName => {
          const layer = layerData.find(item => item.layerName === layerName)
          if (!layer) {
            // eslint-disable-next-line no-console
            console.log(
              `No layer information for: ${layerName}, from floor: ${floor.name}`
            )
          }
          if (layer) {
            const layerType = getType(layer.layerType)

            return {
              // ...layer,
              geojson: layer,
              style: mapStyles({ layerName, layerType }),
            }
          }

          return false
        })
        .filter(item => item),
    }
  })
}

export function useJibestream() {
  const [buildingStyles, setBuildingStyles] = useState()
  const [floorsLayersPositions, setFloorsLayersPositions] = useState()
  const [jBuilding, setJBuilding] = useState()

  const [startSync, setStartSync] = useState()
  const [floorsResolve, setFloorsResolve] = useState({ action: () => {} })

  const fetchVenues = async ({ buildingId, jAccount }) => {
    return new Promise((resolve, reject) => {
      const { clientId, clientSecret, customerId } = jAccount

      const core = new jmap.core.JCore({
        host: 'https://api.jibestream.com',
        auth: new jmap.core.Auth(clientId, clientSecret),
        customerId,
        geojson: true, // <-- Flag for receiving geojson instead of svg maps as of v4.4.0
      })
      core.getVenues((error, availableVenues) => {
        if (error) {
          toast.error(error) // unqoun

          return reject(error)
        }

        const buildings = availableVenues.getAll().map(building => ({
          ...building._,
          coordinate: getJBuildingCenter(building?._?.bbox),
        }))
        let building
        if (buildingId) {
          const fetchedBuilding = availableVenues.getById(buildingId)

          building = {
            jibestream_id: fetchedBuilding.id,
            name: fetchedBuilding.name,
            coordinate: getJBuildingCenter(fetchedBuilding?._?.bbox),
          }
        }
        setJBuilding(building)
        resolve({ buildings, building })
      })
    })
  }

  const fetchJBuildingStyles = async ({ buildingId, core }) => {
    return new Promise((resolve, reject) => {
      core.populateVenueWithDefaultBuilding(
        buildingId,
        async (error, activeVenue) => {
          if (error) {
            toast.error(error) // unqoun

            return reject(error)
          }

          const defaultBuilding = activeVenue.buildings.getDefault()
          const floorsObject = defaultBuilding.floors.getAll()
          const styles = {}
          const layersPositions = {}

          floorsObject.forEach(floor => {
            const lastIndex = floor.map.svgTree.childNodes.length - 1
            const svgImage = floor.map.svgTree.childNodes[lastIndex]

            const stylesObject = fromCssToJS(svgImage.childNodes[0].innerHTML)

            Object.keys(stylesObject).forEach(layerName => {
              const item = stylesObject[layerName]
              if (!styles[layerName]) {
                styles[layerName] = {
                  ...item,
                }
              }
            })
            const floorLayers = []
            svgImage.childNodes.forEach(item => {
              if (item.id) {
                floorLayers.push(item.id)
              }
            })
            layersPositions[floor.id] = floorLayers
          })
          await setBuildingStyles(styles)
          await setFloorsLayersPositions(layersPositions)

          setStartSync(true)
          resolve({
            floorsLayersPositions: layersPositions,
            buildingStyles: styles,
          })
        }
      )
    })
  }

  const fetchJBuildingGeojson = async ({ buildingId, core }) => {
    return new Promise(async (resolve, reject) => {
      core.populateVenueWithDefaultBuilding(
        buildingId,
        async (error, activeVenue) => {
          if (error) {
            toast.error(error) // unqoun

            return reject(error)
          }

          const defaultBuilding = activeVenue.buildings.getDefault()
          const floorsObject = defaultBuilding.floors.getAll()
          const floors = floorsObject.map(item => item._)?.reverse()
          const coordinate = getJBuildingCenter(floors[0].map.geojson.bbox)

          const building = {
            // disable-next-line
            jibestream_id: buildingId,
            name: defaultBuilding?.name,
            floors,
            coordinate,
          }
          await setJBuilding(building)
          setStartSync(true)

          resolve(building)
        }
      )
    })
  }

  const fetchJBuilding = async (buildingId, jAccount) => {
    return new Promise((resolve, reject) => {
      const { clientId, clientSecret, customerId } = jAccount
      const coreProps = {
        host: 'https://api.jibestream.com',
        auth: new jmap.core.Auth(clientId, clientSecret),
        customerId,
      }

      const coreGeojson = new jmap.core.JCore({
        ...coreProps,
        geojson: true,
      })

      const coreSvg = new jmap.core.JCore(coreProps)
      setFloorsResolve({ action: resolve })
      try {
        fetchJBuildingGeojson({
          core: coreGeojson,
          buildingId,
        })
        fetchJBuildingStyles({
          core: coreSvg,
          buildingId,
        })
      } catch (e) {
        reject(e)
      }
    })
  }

  const checkForReduce = async () => {
    const floors = await reduceJBuildingFloors({
      buildingStyles,
      floorsFromJ: jBuilding?.floors,
      floorsLayersPositions,
    })
    floorsResolve.action({
      ...jBuilding,
      floors,
    })
    setStartSync(false)
    setFloorsResolve({ action: () => {} })
  }

  useEffect(() => {
    if (
      jBuilding?.floors?.length > 0 &&
      buildingStyles &&
      floorsLayersPositions &&
      startSync
    ) {
      checkForReduce()
    }
  }, [jBuilding?.floors, buildingStyles, floorsLayersPositions])

  return {
    fetchJBuilding,
    fetchJBuildingStyles,
    fetchVenues,
  }
}
