import './index.css'
import React from 'react'
import ReactDOM from 'react-dom'

import { ApplicationRoot } from 'src/components/system/ApplicationRoot'

import mapboxgl from 'mapbox-gl'
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker'

window.mapboxgl = mapboxgl

mapboxgl.workerClass = MapboxWorker
mapboxgl.accessToken =
  'pk.eyJ1Ijoibm1hY2NvdW50cyIsImEiOiJja2xhazRobjgzbDkxMm9xb2d3YmQ3d2s2In0.wGFavxo8mpa7OI_lEhYUow'

// Use the mapbox-gl-rtl-text plugin to support right-to-left languages such as Arabic and Hebrew.
mapboxgl.setRTLTextPlugin(
  'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js',
  null,
  true // Lazy load the plugin
)
ReactDOM.render(<ApplicationRoot />, document.getElementById('app'))
