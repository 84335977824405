import { useCallback, useState } from 'react'

import { api } from 'src/modules/api'

export function useInvitations() {
  const [isLoading, setIsLoading] = useState(true)
  const [invitations, setInvitations] = useState()

  const get = useCallback(async props => {
    const { page = 0, size = 100, search } = props || {}
    setIsLoading(true)
    let requestUrl = `invitations?page[size]=${size}&page[number]=${page + 1}`

    if (search) {
      requestUrl = `${requestUrl}&q=${search}`
    }

    const res = await api.get(requestUrl)

    setIsLoading(false)

    return res
  }, [])

  const add = useCallback(
    async data => {
      setIsLoading(true)
      const res = await api.post('invitations', data)
      if (res.ok) {
        if (invitations?.length > 0) {
          invitations.push(res.json.invitation)
        } else {
          setInvitations([res.json.invitation])
        }
      }
      setIsLoading(false)

      return res
    },
    [invitations]
  )

  const update = useCallback(async (invitationId, data) => {
    setIsLoading(true)
    const res = await api.patch(`invitations/${invitationId}`, data)
    setIsLoading(false)

    return res
  }, [])

  const remove = useCallback(
    async memberId => {
      setIsLoading(true)
      const res = await api.del(`invitations/${memberId}`)
      res.ok && get()
      setIsLoading(false)

      return res
    },
    [get]
  )

  const confirm = useCallback(async (token, data) => {
    setIsLoading(true)
    const res = await api.post(`invitations/${token}/accept`, data)

    setIsLoading(false)

    return res
  }, [])

  return {
    isLoading,
    add,
    get,
    update,
    remove,
    invitations,
    confirm,
  }
}
