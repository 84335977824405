export const mapCoordToGeoJson = coords => {
  return coords.map(angle => ({
    latitude: +angle.getLngLat().lat,
    longitude: +angle.getLngLat().lng,
  }))
}

export function getCenter(floorAngles) {
  const arrayX = []
  const arrayY = []
  for (let v = 0; v < floorAngles.length; v++) {
    arrayX.push(+floorAngles[v].longitude)
    arrayY.push(+floorAngles[v].latitude)
  }

  arrayX.sort((a, b) => b - a)[0]
  const lgnMin = arrayX[arrayX.length - 1]
  const lgnMax = arrayX[0]

  arrayY.sort((a, b) => b - a)[0]
  const latMin = arrayY[arrayY.length - 1]
  const latMax = arrayY[0]

  return {
    longitude: lgnMin + (lgnMax - lgnMin) / 2,
    latitude: latMin + (latMax - latMin) / 2,
  }
}
