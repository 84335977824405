import React, { useCallback } from 'react'
import { Form, FormSpy } from 'react-final-form'

import validate from 'src/utils/validate'
import isEmail from 'src/utils/validators/isEmail'
import isPasswordRequired from 'src/utils/validators/isPasswordRequired'
import { Alert } from 'src/components/Alert'
import { StyledLink } from 'src/components/Link'
import { Button } from 'src/ui/Button'
import { TextField } from 'src/components/TextField'
import { PasswordField } from 'src/components/PasswordField'
import { Card } from 'src/ui/Card'
import { AuthPageWrapper, PageContentWrapper } from 'src/pages/PageTemplate'
import { useAuth } from 'src/modules/auth'
import { TermsAndPrivacy } from 'src/ui/TermsAndPrivacy'
import { Scroller } from 'src/components/Scroller'
import { InvitationMessage } from 'src/ui'

const initialValues = {
  email: '',
  password: '',
}

const getSubmitError = errorCode => {
  switch (errorCode) {
    case 423: {
      return {
        title: 'You need to confirm the account before continuing.',
        description: '',
      }
    }

    case 401: {
      return {
        title: 'Invalid email and/or password',
        description: 'Please check the entered values and try again',
      }
    }

    default: {
      return {
        title: 'Sorry but something went wrong.',
        description: '',
      }
    }
  }
}

export const SignIn = () => {
  const { signIn } = useAuth()
  const onValidate = useCallback(values => {
    const errors = {}

    errors.email = validate(values.email, isEmail)
    errors.password = validate(values.password, isPasswordRequired)

    return errors
  }, [])

  const onSubmit = useCallback(
    async values => {
      try {
        const res = await signIn(values)

        if (res?.json?.error?.parsedValidations) {
          return res.json.error.parsedValidations
        }
        if (!res.ok) {
          return { error: res.status }
        }
      } catch (error) {
        return { error: t('Something went wrong') }
      }

      return false
    },
    [signIn]
  )

  return (
    <AuthPageWrapper>
      <Scroller>
        <PageContentWrapper>
          <Form
            onSubmit={onSubmit}
            validate={onValidate}
            initialValues={initialValues}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} style={{ textAlign: 'center' }}>
                <InvitationMessage />
                <Card width="500px" style={{ textAlign: 'left' }}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    maxLength={255}
                  />
                  <PasswordField fullWidth label="Password" name="password" />

                  <FormSpy
                    subscription={{
                      submitting: true,
                      pristine: true,
                      valid: true,
                      dirtySinceLastSubmit: true,
                      hasValidationErrors: true,
                      submitErrors: true,
                      error: true,
                      errors: true,
                      submitFailed: true,
                    }}
                  >
                    {({ valid, submitFailed, submitErrors }) => {
                      const submitError = getSubmitError(submitErrors?.error)

                      return (
                        <>
                          <div mt={4}>
                            {!valid && submitFailed && (
                              <Alert
                                type="error"
                                title={t(submitError.title)}
                                description={t(submitError.description)}
                              />
                            )}
                          </div>
                          <div mt={4}>
                            <Button
                              color="primary"
                              width="100%"
                              bold
                              size="lg"
                              onClick={handleSubmit}
                            >
                              {t('Sign In')}
                            </Button>
                          </div>
                        </>
                      )
                    }}
                  </FormSpy>

                  <StyledLink
                    to="/re-confirm"
                    style={{
                      marginTop: 20,
                      width: '100%',
                      textAlign: 'right',
                    }}
                  >
                    {t("Didn't receive confirmation instructions?")}
                  </StyledLink>

                  <StyledLink
                    to="/reset-password"
                    style={{
                      marginTop: 20,
                      width: '100%',
                      textAlign: 'right',
                    }}
                  >
                    {t('Forgot password?')}
                  </StyledLink>

                  <StyledLink
                    to="/sign-up"
                    style={{
                      marginTop: 20,
                      width: '100%',
                      textAlign: 'right',
                    }}
                  >
                    {t("Don't have an account yet?")}
                  </StyledLink>
                  <TermsAndPrivacy />
                </Card>
              </form>
            )}
          />
        </PageContentWrapper>
      </Scroller>
    </AuthPageWrapper>
  )
}
