import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import styled from 'styled-components/macro'

export const Scroller = styled(SimpleBar)`
  height: 100%;

  .simplebar-track {
    opacity: 0.3;

    .simplebar-scrollbar {
      &:before {
        position: absolute;
        content: '';
        border-radius: 4px;
        left: 2px;
        right: 8px;
      }

      &.simplebar-visible:before {
        opacity: 1;
      }
    }

    &.simplebar-vertical {
      top: 15px;
      bottom: 15px;
      width: 14px;
      transition: opacity 400ms ease-in-out;

      ${props =>
        props.left
          ? `
        left: 2px;
        `
          : `
        right: 2px;
        `}

      .simplebar-scrollbar:before {
        top: 2px;
        bottom: 2px;
        background: #5468ff;
      }
    }

    &.simplebar-horizontal {
      display: none;
    }
  }

  &:hover {
    .simplebar-track {
      opacity: 0.8;
    }
  }
`
