import styled from 'styled-components/macro'
import { Link } from 'src/components/Link'

export const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
  height: 34px;
  font-size: 13px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  color: white;
  text-decoration: none;
  background-color: #5468ff;
  font-family: Biko;
  &:visited {
    color: white;
  }
`
