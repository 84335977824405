import React, { useCallback, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'

import { DataTable } from 'src/components/DataTable'
import { ConfirmationModal } from 'src/components/ConfirmationModal'
import { SearchInput } from 'src/components/SearchInput'

import { PageWithHeader, PageContentWrapper } from 'src/pages/PageTemplate'

import { Loader, Button, Card } from 'src/ui'
import { formatTime } from 'src/utils/formatDateTime'
import { useBeacons } from 'src/hooks/useBeacons'
import { useBeaconsImport } from 'src/hooks/useBeaconsImport'
import { useApplications } from 'src/modules/applications'

import { Link } from 'src/components/Link'
import { AddBeaconsImportsModal } from 'src/components/BeaconsImports'

import { ReactComponent as Globe } from 'src/assets/images/svg/globe.svg'
import { ReactComponent as IconEdit } from 'src/assets/images/svg/icon-edit.svg'
import { ReactComponent as IconTrashBin } from 'src/assets/images/svg/icon-trash-bin.svg'

import { StyledButtonLink } from 'src/components/ButtonLink'

import { CreateBeaconModal } from './components/CreateBeaconModal'

export const Beacons = () => {
  const params = useParams()
  const { applicationId, applications } = useApplications()
  const history = useHistory()

  if (!applicationId) {
    history.replace('/applications')
  }

  const applicationName = applications.find(
    application => application.id === applicationId
  )?.name

  const {
    fetchBeacons,
    editBeacon,
    deleteBeacon,
    createBeacon,
    isLoading: isBeaconsLoading,
  } = useBeacons()
  const beaconsImportHook = useBeaconsImport()

  const [createBeaconModalState, setCreateBeaconModalState] = useState(false)

  const [beacons, setBeacons] = useState([])
  const [search, setSearch] = useState(false)
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(20)
  const [currentPageCount, setCurrentPageCount] = useState()
  const [isAddCsv, setIsAddCsv] = useState()

  const toggleCreateBeaconModal = useCallback(
    newVal => setCreateBeaconModalState(cur => newVal ?? !cur),
    []
  )
  const handleSearchChange = useCallback(e => setSearch(e?.target?.value), [])
  const openCreateBeaconModal = useCallback(
    () => setCreateBeaconModalState(true),
    []
  )
  const closeCreateBeaconModal = useCallback(
    () => setCreateBeaconModalState(false),
    []
  )

  const getFilteredBeacons = useCallback(async () => {
    const result = await fetchBeacons({
      search,
      page: currentPageIndex,
      size: currentPageSize,
    })
    setBeacons(result.json.beacons || [])
    setCurrentPageCount(result.headers.get('X-Pager-Total-Pages') || 0)
  }, [currentPageIndex, currentPageSize, fetchBeacons, search])

  const fetchData = useCallback(
    async ({ pageIndex, pageSize }) => {
      setCurrentPageIndex(pageIndex)
      setCurrentPageSize(pageSize)
      const result = await fetchBeacons({
        search,
        page: pageIndex,
        size: pageSize,
      })

      setCurrentPageCount(result.headers.get('X-Pager-Total-Pages') || 0)
      setBeacons(result.json.beacons || [])
    },
    [fetchBeacons, search]
  )

  const handleSubmit = useCallback(
    async values => {
      const action = values.id ? editBeacon : createBeacon
      const res = await action(values)

      if (res?.json?.error?.parsedValidations) {
        return res.json.error.parsedValidations
      }
      if (res.ok) {
        await getFilteredBeacons()
        closeCreateBeaconModal()
      }
    },
    [editBeacon, createBeacon, getFilteredBeacons, closeCreateBeaconModal]
  )

  const confirmBeaconDeletion = useCallback(
    async id => {
      const res = await deleteBeacon(id)

      if (res.ok) {
        if (beacons.length <= 1) {
          setSearch('')
        }
        await getFilteredBeacons()
      }
    },
    [beacons.length, deleteBeacon, getFilteredBeacons]
  )

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Building',
      accessor: beacon => `${beacon.building_name} (ID: ${beacon.building_id})`,
    },
    {
      Header: 'Floor',
      accessor: beacon =>
        beacon.building_floor_id &&
        `${beacon.building_floor_name} (ID: ${beacon.building_floor_id})`,
    },
    {
      Header: 'Zone',
      accessor: () => 'Unassigned',
    },
    {
      Header: 'Beacon type',
      accessor: 'vendor',
    },
    {
      Header: 'Added on',
      accessor: x => formatTime(x.created_at),
    },
    {
      id: 'actions',
      Header: () => <div />,
      Cell: ({ row: { original } }) => (
        <TableActionsCell>
          <IconEdit
            title="Edit"
            onClick={() => toggleCreateBeaconModal(original)}
          />
          <ConfirmationModal
            title="Are you sure you want to delete this beacon?"
            iconTitle="Remove"
            description="This action cannot be undone"
            onConfirm={confirmBeaconDeletion}
            id={original.id}
            component={IconTrashBin}
          />
          {original.latitude && original.building_floor_id && (
            <Link
              title="Show on Map"
              to={`/buildings/${original.building_id}/floors/${original.building_floor_id}/?beacon=${original.id}`}
            >
              <Globe />
            </Link>
          )}
        </TableActionsCell>
      ),
    },
  ]

  return (
    <PageWithHeader>
      <PageContentWrapper style={{ textAlign: 'center' }}>
        <Card fullSize>
          <DataTable
            search={search}
            searchable
            pagination
            isLoading={isBeaconsLoading}
            currentPageIndex={currentPageIndex}
            currentPageSize={currentPageSize}
            pageCount={+currentPageCount}
            fetchData={fetchData}
            columns={columns}
            rows={beacons}
            actions={
              <>
                <Button color="primary" onClick={openCreateBeaconModal}>
                  {t('Add Beacons')}
                </Button>
                <Button color="primary" onClick={() => setIsAddCsv(true)}>
                  {t('Upload Beacons CSV')}
                </Button>
                <StyledButtonLink
                  color="primary"
                  to={`/${
                    params.buildingId ? `buildings/${params.buildingId}/` : ''
                  }beacons/imports`}
                >
                  {t('Go to Imports')}
                </StyledButtonLink>
                <Button color="secondary" disabled>
                  {t('Reset Beacons')}
                </Button>
                <Link to="/applications">{applicationName}</Link>
                <SearchInput
                  placeholder={t('Search beacons..')}
                  onChange={handleSearchChange}
                />
              </>
            }
          />
        </Card>
        {!!createBeaconModalState && (
          <CreateBeaconModal
            onSubmit={handleSubmit}
            onClose={closeCreateBeaconModal}
            initialValues={createBeaconModalState}
          />
        )}
      </PageContentWrapper>
      {(isBeaconsLoading || !beacons) && <Loader />}
      {isAddCsv && (
        <AddBeaconsImportsModal
          beaconsImportHook={beaconsImportHook}
          onClose={() => {
            setIsAddCsv(false)
          }}
        />
      )}
    </PageWithHeader>
  )
}

const TableActionsCell = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: fit-content;
  column-gap: 12px;
  margin: auto;

  > * {
    cursor: pointer;
  }
`
