import { useCallback, useState } from 'react'
import { api } from 'src/modules/api'
import { useApplications } from 'src/modules/applications'

export const useBuildingPoiCategories = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { applicationId } = useApplications()

  const create = useCallback(
    async data => {
      setIsLoading(true)
      const result = await api.post(
        `applications/${applicationId}/building_poi_categories`,
        data
      )
      setIsLoading(false)

      return result?.json?.building_poi_category
    },
    [applicationId]
  )

  const get = useCallback(async () => {
    setIsLoading(true)
    const result = await api.get(
      `applications/${applicationId}/building_poi_categories`
    )

    const data = result?.json.building_poi_categories

    setIsLoading(false)

    return data
  }, [applicationId])

  return {
    create,
    get,
    isLoading,
  }
}
