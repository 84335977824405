export const equals = (array, arrayToCompare) => {
  // if the other array is a falsy value, return
  if (!array || !arrayToCompare) return false

  // compare lengths - can save a lot of time
  if (arrayToCompare.length !== array.length) return false

  for (let i = 0, l = arrayToCompare.length; i < l; i++) {
    // Check if we have nested arrays
    if (arrayToCompare[i] instanceof Array && array[i] instanceof Array) {
      // recurse into the nested arrays
      if (!equals(array[i], arrayToCompare[i])) return false
    } else if (arrayToCompare[i] !== array[i]) {
      // Warning - two different object instances will never be equal: {x:20} != {x:20}
      return false
    }
  }

  return true
}

export const getFromArrayMinValueIndex = arr => {
  let len = arr.length
  let min = Infinity
  let index

  while (len--) {
    if (arr[len] < min) {
      min = arr[len]
      index = len
    }
  }

  return index
}

export const insertAtPosition = (arr, item, index) =>
  arr.reduce((s, a, i) => {
    i === index ? s.push(item, a) : s.push(a)

    return s
  }, [])
