import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components/macro'
import { v4 as uuidv4 } from 'uuid'
import { AsyncPaginate } from 'react-select-async-paginate'

import { useMap } from 'src/modules/map'

import { useMapStudio } from 'src/pages/MapStudio/controllers/MapStudioController'
import { useBeacons as useBeaconsHook } from 'src/hooks/useBeacons'
import { Button } from 'src/ui'
import { Modal } from 'src/components/Modal'
import { useKeyPress } from 'src/hooks/useKeyPress'
import { ModalDialog } from 'src/components/ModalDialog'

import MarkerImage from 'src/assets/images/svg/marker-default.svg'
import MarkerActiveImage from 'src/assets/images/svg/marker-beacon-active.svg'

export const useBeacons = ({ isInitiated }) => {
  const selectRef = useRef()
  const history = useHistory()
  const { floorId, buildingId } = useParams()

  const { map: currentMap, mapDraw } = useMap()
  const { fetchBeacons, editBeacon } = useBeaconsHook()

  const [selectedBeacon, setSelectedBeacon] = useState()
  const [markerPopup] = useState({})
  const [showConfirmation, setShowConfirmation] = useState()
  const [markersOptions, setMarkersOptions] = useState([])

  const [showPopup, setShowPopup] = useState()
  const [lastPoint] = useState({})
  const [searchList, setSearchList] = useState()
  const [hasBeacons, setHasBeacons] = useState()

  const {
    setStartAddBeacon,
    setLoadedBeacons,
    loadedBeaconsOnMap,
    showBeacons,
    loadedBeacons,
    showBeacon,
  } = useMapStudio()
  const escapePressed = useKeyPress('Escape')

  const cancelAddBeacon = useCallback(() => {
    setStartAddBeacon(false)
    setShowPopup(false)
    currentMap.startAddBeacon = false
  }, [currentMap, setStartAddBeacon])

  const loadFilteredBeacons = useCallback(
    async (search, loadedOptions, props) => {
      const { page = 0 } = props || {}
      const searchResult = await fetchBeacons({
        loadByBuildId: true,
        page,
        search,
        size: 20,
      })

      const beacons = searchResult?.json?.beacons || []

      setSearchList(beacons)

      const currentPage = +searchResult.headers.get('X-Pager-Current-Page')
      const totalPages = +searchResult.headers.get('X-Pager-Total-Pages')
      const options = beacons.map(item => ({
        value: item.id,
        label: `${item.name} ${
          item.building_floor_id ? `(Floor: ${item.building_floor_id})` : ''
        }`,
      }))

      return {
        options,
        hasMore: totalPages > currentPage,
        additional: {
          page: page + 1,
        },
      }
    },
    [fetchBeacons]
  )

  const loadBeacons = useCallback(async () => {
    // get beacons for map rendering
    const result = await fetchBeacons({
      loadByBuildId: true,
      size: 20,
    })
    const beacons = result?.json?.beacons || []

    setSearchList(beacons)
  }, [fetchBeacons])

  const loadAllBeacons = useCallback(async () => {
    // get beacons for map rendering
    const result = await fetchBeacons({
      loadAll: true,
      size: 500,
    })
    setLoadedBeacons(result || [])
  }, [fetchBeacons, setLoadedBeacons])
  useEffect(loadAllBeacons, [loadAllBeacons])

  const loadBeacon = useCallback(async () => {
    const result = await fetchBeacons({
      loadByBuildId: true,
      size: 1,
    })
    setHasBeacons(result?.json?.beacons?.length > 0)
  }, [fetchBeacons])
  // load beacons to check what beacon popup should be opened
  useEffect(loadBeacon, [loadBeacon])

  useEffect(() => {
    if (isInitiated && loadedBeacons) {
      loadedBeacons
        .filter(item => item.latitude)
        .forEach(beacon => {
          addMarker([beacon.longitude, beacon.latitude], beacon)
        })
      enableDragMarker(false)
      currentMap.on('click', handleClickOnMap)

      return () => {
        currentMap.off('click', handleClickOnMap)
        loadedBeaconsOnMap.forEach(item => item.marker.remove())
      }
    }
  }, [isInitiated, loadedBeacons])

  useEffect(() => {
    if (isInitiated && searchList?.length) {
      setMarkersOptions(
        searchList.map(item => ({
          ...item,
          label: item.name,
          value: item.id,
        }))
      )
    }
  }, [isInitiated, searchList])

  useEffect(() => {
    if (showBeacons) {
      loadedBeaconsOnMap.forEach(item => item.marker.addTo(currentMap))
    } else {
      loadedBeaconsOnMap.forEach(item => item.marker.remove())
    }
  }, [showBeacons])

  useEffect(() => {
    if (!showBeacon) {
      const activeMarker = document.getElementsByClassName('active-marker')
      if (activeMarker.length) {
        activeMarker[0].classList.remove('active-marker')
      }
    }
  }, [showBeacon])

  useEffect(() => {
    if (currentMap) {
      setShowPopup(false)
    }
  }, [escapePressed, currentMap])

  useEffect(() => {
    if (showPopup) {
      selectRef.current?.select?.focus()
    }
  }, [showPopup])

  const handleClickOnMap = useCallback(
    async event => {
      if (!currentMap.startAddBeacon) return false
      // await loadBeacons()
      setShowPopup(true)
      lastPoint.coordinates = [event.lngLat.lng, event.lngLat.lat]
    },
    [currentMap, lastPoint]
  )

  const saveMarker = useCallback(
    async (marker, beacon) => {
      const lngLat = marker.getLngLat()
      await editBeacon({
        building_floor_id: floorId,
        id: beacon.id,
        uuid: beacon.uuid || marker.uuid,
        latitude: lngLat.lat,
        longitude: lngLat.lng,
      })
    },
    [floorId, editBeacon]
  )

  const handleEngagementsClick = useCallback(beaconId => {
    setShowConfirmation(beaconId)
  }, [])

  const createMarkerPopup = useCallback(
    beacon => {
      const popupElement = document.createElement('div')
      const name = document.createElement('p')
      name.innerHTML = `Name: <strong>${beacon.name}</strong>`

      const engButton = document.createElement('button')
      engButton.addEventListener('click', () =>
        handleEngagementsClick(beacon.id)
      )
      engButton.className = 'engagement-link engagement-remove-link'
      engButton.innerText = 'Delete'
      popupElement.appendChild(name)
      popupElement.appendChild(engButton)

      return new window.mapboxgl.Popup({ offset: 25 }).setDOMContent(
        popupElement
      )
    },
    [handleEngagementsClick]
  )

  const handleMarkerClick = useCallback(
    (event, marker, beacon) => {
      if (markerPopup.current) {
        const element = markerPopup.current.getElement()
        if (element) {
          const button = element.querySelector('button')
          button.removeEventListener('click', handleEngagementsClick)
        }

        markerPopup.current.remove()
        delete markerPopup.current
      }
      event.preventDefault()
      event.stopPropagation()

      if (mapDraw.getMode() === 'static') {
        const popup = createMarkerPopup(beacon)
        marker.setPopup(popup)
        marker.togglePopup()
        markerPopup.current = popup
      }
    },
    [markerPopup, mapDraw, handleEngagementsClick, createMarkerPopup]
  )

  const addMarker = useCallback(
    (coordinates, beacon) => {
      const uuid = uuidv4()
      let marker = loadedBeaconsOnMap?.find(
        item => item.beacon.id === beacon.id
      )?.marker

      if (!marker) {
        const markerElement = document.createElement('div')
        markerElement.className =
          showBeacon && +showBeacon === beacon.id
            ? 'custom-marker active-marker'
            : 'custom-marker'
        markerElement.addEventListener(
          'click',
          event => handleMarkerClick(event, marker, beacon),
          true
        )
        marker = new window.mapboxgl.Marker(markerElement, {
          draggable: true,
        })
        marker.uuid = uuid
        marker.on('dragend', () => {
          saveMarker(marker, beacon)
        })
        loadedBeaconsOnMap.push({ marker, beacon })
      }
      marker.setLngLat(coordinates)
      if ((showBeacon && +showBeacon === beacon.id) || !showBeacon) {
        marker.addTo(currentMap)
      }

      const updatedOptions = markersOptions.filter(
        option => option.id !== beacon.id
      )

      setMarkersOptions(updatedOptions)

      return marker
    },
    [
      loadedBeaconsOnMap,
      currentMap,
      markersOptions,
      showBeacon,
      handleMarkerClick,
      saveMarker,
    ]
  )

  const onSubmit = useCallback(() => {
    const beacon = searchList.find(item => selectedBeacon.value === item.id)
    const marker = addMarker(lastPoint.coordinates, beacon)
    saveMarker(marker, beacon)
    setShowPopup(false)
    setSelectedBeacon(false)
    loadBeacons()
  }, [
    searchList,
    addMarker,
    lastPoint.coordinates,
    saveMarker,
    loadBeacons,
    selectedBeacon,
  ])

  const onClose = () => {
    setShowPopup(false)
  }

  const enableDragMarker = useCallback(
    state => {
      loadedBeaconsOnMap?.forEach(item => {
        item?.marker?.setDraggable(state)
      })
    },
    [loadedBeaconsOnMap]
  )
  const addBeacon = useCallback(() => {
    setStartAddBeacon(true)
    currentMap.startAddBeacon = true
    enableDragMarker(true)
  }, [currentMap, enableDragMarker, setStartAddBeacon])

  const handleCancel = useCallback(() => {
    setShowConfirmation(false)
  }, [])

  const handleDelete = useCallback(async () => {
    const markerIndex = loadedBeaconsOnMap.findIndex(
      item => item.beacon.id === +showConfirmation
    )
    const { beacon, marker } = loadedBeaconsOnMap[markerIndex]

    await editBeacon({
      building_floor_id: null,
      id: beacon.id,
      latitude: null,
      longitude: null,
    })

    marker.remove()
    await loadAllBeacons()
    setShowConfirmation(false)
  }, [loadedBeaconsOnMap, editBeacon, loadAllBeacons, showConfirmation])

  const handleFocus = useCallback(async () => {
    setSelectedBeacon('')
  }, [])

  const handleAddBeacons = useCallback(() => {
    history.push(`/buildings/${buildingId}/beacons`)
  }, [buildingId, history])

  return {
    addBeacon,
    cancelAddBeacon,
    renderPopup: () => (
      <>
        <GlobalStyle />
        {hasBeacons && showPopup && (
          <AddBeaconWrapper>
            <Container>
              <SelectComponent
                value={selectedBeacon}
                loadOptions={loadFilteredBeacons}
                onChange={setSelectedBeacon}
                onFocus={handleFocus}
                selectRef={selectRef}
                isFirstLoad
                loadOptionsOnMenuOpen="true"
              />

              <Actions>
                <Button
                  size="large"
                  color="secondary"
                  variant="contained"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  onClick={onSubmit}
                  disabled={!selectedBeacon}
                >
                  Save
                </Button>
              </Actions>
            </Container>
          </AddBeaconWrapper>
        )}
        {!hasBeacons && showPopup && (
          <ModalDialog
            title="Currently, we don't have assigned beacons for this floor"
            description="Add the new beacons?"
            actions={
              <>
                <Button mr="10px" color="secondary" onClick={onClose}>
                  Skip
                </Button>
                <Button color="primary" onClick={handleAddBeacons}>
                  Yes
                </Button>
              </>
            }
          />
        )}
        {!!showConfirmation && (
          <ModalDialog
            title="Remove Beacon"
            description="This will remove the Beacon from the Map. Are you sure?"
            actions={
              <>
                <Button mr="10px" color="secondary" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button color="primary" onClick={handleDelete}>
                  Confirm
                </Button>
              </>
            }
          />
        )}
      </>
    ),
  }
}

const AddBeaconWrapper = styled(Modal)``

const Container = styled.div`
  padding: 20px;
  margin-top: 43px;
  box-shadow: 0 9.08776px 14.3889px rgba(60, 128, 209, 0.05);
  border-radius: 10px;
  border: 10px solid white;
  background: #fafbff;
  display: flex;
  flex-direction: column;
`
const SelectComponent = styled(AsyncPaginate)`
  position: relative;
  margin-bottom: 20px;
  z-index: 1001;
`
const Actions = styled.div`
  display: flex;
  justify-content: space-around;
  button:first-of-type {
    margin-right: 10px;
  }
`

const GlobalStyle = createGlobalStyle`
  .custom-marker{
    width: 30px;
    height: 52px;
    background-size: 100% auto;
    background-image: url(${MarkerImage});
    background-repeat: no-repeat;
    background-position: left bottom;
    &.active-marker{
      background-image: url(${MarkerActiveImage});
      z-index: 1;
    }
  }
  .mapboxgl-popup{
    z-index: 1;
  }
`
