import { createAsyncThunk } from '@reduxjs/toolkit'
import { api } from 'src/modules/api'

const handleError = err => {
  return err?.response?.data || err?.statusText || 'Something went wrong'
}

export const addCreditCard = createAsyncThunk(
  'creditCard/add',
  async ({ currency }, { rejectWithValue }) => {
    try {
      const result = await api.post(`customers`, {
        currency,
      })

      return result?.json?.customer?.signed_request
    } catch (err) {
      return rejectWithValue(handleError(err))
    }
  }
)

export const loadCreditCards = createAsyncThunk(
  'creditCards/loadAll',
  async () => {
    const result = await api.get(`customers`)

    return result?.json?.customers
  }
)

export const loadCreditCard = createAsyncThunk(
  'creditCard/load',
  async cardId => {
    const result = await api.get(`customers/${cardId}`)

    return result?.json?.customer
  }
)

export const updateCreditCard = createAsyncThunk(
  'creditCard/update',
  async ({ cardId, data }, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.patch(`customers/${cardId}`, data)

      dispatch(loadCreditCards())

      return response?.json?.data
    } catch (err) {
      return rejectWithValue(handleError(err))
    }
  }
)

export const removeCreditCard = createAsyncThunk(
  'creditCard/remove',
  async ({ cardId }, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.del(`customers/${cardId}`)
      dispatch(loadCreditCards())

      return response?.json?.data
    } catch (err) {
      return rejectWithValue(handleError(err))
    }
  }
)
