import React from 'react'

import { SelectInput } from 'src/ui/form/SelectInput'
import {
  Wrapper,
  Label,
  Error,
} from 'src/ui/form/FormField'

export const SelectFieldUI = ({
  label,
  inputProps,
  error,
  className,
  options,
}) => {
  return (
    <Wrapper className={className}>
      {label && <Label>{label}</Label>}

      <div>
        <SelectInput
          invalid={!!error}
          fullWidth
          {...inputProps}
        >
          {options.map(opt => (
            <option key={opt.value} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </SelectInput>
      </div>

      {inputProps && (!!inputProps.error || !!error) && (
        <Error>{inputProps.error || error}</Error>
      )}
    </Wrapper>
  )
}
