export const defaultCircleStyle = {
  'circle-opacity': [
    'interpolate',
    ['exponential', 0.5],
    ['zoom'],
    15,
    0,
    22,
    1,
  ],
  'circle-stroke-opacity': [
    'interpolate',
    ['exponential', 0.5],
    ['zoom'],
    15,
    0,
    22,
    1,
  ],
  'circle-stroke-color': '#0022ff',
  'circle-stroke-width': [
    'interpolate',
    ['linear'],
    ['zoom'],
    15,
    [
      'case',
      ['==', ['get', 'kind'], 'elevator'],
      0.2,
      ['==', ['get', 'kind'], 'stairs'],
      0.2,
      0.1,
    ],
    23,
    [
      'case',
      ['==', ['get', 'kind'], 'elevator'],
      5,
      ['==', ['get', 'kind'], 'stairs'],
      5,
      4,
    ],
  ],
  'circle-radius': [
    'interpolate',
    ['linear'],
    ['zoom'],
    15,
    [
      'case',
      ['==', ['get', 'kind'], 'elevator'],
      0.6,
      ['==', ['get', 'kind'], 'stairs'],
      0.6,
      0.5,
    ],
    23,
    [
      'case',
      ['==', ['get', 'kind'], 'elevator'],
      15,
      ['==', ['get', 'kind'], 'stairs'],
      15,
      7,
    ],
  ],
  'circle-color': [
    'case',
    ['boolean', ['get', 'active'], false],
    'white',
    ['==', ['get', 'kind'], 'stairs'],
    '#00d9fc',
    ['==', ['get', 'kind'], 'elevator'],
    '#fcc500',
    ['boolean', ['get', 'connecting'], false],
    '#B8E986',
    '#F94B5F',
  ],
}
export const defaultLineStyle = {
  'line-color': '#5468ff',
  'line-width': {
    base: 1,
    stops: [
      [15, 1],
      [23, 5],
    ],
  },
  'line-opacity': ['interpolate', ['exponential', 0.5], ['zoom'], 15, 0, 22, 1],
}

export const defaultMultiline = {
  type: 'Feature',
  properties: {
    msNeighbors: [],
  },
  geometry: {
    type: 'MultiLineString',
    coordinates: [],
  },
}

export const defaultDrawingLineFeatures = [
  {
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: [],
    },
  },
  {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [],
    },
  },
]

export const defaultCircleSignStyle = {
  'text-field': [
    'case',
    ['==', ['get', 'kind'], 'stairs'],
    'S',
    ['==', ['get', 'kind'], 'elevator'],
    'E',
    '',
  ],
  'text-font': ['Arial Unicode MS Bold'],
  'text-size': 12,
}
