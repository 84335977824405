import React from 'react'
import { StyledLink } from 'src/components/Link'

const messages = {
  DEFAULT: 'Internal server error',
  NOT_AUTHORIZED: 'Not authorized',
  PAGE_NOT_FOUND: 'Page not found',
  UNPROCESSABLE_ENTITY: 'Unprocessable entity',
  SERVICE_UNAVAILABLE: 'Service unavailable',
}

const getMessage = code => {
  switch (code) {
    case '400':
      return t(messages.NOT_AUTHORIZED)
    case '404':
      return t(messages.PAGE_NOT_FOUND)
    case '422':
      return t(messages.UNPROCESSABLE_ENTITY)
    case '502':
    case '503':
      return t(messages.SERVICE_UNAVAILABLE)
    default:
      return t(messages.DEFAULT)
  }
}

export const ErrorPage = ({
  code,
  showCode = true,
  to = '/',
}) => {
  return (
    <div>
      <div>
        {showCode && `Error ${code}`}
        {getMessage(code)}
        <StyledLink
          size="large"
          color="primary"
          variant="contained"
          to={to}
        >
          Back to home
        </StyledLink>
      </div>
    </div>
  )
}

ErrorPage.MESSAGES = messages
