import React, {
  useContext,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { api } from 'src/modules/api'

const ApplicationsContext = createContext()

export function ApplicationsProvider(props) {
  const [applications, setApplications] = useState([])
  const [applicationId, setApplicationId] = useState(
    +localStorage.getItem('applicationId')
  )
  const [isLoading, setIsLoading] = useState(true)

  const [isLoadingInitially, setIsLoadingInitially] = useState(true)

  const get = useCallback(async () => {
    setIsLoadingInitially(true)
    setIsLoading(true)
    const res = await api.get('applications')

    res.ok && setApplications(res.json.applications)
    setIsLoading(false)
    setIsLoadingInitially(false)

    return res
  }, [])

  // const { data: dataApp } = useQuery(
  //   'applications',
  //   get
  // )

  useEffect(() => {
    const currentAppId = applicationId || +localStorage.getItem('applicationId')
    const isExist = applications?.find(item => item.id === currentAppId)
    if (!isExist && !isLoading) {
      localStorage.removeItem('applicationId')
    }
  }, [applicationId, applications, isLoading])

  // useEffect(() => {
  //   if (!isLoading && !isCompanyLoading && companies?.length) {
  //     const currentAppId =
  //       applicationId || +localStorage.getItem('applicationId')
  //
  //     if (applications.length) {
  //       const isExist = applications.find(item => item.id === currentAppId)
  //       if (!isExist) {
  //         localStorage.removeItem('applicationId')
  //         if (company?.id) {
  //           // history.push('/applications')
  //         }
  //       } else {
  //         setApplicationId(currentAppId)
  //       }
  //     }
  //     if (!applications.length || !currentAppId) {
  //       // return history.push('/applications')
  //     }
  //   }
  // }, [
  //   applicationId,
  //   // isLoading,
  //   // isCompanyLoading,
  //   // companies,
  //   applications,
  //   // history,
  // ])

  const create = useCallback(
    async data => {
      setIsLoading(true)
      const res = await api.post('applications', data)

      setIsLoading(false)
      res.ok && get()

      return res
    },
    [get]
  )

  const update = useCallback(async (id, data) => {
    setIsLoading(true)
    const res = await api.patch(`applications/${id}`, data)

    setIsLoading(false)

    return res
  }, [])

  const remove = useCallback(
    async id => {
      setIsLoading(true)
      const res = await api.del(`applications/${id}`)
      setApplications(applications.filter(app => app.id !== id))
      setIsLoading(false)

      return res
    },
    [applications]
  )

  const contextValue = {
    isLoading: isLoadingInitially || isLoading,
    get,
    setApplicationId,
    applicationId,
    applications,
    create,
    remove,
    update,
  }

  return (
    <ApplicationsContext.Provider value={contextValue}>
      {props.children}
    </ApplicationsContext.Provider>
  )
}

export function useApplications() {
  return useContext(ApplicationsContext)
}
