import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import 'mapbox-gl/dist/mapbox-gl.css'

import { useMap } from 'src/modules/map'
import { SubMenu } from 'src/components/SubMenu'
import { PageWithHeader } from 'src/pages/PageTemplate'

function MapBoxTemplateComponent({ children, className, mapClassName }) {
  const mapContainer = useRef()
  const { setMapContainer, searchElement } = useMap()

  useEffect(() => {
    if (mapContainer.current) {
      setMapContainer(mapContainer)
    }
  }, [mapContainer, setMapContainer])

  return (
    <PageWithHeader search={searchElement} className={className}>
      <Container>
        {children}
        <MapContainer className="map-container-wrapper">
          <Map className={`map-container ${mapClassName}`} ref={mapContainer} />
        </MapContainer>
      </Container>

      <SubMenuContainer>
        <SubMenu />
      </SubMenuContainer>
    </PageWithHeader>
  )
}

const MapContainer = styled.div``
const Container = styled.div`
  position: relative;
  display: inline-flex;
  height: 100%;
  background: white;
`

const Map = styled.div`
  display: block !important;
  position: relative;
  width: calc(100vw - 344px);
  height: calc(100vh - 72px);
`
const SubMenuContainer = styled.div`
  position: absolute;
  top: 85px;
  left: 265px;
  z-index: 3;
`

export const MapBoxTemplate = styled(MapBoxTemplateComponent)``
