import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useBuildings } from 'src/modules/buildings'
import { StyledButtonLink } from 'src/components/ButtonLink'
import { Loader, Title } from 'src/ui'
import { useApplications } from 'src/modules/applications'
import { useMap } from 'src/modules/map'
import { ConfirmationModal } from 'src/components/ConfirmationModal'

import { ReactComponent as IconTrashBin } from 'src/assets/images/svg/icon-trash-bin.svg'
import { useAbility } from 'src/hooks/useAbility'

function BuildingsComponent() {
  const { setShowMarkers } = useMap()
  const {
    buildings,
    list: listBuildings,
    isLoading,
    remove: removeBuilding,
  } = useBuildings()

  const { applicationId } = useApplications()
  const history = useHistory()
  const {
    building: { manage },
  } = useAbility()

  const getBuildings = async () => {
    const loadedBuildings = await listBuildings()
    if (!loadedBuildings?.length && !isLoading && applicationId) {
      history.push('/buildings/new')
    }
  }

  useEffect(() => {
    const markers = []
    buildings.forEach(building => {
      markers.push({
        id: building.id,
        title: building.name,
        lat: building.coordinate.latitude,
        lng: building.coordinate.longitude,
      })
    })

    setShowMarkers(markers)
  }, [buildings])

  useEffect(() => {
    if (!buildings.length || buildings?.[0].application_id !== applicationId) {
      getBuildings()
    }
  }, [applicationId])

  async function handleRemove(id) {
    const result = await removeBuilding(id)
    if (!result?.ok) {
      toast.error(result?.json?.error?.message || 'Something went wrong')
    }
  }

  return (
    <>
      {!isLoading && (
        <BuildingList>
          <Title>Buildings</Title>
          {buildings.map(building => (
            <Building key={building.id}>
              <Address>{building.address}</Address>
              {manage && (
                <TopActions>
                  <ConfirmationModal
                    title="This will remove the building from the database. Are you sure?"
                    iconTitle="This will remove the building from the database. Are you sure?"
                    description="This action cannot be undone"
                    onConfirm={handleRemove}
                    id={building.id}
                    component={IconTrashBin}
                  />
                </TopActions>
              )}

              <Preview>
                {building.photo && (
                  <Photo src={building.photo.medium} alt="preview" />
                )}
              </Preview>
              <Title>{building.name}</Title>
              <Address>{building.address}</Address>
              <Actions>
                <StyledButtonLink
                  size="large"
                  color="secondary"
                  variant="contained"
                  to={`/buildings/${building.id}/integration`}
                >
                  Integration Settings
                </StyledButtonLink>
                <StyledButtonLink
                  size="large"
                  color="primary"
                  variant="contained"
                  to={`/buildings/${building.id}/`}
                >
                  Open
                </StyledButtonLink>
              </Actions>
            </Building>
          ))}
          {manage && (
            <AddBuildWrapper>
              <StyledButtonLink color="primary" to="/buildings/new">
                Add Building
              </StyledButtonLink>
            </AddBuildWrapper>
          )}
        </BuildingList>
      )}
      {isLoading && <Loader />}
    </>
  )
}

export const Buildings = styled(BuildingsComponent)``

const BuildingList = styled.div`
  padding-bottom: 20px;
`

const AddBuildWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;
`

export const Building = styled.div`
  position: relative;
  display: grid;
  width: 344px;
  padding-bottom: 22px;
  border-bottom: 1px solid #e2ebff;
`

export const Photo = styled.img`
  width: 100%;
  max-width: 344px;
  height: auto;
`

export const Preview = styled.div`
  width: 100%;
  max-height: 167px;
  overflow: hidden;
`

export const Address = styled.div`
  margin-top: 10px;
  margin-bottom: 22px;
  padding: 0 30px 0 16px;
  font-weight: bold;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 0.244706px;
  text-transform: uppercase;
  color: #cdd6e9;
`

export const Actions = styled.div`
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const TopActions = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`
