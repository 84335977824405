import React, { useMemo } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { App } from 'src/App'
import { ConfirmAccount } from 'src/pages/user/ConfirmAccount'
import { SignUp } from 'src/pages/user/SignUp'
import { ResetPasswordConfirm } from 'src/pages/user/ResetPasswordConfirm'
import { ResetPassword } from 'src/pages/user/ResetPassword'
import { ReConfirm } from 'src/pages/user/ReConfirm'
import { ProfilePage } from 'src/pages/user/ProfilePage'
import { SignIn } from 'src/pages/user/SignIn'
import ErrorBoundary from 'src/components/ErrorBoundary'
import { useAuth } from 'src/modules/auth'
import { ErrorPage } from 'src/components/ErrorPage'
import { NavTemplate } from 'src/components/NavTemplate'
import { Subscription } from 'src/pages/user/PaymentsSubscription'
import { PaymentCards } from 'src/pages/user/PaymentsCards'
import { useCompany } from 'src/modules/company'
import { Status } from 'src/pages/Status'
import { ConfirmInvitation } from 'src/pages/user/ConfirmInvitation'
import { CompaniesPage } from 'src/pages/Companies'
import { useAbility } from 'src/hooks/useAbility'

const paymentMenu = [
  {
    to: '/payments/cards',
    title: 'Credit Card',
  },
  {
    to: '/payments/subscription',
    title: 'Subscription',
  },
]

export const MainRouter = () => {
  const { isAuthenticated, isLoading: isAuthLoading, user } = useAuth()

  const { company, companies, isLoading, isCompaniesInitiated } = useCompany()
  const { isOwner } = useAbility()

  const redirectTo = useMemo(() => {
    if (!isCompaniesInitiated || isAuthLoading) return ''

    if (isAuthenticated && (!user?.saas_company_id || !company)) {
      return '/companies'
    }

    if (company) {
      if (companies.length > 1) {
        return '/companies'
      }

      if (!company?.subscription) {
        return '/payments/cards'
      }
    }
  }, [
    companies,
    isCompaniesInitiated,
    isAuthLoading,
    isAuthenticated,
    user?.saas_company_id,
    company,
  ])

  function renderSubscriptions(buildProps) {
    return (
      <NavTemplate {...buildProps} menuLinks={paymentMenu}>
        <Subscription {...buildProps} />
      </NavTemplate>
    )
  }

  function renderCreditCard(buildProps) {
    return (
      <NavTemplate {...buildProps} menuLinks={paymentMenu}>
        <PaymentCards {...buildProps} />
      </NavTemplate>
    )
  }

  function renderOauth(buildProps) {
    return <SignIn isOauth {...buildProps} />
  }

  return (
    <ErrorBoundary>
      <Switch>
        <Route path="/status" component={Status} />
        <Route
          exact
          path="/confirm-account/:verificationCode"
          component={ConfirmAccount}
        />
        <Route
          exact
          isAuthenticated={isAuthenticated}
          path="/invitations/:invitationCode"
          component={ConfirmInvitation}
        />
        <AuthRoute
          isAuthenticated={isAuthenticated}
          exact
          path="/sign-up"
          component={SignUp}
        />
        <AuthRoute
          isAuthenticated={isAuthenticated}
          exact
          path="/reset-password/:token"
          component={ResetPasswordConfirm}
        />
        <AuthRoute
          isAuthenticated={isAuthenticated}
          exact
          path="/reset-password"
          component={ResetPassword}
        />
        <AuthRoute
          isAuthenticated={isAuthenticated}
          exact
          path="/re-confirm"
          component={ReConfirm}
        />
        <AuthRoute
          isAuthenticated={isAuthenticated}
          exact
          path="/sign-in"
          component={SignIn}
        />

        {isOwner && (
          <AuthRouteRender
            isAuthenticated={isAuthenticated}
            exact
            path="/payments/subscription"
            render={renderSubscriptions}
          />
        )}

        {isOwner && (
          <AuthRouteRender
            isAuthenticated={isAuthenticated}
            exact
            path="/payments/cards"
            render={renderCreditCard}
          />
        )}

        <AuthRouteRender
          isAuthenticated={isAuthenticated}
          path="/profile"
          component={ProfilePage}
        />
        <AuthRouteRender
          isAuthenticated={isAuthenticated}
          path="/companies"
          component={CompaniesPage}
        />

        {company?.subscription && (
          <AuthRoute
            isAuthenticated={!isAuthenticated}
            path="/"
            component={App}
          />
        )}

        {!isAuthenticated && (
          <Route path="/sign-in/oauth" render={renderOauth} />
        )}

        {redirectTo && <Redirect from="/" to={redirectTo} />}

        {!isAuthenticated && !(isLoading || isAuthLoading) && (
          <Route path="*">
            <ErrorPage code="404" />
          </Route>
        )}

        <Route
          path="/error/:code([\d]{3})"
          component={routeProps => {
            const {
              match: { params },
            } = routeProps

            return <ErrorPage code={params.code} />
          }}
        />
      </Switch>
      <ToastContainer position="top-right" />
    </ErrorBoundary>
  )
}

const AuthRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      return isAuthenticated ? (
        <Redirect {...props} to="/" />
      ) : (
        <Component {...props} />
      )
    }}
  />
)

const AuthRouteRender = ({ render, isAuthenticated, ...rest }) => {
  return isAuthenticated ? (
    <Route {...rest} render={render} />
  ) : (
    <Redirect to="/sign-in" />
  )
}
