import React from 'react'
import styled from 'styled-components/macro'

import { Wrapper, Label, Error } from 'src/ui/form/FormField'
import { TextInput } from 'src/ui/form/TextInput'
import { TextareaInput } from 'src/ui/form/TextareaInput'

export const TextFieldUI = ({
  label,
  inputProps = {},
  error,
  className,
  variant,
  placeholder,
  wrapperProps = {},
  ...props
}) => {
  return (
    <Wrapper className={className} variant={variant} {...wrapperProps}>
      {label && <Label variant={variant}>{label}</Label>}

      <InputWrapper variant={variant}>
        {inputProps.type === 'textarea' && (
          <TextareaInput
            variant={variant}
            invalid={!!error}
            fullWidth
            placeholder={placeholder}
            {...props}
            {...inputProps}
          />
        )}
        {inputProps.type !== 'textarea' && (
          <TextInput
            variant={variant}
            invalid={!!error}
            fullWidth
            placeholder={placeholder}
            {...props}
            {...inputProps}
          />
        )}
      </InputWrapper>

      {inputProps && (!!inputProps.error || !!error) && (
        <Error variant={variant}>{inputProps.error || error}</Error>
      )}
    </Wrapper>
  )
}

const InputWrapper = styled.div`
  ${props =>
    props.variant === 'new' &&
    `
    margin-top: 9px;
  `}
  ${p =>
    p.variant === 'outlined' &&
    `
    padding: 0 10px;
  `}
`
