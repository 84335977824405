import { api } from 'src/modules/api'

export const parallelRequests = (queryUrl, queryCount, key) => {
  return new Promise(async resolve => {
    const totalQueries = []
    const requestCount = queryCount < 2 ? queryCount : 2

    for (let i = 1; i <= queryCount; i++) {
      totalQueries.push(`${queryUrl}&page[number]=${i + 1}`)
    }

    let data = []

    async function fetchPage() {
      const query = totalQueries.shift()

      if (!query) return false

      const result = await api.get(query)

      data = data.concat(result?.json[ key || "building_pois"] || [])

      const allDataCount = result.headers.get('X-Pager-Total-Count')
      const perPage = result.headers.get('X-Pager-Per-Page')

      if (data.length === allDataCount - perPage) {
        resolve(data)
      } else {
        fetchPage()
      }
    }

    for (let i = 0; i < requestCount; i++) fetchPage()
  })
}
