import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'

import { useParams } from 'react-router-dom'
import { DraggableDataTable } from 'src/components/DraggableDataTable'
import { Button } from 'src/ui'
import {
  ImageUploader as ImageUploaderComponent,
  FileUploader,
} from 'src/components/Uploader'
import { createUpload } from 'src/modules/directLoad'
import { Modal } from 'src/components/Modal'
import { useFloors } from 'src/modules/floors'
import { ConfirmationModal } from 'src/components/ConfirmationModal'
import { ReactComponent as IconTrashBin } from 'src/assets/images/svg/icon-trash-bin.svg'
import { SelectInput } from 'src/ui/form/SelectInput'
import { LAYER_KINDS_SELECT_OPTIONS } from 'src/constants'
import { sortBy } from 'lodash'

const initialSortState = [{ id: 'position', desc: false }]

export const EditFloor = ({ onClose, floorId }) => {
  const params = useParams()
  const buildingId = +params.buildingId
  const {
    update,
    get,
    addLayer,
    removeLayer,
    getFloor,
    updateLayer,
    floor,
    clearFloor,
  } = useFloors()
  const [photo, setPhoto] = useState('')
  const [newLayerFile, setNewLayerFile] = useState('')
  const [displayMode, setDisplayMode] = useState('file') // file / layers
  const switchToTable = useCallback(() => {
    setPhoto('')
    setDisplayMode('layers')
  }, [])
  const switchToFile = useCallback(() => setDisplayMode('file'), [])
  const [addLayerModalState, setAddLayerModalState] = useState(false)

  useEffect(() => {
    getFloor(buildingId, floorId)
  }, [buildingId, floorId, getFloor])

  const onSubmit = useCallback(async () => {
    const result = await update(floorId, {
      photo,
    })
    if (result.ok) {
      await get(buildingId)
      clearFloor()
      onClose()
    }
  }, [floorId, photo, clearFloor, update, get, onClose, buildingId])

  const onSubmitNewLayer = useCallback(async () => {
    const result = await addLayer(floorId, {
      position: floor.layers.length + 1,
      file: newLayerFile,
    })
    if (result.ok) {
      await getFloor(buildingId, floorId)
      setNewLayerFile('')
      setAddLayerModalState(false)
    }
  }, [
    floor?.layers?.length,
    addLayer,
    getFloor,
    newLayerFile,
    buildingId,
    floorId,
  ])

  const openAddLayerModal = useCallback(() => setAddLayerModalState(true), [])
  const closeAddLayerModal = useCallback(() => setAddLayerModalState(false), [])

  async function onDrop(file, updateProgressCallback, completeCallback) {
    const upload = await createUpload(
      file,
      updateProgressCallback,
      completeCallback
    )
    setPhoto(upload.signed_id)
  }

  async function onDropLayerFile(
    file,
    updateProgressCallback,
    completeCallback
  ) {
    const upload = await createUpload(
      file,
      updateProgressCallback,
      completeCallback
    )
    setNewLayerFile(upload.signed_id)
  }

  const onRemove = useCallback(() => {
    setPhoto('')
  }, [])

  const onRemoveLayerFile = useCallback(() => {
    setNewLayerFile('')
  }, [])

  const confirmLayerDeletion = useCallback(
    async id => {
      await removeLayer(floor.id, id)

      const newLayers = [...floor.layers].map((l, i) => ({
        id: l.id,
        position: i + 1,
      }))

      await update(floor.id, {
        layers_attributes: newLayers,
      })

      getFloor(buildingId, floor.id)
    },
    [floor?.id, floor?.layers, buildingId, getFloor, removeLayer, update]
  )

  const sortedLayers = sortBy(floor.layers, 'position')

  const onDragLayer = useCallback(
    async (sourceIndex, destinationIndex) => {
      let newLayers = [...sortedLayers]
      const layer = newLayers.splice(sourceIndex, 1)[0]
      newLayers.splice(destinationIndex, 0, layer)
      newLayers = newLayers.map((l, i) => ({
        id: l.id,
        position: i + 1,
      }))

      await update(floor.id, {
        layers_attributes: newLayers,
      })
      getFloor(buildingId, floor.id)
    },
    [floor.id, sortedLayers, buildingId, getFloor, update]
  )

  const changeLayerKind = useCallback(
    async (layerId, newKind) => {
      await updateLayer(floorId, layerId, { kind: newKind })
      getFloor(buildingId, floorId)
    },
    [floorId, updateLayer, getFloor, buildingId]
  )

  const columns = useMemo(
    () => [
      {
        id: 'position',
        Header: 'Position',
        accessor: 'position',
        cellStyle: { textAlign: 'left' },
        sortType: 'basic',
      },
      {
        Header: 'File name',
        accessor: 'file.filename',
        cellStyle: { textAlign: 'left' },
        sortType: 'basic',
      },
      {
        Header: 'Type',
        accessor: 'kind',
        cellStyle: { textAlign: 'left' },
        Cell: ({ row: { original } }) => (
          <SelectInput
            onChange={e => changeLayerKind(original.id, e.target.value)}
            defaultValue={original.kind}
          >
            {LAYER_KINDS_SELECT_OPTIONS.map(opt => (
              <option value={opt.value} key={opt.value}>
                {opt.label}
              </option>
            ))}
          </SelectInput>
        ),
        sortType: 'basic',
      },
      {
        id: 'actions',
        Header: () => <div />,
        cellStyle: { textAlign: 'right' },
        Cell: ({ row: { original } }) => (
          <TableActionsCell>
            <ConfirmationModal
              title="Are you sure you want to delete this layer?"
              description="This action cannot be undone"
              onConfirm={confirmLayerDeletion}
              id={original.id}
              component={IconTrashBin}
            />
          </TableActionsCell>
        ),
      },
    ],
    [confirmLayerDeletion, changeLayerKind]
  )

  if (!floor) {
    return null
  }

  if (displayMode === 'file') {
    return (
      <AddAppWrapper onClose={onClose}>
        {/* onClose={onClose}> */}
        <AddAppContainer>
          <ImageUploader
            defaultPhoto={floor.photo}
            photo={photo}
            onDrop={onDrop}
            onRemove={onRemove}
          />
          <Actions>
            <Button
              size="large"
              color="primary"
              variant="contained"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              size="large"
              color="primary"
              variant="contained"
              onClick={onSubmit}
              disabled={!(photo && floor)}
            >
              Save
            </Button>
          </Actions>

          <Button
            color="primary"
            variant="text"
            onClick={switchToTable}
            mt="14px"
          >
            I have geojson files
          </Button>
        </AddAppContainer>
      </AddAppWrapper>
    )
  }

  if (addLayerModalState) {
    return (
      <AddAppWrapper onClose={onClose}>
        {/* onClose={closeAddLayerModal}> */}
        <AddAppContainer>
          <FileUploader
            acceptedFiles=".json, .geojson"
            showFiletypeIcon
            onDrop={onDropLayerFile}
            onRemove={onRemoveLayerFile}
          />
          <Actions>
            <Button
              size="large"
              color="primary"
              variant="contained"
              onClick={closeAddLayerModal}
            >
              Cancel
            </Button>
            <Button
              size="large"
              color="primary"
              variant="contained"
              onClick={onSubmitNewLayer}
              disabled={!newLayerFile}
            >
              Add
            </Button>
          </Actions>
        </AddAppContainer>
      </AddAppWrapper>
    )
  }

  // displayMode === 'layers'
  return (
    <AddAppWrapper onClose={onClose}>
      {/* onClose={switchToFile}> */}
      <AddAppContainer style={{ minWidth: '600px' }}>
        <DraggableDataTable
          initialSortState={initialSortState}
          columns={columns}
          rows={sortedLayers}
          withDragNDrop
          onDragEnd={onDragLayer}
        />

        <ButtonsContainer>
          <Button color="secondary" onClick={switchToFile} mt="14px">
            Back
          </Button>
          <Button color="primary" onClick={openAddLayerModal} mt="14px">
            Add layer
          </Button>
        </ButtonsContainer>
        <Button
          color="primary"
          variant="text"
          onClick={switchToFile}
          mt="14px"
          style={{ height: 34, minHeight: '34px' }}
        >
          Upload photo
        </Button>
      </AddAppContainer>
    </AddAppWrapper>
  )
}

const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  ${p =>
    p.triple &&
    `
  grid-template-columns: 1fr 1fr 1fr;
  `}
  grid-gap: 20px;
`

const AddAppWrapper = styled(Modal)``

const AddAppContainer = styled.div`
  padding: 20px;
  margin-top: 43px;
  box-shadow: 0 9.08776px 14.3889px rgba(60, 128, 209, 0.05);
  border-radius: 10px;
  border: 10px solid white;
  background: #fafbff;
  display: flex;
  flex-direction: column;
`

const Actions = styled.div`
  display: flex;
  justify-content: space-around;
`

const ImageUploader = styled(ImageUploaderComponent)`
  min-height: 210px;
`

const TableActionsCell = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: fit-content;
  column-gap: 12px;
  margin: auto;
  float: right;

  > * {
    cursor: pointer;
  }
`
