import React, { useCallback, useRef, useState } from 'react'
import styled from 'styled-components/macro'
import { toast } from 'react-toastify'
import DropzoneComponent from 'react-dropzone-component'

import 'react-dropzone-component/styles/filepicker.css'
import 'dropzone/src/dropzone.scss'

export const ImageUploader = ({
  className,
  dropZoneClassName,
  defaultPhoto,
  onDrop,
  onRemove,
  photo,
  autoWidth,
  acceptedFiles = '.jpg, .jpeg, .png, .webp',
  acceptedFilesType = 'image/jpg, image/jpeg, image/png, image/webp',
  showFiletypeIcon = false,
  onUploadedPhoto = () => {},
}) => {
  const [showPreview, setShowPreview] = useState(defaultPhoto?.url)
  const [fileAdded, setFileAdded] = useState(defaultPhoto)
  const [isLoading, setIsLoading] = useState(false)
  const dropZone = useRef()
  const uploader = useRef()

  const componentConfig = {
    postUrl: 'no-url',
    maxFiles: 2,
    iconFiletypes: acceptedFiles.split(','),
  }
  const djsConfig = {
    acceptedFiles: acceptedFilesType,
    maxFilesize: 10,
    autoProcessQueue: false,
    showFiletypeIcon,
    addRemoveLinks: true,
    postUrl: 'no-url',
    maxFiles: 2,
    uploadMultiple: false,
  }

  const progressCallback = useCallback(event => {
    const progress = (event.loaded / event.total) * 100
    dropZone.current.querySelector('.dz-upload').style.width = `${progress}%`
  }, [])

  const successCallback = useCallback(file => {
    uploader.current.emit('complete', file)
    uploader.current.emit('success', file)
    setIsLoading(false)
  }, [])

  const eventHandlers = {
    init: item => {
      uploader.current = item
    },
    addedfile: file => {
      if (
        acceptedFilesType &&
        !new RegExp(file.type.toLowerCase()).test(acceptedFilesType)
      ) {
        toast.error('Not supported file format.')
        dropZone.current.querySelector('.dz-file-preview').remove()

        return false
      }

      const reader = new FileReader()
      reader.onloadend = () => {
        onUploadedPhoto(reader.result)
      }
      reader.readAsDataURL(file)
      const currentFile = dropZone.current.querySelectorAll('.dz-preview')
      if (!photo && currentFile.length === 1) {
        setFileAdded(true)
        setShowPreview(false)
        setIsLoading(true)

        onDrop(file, progressCallback, () => successCallback(file))
      } else {
        dropZone.current.querySelector('.dz-file-preview').remove()
      }
    },
    removedfile: () => {
      setFileAdded(!!defaultPhoto)
      setShowPreview(!!defaultPhoto)
      onRemove()
    },
  }

  return (
    <Wrapper className={className}>
      <DropAreaContainer
        ref={dropZone}
        className={dropZoneClassName}
        photo={photo}
      >
        {!fileAdded && (
          <DefaultText>
            Drag a file here of <span> browse </span> for a file to upload
          </DefaultText>
        )}
        {!!defaultPhoto && !photo && !isLoading && showPreview && (
          <DefaultImage
            autoWidth={autoWidth}
            src={defaultPhoto?.url}
            alt="Preview"
            showPreview={showPreview}
          />
        )}
        {!!defaultPhoto &&
          !photo &&
          !isLoading &&
          defaultPhoto.filename &&
          showPreview && (
            <DefaultPhotoText>{defaultPhoto.filename}</DefaultPhotoText>
          )}
        <DropzoneComponent
          config={componentConfig}
          eventHandlers={eventHandlers}
          djsConfig={djsConfig}
        />
      </DropAreaContainer>
    </Wrapper>
  )
}

const DefaultPhotoText = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  top: 50%;
  text-align: center;
  padding: 0.4em 0.5em 0.2em;
  word-break: break-all;
  border-radius: 3px;
  border: 1px solid rgba(200, 200, 200, 0.8);
  background-color: rgba(255, 255, 255, 0.8);
  color: #5468ff;
`

export const Wrapper = styled.div``

export const ImageUploaderComponent = styled.div``

const DropAreaContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 283px;
  min-height: 180px;
  margin-bottom: 15px;
  font-size: 13px;
  line-height: 13px;
  border: 0.901274px dashed #e5edff;
  box-sizing: border-box;
  border-radius: 6.30892px;
  color: #344356;

  span {
    color: #5468ff;
  }
  .dz-file-preview {
    display: none;
  }
  .dropzone {
    display: flex;
    width: 283px;
    min-height: 93px;
    justify-content: center;
    align-items: center;
    .dz-preview.dz-image-preview {
      margin: 5px;
    }
  }
  .dz-default.dz-message,
  .dz-error-mark,
  .dz-error-message,
  .dz-success-mark {
    display: none;
  }
  .dz-preview.dz-error .dz-error-mark {
    opacity: 0;
  }
  .dropzone .dz-preview .dz-error-message {
    bottom: 100%;
    top: initial;
    margin-bottom: 15px;
    width: 160px;
    left: -20px;
    &:after {
      left: 74px;
      top: initial;
      bottom: -12px;
      border-top: 6px solid #a92222;
      border-bottom: 6px solid transparent;
    }
    span {
      font-family: 'Biko';
      color: white;
    }
  }
  .dz-preview {
    .dz-details {
      display: grid;
      padding: 0 !important;
      justify-items: center;
      align-items: center;
      height: 100%;
      font-family: 'Biko';
      left: -100px;
      right: -100px;
      max-width: initial;
      .dz-size {
        margin-bottom: 40px;
      }
      .dz-filename {
        white-space: normal;
        word-break: break-word;
      }
    }
  }

  div.filepicker {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
  }
  .dz-remove {
    position: absolute;
    text-align: center;
    width: 100%;
    margin-top: 8px;
    font-family: 'Biko';
    color: #ff6355;
    z-index: 21;
  }
  .dz-upload {
    background: linear-gradient(to bottom, #5468ff, #00abfc) !important;
  }
  ${props =>
    props.photo &&
    `
    .dz-success-mark {
      display: block;
      opacity: 1!important;
    }
    .dz-progress{
       display: none;
    }
  `}
`
const DefaultImage = styled.img`
  width: ${props => (props.autoWidth === 'true' ? 'auto' : '260px')};
  margin-top: 10px;
  pointer-events: none;
  ${props =>
    !props.showPreview &&
    `
       display: none;
  `}
`
const DefaultText = styled.div`
  pointer-events: none;
`
