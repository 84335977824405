import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components/macro'

import {
  Building,
  Address,
  Preview,
  Photo,
  Actions,
} from 'src/pages/buildings/buildings'
import { useApplications } from 'src/modules/applications'
import { useBuildings } from 'src/modules/buildings'
import { useCompany } from 'src/modules/company'
import { StyledButtonLink } from 'src/components/ButtonLink'
import { Modal } from 'src/components/Modal'

import {
  AccordionItem,
  Loader,
  SecuredField as TextFieldUI,
  Button,
  Title,
} from 'src/ui'

function BuildingIntegrationComponent() {
  const params = useParams()
  const buildingId = +params.buildingId

  const {
    buildings,
    get: getBuildings,
    isLoading: isBuiildingLoading,
  } = useBuildings()

  const { company } = useCompany()

  const {
    applicationId,
    applications,
    isLoading,
    get: getApplications,
  } = useApplications()
  const building = buildings.find(item => item.id === buildingId) || {}
  const [showQrCode, setShowQrCode] = useState(false)

  const application = applications.find(item => item.id === +applicationId)

  useEffect(() => {
    if (!buildings?.length) {
      getBuildings()
    }
    if (!applications?.length) {
      getApplications()
    }
  }, [applications.length, buildings.length, getApplications, getBuildings])

  if (isLoading || isBuiildingLoading) {
    return <Loader />
  }

  function handleShowQrCoCde() {
    setShowQrCode(!showQrCode)
  }

  return (
    <Building>
      <Title>{building.name}</Title>
      <Address>{building.address}</Address>
      <Preview>
        {building.photo && <Photo src={building.photo.medium} alt="preview" />}
      </Preview>
      <AccordionItem title="Keys">
        <TextField
          fullWidth
          label="Visitor APP ID"
          name="Visitor APP ID"
          inputProps={{
            type: 'password',
            readonly: true,
            disabled: true,
            defaultValue: application.visitor_uid,
            placeholder: '483693909',
          }}
        />

        <TextField
          fullWidth
          label="Visitor APP Secret"
          name="Visitor APP Secret"
          inputProps={{
            type: 'password',
            readonly: true,
            disabled: true,
            defaultValue: application.visitor_secret,
            placeholder: '483693909. 101 RX',
          }}
        />
        <TextField
          fullWidth
          label="Admin APP ID"
          name="Admin APP ID"
          inputProps={{
            type: 'password',
            readonly: true,
            disabled: true,
            defaultValue: application.admin_uid,
            placeholder: '483693909',
          }}
        />

        <TextField
          fullWidth
          label="Admin APP Secret"
          name="Admin APP Secret"
          inputProps={{
            type: 'password',
            readonly: true,
            disabled: true,
            defaultValue: application.admin_secret,
            placeholder: '483693909. 101 RX',
          }}
        />

        {application.qrcode_url && (
          <ShowQrButton
            src={application.qrcode_url}
            alt="QR Code"
            onClick={handleShowQrCoCde}
            color="primary"
            variant="contained"
          >
            View QR Code
          </ShowQrButton>
        )}
      </AccordionItem>

      <ActionsStyled>
        <StyledButtonLink
          size="large"
          color="secondary"
          variant="contained"
          to="/buildings"
        >
          Back
        </StyledButtonLink>
      </ActionsStyled>
      {showQrCode && (
        <Popup show={showQrCode}>
          <QrContainer>
            <QRCode src={application.qrcode_url} alt="QR Code" />
            <p>{company.name}</p>
            <CloseQr type="button" onClick={handleShowQrCoCde} />
          </QrContainer>
        </Popup>
      )}
    </Building>
  )
}

export const BuildingIntegration = styled(BuildingIntegrationComponent)``

const TextField = styled(TextFieldUI)`
  ${props =>
    props.isNew &&
    `
      & label{
        color: #9AACCF;
      }
  `}
  margin-bottom: 25px;
  input {
    margin-top: 9px;
  }
`

const QRCode = styled.img`
  display: block;
  max-width: 280px;
  height: auto;
`

const ActionsStyled = styled(Actions)`
  margin-top: 20px;
`
const ActionButton = styled(Button)`
  width: 148px;
  padding: 0;
`
const ShowQrButton = styled(ActionButton)`
  display: block;
  margin: 0 auto;
`

const Popup = styled(Modal)`
  opacity: 0;
  background: rgba(255, 255, 255, 0.7);
  pointer-events: none;
  transition-duration: 0.3s;
  ${props =>
    props.show &&
    `
    opacity: 1;
    pointer-events: all;
  `}
`

const QrContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 340px;
  height: 370px;
  padding-top: 20px;
  background: white;
`

const CloseQr = styled.button`
  position: absolute;
  top: -25px;
  right: -25px;
  appearance: none;
  box-shadow: none;
  border: none;
  outline: none;
  height: 50px;
  width: 50px;
  background: white;
  border-radius: 50%;
  cursor: pointer;
  &:after,
  &:before {
    position: absolute;
    content: '';
  }
  &:before {
    top: 22px;
    left: 12px;
    display: block;
    content: '';
    height: 1px;
    width: 26px;
    border-bottom: 4px solid red;
    transform: rotate(45deg);
  }
  &:after {
    top: 11px;
    left: 23px;
    display: block;
    content: '';
    height: 26px;
    width: 1px;
    border-right: 4px solid red;
    transform: rotate(45deg);
  }
`
