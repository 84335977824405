import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { AddSubtitle as RoundButton } from 'src/ui/form/TagInput/TagsInputField'
import { Label } from 'src/ui/form/FormField'
import { ReactComponent as IconTrashBin } from 'src/assets/images/svg/icon-trash-bin.svg'
import { Modal } from 'src/components/Modal'
import { RadioGroup } from 'src/ui/form/RadioGroup'
import { Button, TextFieldUI } from 'src/ui'

const preventSubmit = event => {
  event.stopPropagation()
  event.preventDefault()
}

const list = [
  {
    name: 'Not set',
    value: 'missing',
  },
  {
    name: 'Closed',
    value: 'closed',
  },
  {
    name: 'Open 24/7',
    value: 'open24',
  },
  {
    name: 'Daily schedule',
    value: 'daily',
  },
]
const week = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sun', 'Sat']

export const WorkingHoursModal = props => {
  const { onClose, period, workingHours, onChange } = props

  const defaultTime = '00:00'

  const [currentView, setCurrentView] = useState(props.openPeriod)
  const [currentPeriod, setCurrentPeriod] = useState()
  const [initiated, setInitiated] = useState(false)
  // const [workingHoursInitiated, setWorkingHoursInitiated] = useState(false)

  const addNewPeriod = (event, index) => {
    preventSubmit(event.nativeEvent)
    currentPeriod[index].working_hours.push({
      start: defaultTime,
      end: defaultTime,
    })
    setCurrentPeriod([...currentPeriod])
  }
  const removePeriod = (event, index, timeIndex) => {
    preventSubmit(event)

    const updatedPeriod = [...currentPeriod]
    updatedPeriod[index].working_hours.splice(timeIndex, 1)
    setCurrentPeriod(updatedPeriod)
  }

  const handleRadioChange = event => {
    setCurrentView(event.target.value)
  }

  const workingHoursInitial = () => {
    const updatedPeriod = []
    workingHours.forEach((day, index) => {
      updatedPeriod[index] = {
        day: week[index],
        working_hours: day?.map(hoursPeriod => {
          if (!Array.isArray(hoursPeriod)) {
            return hoursPeriod.replace('/', ':')
          }

          return {
            start: hoursPeriod[0],
            end: hoursPeriod[1],
          }
        }),
      }
    })

    return updatedPeriod
  }

  const viewUpdate = () => {
    if (!initiated) {
      return false
    }
    let updatedPeriod = []
    switch (currentView) {
      case 'daily':
        if (!Array.isArray(workingHours)) {
          let i = 0
          for (; i <= 6; i++) {
            updatedPeriod.push({
              day: week[i],
              working_hours: period?.[i]?.length
                ? period[i]
                : [{ start: defaultTime, end: defaultTime }],
            })
          }
        } else {
          updatedPeriod = workingHoursInitial()
        }
        setCurrentPeriod(updatedPeriod)
        break
      case 'missing':
        setCurrentPeriod('')
        break
      case 'open24':
        setCurrentPeriod('00/00')
        break
      default:
        setCurrentPeriod('--/--')
        break
    }
  }

  const initialization = () => {
    let selectedView = 'missing'

    if (workingHours) {
      if (workingHours === '00/00') {
        selectedView = 'open24'
      } else if (workingHours === '--/--') {
        selectedView = 'closed'
      } else if (workingHours.length === week.length) {
        selectedView = 'daily'
        const originalHours = [...workingHoursInitial()]
        setCurrentPeriod(originalHours)
        // setWorkingHoursInitiated(originalHours)
      }
    }

    setCurrentView(selectedView)
    setInitiated(!initiated)
  }

  const handleChange = () => {
    onChange(currentPeriod)
    onClose()
  }

  const handleTimeChange = (event, update) => {
    const updatedPeriod = [...currentPeriod]
    updatedPeriod[update.weekDay].working_hours[update.periodPosition] =
      update.value

    setCurrentPeriod(updatedPeriod)
  }

  const handleTimeBlur = (event, options) => {
    const updatedPeriod = [...currentPeriod]

    if (!event.target.value) {
      event.target.value = defaultTime

      updatedPeriod[options.weekDay].working_hours[options.periodPosition][
        options.key
      ] = defaultTime

      setCurrentPeriod(updatedPeriod)
    }
  }

  const handleDayTimeChange = (event, options) => {
    const updatedPeriod = [...currentPeriod]

    const allDay =
      !options.isActive && (options.isAllDay ? defaultTime : '--:--')

    const result = options.isActive
      ? { start: defaultTime, end: defaultTime }
      : allDay

    updatedPeriod[options.weekDay].working_hours = [result]
    setCurrentPeriod(updatedPeriod)
  }

  useEffect(() => {
    initialization()
  }, [])

  useEffect(() => {
    viewUpdate()
  }, [currentView])

  return (
    <Modal onClose={onClose} closeOnEsc={false} closeOnOutsideClick={false}>
      <ModalContainer>
        <Container>
          <SubHeader>
            <Label>Working Hours</Label>
          </SubHeader>

          <RadioGroup
            list={list}
            name="working_hours"
            onChange={handleRadioChange}
            defaultValue={currentView}
          />

          {currentView === 'daily' && (
            <>
              {Array.isArray(currentPeriod) &&
                currentPeriod?.map((day, index) => {
                  const isDaily =
                    Array.isArray(day.working_hours) &&
                    !day.working_hours[0].includes

                  const isAllDay =
                    !isDaily && day.working_hours[0].includes(defaultTime)

                  const isClosed =
                    !isDaily && day.working_hours[0].includes('--:--')

                  return (
                    <CustomItem key={week[index]}>
                      <Period>
                        <WeekDay>{week[index]}</WeekDay>

                        {isDaily &&
                          day.working_hours.map((hours, timeIndex) => (
                            <TimePeriod key={`${week[index]}${timeIndex + 1}`}>
                              <TextFieldStyled
                                fullWidth
                                placeholder="00"
                                maxLength={255}
                                type="time"
                                min={defaultTime}
                                max="23:59"
                                value={hours.start}
                                size="small"
                                onChange={event =>
                                  handleTimeChange(event, {
                                    weekDay: index,
                                    periodPosition: timeIndex,
                                    value: {
                                      start: event.target.value,
                                      end: hours.end,
                                    },
                                  })
                                }
                                onBlur={event =>
                                  handleTimeBlur(event, {
                                    weekDay: index,
                                    periodPosition: timeIndex,
                                    key: 'start',
                                  })
                                }
                              />
                              /
                              <TextFieldStyled
                                fullWidth
                                placeholder="00"
                                maxLength={255}
                                type="time"
                                min={defaultTime}
                                max="23:59"
                                value={hours.end}
                                size="small"
                                onChange={event =>
                                  handleTimeChange(event, {
                                    weekDay: index,
                                    periodPosition: timeIndex,
                                    value: {
                                      start: hours.start,
                                      end: event.target.value,
                                    },
                                  })
                                }
                                onBlur={event =>
                                  handleTimeBlur(event, {
                                    weekDay: index,
                                    periodPosition: timeIndex,
                                    key: 'end',
                                  })
                                }
                              />
                              {day.working_hours.length > 1 && (
                                <IconTrashBinStyled
                                  onClick={event => {
                                    removePeriod(event, index, timeIndex)
                                  }}
                                />
                              )}
                            </TimePeriod>
                          ))}

                        {!isDaily && (
                          <TextFieldStyled
                            fullWidth
                            placeholder="--"
                            maxLength={255}
                            type="time"
                            readonly
                            value={day.working_hours[0]}
                            size="small"
                            disabled
                          />
                        )}
                        {isDaily && (
                          <RoundButton
                            onClick={event => addNewPeriod(event, index)}
                          >
                            +
                          </RoundButton>
                        )}
                      </Period>
                      {Boolean(!props.isView) && (
                        <Actions>
                          <Button
                            color={isClosed ? 'primary' : ''}
                            onClick={event =>
                              handleDayTimeChange(event, {
                                weekDay: index,
                                isAllDay: false,
                                isActive: isClosed,
                              })
                            }
                          >
                            Closed
                          </Button>
                          <Button
                            color={isAllDay ? 'primary' : ''}
                            onClick={event =>
                              handleDayTimeChange(event, {
                                weekDay: index,
                                isAllDay: true,
                                isActive: isAllDay,
                              })
                            }
                          >
                            {' '}
                            All day
                          </Button>
                        </Actions>
                      )}
                    </CustomItem>
                  )
                })}
            </>
          )}
          <ModalActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button color="primary" onClick={handleChange}>
              Confirm
            </Button>
          </ModalActions>
        </Container>
      </ModalContainer>
    </Modal>
  )
}

const Container = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  button,
  label {
    margin: 0;
  }

  input[type='time']::-webkit-calendar-picker-indicator {
    display: none;
    background: none;
    pointer-events: none !important;
    outline: none;
  }
`

const FlexContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`

const Period = styled(FlexContainer)``
const SubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  ${Label} {
    font-size: 16px;
  }
`

const Actions = styled(FlexContainer)``
const ModalActions = styled(FlexContainer)`
  width: 100%;
  padding-top: 16px;
  justify-content: end;
  border-top: 1px solid #e2ebffcf;
`
const TimePeriod = styled(FlexContainer)`
  gap: 4px;
`
const WeekDay = styled.div`
  width: 20px;
`

const CustomItem = styled(FlexContainer)`
  justify-content: space-between;
  width: 100%;
  ${Actions} {
    button {
      padding: 0 10px;
    }
  }
`

const TextFieldStyled = styled(TextFieldUI)`
  margin: 0;
  input {
    padding: 10px 5px 10px 5px;
  }
`

const ModalContainer = styled.div`
  min-width: 800px;
  width: auto;
  padding: 30px;
  background-color: white;
  border-radius: 5px;
`

const IconTrashBinStyled = styled(IconTrashBin)`
  cursor: pointer;
`
