import React, { createContext, useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { useMap } from 'src/modules/map'
import { clearLayers } from 'src/pages/MapStudio/controllers/clearLayers'
import { useFloors } from 'src/modules/floors'
import { useBuildings } from 'src/modules/buildings'
import { useBuildingPois } from 'src/hooks/useBuildingPois'
import { usePlan } from 'src/pages/MapStudio/features/Plan'

const MapStudioContext = createContext()

export function MapStudioProvider(props) {
  const { map, setShowMarkers } = useMap()
  const location = useLocation()
  const showBeaconOnMap = new URLSearchParams(location.search).get('beacon')

  const [startAddPoi, setStartAddPoi] = useState(false)
  const [idsForUpdate, setIdsForUpdate] = useState([])
  const [selectedPolygon, setSelectedPolygon] = useState()
  const [isDrawLoaded, setIsDrawLoaded] = useState(false)
  const [buildingRoute, setBuildingRoute] = useState()

  const [floorPois, setFloorPois] = useState(null)
  const [floorAnglesMarkers, setFloorAnglesMarkers] = useState()

  const [startAddWay, setStartAddWay] = useState()
  const [startSelectPoi, setStartSelectPoi] = useState()
  const [startAddElevator, setStartAddElevator] = useState({ value: false })
  const [startAddStairs, setStartAddStairs] = useState({ value: false })
  const [startAddBeacon, setStartAddBeacon] = useState()
  const [wayWasAdded, setWayWasAdded] = useState()
  const [showBeacon, setShowBeacon] = useState(showBeaconOnMap)
  const [showBeacons, setShowBeacons] = useState(!showBeaconOnMap)
  const [loadedBeacons, setLoadedBeacons] = useState([])
  const [loadedBeaconsOnMap, setLoadedBeaconsOnMap] = useState([])
  const [showRoutes, setShowRoutes] = useState(!showBeaconOnMap)
  const [stopRouteDraw, setStopRouteDraw] = useState()

  const [currentRoute, setCurrentRoute] = useState({
    activeTool: 'twoWay',
  })

  const [activeTool] = useState({
    value: 'twoWay',
  })

  const [isShowPointId, setIsShowPointId] = useState({
    value: false,
  })
  const [isShowDirection, setIsShowDirection] = useState({
    value: false,
  })

  const [routeGeojson, setRouteGeojson] = useState({
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {
          msNeighbors: [],
        },
        geometry: {
          type: 'MultiLineString',
          coordinates: [],
        },
      },
    ],
  })

  const [building, setBuilding] = useState(null)
  const [floor, setFloor] = useState(null)

  const { getBuilding } = useBuildings()
  const { getFloor } = useFloors()
  const { get: getFloorPois, isLoading: isMapStudioLoading } = useBuildingPois()

  const { buildingId, floorId } = useParams()

  useEffect(() => {
    async function fetch() {
      const loadedBuilding = await getBuilding(buildingId)
      const loadedFloor = await getFloor(buildingId, floorId)
      const loadedFloorPois = await getFloorPois()
      // if (loadedBuilding?.jibestream_id) {
      // }
      setBuilding(loadedBuilding)
      setFloor(loadedFloor)
      setFloorPois(loadedFloorPois)
    }

    fetch()
  }, [buildingId, floorId, getBuilding, getFloor, getFloorPois])

  usePlan({
    building,
    floor,
    floorAnglesMarkers,
    setFloorAnglesMarkers,
  })

  useEffect(() => {
    setShowMarkers([])

    if (map) {
      return function cleanup() {
        clearLayers(map)
      }
    }
  }, [map, setShowMarkers])

  useEffect(() => {
    if (floorAnglesMarkers) {
      return () => {
        floorAnglesMarkers.forEach(marker => marker.remove())
      }
    }
  }, [floorAnglesMarkers])

  const contextValue = {
    building,
    floor,

    currentRoute,
    setCurrentRoute,
    activeTool,
    isShowPointId,
    setIsShowPointId,
    isShowDirection,
    setIsShowDirection,

    idsForUpdate,
    setIdsForUpdate,
    setRouteGeojson,
    setBuildingRoute,
    buildingRoute,

    floorPois,
    setFloorPois,
    floorAnglesMarkers,
    setFloorAnglesMarkers,

    showRoutes,
    setShowRoutes,
    startSelectPoi,
    setStartSelectPoi,
    setStartAddPoi,
    startAddPoi,

    startAddWay,
    startAddElevator,
    setStartAddElevator,
    setStartAddStairs,
    startAddStairs,
    setStartAddWay,
    startAddBeacon,
    setStartAddBeacon,
    wayWasAdded,
    setWayWasAdded,
    loadedBeacons,
    setLoadedBeacons,
    loadedBeaconsOnMap,
    setLoadedBeaconsOnMap,
    setShowBeacons,
    setShowBeacon,
    showBeacons,
    showBeacon,
    routeGeojson,
    selectedPolygon,
    setSelectedPolygon,
    setIsDrawLoaded,
    isDrawLoaded,
    stopRouteDraw,
    setStopRouteDraw,
    isLoading: isMapStudioLoading,
  }

  return (
    <MapStudioContext.Provider value={contextValue}>
      {props.children}
    </MapStudioContext.Provider>
  )
}

export function useMapStudio() {
  return useContext(MapStudioContext)
}
