import dayjs from 'dayjs'

window.x = dayjs

export const formatDate = (
  date,
  format = 'DD/MM/YY',
  fallbackText = 'Invalid date'
) => {
  try {
    return dayjs(date).format(format)
  } catch {
    return fallbackText
  }
}

export const formatTime = (
  date,
  format = 'DD/MM/YY HH:mm',
  fallbackText = 'Invalid date'
) => {
  try {
    return dayjs(date).format(format)
  } catch {
    return fallbackText
  }
}
