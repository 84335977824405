import React, { useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { useDispatch, useSelector } from 'react-redux'

import { Modal } from 'src/components/Modal'
import { Button, Loader } from 'src/ui'
import { Form, FormSpy } from 'react-final-form'
import { Alert } from 'src/components/Alert'

import validate from 'src/utils/validate'

import isRequired from 'src/utils/validators/isRequired'

import { getJBuildings, jibestreamActions } from 'src/redux/slicers/jibestream'
import { SelectField } from 'src/components/SelectField'

export function ConnectJibestreamBuilding(props) {
  const { onClose, jAccount, onBuildingAdded, fetchJBuilding } = props
  const { setJBuilding } = jibestreamActions

  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()
  const jBuildings = useSelector(getJBuildings)

  const options = useMemo(() => {
    return (
      jBuildings?.map(item => ({
        label: item.name,
        value: item.id,
      })) || []
    )
  }, [jBuildings])

  const onValidate = values => {
    const errors = {}

    errors.jibestreamId = validate(values.jibestreamId, isRequired)

    return errors
  }

  const initialValues = {
    jibestreamId: options[0].value,
    name: true,
    building_coordinate: true,
    floors: true,
  }

  const onSubmit = async ({ jibestreamId }) => {
    setIsLoading(true)
    const build = await fetchJBuilding(jibestreamId, jAccount)

    await dispatch(setJBuilding(build))
    setIsLoading(false)
    onBuildingAdded(build)
  }

  const getSubmitError = () => {}

  return (
    <Modal onClose={onClose}>
      <ModalContainer>
        <Form
          onSubmit={onSubmit}
          validate={onValidate}
          initialValues={initialValues}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} style={{ textAlign: 'center' }}>
              <SelectField
                fullWidth
                isMulti={false}
                isSearchable={false}
                options={options}
                label="Building"
                name="jibestreamId"
              />

              <FormSpy
                subscription={{
                  submitting: true,
                  pristine: true,
                  valid: true,
                  dirtySinceLastSubmit: true,
                  hasValidationErrors: true,
                  submitErrors: true,
                  error: true,
                  errors: true,
                  submitFailed: true,
                }}
              >
                {({ valid, submitFailed, submitErrors }) => {
                  const submitError = getSubmitError(submitErrors?.error)

                  return (
                    <ActionWrapper>
                      <div>
                        {!valid && submitFailed && (
                          <Alert
                            type="error"
                            title={t(submitError?.title)}
                            description={t(submitError?.description)}
                          />
                        )}
                      </div>

                      <div>
                        <Button
                          color="primary"
                          width="100%"
                          bold
                          size="lg"
                          onClick={handleSubmit}
                        >
                          {t('Connect')}
                        </Button>
                      </div>
                    </ActionWrapper>
                  )
                }}
              </FormSpy>
            </form>
          )}
        />
      </ModalContainer>
      {isLoading && <Loader />}
    </Modal>
  )
}

const ActionWrapper = styled.div`
  display: flex;
  column-gap: 20px;
  justify-content: end;
`
const ModalContainer = styled.div`
  width: 800px;
  max-width: 90vw;
  padding: 30px;
  background-color: white;
  border-radius: 5px;
`
