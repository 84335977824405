import React from 'react'
import styled from 'styled-components/macro'

import { AccordionItem, Button } from 'src/ui'
import { Link as LinkComponent } from 'src/components/Link'
import { useParams } from 'react-router-dom'
import { useAbility } from 'src/hooks/useAbility'

function EmptyComponent(props) {
  const { label, create, actions, isLoading } = props
  const { buildingId, floorId } = useParams()

  const {
    engagement: { update: canUpdate },
  } = useAbility()

  return (
    <>
      <AccordionItem title={label.toLocaleUpperCase()}>
        {!actions?.length && (
          <NotCreated>
            <Name>{label}</Name> not created
          </NotCreated>
        )}
        <Links>
          {!isLoading &&
            !!actions?.length &&
            actions.map(trigger => (
              <Link
                key={trigger.id}
                to={`/buildings/${buildingId}/floors/${floorId}/engagements/${trigger.id}`}
              >
                {trigger.activity?.name}
              </Link>
            ))}
        </Links>
      </AccordionItem>
      <Actions>
        <Create
          size="large"
          color="secondary"
          variant="contained"
          disabled={!canUpdate}
          onClick={() => create()}
        >
          Create Engagement
        </Create>
      </Actions>
    </>
  )
}

export const Empty = styled(EmptyComponent)``
const Actions = styled.div`
  margin-top: 25px;
  margin-left: 13px;
`

const Create = styled(Button)`
  &:disabled {
    opacity: 0.5;
  }
`

const NotCreated = styled.div`
  margin-bottom: 13px;
  font-family: 'Biko';
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.04em;
  color: #9aaccf;
`
const Name = styled.span`
  text-transform: capitalize;
`
const Links = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`
const Link = styled(LinkComponent)`
  margin-bottom: 10px;
  text-decoration: none;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
`
