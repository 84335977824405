import React, { useMemo } from 'react'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components/macro'

import { NavTemplate } from 'src/components/NavTemplate'
import { Companies, Members, Invitations } from 'src/pages/Companies'
import { useCompany } from 'src/modules/company'
import { PageContentWrapper, PageWithHeader } from 'src/pages/PageTemplate'
import { Loader } from 'src/ui'

export const CompaniesPage = () => {
  const { company, isCompaniesInitiated } = useCompany()

  const canManage = useMemo(() => /owner|super_admin/.test(company?.role), [
    company,
  ])

  const menuLink = useMemo(() => {
    if (!company?.id || !company?.subscription || !canManage) {
      return [
        {
          to: '/companies',
          title: 'Companies',
        },
      ]
    }

    return [
      {
        to: '/companies',
        title: 'Companies',
      },
      {
        to: '/companies/members',
        title: 'Members',
      },
      {
        to: '/companies/invitations',
        title: 'Invitations',
      },
    ]
  }, [company, canManage])

  if (!isCompaniesInitiated) {
    return <Loader />
  }

  return (
    <>
      <Switch>
        {!company?.id && (
          <PageWithHeaderStyled>
            <PageContentWrapperStyled>
              <Route exact path="/companies" component={Companies} />
            </PageContentWrapperStyled>
          </PageWithHeaderStyled>
        )}

        {company?.id && (
          <NavTemplate menuLinks={menuLink}>
            <Route exact path="/companies" component={Companies} />

            {canManage && (
              <>
                <Route exact path="/companies/members" component={Members} />
                <Route
                  exact
                  path="/companies/invitations"
                  component={Invitations}
                />
              </>
            )}
          </NavTemplate>
        )}
      </Switch>
    </>
  )
}

const PageWithHeaderStyled = styled(PageWithHeader)`
  padding: 0;
`
const PageContentWrapperStyled = styled(PageContentWrapper)`
  padding: 72px 0 0 0;
`
