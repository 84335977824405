import React, {
  useContext,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { useAuth } from 'src/modules/auth'
import { useOAuth } from 'src/modules/oAuth'

import { api } from './api'

const CompanyContext = createContext()

export function CompanyProvider(props) {
  const { isAuthenticated } = useAuth()
  const { isOAuthLoading } = useOAuth()

  const [isLoading, setIsLoading] = useState(false)
  const [isCompaniesLoading, setIsCompaniesLoading] = useState(false)
  const [isCompaniesInitiated, setIsCompaniesInitiated] = useState(false)

  const [company, setCompany] = useState()
  const [companies, setCompanies] = useState()
  const [subscription, setSubscription] = useState(false)

  const get = useCallback(async () => {
    setIsLoading(true)

    const res = await api.get('saas_company')

    if (res.ok) {
      setCompany(res?.json?.saas_company)
      setSubscription(res?.json?.saas_company?.subscription)
    }

    setIsLoading(false)

    return res
  }, [])

  const create = useCallback(async data => {
    setIsLoading(true)

    const res = await api.post('saas_companies', data)

    if (res.ok) {
      setCompany(res?.json?.saas_company)
    }

    setIsLoading(false)

    return res
  }, [])

  const getCompanies = useCallback(async () => {
    setIsCompaniesLoading(true)
    const res = await api.get('saas_companies')

    if (res.ok) {
      await setCompanies(res.json.saas_companies)
    }

    setIsCompaniesLoading(false)

    return res
  }, [])

  const update = useCallback(async data => {
    setIsLoading(true)

    const res = await api.patch('saas_company', data)

    setIsLoading(false)

    return res
  }, [])

  const companiesLoad = useCallback(async () => {
    await getCompanies()
    await get()
    setIsCompaniesInitiated(true)
  }, [get, getCompanies])

  useEffect(() => {
    if (isAuthenticated && isOAuthLoading) {
      companiesLoad()
    }
  }, [isAuthenticated, isOAuthLoading, companiesLoad])

  const contextValue = {
    isLoading,
    isCompaniesLoading,
    isCompaniesInitiated,
    get,
    update,
    company,
    create,
    companies,
    subscription,
    getCompanies,
  }

  return (
    <CompanyContext.Provider value={contextValue}>
      {props.children}
    </CompanyContext.Provider>
  )
}

export function useCompany() {
  return useContext(CompanyContext)
}
