import React, { useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components/macro'

import { ModalDialog } from 'src/components/ModalDialog'
import { useSubscription } from 'src/hooks/useSubscription'
import { useCompany } from 'src/modules/company'
import { formatDate, formatTime } from 'src/utils/formatDateTime'
import { Button } from 'src/ui'

export const SubscriptionDetails = props => {
  const { showChargeForm, handleShowChargeForm } = props
  const { cancel: cancelSubscription } = useSubscription()
  const { get: getCompany, subscription, company } = useCompany()

  const [showConfirmation, setShowConfirmation] = useState(false)
  const handleCancel = () => {
    setShowConfirmation(false)
  }

  const handleOpenConfirm = () => {
    setShowConfirmation(true)
  }

  async function handleCancelSubscription() {
    await cancelSubscription()
    await getCompany()
    toast.dismiss()
    handleCancel()
  }

  const subscriptionStatus = useMemo(() => {
    if (subscription.status === 'created') {
      return ' (payment pending)'
    }

    if (
      subscription.status === 'past_due' &&
      +company?.balance_in_currency >= +company?.price_in_currency
    ) {
      return " (last payment attempt wasn't successful, we'll try again in a few hours)"
    }
    if (subscription.status === 'past_due') {
      return ' (payment failed)'
    }

    return ''
  }, [
    company?.balance_in_currency,
    company?.price_in_currency,
    subscription.status,
  ])

  return (
    <>
      <p>
        <b>Status</b>: {subscription.status.replace('_', ' ')}
        {subscriptionStatus}
      </p>

      {subscription.canceled_at && (
        <p>
          <b>Expires At</b>: {formatTime(subscription.expires_at)}
        </p>
      )}
      {!subscription.canceled_at &&
        ['trialing'].indexOf(subscription.status) >= 0 && (
          <p>
            <b>Trial Ends</b>: {formatDate(subscription.trial_ends_at)}
          </p>
        )}

      {!subscription.canceled_at &&
        /active|past_due/.test(subscription.status) && (
          <p>
            <b>Next Payment</b>: {formatTime(subscription.next_payment_at)}
          </p>
        )}

      {!showChargeForm && (
        <Actions>
          <StyledButton color="primary" onClick={handleShowChargeForm}>
            Refill Balance
          </StyledButton>
          {!subscription.canceled_at &&
            /active|past_due|trialing/.test(subscription.status) && (
              <StyledButton color="primary" onClick={handleOpenConfirm}>
                Cancel Subscription
              </StyledButton>
            )}
        </Actions>
      )}

      {!!showConfirmation && (
        <ModalDialog
          title="Cancel Subscription"
          description="This action will remove your current subscription, please confirm."
          actions={
            <>
              <Button mr="10px" color="secondary" onClick={handleCancel}>
                Cancel
              </Button>
              <Button color="primary" onClick={handleCancelSubscription}>
                Confirm
              </Button>
            </>
          }
        />
      )}
    </>
  )
}

const StyledButton = styled(Button)`
  width: max-content;
`

const Actions = styled.div`
  display: flex;
  justify-content: start;
  column-gap: 24px;
  margin-top: 24px;
`
