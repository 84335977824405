import React, { useRef, useState } from 'react'
import styled from 'styled-components/macro'
import DropzoneComponent from 'react-dropzone-component'
import 'react-dropzone-component/styles/filepicker.css'
import 'dropzone/src/dropzone.scss'

export const FileUploader = ({
  className,
  dropZoneClassName,
  onDrop,
  onRemove,
  defaultFile,
  acceptedFiles = '*',
  showFiletypeIcon = false,
}) => {
  const dropZone = useRef()
  const [file, setFile] = useState(defaultFile)
  const uploader = useRef(null)

  const componentConfig = {
    postUrl: 'no-url',
    maxFiles: 2,
  }
  const djsConfig = {
    acceptedFiles,
    maxFilesize: 10,
    autoProcessQueue: false,
    showFiletypeIcon,
    addRemoveLinks: true,
    postUrl: 'no-url',
    maxFiles: 2,
    uploadMultiple: false,
  }

  const progressCallback = event => {
    if (!dropZone?.current) return false

    const progress = (event.loaded / event.total) * 100
    dropZone.current.querySelector('.dz-upload').style.width = `${progress}%`
  }
  const successCallback = newFile => {
    uploader.current.emit('complete', newFile)
    uploader.current.emit('success', newFile)
  }

  const eventHandlers = {
    init: item => {
      uploader.current = item
    },
    addedfile: newFile => {
      const currentFile = dropZone.current.querySelectorAll('.dz-preview')
      if (currentFile.length === 1) {
        setFile(newFile)

        onDrop(newFile, progressCallback, () => successCallback(newFile))
      } else {
        dropZone.current.querySelector('.dz-file-preview').remove()
      }
    },
    removedfile: () => {
      setFile('')
      onRemove()
    },
  }

  return (
    <Wrapper className={className}>
      <DropAreaContainer ref={dropZone} className={dropZoneClassName}>
        {!file && (
          <DefaultText>
            Drag a file here of <span> browse </span> for a file to upload
          </DefaultText>
        )}
        {!!file && <DefaultText>{file.name}</DefaultText>}

        <DropzoneComponent
          config={componentConfig}
          eventHandlers={eventHandlers}
          djsConfig={djsConfig}
        />
      </DropAreaContainer>
    </Wrapper>
  )
}

export const Wrapper = styled.div``

export const ImageUploaderComponent = styled.div``

const DropAreaContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 283px;
  min-height: 180px;
  margin-bottom: 15px;
  font-size: 13px;
  line-height: 13px;
  border: 0.901274px dashed #e5edff;
  box-sizing: border-box;
  border-radius: 6.30892px;
  color: #344356;

  span {
    color: #5468ff;
  }
  .dz-file-preview {
    display: block;
  }
  .dropzone {
    display: flex;
    width: 283px;
    min-height: 93px;
    justify-content: center;
    align-items: center;
    .dz-preview.dz-image-preview {
      margin: 5px;
    }
  }
  .dz-default.dz-message,
  .dz-error-mark,
  .dz-error-message,
  .dz-success-mark {
    display: none;
  }
  .dz-preview.dz-error .dz-error-mark {
    opacity: 0;
  }
  .dropzone .dz-preview .dz-error-message {
    bottom: 100%;
    top: initial;
    margin-bottom: 15px;
    width: 160px;
    left: -20px;
    &:after {
      left: 74px;
      top: initial;
      bottom: -12px;
      border-top: 6px solid #a92222;
      border-bottom: 6px solid transparent;
    }
    span {
      font-family: 'Biko';
      color: white;
    }
  }
  .dz-preview {
    .dz-details {
      display: grid;
      padding: 0 !important;
      justify-items: center;
      align-items: center;
      height: 100%;
      font-family: 'Biko';
      .dz-size {
        margin-bottom: 40px;
      }
    }
  }

  div.filepicker {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
  }
  .dz-remove {
    position: absolute;
    text-align: center;
    width: 100%;
    margin-top: 8px;
    font-family: 'Biko';
    color: red;
    z-index: 21;
  }
  .dz-upload {
    background: linear-gradient(to bottom, #5468ff, #00abfc) !important;
  }
`

const DefaultText = styled.div`
  pointer-events: none;
`
