import { useEffect } from 'react'

import { getCenter } from 'src/utils/coordinates'
import { useMap } from 'src/modules/map'
import { useMapStudio } from 'src/pages/MapStudio/controllers/MapStudioController'
import { useBuildingPois } from 'src/hooks/useBuildingPois'
import { reducePoi } from 'src/pages/MapStudio/controllers/reducePoi'
import { useKeyPress } from 'src/hooks/useKeyPress'

let mapOnClick

export const usePois = ({ isInitiated }) => {
  const { map, mapZoom, mapDraw, isMapLoaded } = useMap()
  const { isLoading: isPoiLoading } = useBuildingPois()

  const {
    floor,
    floorPois,
    setSelectedPolygon,
    setStartAddPoi,
    startAddPoi,
    idsForUpdate,
    setIdsForUpdate,
    setStartAddWay,
  } = useMapStudio()
  const enterKey = useKeyPress('Enter')
  const escapeKey = useKeyPress('Escape')

  useEffect(() => {
    if (startAddPoi) {
      setStartAddPoi(false)
      map.getCanvas().style.cursor = 'auto'
      mapDraw.changeMode('simple_select', {})
    }
  }, [enterKey, escapeKey])

  useEffect(() => {
    if (!map) return
    if (mapZoom < 16) return

    map.getStyle().layers.forEach(layer => {
      if (layer.id.match(/^(nearmotion-(municipality)|gl-draw)(-.*|$)/)) {
        if (mapZoom < 17) {
          map.setLayoutProperty(layer.id, 'visibility', 'none')
        } else {
          map.setLayoutProperty(layer.id, 'visibility', 'visible')
        }
      }
    })
  }, [map, mapZoom])

  useEffect(() => {
    return function clean() {
      if (!map) return false

      map.hasFloors = false
      mapOnClick && map.off('click', mapOnClick)
      map?.off('draw.update', mapOnDrawUpdate)
      map?.off('draw.create', mapOnDrawCreate)
    }
  }, [isMapLoaded])

  function mapOnDrawUpdate(draw) {
    setSelectedPolygon(getSelectedPolygonParams(draw.features))
  }

  function mapOnDrawCreate(draw) {
    map.hasFloors = true
    const selected = draw.features
    if (selected.length) {
      const newPoiId = selected[0].id
      if (idsForUpdate.indexOf(newPoiId) === -1) {
        idsForUpdate.push(newPoiId)
        setIdsForUpdate(idsForUpdate)
      }
    }
    mapOnDrawUpdate(draw)
  }

  useEffect(() => {
    if (!map || !isMapLoaded) return false

    if (isMapLoaded && mapDraw) {
      mapOnClick = () => {
        if (!map.hasFloors) return false
        setSelectedPolygon(null)
        const selected = mapDraw.getSelected().features
        if (selected.length) {
          const newPoiId = selected[0].id
          if (idsForUpdate.indexOf(newPoiId) === -1) {
            idsForUpdate.push(newPoiId)
            setIdsForUpdate(idsForUpdate)
          }

          if (selected[0].geometry?.type === 'Polygon') {
            setSelectedPolygon(getSelectedPolygonParams(selected))
          }
        }
      }
      map.on('click', mapOnClick)
      map.on('draw.update', mapOnDrawUpdate)
      map.on('draw.create', mapOnDrawCreate)
    }
  }, [isMapLoaded])

  useEffect(() => {
    if (floorPois?.length && !isPoiLoading && isInitiated && mapDraw) {
      reducePoi({
        floorPois,
        map,
        mapDraw,
        floorName: floor.name,
      })
    }

    if (isMapLoaded && floorPois) {
      map.hasFloors = Boolean(floorPois.length)
    }
  }, [floorPois, isPoiLoading, isInitiated, map, mapDraw])

  function getSelectedPolygonParams(features) {
    const poiAngles = features[0].geometry.coordinates[0].map(item => ({
      longitude: item[0],
      latitude: item[1],
    }))
    const centerSelected = getCenter(poiAngles)
    mapDraw.setFeatureProperty(features[0].id, 'center', [
      centerSelected.longitude,
      centerSelected.latitude,
    ])

    return {
      poi_id: features[0].id,
      coordinates_attributes: poiAngles,
      ...centerSelected,
      ...features[0].properties,
    }
  }

  const addPolygon = () => {
    setStartAddPoi(true)
    setStartAddWay(false)
    map.startDrawWaypoint = false
    map.getCanvas().style.cursor = 'crosshair'
    mapDraw.changeMode('draw_polygon', {
      escapeKeyStopsDrawing: true, // default true
      allowCreateExceeded: false, // default false
      exceedCallsOnEachMove: false, // default false
    })
  }

  return {
    addPolygon,
    isLoading: isPoiLoading,
  }
}
