import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { toast } from 'react-toastify'

import { DataTable } from 'src/components/DataTable'
import { SelectFieldUI } from 'src/ui/form/SelectFieldUI'
import { Button } from 'src/ui'
import { Modal } from 'src/components/Modal'
import { useApplications } from 'src/modules/applications'

import { Roles } from './model'

export function EditMemberModal(props) {
  const { member, onClose, update } = props
  const { applications, get: getApplications } = useApplications()

  const [memberRole, setMemberRole] = useState(member.role)
  const [applicationIds, setApplicationIds] = useState(
    [].concat(...member.application_ids)
  )

  const memberRequireUpdate = useMemo(() => {
    return (
      member.role === memberRole &&
      JSON.stringify(member.application_ids.sort()) ===
        JSON.stringify(applicationIds.sort())
    )
  }, [applicationIds, memberRole, member])

  const isSuperAdmin = useMemo(() => /super_admin/.test(memberRole), [
    memberRole,
  ])

  const onLoading = useCallback(async () => {
    if (!applications?.length) {
      await getApplications()
    }
  }, [getApplications, applications])

  useEffect(onLoading, [onLoading])

  const updateRole = useCallback(selectedRole => {
    setMemberRole(selectedRole)
  }, [])

  const handleUpdateMember = useCallback(async () => {
    const dataToUpdate = {}
    if (memberRole && member.role !== memberRole) {
      dataToUpdate.role = memberRole
    }
    if (
      !isSuperAdmin &&
      member.application_ids?.sort() !== applicationIds?.sort()
    ) {
      dataToUpdate.application_ids = applicationIds
    }
    if (Object.keys(dataToUpdate).length > 0) {
      const result = await update(member.id, dataToUpdate)
      result.ok && toast.success('Updated successfully')
    }
  }, [
    applicationIds,
    isSuperAdmin,
    member.application_ids,
    member.id,
    member.role,
    memberRole,
    update,
  ])
  const handleSelectApplication = useCallback(
    (applicationId, checked) => {
      setApplicationIds(
        checked
          ? [...applicationIds, applicationId]
          : [...applicationIds.filter(appId => appId !== applicationId)]
      )
    },
    [applicationIds]
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Application Name',
        accessor: 'name',
      },
      {
        id: 'actions',
        Header: 'Actions',
        cellStyle: { width: '70px', textAlign: 'center' },
        Cell: ({ row: { original } }) => (
          <Input
            disabled={isSuperAdmin}
            type="checkbox"
            defaultChecked={
              isSuperAdmin ||
              applicationIds.some(id => {
                return id === original.id
              })
            }
            onChange={({ target: { checked } }) =>
              handleSelectApplication(original.id, checked)
            }
          />
        ),
      },
    ],
    [isSuperAdmin, applicationIds, handleSelectApplication]
  )

  return (
    <Modal onClose={onClose}>
      <ModalContainer>
        {member.admin && (
          <p>
            Member: {member.admin.first_name} {member.admin.last_name}
          </p>
        )}

        <p>Email: {member?.admin?.email || member?.email}</p>
        <div>
          Member Role:
          <SelectField
            name="role"
            options={Roles}
            isMulti={false}
            isSearchable={false}
            defaultValue={memberRole}
            inputProps={{
              defaultValue: memberRole,
              onChange: ({ target: { value } }) => {
                updateRole(value)
              },
            }}
          />
        </div>

        <DataTableStyled
          scrollerAutoHide={false}
          columns={columns}
          rows={applications || []}
        />
        <ActionWrapper>
          <Button color="primary" bold size="lg" onClick={onClose}>
            {t('Close')}
          </Button>

          <Button
            color="primary"
            bold
            size="lg"
            onClick={handleUpdateMember}
            disabled={memberRequireUpdate}
          >
            {t('Save')}
          </Button>
        </ActionWrapper>
      </ModalContainer>
    </Modal>
  )
}

const Input = styled.input`
  cursor: pointer;
`
const ActionWrapper = styled.div`
  display: flex;
  column-gap: 20px;
  justify-content: end;
`
const ModalContainer = styled.div`
  width: 800px;
  max-width: 90vw;
  padding: 30px;
  background-color: white;
  border-radius: 5px;
`

const DataTableStyled = styled(DataTable)`
  height: calc(60vh - 140px);
  th,
  td {
    text-align: left;
  }
`

const SelectField = styled(SelectFieldUI)`
  display: inline-block;
  max-width: 300px;
  margin: 0 0 0 20px;
`
