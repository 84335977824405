import { useMemo } from 'react'
import { useAuth } from 'src/modules/auth'
import { useCompany } from 'src/modules/company'

export const useAbility = () => {
  const { user } = useAuth()
  const { company } = useCompany()

  const isOwner = useMemo(
    () => /owner/.test(user?.role) && /owner/.test(company?.role),
    [company?.role, user?.role]
  )
  const isSuperAdmin = useMemo(() => /super_admin/.test(user?.role), [
    user?.role,
  ])
  const isAdmin = useMemo(() => /admin/.test(user?.role), [user?.role])
  const isManager = useMemo(() => /manager/.test(user?.role), [user?.role])

  const application = useMemo(() => {
    const manage = Boolean(isOwner || isSuperAdmin)
    const update = Boolean(isOwner || isSuperAdmin || isAdmin)

    return {
      manage,
      update,
    }
  }, [isAdmin, isOwner, isSuperAdmin])

  const building = useMemo(() => {
    const manage = Boolean(isOwner || isSuperAdmin)
    const update = Boolean(isOwner || isSuperAdmin || isAdmin)
    const show = Boolean(isOwner || isSuperAdmin || isAdmin || isManager)

    return {
      manage,
      update,
      show,
    }
  }, [isAdmin, isManager, isOwner, isSuperAdmin])

  const floor = useMemo(() => {
    const manage = Boolean(isOwner || isSuperAdmin || isAdmin)
    const update = Boolean(isOwner || isSuperAdmin || isAdmin)
    const show = Boolean(isOwner || isSuperAdmin || isAdmin || isManager)

    return {
      manage,
      update,
      show,
    }
  }, [isAdmin, isManager, isOwner, isSuperAdmin])

  const engagement = useMemo(() => {
    const manage = Boolean(isOwner || isSuperAdmin || isAdmin)
    const update = Boolean(isOwner || isSuperAdmin || isAdmin)
    const show = Boolean(isOwner || isSuperAdmin || isAdmin || isManager)

    return {
      manage,
      update,
      show,
    }
  }, [isAdmin, isManager, isOwner, isSuperAdmin])

  return {
    application,
    building,
    floor,
    engagement,

    isOwner,
    isSuperAdmin,
    isAdmin,
    isManager,
  }
}
