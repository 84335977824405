import {
  defaultCircleSignStyle,
  defaultCircleStyle,
  defaultLineStyle,
} from 'src/pages/MapStudio/features/DrawRoutesConstants'

export const loadLayers = props => {
  const { currentMap, routeGeojson, drawingLineGeojson } = props
  if (!currentMap.getSource('nearmotion-drawingLineGeojson')) {
    currentMap.addSource('nearmotion-drawingLineGeojson', {
      type: 'geojson',
      data: drawingLineGeojson,
    })

    currentMap.addLayer({
      id: 'nearmotion-routes-temp-lines',
      type: 'line',
      source: 'nearmotion-drawingLineGeojson',
      paint: {
        ...defaultLineStyle,
        ...{
          'line-color': '#fbb03b',
          'line-dasharray': [3, 2],
          'line-opacity': 1,
        },
      },
    })

    currentMap.addLayer({
      id: 'nearmotion-routes-temp-points',
      type: 'circle',
      source: 'nearmotion-drawingLineGeojson',
      paint: defaultCircleStyle,
      filter: ['in', '$type', 'Point'],
    })
  }

  if (!currentMap.getSource('nearmotion-routesGeoJson')) {
    currentMap.addSource('nearmotion-routesGeoJson', {
      type: 'geojson',
      data: routeGeojson,
    })

    currentMap.addLayer({
      id: 'nearmotion-routes-lines',
      type: 'line',
      source: 'nearmotion-routesGeoJson',
      paint: defaultLineStyle,
    })
    currentMap.addLayer({
      id: 'nearmotion-routes-points',
      type: 'circle',
      source: 'nearmotion-routesGeoJson',
      paint: defaultCircleStyle,
      filter: ['in', '$type', 'Point'],
    })

    currentMap.addLayer({
      id: 'nearmotion-cluster-count',
      type: 'symbol',
      source: 'nearmotion-routesGeoJson',
      layout: defaultCircleSignStyle,
      paint: {
        'text-color': 'black',
      },
    })

    // Layer for triangles
    // currentMap.addLayer({
    //   id: 'nearmotion-routes-triangles',
    //   type: 'symbol',
    //   source: 'nearmotion-routesGeoJson',
    //
    //   layout: {
    //     'icon-size': 0.25,
    //     'icon-anchor': 'center',
    //     'icon-rotate': 180,
    //     'icon-allow-overlap': true,
    //     'icon-ignore-placement': true,
    //
    //     'icon-image': 'triangle',
    //     'symbol-placement': 'point',
    //   },
    // })
  }
}
