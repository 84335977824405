export default function validate(value = '', rules) {
  let result

  for (const [validator, message] of rules) {
    if (!validator(value)) {
      result = message
      break
    }
  }

  return result
}
