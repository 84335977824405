import React from 'react'
import styled from 'styled-components/macro'

export const TermsAndPrivacy = () => (
  <TermsAndPrivacyStyled>
    <a
      href="https://nearmotion.com/terms-and-conditions/"
      target="_blank"
      rel="noreferrer"
    >
      {t('Terms and Conditions')}
    </a>
    <a
      href="https://nearmotion.com/privacy-policy/"
      target="_blank"
      rel="noreferrer"
    >
      {t('Privacy policy')}
    </a>
    <a
      href="https://nearmotion.com/disclaimer/"
      target="_blank"
      rel="noreferrer"
    >
      {t('Disclaimer')}
    </a>
  </TermsAndPrivacyStyled>
)

const TermsAndPrivacyStyled = styled.div`
  margin-top: 16px;
  display: grid;
  justify-items: end;
  font-size: 13px;
  grid-gap: 6px;
  grid-template-columns: repeat(3, max-content);
  justify-content: center;
  grid-column-gap: 10px;
`
