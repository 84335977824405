import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { useParams } from 'react-router-dom'

import { Link as LinkComponent } from 'src/components/Link'
import { Button } from 'src/ui'

import { ReactComponent as NearIcon } from 'src/assets/images/svg/near.svg'
import { ReactComponent as NearMinimizeIcon } from 'src/assets/images/svg/near-minimize.svg'
import { ReactComponent as NavigationIcon } from 'src/assets/images/svg/navigation.svg'
import { ReactComponent as EngagementIcon } from 'src/assets/images/svg/engagement.svg'
import { ReactComponent as ReportsIcon } from 'src/assets/images/svg/reports.svg'

const SubMenuComponent = props => {
  const { floorId, buildingId } = useParams()

  const [show, setShow] = useState()
  const handleOpen = () => {
    setShow(!show)
  }

  useEffect(() => {
    setShow(false)
  }, [floorId])

  if (!floorId) {
    return false
  }

  return (
    <Container className={props.className}>
      <Near color="primary" onClick={handleOpen} $active={show}>
        {show ? <NearIcon /> : <NearMinimizeIcon />}
        Near
      </Near>

      <Nav $show={show}>
        <Link
          $active={!/reports|engagements|pois/.test(window.location.pathname)}
          onClick={handleOpen}
          to={`/buildings/${buildingId}/floors/${floorId}/`}
        >
          <NavigationIcon />
          Navigation
        </Link>
        {floorId && (
          <>
            <Link
              $active={/engagements/.test(window.location.pathname)}
              onClick={handleOpen}
              to={`/buildings/${buildingId}/floors/${floorId}/engagements`}
            >
              <EngagementIcon />
              Engagements
            </Link>
            <Link
              $active={/reports/.test(window.location.pathname)}
              onClick={handleOpen}
              to={`/buildings/${buildingId}/floors/${floorId}/reports`}
            >
              <ReportsIcon />
              Analytics & Reports
            </Link>
          </>
        )}
      </Nav>
    </Container>
  )
}

const Container = styled.div`
  svg {
    margin-right: 5px;
  }
`

const Near = styled(Button)`
  height: 30px;
  width: 66px;
  padding-left: 23px;
  font-size: 13px;
  line-height: 13px;
  text-transform: uppercase;
  svg {
    position: absolute;
    top: 11px;
    left: 7px;
  }
  ${({ $active }) =>
    $active &&
    `
      color: #5468ff;
      background: #eff2fe;
      svg {
        top: 7px;
        left: 11px;
        fill: #5468ff;
        stroke: #5468ff;
      }
      &:before{
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
      }
  `}
`
const Nav = styled.nav`
  position: absolute;
  display: grid;
  justify-content: center;
  align-content: center;
  top: -13px;
  left: 100%;
  margin-left: 13px;
  width: 170px;
  height: 145px;
  bottom: 72.33%;
  background: #fafbff;
  box-shadow: 0 9.08776px 14.3889px rgba(60, 128, 209, 0.05);
  border-radius: 0 10px 10px 0;
  opacity: 0;
  pointer-events: none;
  transition-duration: 0.3s;
  ${({ $show }) =>
    $show &&
    `
    opacity: 1;
    pointer-events: all; 
  `}
`

const Link = styled(LinkComponent)`
  display: flex;
  align-items: center;
  justify-content: left;
  margin: 5px 0;
  padding: 0 10px;
  width: 150px;
  height: 34px;
  border-radius: 3.4px;
  text-decoration: none;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 13px;
  color: #5468ff;
  &:hover {
    color: white;
    background: #5468ff;
    svg {
      fill: white;
      stroke: white;
    }
  }
  ${({ $active }) =>
    $active &&
    `
      color: white;
      background: #5468ff;
      &:visited{
        color: white;
      }
      svg {
        fill: white;
        stroke: white;
      }
  `}
`

export const SubMenu = styled(SubMenuComponent)``
