import React, { useCallback, useState } from 'react'
import { Form, FormSpy } from 'react-final-form'
import { useParams } from 'react-router-dom'
import styled from 'styled-components/macro'

import { Alert } from 'src/components/Alert'
import { StyledLink } from 'src/components/Link'
import { TextField } from 'src/components/TextField'
import { AuthPageWrapper, PageContentWrapper } from 'src/pages/PageTemplate'
import { Button, Card } from 'src/ui'

import { useAuth } from 'src/modules/auth'

import { TermsAndPrivacy } from 'src/ui/TermsAndPrivacy'

import validate from 'src/utils/validate'
import isPassword from 'src/utils/validators/isPassword'

const initialValues = {}

const getSubmitError = errorCode => {
  switch (errorCode) {
    case 404: {
      return {
        title: 'There is no account with this email',
        description: '',
      }
    }

    default: {
      return {
        title: 'Something went wrong',
        description: '',
      }
    }
  }
}

export const ResetPasswordConfirm = () => {
  const { isLoading, resetPassword } = useAuth()
  const [isSuccess, setIsSuccess] = useState(false)
  const params = useParams()

  const validateForm = values => {
    const errors = {}

    if (!values.password) {
      errors.password = 'Please enter password'
    }

    errors.password = validate(values.password, isPassword)

    if (!values.password_confirmation) {
      errors.password_confirmation = 'Please confirm password'
    }

    if (values.password_confirmation !== values.password) {
      errors.password_confirmation = 'Password mismatch'
    }

    return errors
  }

  const onSubmit = useCallback(
    async values => {
      try {
        const res = await resetPassword({
          ...values,
          reset_password_token: params.token,
        })

        if (res?.json?.error?.parsedValidations) {
          return res.json.error.parsedValidations
        }
        if (!res.ok) {
          return { error: res.status }
        }

        setIsSuccess(true)
      } catch (error) {
        return { error: t('Something went wrong') }
      }

      return false
    },
    [params.token, resetPassword]
  )

  return (
    <AuthPageWrapper>
      <PageContentWrapper style={{ textAlign: 'center' }}>
        <Card
          width="500px"
          style={{
            textAlign: isSuccess ? 'center' : 'left',
          }}
        >
          {isSuccess ? (
            <SuccessMessage>
              {t('Password successfully changed')}

              <StyledLink
                to="/sign-in"
                style={{
                  marginTop: '20px',
                  display: 'block',
                  fontSize: 'inherit',
                  textAlign: 'center',
                }}
              >
                {t('Go to Sign-In page')}
              </StyledLink>
              <TermsAndPrivacy />
            </SuccessMessage>
          ) : (
            <Form
              onSubmit={onSubmit}
              validate={validateForm}
              initialValues={initialValues}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <TextField fullWidth label="Password" name="password" />
                  <TextField
                    fullWidth
                    label="Confirm Password"
                    name="password_confirmation"
                  />

                  <FormSpy
                    subscription={{
                      submitting: true,
                      pristine: true,
                      valid: true,
                      dirtySinceLastSubmit: true,
                      hasValidationErrors: true,
                      submitErrors: true,
                      error: true,
                      errors: true,
                      submitFailed: true,
                    }}
                  >
                    {({
                      valid,
                      submitFailed,
                      dirtySinceLastSubmit,
                      hasValidationErrors,
                      submitErrors,
                    }) => {
                      const submitError = getSubmitError(submitErrors?.error)

                      return (
                        <>
                          <div mt={4}>
                            {!valid && submitFailed && (
                              <Alert
                                type="error"
                                title={t(submitError.title)}
                                description={t(submitError.description)}
                              />
                            )}
                          </div>
                          <div mt={4}>
                            <Button
                              color="primary"
                              width="100%"
                              bold
                              size="lg"
                              disabled={
                                hasValidationErrors ||
                                (submitFailed && !dirtySinceLastSubmit)
                              }
                              loading={isLoading}
                              onClick={handleSubmit}
                            >
                              {t('Reset password')}
                            </Button>
                          </div>
                        </>
                      )
                    }}
                  </FormSpy>

                  <StyledLink
                    to="/sign-in"
                    style={{
                      marginTop: 20,
                      width: '100%',
                      textAlign: 'right',
                    }}
                  >
                    {t('Go to Sign-In page')}
                  </StyledLink>
                  <TermsAndPrivacy />
                </form>
              )}
            />
          )}
        </Card>
      </PageContentWrapper>
    </AuthPageWrapper>
  )
}

const SuccessMessage = styled.div``
