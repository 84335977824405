import React, { createContext, useCallback, useContext, useState } from 'react'

import { multiFileUpload } from 'src/utils/blob'

import { api } from './api'

const FloorsContext = createContext()

export function FloorsProvider(props) {
  const [floors, setFloors] = useState([])
  const [floor, setFloor] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const clearFloor = useCallback(() => setFloor(false), [])

  const get = useCallback(async buildId => {
    if (!buildId) {
      await setFloors([])
      await setIsLoading(false)

      return false
    }
    setIsLoading(true)

    const res = await api.get(`buildings/${buildId}/floors`)
    res.ok && setFloors(res?.json.building_floors)
    setIsLoading(false)

    return res
  }, [])

  // get current floor
  const getFloor = useCallback(async (buildId, floorId, silence) => {
    !silence && setIsLoading(true)
    const res = await api.get(`floors/${floorId}`)
    const currentFloor = res && res.ok ? res.json.building_floor : {}
    setFloor(currentFloor)

    !silence && setIsLoading(false)

    return currentFloor
  }, [])

  const create = useCallback(
    async (buildId, data, silence) => {
      !silence && setIsLoading(true)
      const res = await api.post(`buildings/${buildId}/floors`, data)
      res.ok && get()
      !silence && setIsLoading(false)

      return res
    },
    [get]
  )

  const addLayer = useCallback(async (floorId, data, silence) => {
    !silence && setIsLoading(true)
    const res = await api.post(`floors/${floorId}/layers`, data)
    // res.ok && get()
    !silence && setIsLoading(false)

    return res
  }, [])

  const moveLayer = useCallback(
    async (destinationIndex, layer) => {
      setIsLoading(true)
      const res = await api.patch(`floors/${floor?.id}/layers/${layer.id}`, {
        ...layer,
        position: destinationIndex,
      })
      // res.ok && get()
      setIsLoading(false)

      return res
    },
    [floor?.id]
  )

  const updateLayer = useCallback(async (floorId, layerId, data) => {
    setIsLoading(true)

    const res = await api.patch(`floors/${floorId}/layers/${layerId}`, data)
    setIsLoading(false)

    return res
  }, [])

  const removeLayer = useCallback(async (floorId, layerId, silence) => {
    !silence && setIsLoading(true)
    const res = await api.del(`floors/${floorId}/layers/${layerId}`)

    // res.ok && get()
    !silence && setIsLoading(false)

    return res
  }, [])

  const update = useCallback(async (id, data, silence) => {
    !silence && setIsLoading(true)

    const res = await api.patch(`floors/${id}`, data)
    !silence && setIsLoading(false)

    return res
  }, [])

  const remove = useCallback(
    async id => {
      setIsLoading(true)
      const res = await api.del(`floors/${id}`)
      setFloors(floors.filter(app => app.id !== id))
      setIsLoading(false)

      return res
    },
    [floors]
  )

  const setJFloor = useCallback(
    async properties => {
      const {
        floor: selectedFloor,
        layersToRemove = [],
        buildingId,
        syncStatus,
        setSyncStatus,
        floorId,
      } = properties

      const files = await multiFileUpload(
        selectedFloor.layers,
        syncStatus,
        setSyncStatus
      )

      const newLayers = files.map((l, index) => ({
        position: index,
        file: l?.file?.signed_id,
        kind: 'custom',
        style: selectedFloor.layers[index].style,
      }))

      const layers = layersToRemove.concat(newLayers)

      const updatedFloor = {
        name: selectedFloor.name,
        jibestream_id: selectedFloor.id,
        position: selectedFloor.position,
        layers_attributes: layers,
      }
      const result = await (floorId
        ? update(floorId, updatedFloor, true)
        : create(buildingId, updatedFloor, true))

      return result
    },
    [create, update]
  )

  const getLayersToRemove = async (buildingId, floorId) => {
    let layers = floor?.layers

    if (floor?.id !== floorId) {
      const currentFloor = await getFloor(buildingId, floorId, true)
      layers = currentFloor?.layers
    }

    return layers.map(layer => ({
      id: layer.id,
      _destroy: true,
    }))
  }

  const contextValue = {
    get,
    floor,
    floors,
    moveLayer,
    setFloors,
    setFloor,
    setJFloor,
    updateLayer,
    addLayer,
    removeLayer,
    getFloor,
    clearFloor,
    isLoading,
    create,
    update,
    remove,
    getLayersToRemove,
  }

  return (
    <FloorsContext.Provider value={contextValue}>
      {props.children}
    </FloorsContext.Provider>
  )
}

export function useFloors() {
  return useContext(FloorsContext)
}
