import React from 'react'
import { Field } from 'react-final-form'
import { TextFieldUI } from 'src/ui/form/TextFieldUI'

export const PasswordField = ({ name = '1', ...props }) => {
  return (
    <Field
      name={name}
      type="password"
      render={({ input, meta }) => (
        <TextFieldUI
          {...props}
          inputProps={input}
          error={
            (meta.error || meta.submitError) &&
            meta.touched &&
            (meta.error || meta.submitError?.[0])
          }
        />
      )}
    />
  )
}
