import React, { useCallback } from 'react'
import styled from 'styled-components/macro'
import { Form } from 'react-final-form'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'

import { Alert } from 'src/components/Alert'
import { Button } from 'src/ui/Button'
import { TextField } from 'src/components/TextField'
import { useAuth } from 'src/modules/auth'
import { PasswordField } from 'src/components/PasswordField'
import { Scroller } from 'src/components/Scroller'
import { NavTemplate } from 'src/components/NavTemplate'

import validate from 'src/utils/validate'
import isPasswordRequired from 'src/utils/validators/isPasswordRequired'
import isPassword from 'src/utils/validators/isPassword'
import isEmail from 'src/utils/validators/isEmail'
import { toast } from 'react-toastify'

const getSubmitError = errorCode => {
  switch (errorCode) {
    case 404: {
      return {
        title: 'There is no account with this email',
        description: '',
      }
    }

    default: {
      return {
        title: 'Something went wrong',
        description: '',
      }
    }
  }
}

const menuLink = [
  {
    to: '/profile/settings',
    title: 'Profile Settings',
  },
  {
    to: '/profile/password',
    title: 'Password',
  },
]

export const ProfilePage = () => {
  const { isLoading, updateProfile, user, cleanBeforeLogout } = useAuth()
  const history = useHistory()

  const validateForm = values => {
    const errors = {}
    if (/password/.test(history.location.pathname)) {
      if (!values.password) {
        errors.password = 'Please enter password'
      }

      errors.password = validate(values.password, isPassword)

      if (!values.password_confirmation) {
        errors.password_confirmation = 'Please confirm password'
      }

      if (values.password_confirmation !== values.password) {
        errors.password_confirmation = 'Password mismatch'
      }

      errors.current_password = validate(
        values.current_password,
        isPasswordRequired
      )
    } else {
      errors.email = validate(values.email, isEmail)
    }

    return errors
  }

  const onSubmit = useCallback(
    async values => {
      try {
        const res = await updateProfile(values)

        if (res.ok && values.password) {
          cleanBeforeLogout()
          toast('You were signed out successfully.')

          return setTimeout(() => {
            window.location.href = '/sign-in'
          }, 500)
        }

        if (res?.json?.error?.parsedValidations) {
          return res.json.error.parsedValidations
        }

        if (res.status === 403) {
          return {
            current_password: ['Current password is invalid'],
          }
        }
        if (!res.ok) {
          return { error: res.status }
        }
      } catch (error) {
        return { error: t('Something went wrong') }
      }

      return false
    },
    [updateProfile, cleanBeforeLogout]
  )
  const hasUnconfirmedEmail =
    user?.unconfirmed_email && user?.unconfirmed_email !== user?.email

  return (
    <NavTemplate menuLinks={menuLink}>
      <Form
        onSubmit={onSubmit}
        validate={validateForm}
        initialValues={
          hasUnconfirmedEmail
            ? {
                ...user,
                email: user.unconfirmed_email,
              }
            : user
        }
        render={({
          handleSubmit,
          valid,
          pristine,
          submitFailed,
          dirtySinceLastSubmit,
          hasValidationErrors,
          submitSucceeded,
          submitErrors,
        }) => {
          const submitError = getSubmitError(submitErrors?.error)

          return (
            <StyledForm onSubmit={handleSubmit}>
              <Scroller autoHide={false}>
                <Switch>
                  <Route
                    exact
                    path="/profile/settings"
                    render={() => (
                      <>
                        <TextField
                          label={
                            hasUnconfirmedEmail ? 'Unconfirmed email' : 'Email'
                          }
                          name="email"
                          disabled={hasUnconfirmedEmail}
                          maxLength={255}
                        />
                        <TextField
                          label="First Name"
                          name="first_name"
                          maxLength={255}
                        />
                        <TextField
                          label="Last Name"
                          name="last_name"
                          maxLength={255}
                        />
                        {hasUnconfirmedEmail && (
                          <p>
                            *Please check your mail for confirm new email
                            address
                          </p>
                        )}
                      </>
                    )}
                  />

                  <Route
                    exact
                    path="/profile/password"
                    render={() => (
                      <>
                        <PasswordField
                          label="Password"
                          name="password"
                          autoComplete="off"
                          minLength={8}
                          maxLength={128}
                        />
                        <PasswordField
                          label="Confirm password"
                          name="password_confirmation"
                          autoComplete="off"
                          minLength={8}
                          maxLength={128}
                        />
                        <PasswordField
                          label="Current password"
                          name="current_password"
                          autoComplete="off"
                        />
                      </>
                    )}
                  />
                  <Redirect from="/profile" to="/profile/settings" />
                </Switch>
                <div>
                  {!valid && submitFailed && (
                    <Alert
                      type="error"
                      title={t(submitError.title)}
                      description={t(submitError.description)}
                    />
                  )}
                  {submitSucceeded && !dirtySinceLastSubmit && (
                    <Alert
                      type="success"
                      title={t('Success')}
                      description={t(submitError.description)}
                    />
                  )}
                </div>
                <div>
                  <Button
                    color="primary"
                    width="100%"
                    bold
                    size="lg"
                    disabled={
                      pristine ||
                      hasValidationErrors ||
                      (submitFailed && !dirtySinceLastSubmit) ||
                      (submitSucceeded && !dirtySinceLastSubmit) ||
                      isLoading
                    }
                    loading={isLoading.toString()}
                    onClick={handleSubmit}
                  >
                    {t('Save')}
                  </Button>
                </div>
              </Scroller>
            </StyledForm>
          )
        }}
      />
    </NavTemplate>
  )
}

const StyledForm = styled.form`
  position: relative;
  height: 100%;
  max-width: 501px;
  overflow: auto;
`
