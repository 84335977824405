export const clearLayers = (currentMap, ignore) => {
  if (!currentMap.getStyle()) return false
  currentMap.getStyle().layers.forEach(layer => {
    if (layer.id.match(/^nearmotion-.*/) && !ignore?.test(layer.id)) {
      currentMap.removeLayer(layer.id)
    }
  })

  Object.keys(currentMap.getStyle().sources).forEach(sourceId => {
    if (sourceId.match(/^nearmotion-.*/) && !ignore?.test(sourceId)) {
      currentMap.removeSource(sourceId)
    }
  })
}
