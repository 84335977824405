import { useCallback } from 'react'
import { useCookies } from 'react-cookie'

import { useInvitations } from 'src/hooks/useInvitations'
import { INVITATION_CODE_KEY } from 'src/constants'

export const useConfirmInvitation = () => {
  const { confirm: confirmInvitation } = useInvitations()
  const [cookies, , removeCookie] = useCookies([INVITATION_CODE_KEY])
  const invitationCode = cookies[INVITATION_CODE_KEY]

  const cleanInvitationCode = useCallback(() => {
    removeCookie(INVITATION_CODE_KEY)
  }, [removeCookie])

  const sendConfirmationRequest = useCallback(
    async ({ invitationCode: code, data }) => {
      const result = await confirmInvitation(invitationCode || code, {
        invitee_attributes: { ...data },
      })

      if (result.ok || result.status === 403) {
        cleanInvitationCode()
      }

      return result
    },
    [confirmInvitation, invitationCode, cleanInvitationCode]
  )

  return {
    sendConfirmationRequest,
    cleanInvitationCode,
  }
}
