import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import he from 'he'

import { Loader } from 'src/ui'
import { useCompany } from 'src/modules/company'
import { usePlans } from 'src/hooks/usePlans'
import { useAuth } from 'src/modules/auth'
import { Link } from 'src/components/Link'

import {
  getCreditCards,
  getCreditCardsLoading,
  getCreditCardsInitiated,
  loadCreditCards,
} from 'src/redux/slicers/creditCards'
import { getChargesLoading } from 'src/redux/slicers/subscription'
import { useSubscription } from 'src/hooks/useSubscription'

import { Scroller } from 'src/components/Scroller'

import {
  ChargeBalanceForm,
  SubscriptionDetails,
  SubscribeForm,
} from './components'

const renderSuccess = () => (
  <Success>
    Congratulations! Your subscription is active,{' '}
    <Link to="/applications">[go to applications]</Link>.
  </Success>
)

export const Subscription = props => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { get: getPlans, isLoading: isPlansLoading, plans } = usePlans()

  const creditCards = useSelector(getCreditCards)
  const isCardsLoading = useSelector(getCreditCardsLoading)
  const isCardsInitiated = useSelector(getCreditCardsInitiated)
  const isChargesLoading = useSelector(getChargesLoading)

  const { create: createSubscription } = useSubscription()

  const {
    isLoading: isCompanyLoading,
    get: getCompany,
    company,
    subscription,
  } = useCompany()

  const {
    isLoading: isAuthLoading,
    isLoadingInitially,
    isAuthenticated,
  } = useAuth()

  const [isLoading, setIsLoading] = useState(false)
  const [showChargeForm, setShowChargeForm] = useState(false)

  const loadCards = useCallback(() => {
    if (!creditCards.length && !isCardsLoading && !isCardsInitiated) {
      dispatch(loadCreditCards())
    }
  }, [creditCards.length, dispatch, isCardsLoading, isCardsInitiated])

  useEffect(loadCards, [loadCards])

  const defaultCard = useMemo(() => creditCards.find(card => card.default), [
    creditCards,
  ])

  const selectedPlan = useMemo(
    () => plans.find(item => item.slug === company?.plan_slug),
    [company?.plan_slug, plans]
  )

  const isMadaCard = useMemo(() => /mada/.test(defaultCard?.brand), [
    defaultCard?.brand,
  ])

  const hasBalance =
    +company?.balance_in_currency >= +company?.price_in_currency

  const isTrialing = useMemo(() => company?.trial_eligible, [
    company?.trial_eligible,
  ])

  const trialDaysLeft = useMemo(() => selectedPlan?.trial_days, [
    selectedPlan?.trial_days,
  ])

  const isSubscriptionActive = useMemo(
    () => /active|trialing/.test(subscription?.status),
    [subscription?.status]
  )

  useEffect(getCompany, [getCompany])
  useEffect(getPlans, [isAuthenticated, getPlans])

  useEffect(() => {
    return () => {
      toast.dismiss()
    }
  }, [])

  const handleShowChargeForm = useCallback(
    event => {
      event?.preventDefault()
      event?.stopPropagation()
      setShowChargeForm(!showChargeForm)
    },
    [setShowChargeForm, showChargeForm]
  )

  const checkSubscriptionStatus = (saasCompany, intervalId) => {
    if (/active|past_due|trialing/.test(saasCompany?.subscription?.status)) {
      toast(renderSuccess, {
        autoClose: 1000 * 30,
        closeOnClick: false,
        style: {
          top: '70px',
        },
      })
      clearInterval(intervalId)
      setIsLoading(false)
      setShowChargeForm(false)
    }
  }

  async function handleCreateSubscription() {
    setIsLoading(true)
    const requestCompany = await getCompany()
    const currentCompany = requestCompany?.json?.saas_company || {}
    const currentSubscription = currentCompany.subscription || {}
    if (/trialing|active/.test(currentSubscription?.status)) {
      return toast(he.decode(`You already have subscription.`))
    }

    const request = await createSubscription()
    if (request.ok) {
      const interval = setInterval(async () => {
        const result = await getCompany()
        checkSubscriptionStatus(result?.json?.saas_company, interval)
      }, 2000)
    } else {
      if (request?.json?.error?.code === 1000 && !creditCards?.length) {
        history.push('/payments/cards')
      }
      // prevent to close the information popup on the page change
      setTimeout(() => {
        toast.error(request?.json?.error?.message || request.statusText)
      }, 300)
      setIsLoading(false)
    }
  }

  if (isPlansLoading) {
    return <Loader />
  }

  return (
    <Container className={props.className}>
      <Title>Subscription</Title>
      <Scroller autoHide={false}>
        <DetailsContainer>
          {company?.balance_in_currency && (
            <p>
              <b>Account Balance</b>: {company.balance_in_currency}{' '}
              {company?.currency?.toUpperCase()}
            </p>
          )}

          <p>
            <b>Selected Plan</b>: {selectedPlan?.name}
          </p>
          <p>
            <b>Price</b>: {company?.price_in_currency}{' '}
            {company?.currency?.toUpperCase()}
          </p>

          {!creditCards?.length && isCardsInitiated && (
            <p>
              Please add at least one{' '}
              <Link to="/payments/cards">Credit Card</Link>
            </p>
          )}

          {Boolean(creditCards?.length) && (
            <>
              {subscription ? (
                <SubscriptionDetails
                  showChargeForm={showChargeForm}
                  handleShowChargeForm={handleShowChargeForm}
                />
              ) : (
                (!isMadaCard || hasBalance || isTrialing) &&
                !showChargeForm && (
                  <SubscribeForm
                    isMadaCard={isMadaCard}
                    isTrialing={isTrialing}
                    trialDaysLeft={trialDaysLeft}
                    handleCreateSubscription={handleCreateSubscription}
                    showChargeForm={showChargeForm}
                    handleShowChargeForm={handleShowChargeForm}
                  />
                )
              )}

              {Boolean(
                (isMadaCard && !hasBalance && !subscription) || showChargeForm
              ) && (
                <ChargeBalanceForm
                  setIsLoading={setIsLoading}
                  isMadaCard={isMadaCard}
                  isSubscriptionActive={isSubscriptionActive}
                  defaultCard={defaultCard}
                  subscription={subscription}
                  handleShowChargeForm={handleShowChargeForm}
                  handleCreateSubscription={handleCreateSubscription}
                />
              )}
            </>
          )}
        </DetailsContainer>
      </Scroller>
      {(isLoading ||
        isLoadingInitially ||
        isCompanyLoading ||
        isAuthLoading ||
        isChargesLoading) && <Loader />}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
`

const DetailsContainer = styled.div`
  display: grid;
  column-gap: 20px;
  padding-bottom: 100px;
`
const Success = styled.div``

const Title = styled.h2`
  margin-top: 0;
  height: 62px;
  font-family: 'Biko';
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.5px;
  color: #344356;
`
