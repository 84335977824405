import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ThemeProvider } from 'styled-components'
import { CookiesProvider } from 'react-cookie'

import { AuthProvider } from 'src/modules/auth'
import { CompanyProvider } from 'src/modules/company'
import { MainRouter } from 'src/components/system/MainRouter'
import theme from 'src/theme'
import { BuildingsProvider } from 'src/modules/buildings'
import { ApplicationsProvider } from 'src/modules/applications'
import { FloorsProvider } from 'src/modules/floors'
import { OAuthProvider } from 'src/modules/oAuth'

import store from 'src/redux/store'

export const ApplicationRoot = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: true,
      },
    },
  })

  return (
    <CookiesProvider>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Router>
            <QueryClientProvider client={queryClient}>
              <AuthProvider>
                <OAuthProvider>
                  <CompanyProvider>
                    <ApplicationsProvider>
                      <BuildingsProvider>
                        <FloorsProvider>
                          <MainRouter />
                        </FloorsProvider>
                      </BuildingsProvider>
                    </ApplicationsProvider>
                  </CompanyProvider>
                </OAuthProvider>
              </AuthProvider>
            </QueryClientProvider>
          </Router>
        </ThemeProvider>
      </Provider>
    </CookiesProvider>
  )
}
