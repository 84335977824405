export const LOADING_STATUSES = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  REFRESH: 'REFRESH',
  MORE: 'MORE',
  ERROR: 'ERROR',
}

export const API_ERROR_CODES = {
  NOT_AUTHORIZED: 1001,
  INVALID_TOKEN: 1002,
  ACCOUNT_LOCKED: 1003,
  USER_NOT_FOUND: 1012,
}

export const LAYER_KINDS = [
  'other',
  // 'custom',
  'arrows',
  'be',
  'corridors',
  'doors',
  'elevators',
  'garden',
  'outlayer',
  'parking',
  'rooms',
  'sidewalk',
  'stairs',
  'street',
  'walls',
  'wc',
]

export const LAYER_KINDS_SELECT_OPTIONS = LAYER_KINDS.map(lK => ({
  value: lK,
  label: lK.toUpperCase(),
}))

export const JIBESTREAM_LAYERS = {
  Background: {
    type: 'fill',
    layout: {},
    paint: {
      'fill-opacity': 0.2,
    },
  },
  'Mall-Boundary': {
    type: 'fill',
    layout: {},
    paint: {
      'fill-color': '#dfdfdf',
      'fill-outline-color': '#202020',
      'fill-antialias': true,
      'fill-opacity': 1,
    },
  },
  Boundary: {
    type: 'fill',
    layout: {},
    paint: {
      'fill-color': '#dfdfdf',
      'fill-outline-color': '#202020',
      'fill-antialias': true,
      'fill-opacity': 1,
    },
  },
  Corridors: {
    type: 'fill',
    layout: {},
    paint: {
      'fill-color': '#D9C28D',
      'fill-outline-color': '#202020',
      'fill-antialias': true,
      'fill-opacity': 1,
    },
  },
  'Interior-ParkingLots': {
    type: 'fill',
    layout: {},
    paint: {
      'fill-color': '#40392A',
      'fill-outline-color': '#eee7d9',
      'fill-antialias': true,
      'fill-opacity': 0.6,
    },
  },
  'Parking-Lots': {
    type: 'fill',
    layout: {},
    paint: {
      'fill-color': '#40392A',
      'fill-outline-color': '#eee7d9',
      'fill-antialias': true,
      'fill-opacity': 0.5,
    },
  },
  Obstacles: {
    type: 'fill',
    layout: {},
    paint: {
      'fill-color': '#7F7153',
      'fill-outline-color': '#FFFFFF',
      'fill-antialias': true,
      'fill-opacity': 0.3,
    },
  },
  'Back-of-house': {
    type: 'fill',
    layout: {},
    paint: {
      'fill-color': '#f5ecd8',
      'fill-outline-color': '#202020',
      'fill-antialias': true,
      'fill-opacity': 1,
    },
  },
  Units: {
    type: 'fill-extrusion',
    layout: {},
    paint: {
      'fill-extrusion-color': '#f4eee7',
      'fill-extrusion-height': 2,
      'fill-extrusion-base': 0,
      'fill-extrusion-opacity': 1,
    },
  },
  Restrooms: {
    type: 'fill-extrusion',
    layout: {},
    paint: {
      'fill-extrusion-color': '#f3cda1',
      'fill-extrusion-height': 1,
      'fill-extrusion-base': 0,
      'fill-extrusion-opacity': 1,
    },
  },
  Elevators: {
    type: 'fill',
    layout: {},
    paint: {
      'fill-color': '#f4eee7',
      'fill-outline-color': '#202020',
      'fill-antialias': true,
      'fill-opacity': 1,
    },
  },
  Stairs: {
    type: 'fill',
    layout: {},
    paint: {
      'fill-color': '#f4eee7',
      'fill-outline-color': '#202020',
      'fill-antialias': true,
      'fill-opacity': 1,
    },
  },
  'Pattern-Grass': {
    type: 'fill',
    layout: {},
    paint: {
      'fill-color': '#014c2a',
      'fill-outline-color': '#202020',
      'fill-antialias': true,
      'fill-opacity': 1,
    },
  },
  CustomArtLayer: {
    type: 'fill',
    layout: {},
    paint: {
      'fill-color': '#eeeeee',
      'fill-outline-color': '#202020',
      'fill-antialias': true,
      'fill-opacity': 1,
    },
  },
  'CustomArtLayer-1': {
    type: 'fill',
    layout: {},
    paint: {
      'fill-color': '#eeeeee',
      'fill-outline-color': '#202020',
      'fill-antialias': true,
      'fill-opacity': 1,
    },
  },
  'CustomArtLayer-3': {
    type: 'fill',
    layout: {},
    paint: {
      'fill-color': '#eeeeee',
      'fill-outline-color': '#202020',
      'fill-antialias': true,
      'fill-opacity': 1,
    },
  },
  'CustomArtLayer-4': {
    type: 'fill',
    layout: {},
    paint: {
      'fill-color': '#eeeeee',
      'fill-outline-color': '#202020',
      'fill-antialias': true,
      'fill-opacity': 1,
    },
  },
  'CustomArtLayer-5': {
    type: 'fill',
    layout: {},
    paint: {
      'fill-color': '#cccccc',
      'fill-outline-color': '#202020',
      'fill-antialias': true,
      'fill-opacity': 1,
    },
  },
  'CustomArtLayer-2': {
    type: 'fill',
    layout: {},
    paint: {
      'fill-color': '#585858',
      'fill-outline-color': '#202020',
      'fill-antialias': true,
      'fill-opacity': 1,
    },
  },
  'CustomArtLayer-6': {
    type: 'fill',
    layout: {},
    paint: {
      'fill-color': '#585858',
      'fill-outline-color': '#202020',
      'fill-antialias': true,
      'fill-opacity': 1,
    },
  },
  Openings: {
    type: 'line',
    layout: {},
    paint: {
      'line-color': '#673ab7',
      'line-width': 2,
    },
  },
  Waypoints: {
    type: 'symbol',
    paint: {
      'text-halo-color': '#ffffff',
      'text-halo-width': 1,
    },
    layout: {
      'icon-allow-overlap': true,
      'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
      'text-size': 11,
      'text-transform': 'uppercase',
      'text-letter-spacing': 0.05,
      'text-offset': [0, 1.5],
      'icon-image': '{icon-image}',
      'icon-size': { type: 'identity', property: 'icon-size' },
      'icon-keep-upright': true,
      'text-field': '{text-field}',
    },
  },
}

export const JIBESTREAM_LAYERS_NAMES = Object.keys(JIBESTREAM_LAYERS)

export const JIBESTREAM_LAYERS_OPTIONS = JIBESTREAM_LAYERS_NAMES.map(lK => ({
  value: lK,
  label: lK,
}))

export const mapStyles = {
  other: {
    'line-color': '#8C8C8C',
    'line-width': 1,
  },
  arrows: {
    'fill-color': '#B8E5A1',
    'fill-outline-color': '#020F66',
    'fill-opacity': 0.7,
  },
  be: {
    'fill-color': '#A08880',
    'fill-outline-color': '#151110',
    'fill-opacity': 1,
  },
  corridors: {
    'fill-color': '#fbfafa',
    'fill-outline-color': '#cccccc',
    'fill-opacity': 1,
  },
  Corridors_outline: {
    'line-color': '#919090',
    'line-width': 1,
  },
  doors: {
    'fill-color': '#fbfafa',
    'fill-outline-color': '#b6b7b8',
    'fill-opacity': 0.5,
  },
  Doors_outline: {
    'line-color': '#919090',
    'line-width': 1,
  },
  elevators: {
    'fill-color': '#25D9FA',
    'fill-opacity': 1,
  },
  garden: {
    'fill-color': '#C1E8BB',
    'fill-outline-color': '#b6b7b8',
    'fill-opacity': 1,
  },
  outlayer: {
    'fill-color': 'transparent',
    'fill-outline-color': '#525252',
    'fill-opacity': 0,
  },
  parking: {
    'fill-color': '#B2B2B2',
    'fill-outline-color': '#FFEBAF',
    'fill-opacity': 1,
  },
  rooms: {
    'fill-color': '#FFF8EB',
    'fill-outline-color': '#B2B2B2',
    'fill-opacity': 1,
  },
  Room_outline: {
    'line-color': '#919090',
    'line-width': 1,
  },
  sidewalk: {
    'fill-color': '#CCCCCC',
    'fill-outline-color': '#B2B2B2',
    'fill-opacity': 1,
  },
  stairs: {
    'fill-color': '#25D9FA',
    'fill-outline-color': '#151110',
    'fill-opacity': 1,
  },
  street: {
    'fill-color': '#9C9C9C',
    'fill-outline-color': '#151110',
    'fill-opacity': 1,
  },
  Wall_outline: {
    'line-color': '#919090',
    'line-width': 1,
  },
  walls: {
    'fill-color': '#E6E9EC',
    'fill-outline-color': '#151110',
    'fill-opacity': 1,
  },
  wc: {
    'fill-color': '#566DFB',
    'fill-opacity': 0.7,
  },

  // Jibestream
  Background: {
    'fill-opacity': 0,
  },
  'Mall-Boundary': {
    'fill-color': '#dfdfdf',
    'fill-outline-color': '#202020',
    'fill-antialias': true,
    'fill-opacity': 1,
  },
  Boundary: {
    'fill-color': '#dfdfdf',
    'fill-outline-color': '#202020',
    'fill-antialias': true,
    'fill-opacity': 1,
  },
  Corridors: {
    'fill-color': '#D9C28D',
    'fill-outline-color': '#202020',
    'fill-antialias': true,
    'fill-opacity': 1,
  },
  'Interior-ParkingLots': {
    'fill-color': '#40392A',
    'fill-outline-color': '#eee7d9',
    'fill-antialias': true,
    'fill-opacity': 0.6,
  },
  'Parking-Lots': {
    'fill-color': '#40392A',
    'fill-outline-color': '#eee7d9',
    'fill-antialias': true,
    'fill-opacity': 0.5,
  },
  Obstacles: {
    'fill-color': '#7F7153',
    'fill-outline-color': '#FFFFFF',
    'fill-antialias': true,
    'fill-opacity': 0.3,
  },
  'Back-of-house': {
    'fill-color': '#f5ecd8',
    'fill-outline-color': '#202020',
    'fill-antialias': true,
    'fill-opacity': 1,
  },
  Units: {
    'fill-extrusion-color': '#f4eee7',
    'fill-extrusion-height': 2,
    'fill-extrusion-base': 0,
    'fill-extrusion-opacity': 1,
  },
  Restrooms: {
    'fill-extrusion-color': '#f3cda1',
    'fill-extrusion-height': 1,
    'fill-extrusion-base': 0,
    'fill-extrusion-opacity': 1,
  },
  Elevators: {
    'fill-color': '#f4eee7',
    'fill-outline-color': '#202020',
    'fill-antialias': true,
    'fill-opacity': 1,
  },
  Stairs: {
    'fill-color': '#f4eee7',
    'fill-outline-color': '#202020',
    'fill-antialias': true,
    'fill-opacity': 1,
  },
  'Pattern-Grass': {
    'fill-color': '#014c2a',
    'fill-outline-color': '#202020',
    'fill-antialias': true,
    'fill-opacity': 1,
  },
  CustomArtLayer: {
    'fill-color': '#eeeeee',
    'fill-outline-color': '#202020',
    'fill-antialias': true,
    'fill-opacity': 1,
  },
  'CustomArtLayer-1': {
    'fill-color': '#eeeeee',
    'fill-outline-color': '#202020',
    'fill-antialias': true,
    'fill-opacity': 1,
  },
  'CustomArtLayer-3': {
    'fill-color': '#eeeeee',
    'fill-outline-color': '#202020',
    'fill-antialias': true,
    'fill-opacity': 1,
  },
  'CustomArtLayer-4': {
    'fill-color': '#eeeeee',
    'fill-outline-color': '#202020',
    'fill-antialias': true,
    'fill-opacity': 1,
  },
  'CustomArtLayer-5': {
    'fill-color': '#cccccc',
    'fill-outline-color': '#202020',
    'fill-antialias': true,
    'fill-opacity': 1,
  },
  'CustomArtLayer-2': {
    'fill-color': '#585858',
    'fill-outline-color': '#202020',
    'fill-antialias': true,
    'fill-opacity': 1,
  },
  'CustomArtLayer-6': {
    'fill-color': '#585858',
    'fill-outline-color': '#202020',
    'fill-antialias': true,
    'fill-opacity': 1,
  },

  Openings: {
    'line-color': '#673ab7',
    'line-width': 2,
  },
  Waypoints: {
    paint: {
      'text-halo-color': '#ffffff',
      'text-halo-width': 1,
    },
    layout: {
      'icon-allow-overlap': true,
      'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
      'text-size': 11,
      'text-transform': 'uppercase',
      'text-letter-spacing': 0.05,
      'text-offset': [0, 1.5],
      'icon-image': '{icon-image}',
      'icon-size': { type: 'identity', property: 'icon-size' },
      'icon-keep-upright': true,
      'text-field': '{text-field}',
    },
  },
}

export const MAP_STYLES_NAMES = Object.keys(mapStyles)
export const MAP_LAYERS_OPTIONS = MAP_STYLES_NAMES.map(lK => ({
  value: lK,
  label: lK,
}))

export const INVITATION_CODE_KEY = 'nearmotion_invitation_code'
