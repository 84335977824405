import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { toast } from 'react-toastify'

import { Loader, TextFieldUI, Title } from 'src/ui'
import { useApplications } from 'src/modules/applications'
import { PageContentWrapper, PageWithHeader } from 'src/pages/PageTemplate'
import { Card } from 'src/ui/Card'
import { Link } from 'src/components/Link'
import { useCompany } from 'src/modules/company'

import { ReactComponent as IconTrashBin } from 'src/assets/images/svg/icon-trash-bin.svg'
import EditImageComponent from 'src/assets/images/svg/edit.svg'
import { ConfirmationModal } from 'src/components/ConfirmationModal'
import { useAbility } from 'src/hooks/useAbility'
import { Subtitle } from 'src/ui/Title'

function ApplicationsComponent(props) {
  const {
    get: getApplications,
    create,
    applications,
    isLoading,
    setApplicationId,
    remove: removeApplication,
    update: updateApplication,
  } = useApplications()

  const { get: getCompany, company, isLoading: isCompanyLoading } = useCompany()
  const {
    application: { manage, update },
  } = useAbility()

  const hasApplication = applications?.length > 0

  const canAddApplication =
    company?.subscription &&
    // if there is no limits (executive or enterprise plan)
    (!company.subscription.limit_apps ||
      // or your app limits are not reached
      company.subscription.limit_apps < applications.length)

  const [showAddApp, setShowAddApp] = useState(canAddApplication)
  const [editApp, setEditApp] = useState(false)
  const [editAppName, setEditAppName] = useState(false)
  const [error, setError] = useState('')

  useEffect(getApplications, [getApplications])
  useEffect(() => {
    if (!company && !isCompanyLoading) {
      getCompany()
    }
  }, [isCompanyLoading, company, getCompany, getApplications])

  useEffect(() => {
    if (!isLoading) {
      setShowAddApp(canAddApplication)
    }
  }, [applications, isLoading, canAddApplication])

  const [name, setAppName] = useState('')

  const handleErrors = errors => {
    if (errors?.validations?.name) {
      setError(`Name ${errors?.validations?.name}`)
    } else {
      toast.error(errors.message)
    }
  }

  async function onSubmit() {
    if (name) {
      const result = await create({ name })
      if (result?.ok) {
        setAppName('')
        setError('')
        setShowAddApp(canAddApplication)
      } else {
        handleErrors(result?.json?.error)
      }
    }
  }

  async function handleRemove(id) {
    const result = await removeApplication(id)
    if (result?.ok) {
      setAppName('')
      setShowAddApp(canAddApplication)
    } else {
      toast.error(result?.json?.error?.message || 'Something went wrong')
    }
  }
  async function handleUpdate(id) {
    const result = await updateApplication(id, { name: editAppName })
    if (result?.ok) {
      const currentApp = applications.find(item => item.id === +id)
      currentApp.name = editAppName
      setEditApp(false)
    } else {
      handleErrors(result?.json?.error)
    }
  }

  function handleAppName(event) {
    setAppName(event.target.value)
    setError('')
  }

  function handleCancel() {
    setAppName('')
  }

  function handleEdit(event, id, appName) {
    event.preventDefault()
    event.stopPropagation()
    setEditApp(id)
    setEditAppName(appName)
  }

  async function handleApplication(applicationId) {
    await setApplicationId(applicationId)
    localStorage.setItem('applicationId', applicationId)
  }

  function handleNameChange({ target: { value } }) {
    setEditAppName(value || '')
    setError('')
  }

  if (isCompanyLoading) {
    return <Loader />
  }

  return (
    <PageWithHeader className={props.className}>
      <PageContentWrapper>
        <Card fullSize scrollable>
          <Link to="/companies">{company.name}</Link>
          <Title>Applications</Title>
          <ApplicationList>
            {hasApplication &&
              applications.map(application => {
                const isEdit = editApp === application.id
                const AppContainer = isEdit
                  ? AppContainerStyled
                  : AppLinkContainer

                return (
                  <AppContainer
                    key={application.id}
                    onClick={() => handleApplication(application.id)}
                    to="/buildings"
                  >
                    {isEdit && update ? (
                      <TextField
                        fullWidth
                        name="editAppName"
                        maxLength={255}
                        error={editAppName && error}
                        inputProps={{
                          maxLength: 255,
                          value: editAppName,
                          placeholder: 'Application Name',
                          onChange: handleNameChange,
                        }}
                      />
                    ) : (
                      application.name
                    )}
                    {update && (
                      <Actions>
                        {!isEdit && (
                          <Edit
                            size="large"
                            color="primary"
                            variant="contained"
                            onClick={event =>
                              handleEdit(
                                event,
                                application.id,
                                application.name
                              )
                            }
                          />
                        )}
                        {isEdit && (
                          <>
                            <Submit
                              size="large"
                              color="primary"
                              variant="contained"
                              onClick={() => handleUpdate(application.id)}
                              disabled={!editAppName}
                            />
                            <Cancel
                              size="large"
                              color="primary"
                              variant="contained"
                              onClick={event => handleEdit(event)}
                            />
                          </>
                        )}
                        {manage && (
                          <ConfirmationModal
                            title="This will remove the application from the database. Are you sure?"
                            iconTitle="This will remove the application from the database. Are you sure?"
                            description="This action cannot be undone"
                            onConfirm={handleRemove}
                            id={application.id}
                            component={Remove}
                          />
                        )}
                      </Actions>
                    )}
                  </AppContainer>
                )
              })}
            {!hasApplication && !manage && (
              <RequestApplication>
                <Title>
                  There are no available Applications in your current
                  Organization.
                </Title>
                <Subtitle>
                  Ask your Organization administrators to create one or give you
                  the access.
                </Subtitle>
              </RequestApplication>
            )}
            {showAddApp && manage && (
              <CreateApp>
                <TextField
                  fullWidth
                  name="appName"
                  maxLength={255}
                  error={name && error}
                  inputProps={{
                    maxLength: 255,
                    value: name,
                    placeholder: 'Application Name',
                    onChange: handleAppName,
                  }}
                />
                <Actions>
                  <Submit
                    size="large"
                    color="primary"
                    variant="contained"
                    onClick={onSubmit}
                    disabled={!name}
                  />
                  <Cancel
                    size="large"
                    color="primary"
                    variant="contained"
                    onClick={handleCancel}
                  />
                </Actions>
              </CreateApp>
            )}
          </ApplicationList>
          {isLoading && <Loader />}
        </Card>
      </PageContentWrapper>
    </PageWithHeader>
  )
}

export const Applications = styled(ApplicationsComponent)``

const ApplicationList = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const linkStyles = css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0 0 15px;
  width: 200px;
  height: 200px;
  box-shadow: 0 9.08776px 14.3889px rgba(60, 128, 209, 0.05);
  border-top: 3px solid rgb(84 104 255);
  border-radius: 5px;
  font-family: 'Biko', serif;
  cursor: pointer;
`

const AppLinkContainer = styled(Link)`
  ${linkStyles}
`
const AppContainerStyled = styled.div`
  ${linkStyles}
`
const CreateApp = styled.div`
  ${linkStyles}
`

const Actions = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 5px;
  right: 5px;
`

const ButtonsStyles = css`
  position: relative;
  height: 36px;
  width: 30px;
  cursor: pointer;
`

const Submit = styled.div`
  ${ButtonsStyles}
  &:after {
    display: block;
    position: absolute;
    top: 8px;
    left: 10px;
    content: '';
    height: 15px;
    width: 10px;
    border-bottom: 2px solid green;
    border-right: 2px solid green;
    transform: rotate(45deg);
  }
  &[disabled] {
    opacity: 0.3;
    pointer-events: none;
  }
`

const Edit = styled.div`
  ${ButtonsStyles}
  height: 30px;
  background-image: url(${EditImageComponent});
  background-repeat: no-repeat;
  background-size: auto 16px;
  background-position: center 8px;
`

const Cancel = styled.div`
  ${ButtonsStyles}
  &:before {
    position: relative;
    top: 18px;
    left: 7px;
    display: block;
    content: '';
    height: 1px;
    width: 16px;
    border-bottom: 2px solid #ff6355;
    transform: rotate(45deg);
  }
  &:after {
    position: absolute;
    top: 11px;
    left: 14px;
    display: block;
    content: '';
    height: 16px;
    width: 1px;
    border-right: 2px solid #ff6355;
    transform: rotate(45deg);
  }
`

const TextField = styled(TextFieldUI)`
  width: 321px;
  margin: 0;
  input {
    box-shadow: none;
    outline: none;
    &:focus {
      outline: none;
    }
    text-align: center;
  }
`

const Remove = styled(IconTrashBin)`
  margin: 10px;
`

const RequestApplication = styled.div`
  display: flex; 
  height: 100%;
  width: 100%;
  min-height: 60vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
`
