import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'

import { PageWithHeader, PageContentWrapper } from 'src/pages/PageTemplate'

import { Card, Loader } from 'src/ui'
import { useStatus } from 'src/hooks/useStatus'

import { ReactComponent as SuccessComponent } from 'src/assets/images/svg/status/success.svg'
import { ReactComponent as WarningComponent } from 'src/assets/images/svg/status/warning.svg'
import { ReactComponent as ErrorComponent } from 'src/assets/images/svg/status/error.svg'

const statusTitles = {
  api: 'API',
}

export const Status = () => {
  const [statuses, setStatuses] = useState()
  const [statusColor, setStatusColor] = useState('transparent')
  const { get: getStatus, isLoading } = useStatus()

  const systemStatus = useMemo(() => {
    if (statuses?.some(item => item.status === 'error') || !statuses?.length) {
      setStatusColor('#FF6355')

      return 'Status Check Failed'
    }

    switch (statuses?.find(item => item.name === 'status')?.status) {
      case 'severe': {
        setStatusColor('#FF6355')

        return 'Major Outages Detected'
      }
      case 'warn': {
        setStatusColor('orange')

        return 'All Systems Operational With Minor Issues'
      }
      default: {
        setStatusColor('#6BC839')

        return 'All Systems Operational'
      }
    }
  }, [statuses])

  const loadStatus = useCallback(async () => {
    const request = await getStatus()

    if (!request.ok) return setStatuses([])

    setStatuses(
      Object.keys(request.json).map(name => ({
        name: statusTitles[name] || name,
        status: request.json[name],
      }))
    )
  }, [getStatus])

  const statusIcon = useCallback(status => {
    switch (status) {
      case 'error': {
        return <ErrorComponent title={status} />
      }
      case 'severe': {
        return <ErrorComponent title={status} />
      }
      case 'warn': {
        return <WarningComponent title={status} />
      }
      default: {
        return <SuccessComponent title={status} />
      }
    }
  }, [])

  const statusMessage = useCallback((name, status) => {
    switch (status) {
      case 'error': {
        return (
          <>
            <span>{name}</span>: error while checking the status
          </>
        )
      }
      case 'severe': {
        return (
          <>
            <span>{name}</span>: major outages
          </>
        )
      }
      case 'warn': {
        return (
          <>
            <span>{name}</span>: service is operational but some minor outages
            are possible.
          </>
        )
      }
      default: {
        return (
          <>
            <span>{name}</span>: fully operational
          </>
        )
      }
    }
  }, [])

  useEffect(loadStatus, [loadStatus])

  if (isLoading || !statuses) return <Loader />

  return (
    <PageWithHeader>
      <PageContentWrapper style={{ textAlign: 'center' }}>
        <SystemStatus
          css={`
            background-color: ${statusColor};
          `}
        >
          <h2>{systemStatus}</h2>
        </SystemStatus>
        {!!statuses?.length && (
          <CardStyled width="500px">
            <List>
              {statuses
                ?.filter(item => {
                  return item.name !== 'status'
                })
                .map(item => (
                  <ListItem key={item.name}>
                    <Option>{statusMessage(item.name, item.status)}</Option>
                    <Value>{statusIcon(item.status)}</Value>
                  </ListItem>
                ))}
            </List>
          </CardStyled>
        )}
      </PageContentWrapper>
    </PageWithHeader>
  )
}

const List = styled.ul`
  margin: 0;
  padding: 0;
  font-family: 'Biko', serif;
`

const ListItem = styled.li`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr max-content;
  margin-top: 16px;

  padding: 0 10px 10px 10px;
  border-bottom: 1px solid #e2ebff;
`
const Option = styled.div`
  text-align: left;

  span {
    text-transform: capitalize;
  }
`
const Value = styled.div`
  svg {
    width: 20px;
  }
`
const CardStyled = styled(Card)`
  margin-top: 43px;
  width: 100%;
  max-width: 500px;
`

const SystemStatus = styled.div`
  margin: 0 auto;
  max-width: 500px;
  border-radius: 10px;
  color: white;
  h2 {
    padding: 25px 10px;
    margin: 0;
    line-height: 28px;
  }
`
