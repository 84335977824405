import { createSlice } from '@reduxjs/toolkit'

const jibestreamSlice = createSlice({
  name: 'jibestream',
  initialState: {
    loading: false,
    account: {
      clientId: '',
      clientSecret: '',
      customerId: '',
    },
    buildings: [],
    building: {},
  },
  reducers: {
    setJAccount: (state, action) => {
      state.account = action.payload
    },
    setJBuildings: (state, action) => {
      state.buildings = action.payload
    },
    setJBuilding: (state, action) => {
      state.building = {
        ...state.building,
        ...action.payload,
      }
    },
    init: state => {
      state.building = {}
      state.buildings = []
      state.account = {
        clientId: '',
        clientSecret: '',
        customerId: '',
      }
    },
  },
})

export const jibestreamActions = jibestreamSlice.actions
export const jibestreamReducer = jibestreamSlice.reducer
