import styled, { css } from 'styled-components'

export const StyledSelect = css`
  background: #ffffff;
  font-size: 14px;
  color: #344356;
  padding: 20px;
  border-radius: 3.66839px;
  border: none;
  box-shadow: 0 11.0052px 25.6788px rgba(129, 129, 129, 0.04);

  ${props =>
    props.fullWidth &&
    `
  width: 100%;
  `}

  &:focus {
    outline: 1px solid #ceceff;
  }

  &::placeholder {
    color: #9aaccf;
  }

  ${props =>
    props.invalid &&
    `
  border: 1px solid red;
  `}
`
export const SelectInput = styled.select`
  ${StyledSelect}
`

export const customSelectStyles = {
  control: (provided, state) => ({
    display: 'flex',
    fontSize: '14px',
    color: '#344356',
    padding: '10px',
    border: state.isFocused ? '1px solid #ceceff' : '1px solid transparent',
    boxShadow: '0px 11.0052px 25.6788px rgb(129 129 129 / 4%)',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
}
