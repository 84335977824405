import { useCallback, useState } from 'react'

import { api } from 'src/modules/api'

export function useSubscription() {
  const [isLoading, setIsLoading] = useState(false)

  const create = useCallback(async () => {
    setIsLoading(true)
    const res = await api.post('subscription')
    setIsLoading(false)

    return res
  }, [])

  const cancel = useCallback(async () => {
    setIsLoading(true)
    const res = await api.del('subscription')
    setIsLoading(false)

    return res
  }, [])

  return {
    isLoading,
    create,
    cancel,
  }
}
