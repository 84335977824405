import React from 'react'
import styled from 'styled-components/macro'

import { MapResizeStudioBox } from 'src/pages/MapStudio/FloorResize/MapResizeStudioBox'
import { MapStudioResizeProvider } from 'src/modules/map-floor-resize-studio'
import { SideBar } from 'src/components/SideBar'
import { PageWithHeader } from 'src/pages/PageTemplate'

function FloorResizeStudioComponent({ children, className }) {
  return (
    <PageWithHeader>
      <Container>
        <MapStudioResizeProvider className={className}>
          <SideBar>{children}</SideBar>
          <MapResizeStudioBox />
        </MapStudioResizeProvider>
      </Container>
    </PageWithHeader>
  )
}

export const MapFloorResizeStudio = styled(FloorResizeStudioComponent)``

const Container = styled.div`
  display: flex;
  position: relative;
  background: white;
  overflow: hidden;
`
