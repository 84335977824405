import React, { useState } from 'react'
import styled from 'styled-components/macro'

import { ReactComponent as InfoComponent } from 'src/assets/images/svg/info.svg'

function AccordionItemComponent(props) {
  const [show, setShow] = useState(true)
  function handleClick() {
    setShow(!show)
  }

  return (
    <Container show={show} className={props.className}>
      <Title onClick={handleClick}>
        <span>{props.title}</span>
        {props.info && <Info />}
      </Title>
      <Hr />
      {props.children}
    </Container>
  )
}

export const AccordionItem = styled(AccordionItemComponent)``

const Container = styled.div`
  margin: 0 11px;
  padding: 18.42px;
  padding-top: 0;
  margin-top: 25px;
  box-shadow: 0 9.08776px 14.3889px rgba(60, 128, 209, 0.05);
  border-radius: 10px;
  background: white;
  overflow: hidden;
  height: auto;
  ${props =>
    !props.show &&
    `
      height: 64px;
  `}
`

const Hr = styled.hr`
  border-color: #eaf0f3;
  border-top-width: 0;
  margin: 0;
  margin-bottom: 25px;
`

const Title = styled.h2`
  display: flex;
  margin: 0;
  height: 64px;
  font-weight: bold;
  font-size: 14px;
  line-height: 13px;
  align-items: center;
  text-transform: uppercase;
  color: black;
  cursor: pointer;
`
const Info = styled(InfoComponent)`
  height: 11px;
  margin-left: 5px;
`
