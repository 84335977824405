import React from 'react'
import styled from 'styled-components/macro'
import { typography, space, color } from 'styled-system'

export const Button = ({ children, ...props }) => (
  <ButtonComponent {...props}>{children}</ButtonComponent>
)

const ButtonComponent = styled.button`
  ${typography}
  ${space}
  ${color}
  padding: 0 40px;
  height: 34px;
  font-size: 13px;
  border-radius: 4px;
  cursor: pointer;
  border: none;

  &[disabled] {
    cursor: default;
  }

  ${props =>
    props.size === 'lg' &&
    `
      height: 40px;
      font-size: 16px;
  `}

  ${props =>
    props.width &&
    `
      width: ${props.width};
  `}

  ${p =>
    p.color === 'secondary' &&
    `
      color: #5468FF;
      background-color: #EFF2FE;
  `}

  ${p =>
    p.color === 'primary' &&
    `
      color: white;
      background-color: #5468FF;

      &[disabled] {
        background-color: #adb6ff;
      }
  `}

  ${p =>
    p.bold &&
    `
      font-weight: 600;
  `}

  ${p =>
    p.loading === 'true' &&
    `
      opacity: 0.7;
      cursor: loading;
  `}

  ${p =>
    p.variant === 'text' &&
    `
    border: none;
    background-color: transparent;
    color: #5468FF;
    outline: none;
    
    &:hover {
      background-color: #5468ff14;
    }
    &:focus {
      background-color: #5468ff14;
    }
  `}
`
