import { createAsyncThunk } from '@reduxjs/toolkit'
import { api } from 'src/modules/api'

const handleError = err => {
  return err?.response?.data || err?.statusText || 'Something went wrong'
}

export const addCharge = createAsyncThunk(
  'charges/addCharge',
  async ({ amount, currency, cvv }, { rejectWithValue }) => {
    try {
      const result = await api.post(`charges`, {
        amount,
        currency,
        card_security_code: cvv,
      })

      return result?.json?.charge || result
    } catch (err) {
      return rejectWithValue(handleError(err))
    }
  }
)

export const loadCharges = createAsyncThunk('charges/loadAll', async () => {
  const result = await api.get(`charges`)

  return result?.json?.charges
})

export const loadCharge = createAsyncThunk(
  'charges/loadCharge',
  async ({ chargeId }) => {
    const result = await api.get(`charges/${chargeId}`)

    return result?.json?.charge
  }
)
