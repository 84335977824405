import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { NAMED_ROUTES } from 'src/pages/namedRoutes'

const useRouter = () => {
  const history = useHistory()

  const getPath = useCallback(name => NAMED_ROUTES[name], [])

  const getRoutePath = useCallback(({ name, query = {} }) => {
    let route = name
    if (Object.keys(query).length > 0) {
      route += `?${JSON.stringify(query)}`
    }

    return route
  }, [])

  const push = useCallback(
    ({ name, params = {}, query = {} }) => {
      const path = getRoutePath({ name, params, query })
      history.push(path)
    },
    [history, getRoutePath]
  )

  return {
    getPath,
    getRoutePath,
    push,
  }
}

export default useRouter
