import { DirectUpload } from '@rails/activestorage'

const apiHost = process.env.REACT_APP_API_BASE_URL
const apiUrl = new URL('/api/company/v1/direct_uploads/', apiHost).href

function createAuthHeaders(token) {
  return {
    Authorization: `Token token="${token}"`,
  }
}

function createDirectUpload(file, token, updateProgress, completeCallback) {
  return new Promise((resolve, reject) => {
    const upload = new DirectUpload(file, apiUrl, {
      directUploadWillCreateBlobWithXHR(xhr) {
        if (token) {
          for (const [header, val] of Object.entries(
            createAuthHeaders(token)
          )) {
            xhr.setRequestHeader(header, val)
          }
        }
      },

      directUploadWillStoreFileWithXHR(xhr) {
        xhr.upload.addEventListener('progress', updateProgress)
      },
    })

    upload.create((error, blob) => {
      if (error) {
        reject(error)
      } else {
        completeCallback()
        resolve(blob)
      }
    })
  })
}

export async function createUpload(
  file,
  updateProgressCallback,
  completeCallback
) {
  const token = localStorage.getItem('token')
  const blob = await createDirectUpload(
    file,
    token,
    updateProgressCallback,
    completeCallback
  )

  return blob
}
