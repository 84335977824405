import React, {
  useContext,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useCookies } from 'react-cookie'

import { Loader } from 'src/ui'
import { useConfirmInvitation } from 'src/hooks/useConfirmInvitations'
import { INVITATION_CODE_KEY } from 'src/constants'

import { api } from './api'

const AuthContext = createContext()

export function AuthProvider(props) {
  const [cookies, , removeCookie] = useCookies([INVITATION_CODE_KEY])
  const invitationCode = cookies[INVITATION_CODE_KEY]
  const [accessToken, setAccessToken] = useState(false)
  const [user, setUser] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isInvitationConfirmLoading, setIsInvitationConfirmLoading] = useState(
    false
  )
  const [isLoadingInitially, setIsLoadingInitially] = useState(true)

  const {
    sendConfirmationRequest,
    cleanInvitationCode,
  } = useConfirmInvitation()

  const signUp = useCallback(async data => {
    setIsLoading(true)

    const res = await api.post('registration', data)

    setIsLoading(false)

    return res
  }, [])

  const cleanBeforeLogout = useCallback(async () => {
    localStorage.removeItem('unauthorized')
    localStorage.removeItem('oauth_json')
    localStorage.removeItem('token')
    localStorage.removeItem('applicationId')

    cleanInvitationCode()
  }, [cleanInvitationCode])

  const logOut = useCallback(async () => {
    setIsLoading(true)
    setIsAuthenticated(false)

    if (accessToken && isAuthenticated) await api.del('session')

    cleanBeforeLogout()

    setAccessToken(null)
  }, [accessToken, cleanBeforeLogout, isAuthenticated])

  const getUser = useCallback(async () => {
    setIsLoading(true)
    const res = await api.get('admin')

    res.ok ? setUser(res.json.admin) : setUser(null)
    setIsLoading(false)

    return res?.json?.admin
  }, [])

  const signIn = useCallback(async data => {
    setIsLoading(true)
    localStorage.removeItem('unauthorized')

    const res = await api.post('session', data)

    if (res.json.access_token?.key) {
      setAccessToken(res.json.access_token.key)
    }

    return res
  }, [])

  const confirmAccount = useCallback(async token => {
    setIsLoading(true)

    const res = await api.post('registration/confirm', {
      confirmation_token: token,
    })

    if (res.json.access_token?.key) {
      setAccessToken(res.json.access_token.key)
    }

    return res
  }, [])

  const requestResetPassword = useCallback(async email => {
    setIsLoading(true)
    const res = await api.post('password', {
      email,
    })
    setIsLoading(false)

    return res
  }, [])

  const resetPassword = useCallback(async data => {
    setIsLoading(true)
    const res = await api.patch('password', data)
    setIsLoading(false)

    return res
  }, [])

  const requestReConfirm = useCallback(async email => {
    setIsLoading(true)
    const res = await api.post('registration/resend_confirmation', {
      email,
    })
    setIsLoading(false)

    return res
  }, [])

  const updateProfile = useCallback(
    async data => {
      setIsLoading(true)
      const res = await api.patch('admin', data)
      if (!data.password_confirmation) {
        await getUser()
      }

      setIsLoading(false)

      return res
    },
    [getUser]
  )

  useEffect(() => {
    if (localStorage.getItem('unauthorized')) {
      return logOut()
    }

    const cookieToken = cookies.nearmotion_token

    let token = localStorage.getItem('token')

    if (cookieToken && token !== cookieToken) {
      token = cookieToken
      localStorage.setItem('token', token)
    }
    // const token = cookieToken || localStorage.getItem('token')

    setAccessToken(token)

    // Remove the token from cookies if it's there
    if (cookieToken) {
      removeCookie('nearmotion_token')
    }
  }, [cookies.nearmotion_token, logOut, removeCookie])

  const checkAccessToken = async () => {
    if (accessToken === false) return
    if (/confirm-account/.test(window.location.pathname) && user?.id)
      return false

    if (accessToken) {
      // Save token in local storage for future use
      localStorage.setItem('token', accessToken)

      // TODO: Get rid of this
      window.token = accessToken

      await getUser()
    } else {
      setUser(null)
    }
  }

  useEffect(() => {
    checkAccessToken()
  }, [accessToken])

  useEffect(() => {
    if (user === false) return
    const ignorePages = /confirm-account|invitations|sign-up|sign-in|reset-password|re-confirm|status/.test(
      window.location.pathname
    )

    setIsAuthenticated(user?.id)
    setIsLoadingInitially(false)

    if (!user && !ignorePages) {
      window.location.href = '/sign-in'
    }

    if (invitationCode && user?.id && !isInvitationConfirmLoading) {
      setIsInvitationConfirmLoading(true)
      const loadInvitation = async () => {
        const result = await sendConfirmationRequest({
          invitationCode,
          data: {
            email: user.email,
            first_name: user.first_name,
            last_name: user.last_name,
          },
        })
        if (result.ok) {
          window.location.href = '/'
        }
      }
      loadInvitation()
    }
  }, [
    sendConfirmationRequest,
    user,
    isInvitationConfirmLoading,
    invitationCode,
  ])

  const contextValue = {
    accessToken,
    user,
    setUser,
    isAuthenticated,
    isLoading,
    signUp,
    updateProfile,
    signIn,
    confirmAccount,
    requestResetPassword,
    resetPassword,
    requestReConfirm,
    cleanBeforeLogout,
    logOut,
    isLoadingInitially,
  }

  return (
    <AuthContext.Provider value={contextValue}>
      {isLoadingInitially ? <Loader /> : props.children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  return useContext(AuthContext)
}
