import React from 'react'
import { Field } from 'react-final-form'
import { SelectFieldUI } from 'src/ui/form/SelectFieldUI'

export const SelectField = ({ name = '1', ...props }) => {
  return (
    <Field
      name={name}
      render={({ input, meta }) => (
        <SelectFieldUI
          {...props}
          inputProps={{
            ...input,
            disabled: props?.inputProps?.disabled,
          }}
          error={
            (meta.error || meta.submitError) &&
            meta.touched &&
            (meta.error || meta.submitError?.[0])
          }
        />
      )}
    />
  )
}
