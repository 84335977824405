import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { Form, FormSpy } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'

import { TextField } from 'src/components/TextField'
import { Alert as AlertComponent } from 'src/components/Alert'
import { AccordionItem, Button } from 'src/ui'
import { useAbility } from 'src/hooks/useAbility'
import { toast } from 'react-toastify'

export const CreateCustom = props => {
  const { onClose, trigger, setCurrentItem, handleCreate } = props
  const [initialValues, setInitialValues] = useState()

  const {
    engagement: { update: canUpdate },
  } = useAbility()

  const handleTriggerChange = useCallback(() => {
    const activity = trigger.activity || trigger.activity_attributes
    const customAttributes =
      activity.custom_attributes || activity.custom_attributes_attributes

    const editTrigger = {
      id: trigger.id,
      action_name: activity.name,
      event_type: trigger.event_type,
      customs:
        customAttributes?.length > 0
          ? customAttributes
          : [
              {
                name: '',
                value: '',
              },
            ],
    }

    setInitialValues(editTrigger)
  }, [trigger])

  useEffect(() => {
    if (trigger && !initialValues) {
      handleTriggerChange()
    } else if (!initialValues) {
      setInitialValues({
        customs: [
          {
            name: '',
            value: '',
          },
        ],
      })
    }
  }, [initialValues, trigger])

  const handleSave = async values => {
    if (!canUpdate) return

    const newTrigger = {
      id: values.id,
      activity_attributes: {
        name: values.action_name,
        scheme: 'custom',
      },
    }
    if (!newTrigger.activity_attributes.custom_attributes_attributes) {
      newTrigger.activity_attributes.custom_attributes_attributes = []
    }

    if (values.customs.length) {
      newTrigger.activity_attributes.custom_attributes_attributes = values.customs.filter(
        custom => custom.name?.trim().length && custom.value?.trim().length
      )
    }

    if (!newTrigger.activity_attributes.custom_attributes_attributes.length) {
      toast.error('Please add at last one key/value pair.')
    } else {
      await setCurrentItem(newTrigger)
      handleCreate(newTrigger)
    }
  }
  const validateForm = values => {
    const errors = {}
    if (!values.action_name) {
      errors.action_name = 'Action name Required'
    }

    if (values?.customs?.length) {
      values.customs.forEach((item, index) => {
        if (!item.name) {
          if (!errors.customs) {
            errors.customs = []
          }
          errors.customs[index] = { name: 'Title Required' }
        }
        if (!item.value) {
          if (!errors.customs) {
            errors.customs = []
          }
          if (!errors.customs[index]) {
            errors.customs[index] = {}
          }
          errors.customs[index].value = 'Value Required'
        }
      })
    }

    return errors
  }

  return (
    <Container>
      <AccordionItem title="Engagement">
        <HeaderTitle>Create Custom</HeaderTitle>
        <Form
          onSubmit={handleSave}
          validate={validateForm}
          mutators={{
            ...arrayMutators,
          }}
          initialValues={initialValues}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} style={{ textAlign: 'center' }}>
              <TextField
                fullWidth
                label="Action name"
                name="action_name"
                placeholder="Starbucks"
                maxLength={255}
              />

              <FieldArray name="customs">
                {({ fields }) => (
                  <div>
                    {fields?.map((name, index) => (
                      <CustomItem key={name}>
                        <TextFieldStyled
                          name={`${name}.name`}
                          fullWidth
                          label="Title"
                          placeholder="Welcome to Starbucks"
                          maxLength={255}
                        />

                        <TextFieldStyled
                          name={`${name}.value`}
                          fullWidth
                          label="Value"
                          placeholder="Value"
                          maxLength={255}
                        />

                        <Remove
                          type="button"
                          onClick={() => fields.remove(index)}
                        >
                          Remove
                        </Remove>
                      </CustomItem>
                    ))}
                    <AddCustom
                      type="button"
                      color="primary"
                      onClick={() => fields.push({ name: '', value: '' })}
                    >
                      Add key/value pair
                    </AddCustom>
                  </div>
                )}
              </FieldArray>

              <FormSpy
                subscription={{
                  submitting: true,
                  pristine: true,
                  valid: true,
                  dirtySinceLastSubmit: true,
                  hasValidationErrors: true,
                  submitErrors: true,
                  error: true,
                  errors: true,
                  submitFailed: true,
                }}
              >
                {({ submitErrors }) => {
                  const baseError = submitErrors?.message

                  return (
                    <>
                      {!!baseError && (
                        <Alert type="error" title={t(baseError)} />
                      )}
                      <ActionWrapper>
                        <Cancel
                          color="secondary"
                          size="lg"
                          onClick={() => onClose()}
                        >
                          {t('Cancel')}
                        </Cancel>

                        <Button
                          disabled={!canUpdate}
                          color="primary"
                          size="lg"
                          onClick={handleSubmit}
                        >
                          {t('Next')}
                        </Button>
                      </ActionWrapper>
                    </>
                  )
                }}
              </FormSpy>
            </form>
          )}
        />
      </AccordionItem>
    </Container>
  )
}

const HeaderTitle = styled.h1`
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.5px;
  color: #344356;
  margin: 20px 20px 40px 0;
`

const Container = styled.div`
  font-family: 'Biko';
  select,
  input {
    width: 285.19px;
    padding-left: 0;
    font-size: 12px;
    line-height: 12px;
    border: none;
    border-bottom: 1px solid #e2ebffcf;
    border-radius: 0;
    &:focus {
      outline: none;
      border-bottom-color: #5468ff;
    }
  }
  label {
    margin-bottom: 0;
    margin-left: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    color: #344356;
  }
`
const ActionWrapper = styled.div`
  display: flex;
`

const Cancel = styled(Button)`
  margin-right: 18px;
`

const Alert = styled(AlertComponent)`
  background: white;
  > div:first-of-type {
    background: white;
    color: #ff6355;
  }
`

const Remove = styled.div`
  width: max-content;
  color: #ff6355;
  cursor: pointer;
`
const CustomItem = styled.div`
  display: grid;
  justify-items: end;
`

const TextFieldStyled = styled(TextField)`
  margin-bottom: 10px;
`

const AddCustom = styled(Button)`
  margin-bottom: 15px;
`
