/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components/macro'
import { typography, space, color } from 'styled-system'
import { useTable, useRowSelect, useSortBy, usePagination } from 'react-table'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { Scroller } from 'src/components/Scroller'

import DraggableDotsIcon from 'src/assets/images/svg/draggableDotsIcon.svg'
import { ReactComponent as ArrowImage } from 'src/assets/images/svg/arrow-down.svg'

export const DraggableDataTable = ({
  pagination,
  columns: columnsProp,
  rows: rowsProp,
  pageCount: controlledPageCount,
  currentPageSize,
  fetchData,
  actions,
  withSelection,
  isSortable,
  initialSortState,
  withDragNDrop,
  onDragEnd,
  currentPageIndex,
  isLoading,
}) => {
  const plugins = [
    withSelection && useRowSelect,
    isSortable && useSortBy,
  ].filter(Boolean)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // Get the state from the instance
    state: { pageIndex, pageSize },

    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = useTable(
    {
      columns: columnsProp,
      data: rowsProp,
      initialState: {
        sortBy: initialSortState,
        pageIndex: 0,
        pageCount: controlledPageCount,
        pageSize: currentPageSize,
      },
      manualPagination: pagination, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount,
    },
    ...plugins,
    pagination ? usePagination : false
    // hooks => {
    // TODO : Do not just uncomment! It's legacy. If you're going to use selectable checkboxes,
    // please prepend the appropriate TH and TD in the tr's like DnD
    // hooks.visibleColumns.push(columns => [
    //   {
    //     id: 'selection',
    //     Header: ({ getToggleAllRowsSelectedProps }) => (
    //       <div>
    //         <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
    //       </div>
    //     ),
    //     Cell: ({ row }) => (
    //       <div>
    //         <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
    //       </div>
    //     ),
    //   },
    //   ...columns,
    // ])
    // }
  )

  const onDragEndHandler = useCallback(
    result => {
      if (!result.destination) {
        return
      }

      if (result.destination.index === result.source.index) {
        return
      }

      onDragEnd &&
        onDragEnd(result.source.index, result.destination.index, result)
    },
    [onDragEnd]
  )

  const fetchOnPageChange = useCallback(() => {
    if (pageIndex !== currentPageIndex && pagination && !isLoading) {
      fetchData({ pageIndex, pageSize })
    }
  }, [currentPageIndex, fetchData, isLoading, pageIndex, pageSize, pagination])

  useEffect(() => {
    fetchOnPageChange()
  }, [fetchOnPageChange, pageIndex, pageSize])

  return (
    <>
      {!!actions && <Actions>{actions}</Actions>}
      <Table fullSize actions={actions}>
        <Scroller>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <THeadTR {...headerGroup.getHeaderGroupProps()}>
                  {!!withDragNDrop && <TH dnd />}
                  {headerGroup.headers.map(column => (
                    <TH
                      {...column.getHeaderProps(
                        isSortable
                          ? column.getHeaderProps(column.getSortByToggleProps())
                          : undefined
                      )}
                      style={column.cellStyle}
                    >
                      {column.render('Header')}
                      <span>
                        {column.isSorted && (
                          <Arrow desc={column.isSortedDesc} />
                        )}
                      </span>
                    </TH>
                  ))}
                </THeadTR>
              ))}
            </thead>
            <DragDropContext onDragEnd={onDragEndHandler}>
              <Droppable droppableId="list">
                {providedDroppable => (
                  <tbody
                    {...getTableBodyProps()}
                    ref={providedDroppable.innerRef}
                    {...providedDroppable.droppableProps}
                  >
                    {rows.map((row, rowIndex) => {
                      prepareRow(row)

                      return (
                        <Draggable
                          draggableId={row.id}
                          index={rowIndex}
                          key={row.id || rowIndex}
                        >
                          {(providedDraggable, snapshot) => (
                            <TBodyTR
                              {...row.getRowProps()}
                              ref={providedDraggable.innerRef}
                              {...providedDraggable.draggableProps}
                              isDragging={snapshot.isDragging}
                              style={{
                                ...providedDraggable.draggableProps.style,
                                boxShadow: snapshot.isDragging
                                  ? '-4px 4px 8px rgb(10 16 45 / 6%), -8px 8px 16px rgb(10 16 45 / 6%)'
                                  : 'none',
                                borderRadius: snapshot.isDragging ? 4 : 0,
                              }}
                            >
                              {!!withDragNDrop && (
                                <>
                                  <TD
                                    dnd
                                    {...providedDraggable.dragHandleProps}
                                  />
                                </>
                              )}
                              {row.cells.map(cell => {
                                return (
                                  <TD
                                    {...cell.getCellProps()}
                                    style={cell.column.cellStyle}
                                  >
                                    {cell.render('Cell')}
                                  </TD>
                                )
                              })}
                            </TBodyTR>
                          )}
                        </Draggable>
                      )
                    })}
                    {providedDroppable.placeholder}
                  </tbody>
                )}
              </Droppable>
            </DragDropContext>
          </table>
        </Scroller>
        {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
      */}
        {pagination && (
          <div className="pagination">
            <button
              type="button"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {'<<'}
            </button>{' '}
            <button
              type="button"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              {'<'}
            </button>{' '}
            <button
              type="button"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              {'>'}
            </button>{' '}
            <button
              type="button"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {'>>'}
            </button>{' '}
            <span>
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{' '}
            </span>
            <span>
              | Go to page:{' '}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  gotoPage(page)
                }}
                style={{ width: '100px' }}
              />
            </span>{' '}
            <select
              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value))
              }}
            >
              {[20, 50, 100, 200, 300, 600, 1000].map(size => (
                <option key={size} value={size}>
                  Show {size}
                </option>
              ))}
            </select>
          </div>
        )}
      </Table>
    </>
  )
}

const THeadTR = styled.tr``

const TBodyTR = styled.tr`
  background-color: white;
  :hover {
    background-color: #fafbff;
  }

  :last-child {
    td {
      border-bottom: 0;
    }
  }

  ${p =>
    p.isDragging &&
    `
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    td {
      height: unset;
    }
  `}
`

const TH = styled.th`
  font-weight: 400;
  cursor: pointer;
  &:first-child input[type='checkbox'] {
    float: left;
  }
  margin: 0;
  padding: 0.5rem;
  height: 51px;

  :last-child {
    border-right: 0;
  }
`

const TD = styled.td`
  ${typography}
  ${space}
  ${color}
  margin: 0;
  padding: 0.5rem;
  height: 51px;

  :last-child {
    border-right: 0;
  }

  ${p =>
    p.dnd &&
    `
    padding: 1rem;
    background-image: url(${DraggableDotsIcon});
    background-size: 69%;
    background-position: center;
    background-repeat: no-repeat;
  `}
`

const Arrow = styled(ArrowImage)`
  margin-left: 10px;

  ${p =>
    !p.desc &&
    `
  transform: rotate(180deg);
  `}
`

const Table = styled.div`
  position: relative;
  height: 100%;

  ${p => p.actions && 'height: calc(100% - 65px);'}

  table {
    border-spacing: 0;
    width: 100%;
    color: #354168;
    font-size: 12px;

    thead {
      color: #9aaccf;
      font-size: 14px;
    }
  }
`
const Actions = styled.div`
  text-align: left;
  margin-bottom: 21px;
  display: flex;
  align-items: center;
  > * {
    margin-right: 35px;
  }
`
