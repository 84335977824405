import React, { useEffect, useMemo } from 'react'
import { Form, FormSpy } from 'react-final-form'
import styled from 'styled-components/macro'
import { Modal } from 'src/components/Modal'
import { TextField } from 'src/components/TextField'
import { SelectField } from 'src/components/SelectField'
import { Button } from 'src/ui'
import { useBuildings } from 'src/modules/buildings'
import { Alert } from 'src/components/Alert'
import validate from 'src/utils/validate'
import isFieldComplete from 'src/utils/validators/isFieldComplete'
import isRequired from 'src/utils/validators/isRequired'
import isUuid from 'src/utils/validators/isUuid'
import { useFloors } from 'src/modules/floors'

export const CreateBeaconModal = ({ onSubmit, onClose, initialValues }) => {
  const { buildings, get: getBuildings } = useBuildings()
  const { floors, get: getFloors } = useFloors()

  const validateForm = values => {
    const errors = {}

    errors.building_id = validate(
      values.building_id,
      isFieldComplete('building_id', 'Please select building')
    )

    errors.name = validate(values.name, isRequired)
    errors.major = validate(values.major, isRequired)
    errors.minor = validate(values.minor, isRequired)
    errors.uuid = validate(values.uuid, isUuid)

    return errors
  }

  useEffect(() => {
    if (!buildings.length) {
      getBuildings()
    }
  }, [buildings.length, getBuildings])

  useEffect(() => {
    if (
      !floors.length ||
      floors?.[0]?.building_id !== initialValues.building_id
    ) {
      getFloors(initialValues.building_id)
    }
  }, [])

  const options = useMemo(
    () =>
      [{ label: t('Please select'), value: '' }].concat(
        buildings.map(building => ({
          label: building.name,
          value: building.id,
        }))
      ),
    [buildings]
  )

  const floorsOptions = useMemo(
    () =>
      [{ label: t('Please select'), value: '' }].concat(
        floors.map(floor => ({
          label: floor.name,

          value: floor.id,
        }))
      ),
    [floors]
  )
  const handleFormChange = async props => {
    if (
      props.values.building_id &&
      (!floors.length ||
        (floors.length &&
          parseInt(props.values.building_id, 10) !==
            parseInt(floors[0].building_id, 10)))
    ) {
      await getFloors(props.values.building_id)
      props.values.building_floor_id = ''
    }
  }

  return (
    <Modal onClose={onClose}>
      <ModalContainer>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          validate={validateForm}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} style={{ textAlign: 'center' }}>
              {!initialValues?.building_id && (
                <SelectField
                  fullWidth
                  isMulti={false}
                  isSearchable={false}
                  options={options}
                  label="Building"
                  name="building_id"
                />
              )}
              {initialValues?.building_id && (
                <TextField
                  fullWidth
                  label="Building"
                  name="building_name"
                  disabled="disabled"
                />
              )}
              <SelectField
                fullWidth
                isMulti={false}
                isSearchable={false}
                options={floorsOptions}
                label="Floor"
                name="building_floor_id"
              />
              <TextField fullWidth label="Name" name="name" />
              <TextField fullWidth label="UUID" name="uuid" />
              <TextField fullWidth label="Major" name="major" />
              <TextField fullWidth label="Minor" name="minor" />
              <TextField
                fullWidth
                label="Longitude"
                name="longitude"
                type="number"
              />
              <TextField
                fullWidth
                label="Latitude"
                name="latitude"
                type="number"
              />
              <FormSpy onChange={handleFormChange} />
              <FormSpy>
                {({
                  errors,
                  submitErrors,
                  submitFailed,
                  dirtySinceLastSubmit,
                  hasValidationErrors,
                }) => {
                  const baseError =
                    errors?.base?.[0] ||
                    errors?.base ||
                    submitErrors?.base?.[0] ||
                    submitErrors?.base

                  return (
                    <>
                      {!!baseError && (
                        <Alert type="error" title={t(baseError)} />
                      )}
                      <ActionWrapper>
                        <Button
                          color="secondary"
                          bold
                          size="lg"
                          onClick={onClose}
                        >
                          {t('Cancel')}
                        </Button>

                        <Button
                          color="primary"
                          bold
                          size="lg"
                          disabled={
                            hasValidationErrors ||
                            (submitFailed && !dirtySinceLastSubmit)
                          }
                          onClick={handleSubmit}
                        >
                          {initialValues.id ? t('Save') : t('Create')}
                        </Button>
                      </ActionWrapper>
                    </>
                  )
                }}
              </FormSpy>
            </form>
          )}
        />
      </ModalContainer>
    </Modal>
  )
}

const ActionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
`

const ModalContainer = styled.div`
  width: 800px;
  padding: 30px;
  background-color: white;
  border-radius: 5px;
`
