/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { typography, space, color } from 'styled-system'
import { useTable, useRowSelect, useSortBy, usePagination } from 'react-table'

import { Scroller } from 'src/components/Scroller'

import { ReactComponent as ArrowImage } from 'src/assets/images/svg/arrow-down.svg'

export const DataTable = ({
  search,
  searchable,
  pagination,
  className,
  columns: columnsProp,
  rows: rowsProp,
  pageCount: controlledPageCount,
  currentPageSize,
  fetchData,
  actions,
  withSelection,
  isSortable,
  initialSortState,
  currentPageIndex,
  isLoading,
  scrollerAutoHide = true,
}) => {
  const plugins = [
    withSelection && useRowSelect,
    isSortable && useSortBy,
  ].filter(Boolean)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // Get the state from the instance
    state: { pageIndex, pageSize },

    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = useTable(
    {
      columns: columnsProp,
      data: rowsProp,
      initialState: {
        sortBy: initialSortState,
        pageIndex: 0,
        pageCount: controlledPageCount,
        pageSize: currentPageSize,
      },
      manualPagination: pagination, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount,
    },
    ...plugins,
    pagination ? usePagination : false
  )

  useEffect(() => {
    if (
      (searchable && typeof search === 'string') ||
      (pagination &&
        ((pageIndex !== currentPageIndex && !isLoading) ||
          pageSize !== rows.length))
    ) {
      fetchData({ pageIndex, pageSize })
    }
  }, [fetchData, pageIndex, pageSize, search])

  return (
    <>
      {!!actions && <Actions>{actions}</Actions>}
      <Table fullSize actions={actions} className={className}>
        <Scroller autoHide={scrollerAutoHide}>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <THeadTR {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <TH
                      {...column.getHeaderProps(
                        isSortable
                          ? column.getHeaderProps(column.getSortByToggleProps())
                          : undefined
                      )}
                      style={column.cellStyle}
                    >
                      {column.render('Header')}
                      <span>
                        {column.isSorted && (
                          <Arrow desc={column.isSortedDesc} />
                        )}
                      </span>
                    </TH>
                  ))}
                </THeadTR>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row)

                return (
                  <TBodyTR {...row.getRowProps()} key={row.id}>
                    {row.cells.map(cell => {
                      return (
                        <TD
                          {...cell.getCellProps()}
                          style={cell.column.cellStyle}
                        >
                          {cell.render('Cell')}
                        </TD>
                      )
                    })}
                  </TBodyTR>
                )
              })}
            </tbody>
          </table>
        </Scroller>
        {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
      */}
        {pagination && (
          <Pagination className="pagination">
            <PageSelector>
              <span>
                Go to page:{' '}
                <GoToPage
                  type="number"
                  defaultValue={pageIndex + 1}
                  disabled={pageOptions.length <= 1}
                  onChange={e => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                    gotoPage(page)
                  }}
                />
              </span>{' '}
              <select
                value={pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value))
                }}
              >
                {[20, 50, 100, 200, 300, 600, 1000].map(size => (
                  <option key={size} value={size}>
                    Show {size}
                  </option>
                ))}
              </select>
            </PageSelector>
            <Navigation>
              <NavigationActions>
                <NavButton
                  type="button"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  {'<<'}
                </NavButton>{' '}
                <NavButton
                  type="button"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  {'<'}
                </NavButton>{' '}
                <NavButton
                  type="button"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  {'>'}
                </NavButton>{' '}
                <NavButton
                  type="button"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {'>>'}
                </NavButton>{' '}
              </NavigationActions>

              <span>
                Page{' '}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{' '}
              </span>
            </Navigation>
          </Pagination>
        )}
      </Table>
    </>
  )
}

const THeadTR = styled.tr``

export const TBodyTR = styled.tr`
  background-color: white;
  :hover {
    background-color: #fafbff;
  }

  :last-child {
    td {
      border-bottom: 0;
    }
  }
`

export const TH = styled.th`
  font-weight: 400;
  cursor: pointer;
  &:first-child input[type='checkbox'] {
    float: left;
  }
  margin: 0;
  padding: 0.5rem;
  height: 51px;

  :last-child {
    border-right: 0;
  }
`

export const TD = styled.td`
  ${typography}
  ${space}
  ${color}
  margin: 0;
  padding: 0.5rem;
  height: 51px;

  :last-child {
    border-right: 0;
  }
`

const Arrow = styled(ArrowImage)`
  margin-left: 10px;

  ${p =>
    !p.desc &&
    `
  transform: rotate(180deg);
  `}
`

export const Table = styled.div`
  position: relative;
  height: 100%;

  ${p => p.actions && 'height: calc(100% - 120px);'}

  table {
    border-spacing: 0;
    width: 100%;
    color: #354168;
    font-size: 12px;

    thead {
      color: #9aaccf;
      font-size: 14px;
    }
  }
`
const Actions = styled.div`
  text-align: left;
  margin-bottom: 21px;
  display: flex;
  height: 44px;
  align-items: center;
  > * {
    margin-right: 35px;
  }
`
const Pagination = styled.div`
  display: grid;
  grid-gap: 10px;
  justify-content: center;
  justify-items: center;
  color: #354168;
`
const PageSelector = styled.div``
const Navigation = styled.div`
  display: grid;
  grid-gap: 10px;
  text-align: center;
`
const NavigationActions = styled.div`
  display: flex;
`
const GoToPage = styled.input`
  border-top: none;
  border-left: none;
  border-right: none;
  width: 40px;
`
const NavButton = styled.button`
  appearance: none;
  background: transparent;
  border: none;
  font-size: 16px;
`
