import React, { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components/macro'
import { Scroller } from 'src/components/Scroller'

const stopPropagation = e => {
  e.stopPropagation()
}

export const Modal = ({
  onClose,
  children,
  className,
  backdropClassName,
  closeOnEsc = true,
  closeOnOutsideClick = true,
}) => {
  const scroller = useRef()

  useEffect(() => {
    if (closeOnEsc) {
      const close = e => {
        if (e.keyCode === 27) {
          onClose && onClose()
        }
      }

      window.addEventListener('keydown', close)

      return () => window.removeEventListener('keydown', close)
    }
  }, [closeOnEsc, onClose])

  // added refresh modal scroller on the modal content change
  useEffect(() => {
    scroller.current.recalculate()
  })

  return createPortal(
    <Backdrop
      onClick={closeOnOutsideClick ? onClose : undefined}
      className={backdropClassName}
    >
      <StyledScroller autoHide={false} ref={scroller}>
        <ModalContainer className={className} onClick={stopPropagation}>
          {children}
        </ModalContainer>
        <BottomWhiteSpace />
      </StyledScroller>
    </Backdrop>,
    document.getElementById('modal-container')
  )
}

const Backdrop = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 72px;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #13131336;
  z-index: 3;

  & .simplebar-content {
    height: 100%;
    display: grid;
    justify-content: center;

    .simplebar-content {
      display: block;
    }
  }
`

const StyledScroller = styled(Scroller)`
  width: 100%;
`

const BottomWhiteSpace = styled.div`
  height: 1px;
`

export const ModalContainer = styled.div`
  display: flex;
  margin: 30px 0;
  justify-content: center;
  width: fit-content;
  height: fit-content;
`
