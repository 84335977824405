import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'

import { useMap } from 'src/modules/map'

const markers = []
let searchMarker = null

function BuildingMapComponent(props) {
  const {
    map,
    geocodingClient,
    setShowMarkers,
    setShowSearchMarker,
    showSearchMarker,
    showMarkers,
    setLongitude,
    setLatitude,
    setAddress,
    flyTo,
  } = useMap()

  const [locationCoords, setLocationCoords] = useState(null)
  const [coordsLoaded, setCoordsLoaded] = useState(false)

  const getUserLocation = useCallback(() => {
    if (locationCoords) return

    if (!navigator.geolocation) {
      setLocationCoords({
        lat: 1,
        lng: 1,
      })
      setCoordsLoaded(true)

      return
    }

    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        setLocationCoords({
          lat: coords.latitude,
          lng: coords.longitude,
        })

        setCoordsLoaded(true)
      },
      () => {
        setLocationCoords({
          lat: 1,
          lng: 1,
        })
        setCoordsLoaded(true)
      },
      {
        enableHighAccuracy: false,
        timeout: 5000,
        maximumAge: 600000,
      }
    )
  }, [locationCoords, setLocationCoords, setCoordsLoaded])

  // Update address on drag-n-drop
  const onMarkerDragEnd = useCallback(() => {
    if (!searchMarker) return

    const coordinates = searchMarker.getLngLat()

    setLatitude(coordinates.lat)
    setLongitude(coordinates.lng)

    setLocationCoords({
      lat: coordinates.lat,
      lng: coordinates.lng,
    })

    if (!geocodingClient) return

    geocodingClient.geocoderService
      .reverseGeocode({
        query: [coordinates.lng, coordinates.lat],
      })
      .send()
      .then(response => {
        setAddress(readAddress(response))
      })
  }, [
    geocodingClient,
    setAddress,
    setLatitude,
    setLongitude,
    setLocationCoords,
  ])

  const updateSearchMarker = useCallback(
    e => {
      if (!searchMarker) return

      if (searchMarker.getElement().parentNode) {
        searchMarker.setLngLat([e.lngLat.lng, e.lngLat.lat])
        onMarkerDragEnd()
      }
    },
    [onMarkerDragEnd]
  )

  // Init

  useEffect(() => {
    if (!map) return

    if (!searchMarker) {
      searchMarker = new window.mapboxgl.Marker({
        draggable: true,
      })
    }

    searchMarker.on('dragend', onMarkerDragEnd)
    map.on('click', updateSearchMarker)

    return () => {
      searchMarker.off('dragend', onMarkerDragEnd)
      map.off('click', updateSearchMarker)

      searchMarker?.remove()
      searchMarker = null
      markers.forEach(marker => marker.remove())
    }
  }, [map, onMarkerDragEnd, updateSearchMarker])

  // Building Markers

  useEffect(() => {
    if (!map) return
    if (showMarkers.length > 0) setShowSearchMarker(false)

    // Remove existing markers from the Map
    markers.forEach(marker => marker.remove())
    // Truncate markers cache
    markers.length = 0

    // Build new markers and cache them
    showMarkers?.forEach(coords => {
      const marker = new window.mapboxgl.Marker({
        draggable: false,
      }).setLngLat([coords.lng, coords.lat])

      marker.addTo(map)
      markers.push(marker)
    })

    // Fly to new maekers
    if (markers.length === 1 && showMarkers?.length) {
      map.easeTo({
        padding: {
          left: 100,
        },
        duration: 0,
      })
      flyTo(markers[0].getLngLat(), { zoom: 12 })
    } else if (markers.length > 1) {
      const coords = []

      markers.forEach(marker => coords.push(marker.getLngLat()))

      const bounds = coords.reduce((b, c) => {
        return b.extend(c)
      }, new window.mapboxgl.LngLatBounds(coords[0], coords[0]))

      map.fitBounds(bounds, { padding: 100 })
    }
  }, [map, showMarkers, setShowMarkers, setShowSearchMarker, flyTo])

  // Search Marker

  useEffect(() => {
    if (!map) return

    // Reset other markers
    if (showSearchMarker) {
      setShowMarkers([])
      getUserLocation()
    }
  }, [map, showSearchMarker, setShowMarkers, getUserLocation])

  useEffect(() => {
    if (!searchMarker || !coordsLoaded) return

    if (showSearchMarker) {
      searchMarker.setLngLat(locationCoords)

      if (!searchMarker.getElement().parentNode) {
        searchMarker.addTo(map)
        const coords = searchMarker.getLngLat()
        if (coords.lng !== 1 && coords.lat !== coords.lng) {
          map.easeTo({
            padding: {
              left: 100,
            },
            duration: 0,
          })
          flyTo(coords)
        }
      }
    } else {
      searchMarker.remove()
    }
  }, [map, locationCoords, showSearchMarker, coordsLoaded, flyTo])

  return <Wrapper className={props.className}>{props.children}</Wrapper>
}

// Fetch address from geolocation response
export const readAddress = response => {
  // GeoJSON with geocoding matches

  const addressFeature = response.body.features.find(item =>
    /address/.test(item.place_type[0])
  )

  const poiFeature = response.body.features.find(item =>
    /poi/.test(item.place_type[0])
  )

  let address = null

  if (addressFeature?.properties?.address) {
    address = addressFeature.place_name
  } else if (poiFeature?.place_name && poiFeature?.text) {
    return poiFeature.place_name.replace(`${poiFeature.text}, `, '')
  } else if (poiFeature?.properties?.address) {
    address = poiFeature?.properties?.address
  } else {
    const postcodeFeature = response.body.features.find(item =>
      /postcode/.test(item.place_type[0])
    )?.text

    const regionFeature = response.body.features.find(item =>
      /region/.test(item.place_type[0])
    )?.text

    const countryFeature = response.body.features.find(item =>
      /country/.test(item.place_type[0])
    )?.text

    address = [postcodeFeature, regionFeature, countryFeature]
      ?.filter(item => !!item)
      .toString()
      .replace(/,/gi, ', ')
  }

  return address
}

export const MapBox = styled(BuildingMapComponent)``

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`
