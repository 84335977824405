import { Form } from 'react-final-form'
import React from 'react'
import styled from 'styled-components/macro'

import { Button } from 'src/ui'

export const SubscribeForm = props => {
  const {
    isTrialing,
    trialDaysLeft,
    handleCreateSubscription,
    showChargeForm,
    handleShowChargeForm,
    isMadaCard,
  } = props

  return (
    <Form
      onSubmit={handleCreateSubscription}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          {isTrialing && (
            <p>
              <b>Free Trial</b>: {trialDaysLeft} day(s) (you won&apos;t be
              billed during this period and can cancel your subscription free of
              charge)
            </p>
          )}

          <Actions>
            <Button color="primary" onClick={handleShowChargeForm}>
              {showChargeForm ? 'Cancel' : 'Refill Balance'}
            </Button>

            <Button color="primary" onClick={handleSubmit}>
              {t(`${isTrialing ? 'Confirm & ' : ''}Subscribe`)}
            </Button>
          </Actions>
          {isMadaCard && (
            <p>
              *Notice: MADA cards do not support RECURRING payments and we
              won&apos;t be able to charge your card automatically.
              <br /> To keep your subscription active please add funds to your
              balance ahead.
            </p>
          )}
        </form>
      )}
    />
  )
}

const Actions = styled.div`
  display: flex;
  column-gap: 20px;
  margin-top: 20px;
`
