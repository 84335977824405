import { createSlice } from '@reduxjs/toolkit'

const routeSlice = createSlice({
  name: 'reports',
  initialState: {
    loading: false,
    neighbors: [],
    activeDrawTool: false,
  },
  reducers: {
    setRouteNeighbors: (state, action) => {
      state.neighbors = action.payload
    },
    setActiveDrawTool: (state, action) => {
      state.activeDrawTool = action.payload.activeDrawTool
    },
  },
  extraReducers: builder => {},
})

export const routeActions = routeSlice.actions
export const routeReducer = routeSlice.reducer
