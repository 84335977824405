import React, { useRef, useEffect, useCallback, useMemo } from 'react'
import styled from 'styled-components/macro'
import { useParams } from 'react-router-dom'

import { useAuth } from 'src/modules/auth'
import { useApplications } from 'src/modules/applications'
import { Link } from 'src/components/Link'

import { ReactComponent as LogoImage } from 'src/assets/images/svg/logo17.svg'
import { ReactComponent as UserImage } from 'src/assets/images/svg/user.svg'
import { ReactComponent as ArrowImage } from 'src/assets/images/svg/arrow-down.svg'
import { ReactComponent as SearchImageComponent } from 'src/assets/images/svg/search.svg'
import { useCompany } from 'src/modules/company'
import { useAbility } from 'src/hooks/useAbility'

function HeaderComponent(props) {
  const searchRef = useRef()
  const { buildingId, floorId, triggerId } = useParams()

  const { logOut, isAuthenticated } = useAuth()
  const { isOwner } = useAbility()
  const { company } = useCompany()
  const { applicationId } = useApplications()
  const isSubscribed = useMemo(() => {
    return company?.subscription
  }, [company])

  useEffect(() => {
    if (props.search) {
      const currentNode = searchRef.current
      // check if the browser supports the replaceChildren method
      if (currentNode.replaceChildren) {
        currentNode.replaceChildren(props.search)
      } else {
        currentNode.innerHTML = ''
        currentNode.append(props.search)
      }

      currentNode
        ?.getElementsByTagName('input')[0]
        ?.setAttribute('placeholder', 'Start by typing the building address')
    }
  }, [props.search])

  const logoOnClick = useCallback(() => {
    if (!isSubscribed && !company?.id) return '/companies'
    if (!isSubscribed && company?.id) return '/payments/cards'
    if (!applicationId) return '/applications'
    if (triggerId) return `/buildings/${buildingId}/floors/${floorId}`
    if (floorId) return `/buildings/${buildingId}`
    if (buildingId) return `/buildings`

    return '/applications'
  }, [applicationId, buildingId, company?.id, floorId, isSubscribed, triggerId])

  return (
    <HeaderContainer>
      <Button type="button" to={logoOnClick}>
        <Logo />
      </Button>
      {props.search && (
        <Search>
          <div ref={searchRef} />
          <SearchImage />
        </Search>
      )}
      <Navigation>
        <LinkStyled
          title="Contact"
          target="_blank"
          href="https://nearmotion.com/about/#contact-us"
        >
          Contact
        </LinkStyled>
        {/* <Button to="/help" title="Help">
          Help
        </Button> */}
        <ProfileMenu>
          <User />
          <Arrow />
          <ProfileSubmenu>
            {isAuthenticated && (
              <>
                {isSubscribed && (
                  <>
                    <Button to="/applications">Applications</Button>
                    {!!applicationId && <Button to="/beacons">Beacons</Button>}
                  </>
                )}

                <Button to="/companies">Companies</Button>
                <Button to="/profile">Profile</Button>
                {company?.id && isOwner && !isSubscribed?.offline && (
                  <Button to="/payments/cards">Payments</Button>
                )}

                <hr />
              </>
            )}

            <OutsideLink
              href="https://nearmotion.com/terms-and-conditions/"
              target="_blank"
              rel="noreferrer"
            >
              {t('Terms and Conditions')}
            </OutsideLink>
            <OutsideLink
              href="https://nearmotion.com/privacy-policy/"
              target="_blank"
              rel="noreferrer"
            >
              {t('Privacy policy')}
            </OutsideLink>
            <OutsideLink
              href="https://nearmotion.com/disclaimer/"
              target="_blank"
              rel="noreferrer"
            >
              {t('Disclaimer')}
            </OutsideLink>
            <hr />
            {isAuthenticated && (
              <Button to="/#" onClick={logOut}>
                Logout
              </Button>
            )}
            {!isAuthenticated && <Button to="/sign-in">Login</Button>}
          </ProfileSubmenu>
        </ProfileMenu>
      </Navigation>
    </HeaderContainer>
  )
}

export const Header = styled(HeaderComponent)``

const HeaderContainer = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  width: 100%;
  height: 72px;
  padding: 0 63px;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #f0f2f7;
  box-shadow: 0 2px 16px rgba(60, 128, 209, 0.05);
  z-index: 4;
`
const Logo = styled(LogoImage)`
  height: 34px;
`
const User = styled(UserImage)`
  width: 36px;
`
const Arrow = styled(ArrowImage)``

const LinkStyled = styled.a`
  margin-right: 31px;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
`
const OutsideLink = styled.a`
  width: 140px;
  padding: 5px 0;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`
const Button = styled(Link)`
  padding: 7px 0;
  margin-right: 31px;
  font-size: 14px;
  line-height: 14px;

  text-decoration: none;
  cursor: pointer;
`

const Navigation = styled.div`
  display: flex;
  align-items: center;
`

const ProfileSubmenu = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  display: grid;
  padding: 5px 10px;
  margin-top: -10px;
  opacity: 0;
  pointer-events: none;
  transition-duration: 0.3s;
  background: #ffffff;
  border: 1px solid #f0f2f7;
  box-shadow: 0 2px 16px rgba(60, 128, 209, 0.05);
  hr {
    width: 100%;
    background: #dee1ea8f;
    border: none;
    height: 1px;
  }
`

const ProfileMenu = styled.div`
  position: relative;
  width: 70px;
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    ${ProfileSubmenu} {
      opacity: 1;
      pointer-events: auto;
    }
  }
`
const SearchImage = styled(SearchImageComponent)`
  position: absolute;
  top: 15px;
  left: 26px;
  width: 16px;
  z-index: 1;
`

const Search = styled.div`
  position: absolute;
  width: 384px;
  height: 44px;
  left: 291px;
  top: 14px;
  box-shadow: none;
  .mapboxgl-ctrl-geocoder {
    width: 100% !important;
    height: 44px;
    svg {
      display: none;
    }
    input {
      padding-left: 53px;
      width: 100% !important;
      height: 44px;
      background: #fafbff;
      border-radius: 14px;
      outline: none;
      ::placeholder {
        color: #9aaccf;
        font-family: 'Biko';
        font-size: 14px;
        line-height: 14px;
      }
    }
  }
  .mapboxgl-ctrl-geocoder,
  .mapboxgl-ctrl-geocoder .suggestions {
    box-shadow: none !important;
  }
`
