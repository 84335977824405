export const triggersOptions = [
  {
    label: 'On Hello',
    value: 'enter',
  },
  {
    label: ' On Exit',
    value: 'leave',
  },
  {
    label: 'Near',
    value: 'near',
  },
  {
    label: 'Far',
    value: 'far',
  },
  {
    label: 'Immediate',
    value: 'immediate',
  },
]

export const templateOptions = [
  {
    label: 'Template with logo & image',
    value: 'template_1',
  },
  {
    label: 'Template with image',
    value: 'template_2',
  },
  {
    label: 'Template with logo & image QR/Bar code & identifier',
    value: 'template_3',
  },
  {
    label: 'Template with image QR/Bar code & identifier',
    value: 'template_4',
  },
  {
    label: 'Template with logo & image & button options',
    value: 'template_5',
  },
]
