import React, { useEffect, useState, useCallback, useMemo } from 'react'
import DatePicker from 'react-datepicker'
import styled, { createGlobalStyle } from 'styled-components/macro'
import { Bar } from 'react-chartjs-2'
import { useHistory, useParams } from 'react-router-dom'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'

import 'react-datepicker/dist/react-datepicker.css'

import { ReactComponent as HeatMapIcon } from 'src/assets/images/svg/heatmap-zoom.svg'
import { useMap } from 'src/modules/map'
import { useHeatmap } from 'src/pages/Reports/features/Heatmap'
import { useMapStudio } from 'src/pages/MapStudio/controllers/MapStudioController'
import { clearLayers } from 'src/pages/MapStudio/controllers/clearLayers'
import { useFloors } from 'src/modules/floors'
import { getStats, loadStats } from 'src/redux/slicers/reports'
import { Loader, Title } from 'src/ui'

import { Wrapper, Label } from 'src/ui/form/FormField'

const dateFormat = 'MM/dd/yyyy h:mm aa'

function ReportsComponent(props) {
  const history = useHistory()
  const params = useParams()
  const dispatch = useDispatch()

  const { floorId, buildingId } = params
  const {
    map: currentMap,
    isMapLoaded,
    navigation,
    mapFullscreen,
    setDefaultCenter,
    mapContainer,
  } = useMap()
  const { floors, get: getFloors, floor } = useFloors()

  const [mapZoomed, setMapZoomed] = useState()
  const { building } = useMapStudio()
  const {
    isLoaded: isHeadMapLoaded,
    load: loadHeatmap,
    sevenDaysBefore,
  } = useHeatmap()

  const [startDate, setStartDate] = useState(sevenDaysBefore)

  const [endDate, setEndDate] = useState(new Date())
  const statisticData = useSelector(getStats)

  const [options, setOptions] = useState()

  const visitorsData = useMemo(
    () => ({
      options: {
        redraw: false,
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          redraw: false,
          intersect: false,
        },
      },
      labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      datasets: [
        {
          type: 'line',
          label: 'Current Week',
          borderColor: `#5468FF`,
          borderWidth: 2,
          lineTension: 0.3,
          fill: false,
          data: statisticData?.this_week
            ? Object.keys(statisticData.this_week).map(
                key => statisticData?.this_week[key]
              )
            : [],
        },
        {
          type: 'line',
          label: 'Previous Week',
          borderColor: `#E2EBFF`,
          borderWidth: 2,
          lineTension: 0.3,

          fill: false,
          data: statisticData?.last_week
            ? Object.keys(statisticData.last_week).map(
                key => statisticData?.last_week[key]
              )
            : [],
        },
      ],
    }),
    [statisticData]
  )

  const mapOnLoad = useCallback(() => {
    if (currentMap && mapFullscreen) {
      currentMap.removeControl(mapFullscreen, 'bottom-right')
    }
  }, [currentMap, mapFullscreen])

  const clearMapOnExit = useCallback(() => {
    return function clear() {
      setDefaultCenter(false)
      if (!currentMap || !building) return false
      const coords = {
        lng: building.coordinate.longitude,
        lat: building.coordinate.latitude,
      }
      currentMap
        .setCenter(coords)
        .setZoom(16.3)
        .addControl(navigation, 'bottom-right')
        .addControl(mapFullscreen, 'bottom-right')
      window.dispatchEvent(new Event('resize'))
    }
  }, [building, currentMap, mapFullscreen, navigation, setDefaultCenter])

  useEffect(clearMapOnExit, [currentMap, clearMapOnExit, building])

  useEffect(() => {
    mapOnLoad()
  }, [currentMap])

  useEffect(() => {
    const load = async () => {
      await getFloors(buildingId)
    }
    load()
  }, [buildingId, getFloors])

  useEffect(() => {
    if (floors.length) {
      setOptions(
        floors.map(floorItem => ({
          value: floorItem.id,
          label: `Floor: ${floorItem.name || floorItem.id}`,
        }))
      )
    }
  }, [floors])

  const loadStatsData = useCallback(async () => {
    await dispatch(loadStats({ buildingId, floorId }))
  }, [buildingId, dispatch, floorId])

  useEffect(loadStatsData, [loadStatsData])

  const centerMap = useCallback(() => {
    if (!isMapLoaded || !mapContainer?.current) return false
    setDefaultCenter(true)

    if (building) {
      const coords = {
        lng: building.coordinate.longitude,
        lat: building.coordinate.latitude,
      }

      currentMap
        .setZoom(16.3)
        .setCenter(coords)
        .easeTo({
          padding: {
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
          },
          duration: 0,
        })
        .removeControl(navigation)
        .removeControl(mapFullscreen)
    }
  }, [
    building,
    currentMap,
    mapContainer,
    isMapLoaded,
    mapFullscreen,
    navigation,
    setDefaultCenter,
  ])

  useEffect(() => centerMap, [
    currentMap,
    isMapLoaded,
    building,
    centerMap,
    mapContainer,
  ])

  const handleMapZoom = useCallback(async () => {
    await setMapZoomed(!mapZoomed)

    window.dispatchEvent(new Event('resize'))

    currentMap
      .setCenter({
        lng: building.coordinate.longitude,
        lat: building.coordinate.latitude,
      })
      .setZoom(!mapZoomed ? 18 : 16.3)
    currentMap.easeTo({
      duration: 0,
    })
  }, [building, currentMap, mapZoomed])

  const handleFloorChange = useCallback(
    option => {
      clearLayers(currentMap)
      history.replace(
        `/buildings/${buildingId}/floors/${option.value}/reports`,
        true
      )
    },
    [buildingId, history, currentMap]
  )

  const renderVisitors = useMemo(() => {
    return (
      <Chart style={{ width: '30vw', height: '300px' }}>
        <Bar
          options={visitorsData.options}
          height="250px"
          data={visitorsData}
          type="bar"
        />
      </Chart>
    )
  }, [visitorsData])

  const handleStartDateChange = date => {
    loadHeatmap({ startDate: date.getTime(), endDate: endDate.getTime() })
    setStartDate(date)
  }

  const handleEndDateChange = date => {
    loadHeatmap({ startDate: startDate.getTime(), endDate: date.getTime() })
    setEndDate(date)
  }

  const dayClassName = date => {
    const lastMidnight = new Date()
    lastMidnight.setHours(0, 0, 0, 0)

    return date.getTime() < lastMidnight.getTime() ? 'date-in-past' : ''
  }

  return (
    <Container className={props.className}>
      <GlobalStyle $mapZoomed={mapZoomed} />
      <Report>
        <Title>Reports & Analytics</Title>
        <GridContainer $mapZoomed={mapZoomed}>
          <HeatMapContainer $mapZoomed={mapZoomed}>
            <SubTitle>
              Heatmap
              <Actions>
                <Zoom onClick={handleMapZoom}>
                  <HeatMapIcon />
                </Zoom>

                <Filters>
                  <Filter>
                    <LabelStyled>From:</LabelStyled>
                    <DatePickerStyled
                      dateFormat={dateFormat}
                      selected={startDate}
                      onChange={handleStartDateChange}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      showTimeInput
                      dayClassName={dayClassName}
                    />
                  </Filter>
                  <Filter>
                    <LabelStyled>To:</LabelStyled>
                    <DatePickerStyled
                      dateFormat={dateFormat}
                      selected={endDate}
                      onChange={handleEndDateChange}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      showTimeInput
                      dayClassName={dayClassName}
                    />
                  </Filter>
                  {floor?.name && (
                    <SelectComponent
                      closeMenuOnSelect="true"
                      placeholder="Floor"
                      isMulti={false}
                      isSearchable={false}
                      options={options}
                      defaultValue={{
                        label: `Floor: ${floor.name}`,
                      }}
                      onChange={handleFloorChange}
                    />
                  )}
                </Filters>
              </Actions>
            </SubTitle>
            <MapContainer>{props.children}</MapContainer>
          </HeatMapContainer>
          {isHeadMapLoaded && (
            <SubContainer>
              <SubTitle>Number of Visitors</SubTitle>
              <MapContainer>{renderVisitors}</MapContainer>
            </SubContainer>
          )}
        </GridContainer>
      </Report>
      {(!currentMap || !isMapLoaded || !building || !isHeadMapLoaded) && (
        <Loader />
      )}
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  width: 100vw;
  z-index: 3;
  height: calc(100vh - 72px);

  & + .mapboxgl-map .mapboxgl-ctrl-attrib {
    display: none;
  }
  .mapboxgl-canvas-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  pointer-events: none;
  .react-datepicker__day.date-in-past:not(:hover) {
    color: #3d3d3d;
  }
  .react-datepicker__day.react-datepicker__day--outside-month:not(:hover) {
    color: #c0c0c0;
  }
  .react-datepicker__day--in-selecting-range.date-in-past,
  .react-datepicker__day--in-selecting-range.date-in-past:not(:hover),
  .react-datepicker__day--in-selecting-range.react-datepicker__day--outside-month,
  .react-datepicker__day--in-selecting-range.react-datepicker__day--outside-month:not(:hover) {
    color: white;
  }
`

const Report = styled.div`
  position: relative;
  top: 0;
  z-index: 1;
`

const GridContainer = styled.div`
  position: relative;
  display: grid;
  margin: 0 70px;
  margin-top: 60px;
  margin-bottom: 160px;
  height: calc(100vh - 250px);
  min-height: 450px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 20px;
  ${props =>
    props.$mapZoomed &&
    `
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      ${SubContainer}{
        display: none;
      }
  `}
`

const SubContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: 100px 1fr;
  min-height: 125px;
  height: 100%;
  background: #ffffff;
  box-shadow: 0 9.08776px 14.3889px rgba(60, 128, 209, 0.05);
  border-radius: 10px;
  pointer-events: all;
`
const Chart = styled.div`
  position: relative;
  margin: 0 auto;
`

const Zoom = styled.div`
  margin-left: 10px;
`

const MapContainer = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
`
const HeatMapContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr;
  margin-left: 0;
  margin-top: 0;
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

  ${props =>
    props.$mapZoomed &&
    `
      width: calc(100vw - 131px);
      
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
  `}
`

const SubTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  color: #344356;
  pointer-events: all;
  background: white;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  pointer-events: all;
`
const SelectComponent = styled(Select)`
  position: relative;
  width: 150px;
  z-index: 1001;
`

const DatePickerStyled = styled(DatePicker)`
  width: 170px;
  border: 1px solid #cccccc;
  background: #ffffff;
  border-radius: 3.66839px;
  font-size: 14px;
  color: #344356;
  box-shadow: 0px 11.0052px 25.6788px rgba(129, 129, 129, 0.04);
`
export const Reports = styled(ReportsComponent)``

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: end;
  grid-template-columns: max-content max-content 1fr;
  justify-items: end;
  padding-bottom: 18px;
`
const Filters = styled.div`
  display: flex;
  gap: 24px;
  align-items: end;
`

const Filter = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  .react-datepicker__input-container input {
    font-size: 17px;
    font-family: 'Biko', serif;
    line-height: 31px;
    padding: 4px 10px 1px 10px;
  }
`
const LabelStyled = styled(Label)`
  margin-left: 0;
  margin-bottom: 5px;
  font-size: 16px;
`

const GlobalStyle = createGlobalStyle`
  .map-container-wrapper {
    position: relative;
    display: grid;
    margin: 0 70px;
    margin-top: 204px;
    margin-bottom: 160px;
    height: calc(100vh - 350px);
    width: calc(100vw - 140px);
    min-height: 370px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 20px;
    pointer-events: none;
    
    ${props =>
      props.$mapZoomed &&
      `
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    `}
    .map-container {
      width: 100%;
      height: 100%;
      pointer-events: all;
    }
}`
