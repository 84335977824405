import styled from 'styled-components/macro'
import { Link } from 'src/components/Link'

export const StyledButtonLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  width: 148px;
  font-size: 13px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  text-decoration: none;
  &[disabled] {
    cursor: default;
  }

  ${props =>
    props.width &&
    `
      width: ${props.width};
  `}

  ${p =>
    p.color === 'secondary' &&
    `
      &, &:visited {
        color: #5468FF;
        background-color: #EFF2FE;
      }
  `}

  ${p =>
    p.color === 'primary' &&
    `
      &, &:visited {
        color: white;
        background-color: #5468FF;
      }
      
      &[disabled] {
        background-color: #adb6ff;
      }
  `}

  ${p =>
    p.bold &&
    `
      font-weight: 600;
  `}

  ${p =>
    p.loading &&
    `
      opacity: 0.7;
      cursor: loading;
  `}
`
