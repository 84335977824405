export default [
  [password => password.length >= 7, 'Minimum length is 7 symbols'],
  [
    password => password.match(/\p{Lu}/gu),
    'Password should have at least one uppercase letter',
  ],
  [
    password => password.match(/\p{Ll}/gu),
    'Password should have at least one lowercase letter',
  ],
  [
    password => password.match(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/),
    'Password should have at least one special symbol',
  ],
  [
    password => password.match(/[0-9]/),
    'Password should have at least one number',
  ],
]
