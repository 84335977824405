import React, { useState } from 'react'
import styled from 'styled-components/macro'

import { ReactComponent as PassImageComponent } from 'src/assets/images/svg/show.svg'
import { ReactComponent as CopyImageComponent } from 'src/assets/images/svg/copy.svg'

function SecuredFieldComponent({
  label,
  inputProps,
  className,
}) {
  const [type, setType] = useState(
    inputProps.type || 'password'
  )
  function handleShowHidden() {
    setType(type === 'password' ? 'text' : 'password')
  }

  function handleCopyValue() {
    navigator.clipboard.writeText(
      inputProps.defaultValue || inputProps.value
    )
  }

  return (
    <Container className={className}>
      <Label>
        {label}
        <Input
          {...inputProps}
          type={type}
          autocomplete="off"
        />
      </Label>

      <CopyText type="button" onClick={handleCopyValue}>
        <CopyImageComponent />
      </CopyText>
      <ShowHidden
        show={type !== 'password'}
        type="button"
        onClick={handleShowHidden}
      >
        <PassImageComponent />
      </ShowHidden>
    </Container>
  )
}

export const SecuredField = styled(SecuredFieldComponent)``

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 25px 20px;
  align-items: flex-end;
  justify-content: space-between;
`
const Label = styled.label`
  font-family: 'Biko';
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
`

const Input = styled.input`
  width: 100%;
  border-radius: 4px;
  font-size: 12px;
  line-height: 12px;
  background: #ffffff;
  color: #9aaccf;
  border: none;
  box-shadow: none;

  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #9aaccf;
  }
`
const CopyText = styled.button`
  width: 20px;
  padding: 0;
  background: transparent;
  appearance: none;
  outline: none;
  border: none;
  box-shadow: transparent;
  cursor: pointer;
  svg {
    width: 15px;
    height: 15px;
    fill: #9aaccf;
  }
  &:hover {
    svg {
      fill: #5468ff;
    }
  }
`

const ShowHidden = styled(CopyText)`
  svg {
    width: 20px;
    height: auto;
  }
  ${props =>
    props.show &&
    `
    svg {
      fill: #5468ff;
    }
  `}
`
