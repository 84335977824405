import React, { createContext, useCallback, useContext, useState } from 'react'

import { Loader } from 'src/ui'
import { useApplications } from 'src/modules/applications'
import { api } from 'src/modules/api'

const BuildingsContext = createContext()

export function BuildingsProvider(props) {
  const { applicationId } = useApplications()

  const { isLoadingInitially } = props

  const [building, setBuilding] = useState(false)
  const [buildings, setBuildings] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isBuildingLoading, setBuildingIsLoading] = useState(true)

  const get = useCallback(async () => {
    setIsLoading(true)
    let res

    if (applicationId) {
      setBuildings([])
      res = await api.get(`applications/${applicationId}/buildings`)
      await setBuildings(res.ok ? res.json.buildings : [])
    }
    setIsLoading(false)

    return res
  }, [applicationId])

  const list = useCallback(async () => {
    setIsLoading(true)
    const res = await api.get(`applications/${applicationId}/buildings`)
    const data = res.ok ? res.json.buildings : []
    setIsLoading(false)
    setBuildings(data)

    return data
  }, [applicationId])

  const create = useCallback(
    async data => {
      setIsLoading(true)
      const res = await api.post(
        `applications/${applicationId}/buildings`,
        data
      )

      res.ok && get()
      setIsLoading(false)

      return res
    },
    [get, applicationId]
  )

  const getBuilding = useCallback(async id => {
    setBuildingIsLoading(true)
    const res = await api.get(`buildings/${id}`)
    const build = res.ok ? res.json.building : null
    setBuilding(build)
    setBuildingIsLoading(false)
    setIsLoading(false)

    return build
  }, [])

  const update = useCallback(
    async (id, data) => {
      setIsLoading(true)
      const res = await api.patch(`buildings/${id}`, data)

      res.ok && get()
      setIsLoading(false)

      return res
    },
    [get]
  )

  const remove = useCallback(
    async id => {
      setIsLoading(true)
      const res = await api.del(`buildings/${id}`)
      if (res.ok) {
        const loadedBuildings = await list()

        setBuildings(loadedBuildings)
      }
      setIsLoading(false)

      return res
    },
    [list]
  )

  const contextValue = {
    get,
    buildings,
    setBuildings,
    isLoading,
    list,
    create,
    building,
    getBuilding,
    isBuildingLoading,
    update,
    remove,
  }

  return (
    <BuildingsContext.Provider value={contextValue}>
      {isLoadingInitially ? <Loader /> : props.children}
    </BuildingsContext.Provider>
  )
}

export function useBuildings() {
  return useContext(BuildingsContext)
}
