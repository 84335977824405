import {
  creditCardReducer,
  creditCardsReducer,
} from 'src/redux/slicers/creditCards'
import { chargesReducer } from 'src/redux/slicers/subscription'
import { reportsReducer } from 'src/redux/slicers/reports'
import { jibestreamReducer } from 'src/redux/slicers/jibestream'
import { routeReducer } from 'src/redux/slicers/routes'

export const rootReducers = {
  creditCard: creditCardReducer,
  creditCards: creditCardsReducer,
  charges: chargesReducer,
  reports: reportsReducer,
  route: routeReducer,
  jibestream: jibestreamReducer,
}
