import React from 'react'
import styled from 'styled-components'
import { useCookies } from 'react-cookie'

import { INVITATION_CODE_KEY } from 'src/constants'

export const InvitationMessage = () => {
  const [cookies] = useCookies([INVITATION_CODE_KEY])
  const invitationCode = cookies[INVITATION_CODE_KEY]

  if (!invitationCode) return <></>

  return (
    <InvitationMessageStyled>
      Please login or register to accept the invitation
    </InvitationMessageStyled>
  )
}

const InvitationMessageStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  font-size: 20px;
  background: rgb(84, 104, 255);
  max-width: 500px;
  margin: 0 auto 32px;
  color: white;
`
