import React from 'react'
import { StyledLink } from 'src/components/Link'
import { Button } from 'src/ui/Button'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch() {
    this.setState({ hasError: true })
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props
    if (hasError) {
      return (
        <div
          maxWidth="md"
          style={{
            paddingTop: '2rem',
            paddingBottom: '2rem',
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <div>
            <div />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                justifyContent: 'center',
              }}
            >
              <div mb={2} style={{ textAlign: 'center' }} />
              <span align="center" variant="h5">
                Something went wrong
              </span>
              <div
                mb={4}
                mt={2}
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  onClick={() =>
                    window.location.reload(false)
                  }
                >
                  Refresh page
                </Button>
                <StyledLink
                  size="large"
                  color="primary"
                  variant="text"
                  to="/"
                  onClick={() =>
                    (window.location.href = '/')
                  }
                  style={{ marginTop: '1rem' }}
                >
                  Back to home
                </StyledLink>
              </div>

              <span
                component="div"
                align="center"
                variant="caption"
                color="textSecondary"
              >
                * If the situation is repeated several
                times, please contact the site support
                service.
              </span>
            </div>
          </div>
        </div>
      )
    }

    return children
  }
}

export default ErrorBoundary
