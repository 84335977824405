// Where the indoor layers will be inserted.
import { mapStyles } from 'src/constants'

const beforeLayerId = 'marine-label-sm-ln'

const addMapLayer = props => {
  const {
    currentMap,
    layerId,
    layerType,
    layerUrl,
    layout = {},
    paint = {},
  } = props

  const srcId = `${layerId}-src`

  if (!currentMap.getSource(srcId)) {
    currentMap.addSource(srcId, {
      type: 'geojson',
      data: layerUrl,
    })
  }

  if (!currentMap.getLayer(layerId)) {
    currentMap.addLayer(
      {
        id: layerId,
        type: layerType,
        source: `${layerId}-src`,
        layout,
        paint,
      },
      beforeLayerId
    )
  }
}

export const renderFloorLayers = ({ floor, currentMap, isJBuilding }) => {
  if (isJBuilding) {
    floor.layers.forEach(layer => {
      const layerId = `nearmotion-layer-${layer.id}`

      const paint = {}
      Object.keys(layer.style).forEach(key => {
        if (key !== 'type') {
          paint[key] = /-width/.test(key) ? +layer.style[key] : layer.style[key]
        }
      })

      const layerToMap = {
        currentMap,
        layerId,
        layerType: layer.style.type,
        layerUrl: layer.file.url,
        paint,
      }

      addMapLayer(layerToMap)
    })
  } else {
    floor.layers.forEach(layer => {
      const layerId = `nearmotion-layer-${layer.id}`
      const layerType =
        layer.kind?.includes('outline') || layer.kind?.includes('other')
          ? 'line'
          : 'fill'

      addMapLayer({
        currentMap,
        layerId,
        layerType,
        layerUrl: layer.file.url,
        paint: mapStyles[layer.kind],
      })

      if (!currentMap.getLayer(layerId)) {
        if (layer.kind === 'outlayer') {
          currentMap.addLayer(
            {
              id: `${layerId}-line`,
              type: 'line',
              source: `${layerId}-src`,
              layout: {},
              paint: {
                'line-color': '#525252',
                'line-width': 5,
              },
            },
            beforeLayerId
          )
        }
      }
    })
  }
}
