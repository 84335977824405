import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { useParams } from 'react-router-dom'
import { FieldArray } from 'react-final-form-arrays'
import { Field, Form, FormSpy } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import exportFromJSON from 'export-from-json'
import { OpenLocationCode } from 'open-location-code'
import { toast } from 'react-toastify'
import { typography, space, color } from 'styled-system'

import { PageWithHeader, PageContentWrapper } from 'src/pages/PageTemplate'
import { ReactComponent as IconTrashBin } from 'src/assets/images/svg/icon-trash-bin.svg'
import { ReactComponent as SaveIcon } from 'src/assets/images/svg/icon-save.svg'

import { ConfirmationModal } from 'src/components/ConfirmationModal'
import { Button, Loader, Title } from 'src/ui'
import { Card } from 'src/ui/Card'

import { useBuildingPois } from 'src/hooks/useBuildingPois'
import { useBuildingPoiCategories } from 'src/hooks/useBuildingPoiCategories'
import { TagsInputField } from 'src/ui/form/TagInput/TagsInputField'
import { Scroller } from 'src/components/Scroller'
import { SubMenu } from 'src/components/SubMenu'
import { TextField } from 'src/components/TextField'
import { SelectField } from 'src/components/SelectField'
import { useFloors } from 'src/modules/floors'
// import { WorkingHours } from 'src/components/WorkingHours/WorkingHours'

export const Poi = () => {
  const { buildingId, floorId } = useParams()
  const {
    get: getPois,
    update: updatePoi,
    remove: deletePoi,
  } = useBuildingPois()

  const { get: getPoiCategories } = useBuildingPoiCategories()
  const { floor, getFloor } = useFloors()

  const [pois, setPois] = useState()
  const [updatedPois, setUpdatedPois] = useState()
  const [poisForm, setPoisForm] = useState()
  const [poisCategories, setPoisCategories] = useState()
  const [openLocationCode] = useState(new OpenLocationCode())

  const load = useCallback(async () => {
    const categories = await getPoiCategories()
    setPoisCategories(categories)

    const result = await getPois()
    setPois(result)
    if (!floor) {
      getFloor(buildingId, floorId)
    }
  }, [buildingId, floor, floorId, getFloor, getPoiCategories, getPois])

  useEffect(load, [load])

  async function handleDelete(poiId) {
    setPois(pois.filter(poi => poi.id !== poiId))
    await deletePoi(poiId)
  }

  async function updateCategory(event, poi) {
    const { value } = event.target
    poi.category_id = value
  }

  async function handleExport(event) {
    event.preventDefault()
    event.stopPropagation()

    const data = updatedPois.map(poi => ({
      POI_ID: poi.id,
      Title: poi.title,
      Subtitle: poi.subtitles.toString(),
      Category_ID: poi.category_id ? poi.category_id : '',
      'Category Name': poi.category_id
        ? poisCategories?.find(category => +category.id === +poi.category_id)
            ?.name
        : '',
      'Floor title': poi.building_floor_id,
      'Poi link': new URL(
        `/share/link.html?${openLocationCode.encode(
          +poi.latitude,
          +poi.longitude
        )}&${floorName}`,
        process.env.REACT_APP_API_BASE_URL
      ).href,
    }))
    const fileName = 'pois'
    const exportType = 'xls'
    exportFromJSON({ data, fileName, exportType })
  }

  async function onPoiChange(event, updatedPoi, key) {
    event.preventDefault()
    event.stopPropagation()
    const indexPoi = key.match(/\d+/gi)[0]

    const result = await updatePoi(updatedPoi.id, {
      title: updatedPoi.title,
      subtitles: updatedPoi.subtitles,
      category_id: updatedPoi.category_id,
    })

    if (!result.ok) {
      toast.error(result?.json?.error?.message || 'Something went wrong')
    } else {
      delete poisForm.dirtyFields[key]
      pois[indexPoi] = updatedPoi

      setPoisForm([])
      setPoisForm(poisForm)

      setPois(pois)
    }
  }

  function handleFormChange(form) {
    setPoisForm(form)
    if (form?.values?.pois) {
      setUpdatedPois(form.values.pois)
    }
  }

  let floorName = floor?.name ? floor.name : floor.id
  if (floorName === 0) {
    floorName = 'G'
  }

  return (
    <Page>
      <Title>Points of Interest</Title>
      <SubMenuContainer>
        <SubMenu />
      </SubMenuContainer>
      <PageContent style={{ textAlign: 'center' }}>
        <CardStyled fullSize>
          <Form
            onSubmit={() => {}}
            initialValues={{ pois }}
            mutators={{
              ...arrayMutators,
            }}
            render={() => (
              <form onSubmit={() => {}}>
                <FormSpy onChange={handleFormChange} />
                <FormSpy
                  subscription={{
                    dirtySinceLastSubmit: false,
                    dirtyFields: true,
                    submitting: true,
                    modified: true,
                    visited: false,
                  }}
                >
                  {() => (
                    <>
                      <Actions>
                        <Button
                          size="large"
                          color="primary"
                          variant="contained"
                          onClick={handleExport}
                        >
                          Export to Excel
                        </Button>
                      </Actions>
                    </>
                  )}
                </FormSpy>
                <ScrollWrap>
                  <Scroller autoHide="false">
                    <Table>
                      <table>
                        <THead>
                          <tr>
                            <TH>POI ID</TH>
                            <TH>Title</TH>
                            <TH>Subtitle</TH>
                            <TH>Category</TH>
                            {/* <TH>Working Hours</TH> */}
                            <TH>Floor title</TH>
                            <TH>POI Link</TH>
                            <TH>Actions</TH>
                          </tr>
                        </THead>
                        <tbody>
                          <FieldArray name="pois">
                            {({ fields }) =>
                              fields?.map((fieldKey, index) => {
                                const poi = fields.value[index]
                                let requireTagSave
                                let requireCategorySave
                                let requireTitleSave
                                let requireSave
                                if (poisForm?.dirtyFields) {
                                  Object.keys(poisForm.dirtyFields).forEach(
                                    key => {
                                      if (key === 'pois') {
                                        return
                                      }
                                      const indexPoi = key.match(/\d+/gi)[0]

                                      if (
                                        pois[indexPoi] &&
                                        pois[indexPoi].id === poi.id
                                      ) {
                                        requireTitleSave =
                                          pois[indexPoi].title !== poi.title
                                        requireTagSave =
                                          pois[indexPoi].subtitles !==
                                          poi.subtitles
                                        requireCategorySave =
                                          pois[indexPoi].category_id !==
                                          poi.category_id

                                        requireSave =
                                          requireTitleSave ||
                                          requireTagSave ||
                                          requireCategorySave
                                            ? key
                                            : false
                                      }
                                    }
                                  )
                                }

                                return (
                                  <TBodyTR key={fieldKey}>
                                    <TD>{poi.id}</TD>
                                    <TD>
                                      <TextField
                                        name={`${fieldKey}.title`}
                                        type="text"
                                        maxLength={255}
                                      />
                                    </TD>
                                    <TD>
                                      <TagsInputFieldStyled
                                        name={`${fieldKey}.subtitles`}
                                      />
                                    </TD>
                                    <TD>
                                      {poisCategories?.length > 0 && (
                                        <SelectInputStyled
                                          fullWidth
                                          isMulti={false}
                                          isSearchable={false}
                                          name={`${fieldKey}.category_id`}
                                          onChange={event =>
                                            updateCategory(event, poi)
                                          }
                                          defaultValue={poi.category_id || ''}
                                          options={poisCategories?.map(
                                            category => ({
                                              label: category.name,
                                              value: category.id,
                                            })
                                          )}
                                        />
                                      )}
                                    </TD>
                                    {/*  <TD>
                                      <Field
                                        name="working_hours"
                                        component={WorkingHours}
                                      />
                                    </TD> */}
                                    <TD>{poi.building_floor_id}</TD>
                                    <TD>
                                      <a
                                        target="_blank"
                                        href={`/share/link.html?${openLocationCode.encode(
                                          +poi.latitude,
                                          +poi.longitude
                                        )}&${floorName}`}
                                        rel="noreferrer"
                                      >
                                        Link
                                      </a>
                                    </TD>
                                    <TD>
                                      <TableActionsCell>
                                        <ConfirmationModal
                                          title="This will remove the POI from the database. Are you sure?"
                                          iconTitle="This will remove the POI from the database. Are you sure?"
                                          description="This action cannot be undone"
                                          onConfirm={handleDelete}
                                          id={poi.id}
                                          component={IconTrashBin}
                                        />
                                        <SaveIconStyled
                                          title="Save the changes"
                                          disabled={!requireSave}
                                          onClick={event => {
                                            onPoiChange(event, poi, requireSave)
                                          }}
                                        />
                                      </TableActionsCell>
                                    </TD>
                                  </TBodyTR>
                                )
                              })
                            }
                          </FieldArray>
                        </tbody>
                      </table>
                    </Table>
                  </Scroller>
                  {!pois && <Loader />}
                </ScrollWrap>
              </form>
            )}
          />
        </CardStyled>
      </PageContent>
    </Page>
  )
}

const Page = styled(PageWithHeader)``
const PageContent = styled(PageContentWrapper)`
  padding-top: 12px;
`

const ScrollWrap = styled.div`
  position: relative;
  height: calc(100vh - 355px);
  overflow: hidden;
`
const CardStyled = styled(Card)`
  padding-right: 10px;
  padding-left: 20px;
  height: calc(100vh - 206px);
`

const SubMenuContainer = styled.div`
  position: absolute;
  top: 85px;
  left: 265px;
  z-index: 3;
`

const Actions = styled.div`
  display: grid;
  justify-content: start;
  margin-bottom: 25px;
  margin-right: 30px;
  grid-auto-flow: column;
  grid-gap: 20px;
`
const TableActionsCell = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: max-content max-content;
  grid-gap: 12px;
  justify-content: center;

  > * {
    cursor: pointer;
  }
`

const TagsInputFieldStyled = styled(TagsInputField)`
  max-width: 350px;
  .subtitle-items {
    max-width: 350px;
  }
`
const SelectInputStyled = styled(SelectField)`
  max-width: 250px;
`

const THead = styled.thead`
  color: #9aaccf;
  font-size: 14px;
`

const TBodyTR = styled.tr`
  vertical-align: baseline;
  background-color: white;
`

const TH = styled.th`
  font-weight: 400;
  cursor: pointer;
  &:first-child input[type='checkbox'] {
    float: left;
  }
  margin: 0;
  padding: 0.5rem;
  height: 51px;

  :last-child {
    border-right: 0;
  }
`

const TD = styled.td`
  ${typography}
  ${space}
  ${color}
  margin: 0;
  padding: 0.5rem;
  height: max-content;

  border-bottom: 1px solid rgba(225, 229, 255, 0.75);
  :last-child {
    border-right: 0;
  }
`

const Table = styled.div`
  position: relative;
  height: 100%;
  padding-right: 25px;
  min-height: max-content;

  table {
    border-spacing: 0;
    width: 100%;
    color: #354168;
    font-size: 12px;
  }
`
const SaveIconStyled = styled(SaveIcon)`
  height: 17px;
  cursor: pointer;
  color: #5468ff;
  ${props =>
    props.disabled &&
    `
    color: #9AACCF;
    opacity: 0.5;
    pointer-events: none;
  `}
`
