// Where the indoor layers will be inserted.
const beforeLayerId = 'marine-label-sm-ln'

export const reducePoi = ({ map, mapDraw, floorPois, floorName }) => {
  if (/reports|engagements|pois/gi.test(window.location.pathname)) {
    return false
  }
  if (!floorPois || !floorPois?.length) return false

  const geojson = {
    type: 'FeatureCollection',
    features: [],
  }

  const icons = []
  let iconsLoaded = 0

  floorPois
    .filter(poi => poi.coordinates.length > 1)
    .forEach(poi => {
      const icon = poi.icon ? poi.icon['1x'] : ''

      geojson.features.push({
        id: poi.poi_id,
        type: 'Feature',
        properties: {
          icon,
          subtitles: poi.subtitles || [],
          category_id: poi.category_id || 0,
          title: poi.title,
          center: [poi.longitude, poi.latitude],
          Level: floorName,
          color: poi.color || 'rgba(63, 177, 206, 0.2)',
          // fill: poi.color,
          outline: poi.outline || '#46b3ce',
          'fill-opacity': 0.5,
          working_hours: poi.working_hours,
        },
        geometry: {
          coordinates: [
            poi.coordinates.map(item => [+item.longitude, +item.latitude]),
          ],
          type: 'Polygon',
        },
      })

      if (icon) icons.push(icon)
    })

  if (icons.length) {
    icons.forEach(icon => {
      map.loadImage(icon, (error, image) => {
        iconsLoaded += 1
        if (error) throw error
        if (!map.listImages().includes(icon)) map.addImage(icon, image)
        if (iconsLoaded === icons.length) drawPois()
      })
    })
  } else {
    drawPois()
  }

  function drawPois() {
    if (/reports|engagements|pois/gi.test(window.location.pathname)) {
      return false
    }
    if (!floorPois || !floorPois?.length) return false

    if (map.getSource('nearmotion-municipalities')) {
      map.getSource('nearmotion-municipalities').setData(geojson)
    } else {
      map.addSource('nearmotion-municipalities', {
        type: 'geojson',
        data: geojson,
      })

      map.addLayer(
        {
          id: 'nearmotion-polygons',
          type: 'fill',
          source: 'nearmotion-municipalities',
          paint: {
            'fill-opacity': [
              'case',
              ['boolean', ['feature-state', 'hover'], false],
              1,
              1,
            ],
            'fill-color': { type: 'identity', property: 'color' },
            'fill-outline-color': '#a1a6a2',
          },
        },
        beforeLayerId
      )

      map.addLayer({
        id: 'nearmotion-polygons-outline',
        type: 'line',
        source: 'nearmotion-municipalities',
        layout: {},
        paint: {
          'line-color': { type: 'identity', property: 'outline' },
          'line-width': 1,
        },
      })

      map.addLayer(
        {
          id: 'nearmotion-municipality-name',
          type: 'symbol',
          source: 'nearmotion-municipalities',
          layout: {
            'icon-anchor': 'bottom',
            'icon-allow-overlap': false,
            'icon-image': '{icon}',
            'icon-size': 1,
            'text-field': '{title}',
            'text-size': 16,
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            'text-offset': [0, 0.8],
            'symbol-placement': 'point',
          },
          paint: {
            'text-color': [
              'case',
              ['boolean', ['feature-state', 'hover'], false],
              'rgba(255,0,0,0.75)',
              'rgba(0,0,0,0.75)',
            ],
            'text-halo-color': [
              'case',
              ['boolean', ['feature-state', 'hover'], false],
              'rgba(255,255,0,0.75)',
              'rgba(255,255,255,0.75)',
            ],
            'text-halo-width': 2,
            'text-halo-blur': 0,
          },
        },
        beforeLayerId
      )
    }
    mapDraw.add(geojson)
  }
}
