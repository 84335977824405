import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { css } from 'styled-components'

import { DataTable } from 'src/components/DataTable'
import { ConfirmationModal } from 'src/components/ConfirmationModal'

import { ReactComponent as IconTrashBin } from 'src/assets/images/svg/icon-trash-bin.svg'

import { useAuth } from 'src/modules/auth'
import { ReactComponent as EditImage } from 'src/assets/images/svg/actions/edit.svg'
import { EditMemberModal } from 'src/pages/Companies/components/EditMemberModal'
// import { SearchInput } from 'src/components/SearchInput'
import { useCompanyAdmins } from 'src/hooks/useCompanyAdmins'

export function Members(props) {
  const {
    get: getAdmins,
    remove: removeMember,
    update: updateMember,
    isLoading: isAdminsLoading,
  } = useCompanyAdmins()

  const { user } = useAuth()
  const [memberToEdit, setMemberToEdit] = useState()

  const [admins, setAdmins] = useState([])
  const [search] = useState('')
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(20)
  const [currentPageCount, setCurrentPageCount] = useState()

  const getFilteredAdmins = useCallback(async () => {
    const result = await getAdmins({
      search,
      page: currentPageIndex,
      size: currentPageSize,
    })
    await setAdmins(result.json.saas_company_admins || [])
    setCurrentPageCount(result.headers.get('X-Pager-Total-Pages') || 0)

    return result
  }, [currentPageIndex, currentPageSize, getAdmins, search])

  const fetchData = useCallback(
    async ({ pageIndex, pageSize }) => {
      setCurrentPageIndex(pageIndex)
      setCurrentPageSize(pageSize)
      const result = await getAdmins({
        search,
        page: pageIndex,
        size: pageSize,
      })

      setCurrentPageCount(result.headers.get('X-Pager-Total-Pages') || 0)
      setAdmins([])
      setAdmins(result.json.saas_company_admins || [])
    },
    [getAdmins, search]
  )

  // const handleSearchChange = useCallback(e => setSearch(e?.target?.value), [])
  const confirmRemoveMember = useCallback(
    async memberId => {
      await removeMember(memberId)
      await getFilteredAdmins()
    },
    [getFilteredAdmins, removeMember]
  )

  const handleEditUser = useCallback(member => {
    setMemberToEdit(member)
  }, [])

  const onClose = useCallback(() => {
    setMemberToEdit(null)
  }, [])

  const handleUpdateMember = useCallback(
    async (id, data) => {
      const result = await updateMember(id, data)
      if (result.ok) {
        const request = await getFilteredAdmins()
        setMemberToEdit(null)
        if (request.ok) {
          setMemberToEdit(
            request?.json?.saas_company_admins?.find(admin => admin.id === id)
          )
        }
      }

      return result
    },
    [getFilteredAdmins, updateMember]
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Member First Name',
        accessor: 'admin.first_name',
      },
      {
        Header: 'Member Last Name',
        accessor: 'admin.last_name',
      },
      {
        Header: 'Member Email',
        accessor: 'admin.email',
      },
      {
        Header: 'Member Role',
        accessor: 'role',
      },
      {
        id: 'actions',
        Header: 'Actions',
        cellStyle: { width: '70px', textAlign: 'center' },
        Cell: ({ row: { original } }) => (
          <TableActionsCell>
            {!(
              /owner/.test(original.role) || user.id === original.admin_id
            ) && (
              <>
                <EditImageStyled
                  title="Manage Application user access"
                  onClick={() => handleEditUser(original)}
                />
                <ConfirmationModal
                  closeOnConfirm="true"
                  title={
                    original.admin_id !== user.id
                      ? 'Are you sure you want to delete this member from the company?'
                      : 'You are about to delete yourself from this Company.'
                  }
                  description="This action cannot be undone"
                  onConfirm={confirmRemoveMember}
                  id={original.id}
                  component={IconTrashBinStyled}
                />
              </>
            )}
          </TableActionsCell>
        ),
      },
    ],
    [confirmRemoveMember, handleEditUser, user.id]
  )

  return (
    <Container className={props?.className}>
      <DataTableStyled
        pagination
        isLoading={isAdminsLoading}
        currentPageIndex={currentPageIndex}
        currentPageSize={currentPageSize}
        pageCount={+currentPageCount}
        fetchData={fetchData}
        columns={columns}
        rows={admins}
        // actions={
        //   <SearchInput
        //     placeholder={t('Search members..')}
        //     onChange={handleSearchChange}
        //   />
        // }
      />
      {memberToEdit && (
        <EditMemberModal
          member={memberToEdit}
          onClose={onClose}
          update={handleUpdateMember}
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  height: calc(100vh - 290px);
`

const DataTableStyled = styled(DataTable)`
  padding: 0 16px;
  th {
    text-align: left;
  }
  table {
    padding-right: 10px;
  }
`
const TableActionsCell = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: space-evenly;
`
const svgStyled = css`
  cursor: pointer;
  width: 16px;
  height: 16px;
`
const EditImageStyled = styled(EditImage)`
  ${svgStyled}
`
const IconTrashBinStyled = styled(IconTrashBin)`
  ${svgStyled}
`
