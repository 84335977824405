import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'

import { api } from 'src/modules/api'

export const useBuildingRoute = () => {
  const params = useParams()
  const { floorId } = params

  const [isLoading, setIsLoading] = useState(false)

  // get available Route for current building floor
  const get = useCallback(async () => {
    setIsLoading(true)
    const result = await api.get(`floors/${floorId}/route`)

    setIsLoading(false)

    return result.ok && result.json ? result.json?.building_route_points : []
  }, [floorId])

  // create Route for current building floor
  const update = useCallback(
    async data => {
      setIsLoading(true)
      const res = await api.put(`floors/${floorId}/route`, data)
      setIsLoading(false)

      return res
    },
    [floorId]
  )

  return {
    isLoading,
    get,
    update,
  }
}
