import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { useHistory, useParams } from 'react-router-dom'

import { AccordionItem, BackLink, Button, Loader } from 'src/ui'
import { useMapResizeStudio } from 'src/modules/map-floor-resize-studio'
import { useFloors } from 'src/modules/floors'
import { useBuildings } from 'src/modules/buildings'
import { useAbility } from 'src/hooks/useAbility'

function FloorComponent() {
  const history = useHistory()
  const params = useParams()
  const { floorId, buildingId } = params
  const { getFloor, floor, isLoading, update, get: getFloors } = useFloors()
  const { getBuilding, building } = useBuildings()
  const { buildingAngles } = useMapResizeStudio({ floor })

  const {
    floor: { update: canUpdate },
  } = useAbility()

  useEffect(() => {
    if (!floor || floor?.id !== floorId) {
      getFloor(buildingId, floorId)
    }
    if (!building) {
      getBuilding(buildingId)
    }
  }, [])

  if (isLoading) {
    return <Loader />
  }

  async function onSubmit() {
    if (buildingAngles.length) {
      await update(floorId, { coordinates_attributes: buildingAngles })
      await getFloors(buildingId)
      history.push(`/buildings/${buildingId}/floors/${floorId}`)
    }
  }

  return (
    <>
      <Container>
        <AccordionItem title="Floor">
          <Title>Floor Name: {floor.name}</Title>
        </AccordionItem>
      </Container>
      <Actions>
        <BackLink to={`/buildings/${buildingId}`}>Back</BackLink>
        <Button
          disabled={!canUpdate}
          size="large"
          color="primary"
          variant="contained"
          onClick={onSubmit}
        >
          Save
        </Button>
      </Actions>
    </>
  )
}

export const FloorResize = styled(FloorComponent)``

const Title = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 11px;
  color: #9aaccf;
`

const Container = styled.div``
const Actions = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: space-around;
`
