import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import Select from 'react-select'
import { useParams, useHistory } from 'react-router-dom'
import { set } from 'lodash'

import { DraggableDataTable } from 'src/components/DraggableDataTable'
import { Button } from 'src/ui'
import { ImageUploader as ImageUploaderComponent } from 'src/components/Uploader'
import { createUpload } from 'src/modules/directLoad'
import { Modal } from 'src/components/Modal'
import { ConfirmationModal } from 'src/components/ConfirmationModal'
import { useFloors } from 'src/modules/floors'
import { FileUploader } from 'src/components/Uploader/FileUploader'
import { ReactComponent as IconTrashBin } from 'src/assets/images/svg/icon-trash-bin.svg'
import { customSelectStyles, SelectInput } from 'src/ui/form/SelectInput'
import { LAYER_KINDS_SELECT_OPTIONS } from 'src/constants'

import { Error } from 'src/ui/form/FormField'

function AddFloorComponent(props) {
  const { onClose } = props
  const params = useParams()
  const history = useHistory()

  const buildingId = +params.buildingId
  const { create, floors, get } = useFloors()
  const [newLayerFile, setNewLayerFile] = useState('')
  const [addLayerModalState, setAddLayerModalState] = useState(false)
  const [layers, setLayers] = useState([])
  const [floor, setFloor] = useState('')
  const [photo, setPhoto] = useState('')
  const [error, setError] = useState(false)
  const [displayMode, setDisplayMode] = useState('file') // file / layers
  const switchToTable = useCallback(() => {
    setDisplayMode('layers')
    setLayers([])
  }, [])
  const switchToFile = useCallback(() => {
    setDisplayMode('file')
    setPhoto('')
  }, [])

  let options = Array.from({ length: +props.floors }, (v, k) => k)
    .filter(item => !!item)
    .reverse()
    .concat(['G'])
    .concat(
      Array.from({ length: +props.basementFloors }, (v, k) => `-${k + 1}`)
    )

  if (floors.length) {
    options = options.filter(floorName => {
      const isExist = floors.find(floorItem => {
        return String(floorItem.name) === String(floorName)
      })

      return !isExist
    })
  }

  options = options.map(item => ({
    label: item,
    value: item,
  }))

  async function onSubmit() {
    if (floor) {
      const result = await create(buildingId, {
        name: floor,
        photo: photo.signed_id,
        layers_attributes: layers.map((l, i) => ({
          ...l,
          position: i + 1,
          file: l?.file?.signed_id,
        })),
      })
      if (result.ok) {
        setError('')
        await get(buildingId)
        history.push(
          `/buildings/${buildingId}/floors/${result.json.building_floor.id}${
            photo?.signed_id ? '/edit' : ''
          }`
        )
        onClose()
      } else {
        setError(result?.json?.error?.message)
      }
    }
  }

  function handleChange({ label }) {
    setFloor(label)
  }

  async function onDrop(file, updateProgressCallback, completeCallback) {
    const upload = await createUpload(
      file,
      updateProgressCallback,
      completeCallback
    )
    setPhoto(upload)
  }

  const onDropLayerFile = useCallback(
    async (file, updateProgressCallback, completeCallback) => {
      const upload = await createUpload(
        file,
        updateProgressCallback,
        completeCallback
      )
      setNewLayerFile(upload)
    },
    []
  )

  const onRemoveLayerFile = useCallback(() => {
    setNewLayerFile('')
  }, [])

  function onRemove() {
    setPhoto('')
  }

  const openAddLayerModal = useCallback(() => setAddLayerModalState(true), [])
  const closeAddLayerModal = useCallback(() => setAddLayerModalState(false), [])

  const confirmLayerDeletion = useCallback(async id => {
    setLayers(curLayers =>
      curLayers.filter(layer => {
        // eslint-disable-next-line no-underscore-dangle
        return layer._id !== id
      })
    )
  }, [])

  const changeLayerKind = useCallback(
    (index, newKind) => {
      setLayers(curLayers => {
        const newLayers = [...curLayers]
        set(newLayers, `[${index}].kind`, newKind)

        return newLayers
      })
    },
    [setLayers]
  )

  const columns = useMemo(
    () => [
      {
        id: 'position',
        Header: 'Position',
        accessor: 'position',
        Cell: ({ row }) => row.index + 1,
        cellStyle: { textAlign: 'left' },
        sortType: 'basic',
      },
      {
        position: 'fileName',
        Header: 'File name',
        accessor: 'file.filename',
        cellStyle: { textAlign: 'left' },
        sortType: 'basic',
      },
      {
        Header: 'Type',
        accessor: '_id',
        cellStyle: { textAlign: 'left' },
        Cell: ({ row: { index, original } }) => (
          <SelectInput
            onChange={e => changeLayerKind(index, e.target.value)}
            defaultValue={original.kind}
          >
            {LAYER_KINDS_SELECT_OPTIONS.map(opt => (
              <option value={opt.value}>{opt.label}</option>
            ))}
          </SelectInput>
        ),
        sortType: 'basic',
      },
      {
        id: 'actions',
        Header: () => <div />,
        accessor: () => Math.random(),
        cellStyle: { textAlign: 'right' },
        Cell: ({ row: { original } }) => (
          <TableActionsCell>
            <ConfirmationModal
              closeOnConfirm
              title="Are you sure you want to delete this layer?"
              description="This action cannot be undone"
              onConfirm={() => {
                // eslint-disable-next-line no-underscore-dangle
                confirmLayerDeletion(original._id)
              }}
              id={original.id}
              component={IconTrashBin}
            />
          </TableActionsCell>
        ),
      },
    ],
    [changeLayerKind, confirmLayerDeletion]
  )

  const onSubmitNewLayer = useCallback(async () => {
    setLayers(curLayers => {
      return [...curLayers, { file: newLayerFile, _id: Date.now() }]
    })
    setNewLayerFile('')
    setAddLayerModalState(false)
  }, [newLayerFile])

  const onDragLayer = useCallback(
    async (sourceIndex, destinationIndex) =>
      setLayers(curLayers => {
        const newLayers = [...curLayers]
        const layer = newLayers.splice(sourceIndex, 1)[0]
        newLayers.splice(destinationIndex, 0, layer)

        return newLayers
      }),
    []
  )

  if (displayMode === 'file') {
    return (
      <>
        <AddAppWrapper onClose={onClose} className={props.className}>
          <AddAppContainer>
            <Title>Choose floor</Title>
            <SelectComponent
              styles={customSelectStyles}
              closeMenuOnSelect="true"
              placeholder="Floor"
              isMulti={false}
              isSearchable={false}
              options={options}
              defaultValue={{ label: floor }}
              onChange={handleChange}
            />

            <ImageUploader photo={photo} onDrop={onDrop} onRemove={onRemove} />
            <Actions>
              <Button
                size="large"
                color="secondary"
                variant="contained"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                size="large"
                color="primary"
                variant="contained"
                onClick={onSubmit}
                disabled={!(photo && floor)}
              >
                Save
              </Button>
            </Actions>
            {error && <StyledError>{error}</StyledError>}
            <Button
              color="primary"
              variant="text"
              onClick={switchToTable}
              mt="14px"
            >
              I have geojson files
            </Button>
          </AddAppContainer>
        </AddAppWrapper>
      </>
    )
  }

  if (addLayerModalState) {
    return (
      <AddAppWrapper onClose={onClose}>
        <AddAppContainer>
          <FileUploader
            acceptedFiles=".json, .geojson"
            showFiletypeIcon
            onDrop={onDropLayerFile}
            onRemove={onRemoveLayerFile}
          />
          <Actions>
            <Button
              size="large"
              color="primary"
              variant="contained"
              onClick={closeAddLayerModal}
            >
              Cancel
            </Button>
            <Button
              size="large"
              color="primary"
              variant="contained"
              onClick={onSubmitNewLayer}
              disabled={!newLayerFile}
            >
              Add
            </Button>
          </Actions>
        </AddAppContainer>
      </AddAppWrapper>
    )
  }

  return (
    <AddAppWrapper onClose={onClose} className={props.className}>
      <AddAppContainer style={{ minWidth: '600px' }}>
        <SelectComponent
          styles={customSelectStyles}
          closeMenuOnSelect="true"
          placeholder="Floor"
          isMulti={false}
          isSearchable={false}
          options={options}
          defaultValue={{ label: floor }}
          onChange={handleChange}
        />
        <DraggableDataTable
          columns={columns}
          rows={layers}
          withDragNDrop
          onDragEnd={onDragLayer}
        />

        <ButtonsContainer triple>
          <Button color="secondary" onClick={onClose} mt="14px">
            Back
          </Button>
          <Button color="primary" onClick={openAddLayerModal} mt="14px">
            Add layer
          </Button>
          <Button
            color="primary"
            onClick={onSubmit}
            disabled={!(layers.length && floor)}
            mt="14px"
          >
            Save
          </Button>
        </ButtonsContainer>
        <Button
          color="primary"
          variant="text"
          onClick={switchToFile}
          mt="14px"
          style={{ height: 34, minHeight: '34px' }}
        >
          Upload photo
        </Button>
      </AddAppContainer>
    </AddAppWrapper>
  )
}

const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  ${p =>
    p.triple &&
    `
    grid-template-columns: 1fr 1fr 1fr;
  `}
`

const AddAppWrapper = styled(Modal)``
const StyledError = styled(Error)`
  margin-top: 24px;
`

const AddAppContainer = styled.div`
  padding: 20px;
  margin-top: 43px;
  box-shadow: 0 9.08776px 14.3889px rgba(60, 128, 209, 0.05);
  border-radius: 10px;
  border: 10px solid white;
  background: #fafbff;
  display: flex;
  flex-direction: column;
`

const Actions = styled.div`
  display: flex;
  justify-content: space-around;
`
const Title = styled.h2`
  margin: 0;
  font-size: 18px;
  color: #344356;
`

const SelectComponent = styled(Select)`
  position: relative;
  margin: 20px 0;
  z-index: 1001;
`
const ImageUploader = styled(ImageUploaderComponent)`
  min-height: 210px;
`

const TableActionsCell = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: fit-content;
  column-gap: 12px;
  margin: auto;
  float: right;

  > * {
    cursor: pointer;
  }
`

export const AddFloor = styled(AddFloorComponent)``
