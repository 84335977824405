import React from 'react'
import { Field } from 'react-final-form'
import { TextFieldUI } from 'src/ui/form/TextFieldUI'

export const TextField = ({
  name = '1',
  type = 'text',
  format,
  formatOnBlur,
  ...props
}) => {
  return (
    <Field
      name={name}
      type={type}
      format={format}
      formatOnBlur
      validate={props?.fieldValidate}
      render={({ input, meta }) => (
        <TextFieldUI
          {...props}
          inputProps={input}
          error={
            (meta.error || meta.submitError) &&
            meta.touched &&
            (meta.error || meta.submitError?.[0])
          }
        />
      )}
    />
  )
}
