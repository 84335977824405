import React, { useState, useEffect, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Select from 'react-select'

import styled from 'styled-components/macro'
import { Modal } from 'src/components/Modal'
import { ImageUploader } from 'src/components/Uploader'

import { useBuildings } from 'src/modules/buildings'
import { Button, Loader } from 'src/ui'
import { useApplications } from 'src/modules/applications'
import { TextInput } from 'src/ui/form/TextInput'
import { createUpload } from 'src/modules/directLoad'
import { toast } from 'react-toastify'
import { customSelectStyles } from 'src/ui/form/SelectInput'

export const AddBeaconsImportsModal = ({
  onClose,
  beaconsImportHook,
  beaconsImports,
}) => {
  const { buildingId } = useParams()
  const history = useHistory()

  const {
    applicationId,
    applications,
    get: getApplications,
    isLoading: isApplicationsLoading,
    setApplicationId,
  } = useApplications()

  const { buildings, get: getBuildings } = useBuildings()
  const { create: createImport } = beaconsImportHook

  const [applicationsOptions, setApplicationsOptions] = useState()

  const [buildingsOptions, setBuildingsOptions] = useState([])
  const [importName, setImportName] = useState('')
  const [selectedBuildingId, setSelectedBuildingId] = useState(buildingId)

  const [defaultApplication, setDefaultApplication] = useState()
  const [defaultBuilding, setDefaultBuilding] = useState()

  const [beaconCsv, setBeaconCsv] = useState('')

  const onDrop = async (file, updateProgressCallback, completeCallback) => {
    const upload = await createUpload(
      file,
      updateProgressCallback,
      completeCallback
    )
    setBeaconCsv(upload.signed_id)
  }

  const handleNext = async () => {
    if (beaconCsv) {
      const result = await createImport({
        building_id: selectedBuildingId,
        name: importName,
        file: beaconCsv,
      })

      if (result.ok && result?.json?.beacon_import) {
        onClose()
        beaconsImports?.push(result.json.beacon_import)
        history.push(
          `/${buildingId ? `buildings/${buildingId}/` : ''}beacons/imports/${
            result.json.beacon_import.id
          }`
        )
      } else {
        toast.error(
          result?.json?.error?.message ||
            'Something went wrong, please use correct csv file.'
        )
      }
    }
  }

  function onRemove() {
    setBeaconCsv('')
  }

  function handleImportName({ target: { value } }) {
    setImportName(value)
  }

  async function handleApplicationChange(selection) {
    setBuildingsOptions([])
    setDefaultBuilding({})
    setSelectedBuildingId('')

    setApplicationId(selection.value)
    setBeaconCsv('')
  }

  const handleBuildingChange = selection => {
    setSelectedBuildingId(selection.value)
  }

  const loadApplications = useCallback(async () => {
    if (!applications?.length) {
      await getApplications()
    }
  }, [applications?.length, getApplications])

  useEffect(() => {
    loadApplications()
  }, [loadApplications])

  const loadBuildings = useCallback(async () => {
    await getBuildings()
  }, [getBuildings])

  const reduceApplications = useCallback(() => {
    let filteredApplications = []
    let defaultApp

    filteredApplications = applications.map(item => ({
      label: item.name,
      value: item.id,
    }))

    if (applicationId) {
      if (applications.length) {
        defaultApp = filteredApplications.filter(
          item => item.value === +applicationId
        )
      } else {
        defaultApp = {
          label: applicationId,
          value: applicationId,
        }
        filteredApplications = [defaultApp]
      }
    }

    setApplicationsOptions(filteredApplications)
    setDefaultApplication(defaultApp)
    loadBuildings()
  }, [applicationId, applications, loadBuildings])

  useEffect(() => {
    if (!isApplicationsLoading) {
      reduceApplications()
    }
  }, [applications, isApplicationsLoading, reduceApplications])

  const reduceBuildings = useCallback(() => {
    const filteredBuildings = buildings.map(item => ({
      label: item.name,
      value: item.id,
    }))
    let defaultBuild
    setBuildingsOptions(filteredBuildings)

    if (filteredBuildings.length === 1) {
      setSelectedBuildingId(filteredBuildings[0].value)
    } else if (buildingId) {
      if (buildings.length) {
        defaultBuild = filteredBuildings.find(
          item => item.value === +buildingId
        )
      }

      // eslint-disable-next-line no-underscore-dangle
      setSelectedBuildingId(
        +defaultBuild?.value || filteredBuildings?.[0]?.value
      )
    }

    setDefaultBuilding(defaultBuild || filteredBuildings?.[0])
  }, [buildingId, buildings])

  useEffect(() => {
    reduceBuildings()
  }, [buildings?.length])

  if (isApplicationsLoading) {
    return <Loader />
  }

  return (
    <Modal onClose={onClose}>
      <ModalContainer>
        <AddABeacon>Beacons Import</AddABeacon>
        <ImportsContainer>
          <div>
            {!!applicationsOptions?.length && (
              <SelectComponent
                styles={customSelectStyles}
                closeMenuOnSelect="true"
                defaultValue={defaultApplication}
                options={applicationsOptions}
                placeholder="Select Application"
                isMulti={false}
                isSearchable={false}
                isDisabled={applications.length <= 1}
                onChange={handleApplicationChange}
              />
            )}

            {!!buildingsOptions.length && (
              <SelectComponent
                styles={customSelectStyles}
                closeMenuOnSelect="true"
                defaultValue={defaultBuilding}
                options={buildingsOptions}
                placeholder="Select Building"
                isMulti={false}
                isSearchable={false}
                isDisabled={buildings.length <= 1 || !applicationId}
                onChange={handleBuildingChange}
              />
            )}

            <TextInputStyled
              placeholder="Name for the import (optional)"
              onChange={handleImportName}
            />
          </div>
          <ImageUploaderStyled
            acceptedFiles=".csv"
            acceptedFilesType="text/csv"
            onDrop={onDrop}
            onRemove={onRemove}
            dropZoneClassName="styled-drop-zone"
          />
        </ImportsContainer>

        <ActionWrapper>
          <Actions>
            <Button color="secondary" bold size="lg" onClick={onClose}>
              {t('Cancel')}
            </Button>
            <Button
              color="primary"
              bold
              size="lg"
              onClick={handleNext}
              disabled={!beaconCsv || !selectedBuildingId}
            >
              {t('Next')}
            </Button>
          </Actions>
        </ActionWrapper>
      </ModalContainer>
    </Modal>
  )
}

const ModalContainer = styled.div`
  padding: 30px;
  background-color: white;
  border-radius: 5px;
  min-width: 80vw;
`
const AddABeacon = styled.p`
  padding: 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 14px;
  color: #344356;
`

export const ActionWrapper = styled.div`
  display: grid;
  justify-content: end;
  margin-top: 16px;
`

export const Actions = styled.div`
  display: flex;
  justify-content: space-around;
  column-gap: 20px;
  max-width: 400px;
`
const ImportsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`

const SelectComponent = styled(Select)`
  position: relative;
  margin: 20px 0;
  border: none;
  &:focus {
    background: red;
  }
`

const TextInputStyled = styled(TextInput)`
  width: 100%;
`
const ImageUploaderStyled = styled(ImageUploader)`
  margin-top: 16px;

  .styled-drop-zone {
    width: 100%;
    height: 100%;
  }

  &:focus {
    outline-color: #2684ff;
  }

  ${props =>
    props.disabled &&
    `
    opacity: 0.5;
    pointer-events: none;
  `}

  div.dz-preview.dz-file-preview {
    display: block;
  }
`
