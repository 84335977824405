import React, { useMemo } from 'react'
import styled from 'styled-components/macro'

import { Button, Loader } from 'src/ui'
import { Modal } from 'src/components/Modal'
import { useFloors } from 'src/modules/floors'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { Form } from 'react-final-form'
import { CheckboxUI } from 'src/ui/form/Checkbox'
import { useSelector } from 'react-redux'
import { getJBuilding } from 'src/redux/slicers/jibestream/selectors'
import { useBuildings } from 'src/modules/buildings'

export const SyncStatus = props => {
  const {
    syncStatus,
    className,
    floorsInBuilding,
    setSyncStatus,
    buildingId,
    onClose,
    setSyncJFloors,
  } = props

  const { setJFloor, getLayersToRemove, get: getFloors } = useFloors()
  const { getBuilding } = useBuildings()

  const { floors: jFloors } = useSelector(getJBuilding)

  const validateForm = () => {}
  const initialValues = useMemo(
    () => ({
      floors:
        jFloors?.map(floor => {
          const existFloor = floorsInBuilding?.find(
            floorInBuilding => +floorInBuilding.jibestream_id === floor.id
          )

          return {
            ...floor,
            sync: !existFloor,
            existFloorId: existFloor?.id,
          }
        }) || [],
    }),
    [jFloors, floorsInBuilding]
  )

  const multiFloorsSync = syncFloors => {
    return new Promise(async resolve => {
      const totalQueries = [...syncFloors]

      const data = []
      async function upload() {
        const floor = totalQueries.shift()

        if (!floor) return false
        let layersToRemove
        if (floor.existFloorId) {
          layersToRemove = await getLayersToRemove(
            buildingId,
            floor.existFloorId
          )
        }

        const status = {
          ...syncStatus,
          files: floor.layers.length,
          floors: syncFloors.length,
          floor: syncFloors.length - totalQueries.length,
          floorName: floor.name,
        }

        await setSyncStatus(status)

        const result = await setJFloor({
          floor,
          layersToRemove,
          buildingId,
          syncStatus: status,
          setSyncStatus,
          floorId: floor.existFloorId,
        })

        data.push(result)

        if (!totalQueries.length) {
          resolve(data)
        } else {
          upload()
        }
      }

      upload()
    })
  }

  const handleSave = async values => {
    const floorsToSync = values.floors.filter(floor => floor.sync)
    const result = await multiFloorsSync(floorsToSync)
    if (result.length === floorsToSync.length) {
      setSyncJFloors(false)
      setSyncStatus({})
      getFloors(buildingId)
      getBuilding(buildingId)
    }
  }

  return (
    <AddAppWrapper className={className}>
      {!jFloors?.length && <Loader />}
      <AddAppContainer>
        <h1>Synchronizing Floors</h1>

        {!jFloors?.length && (
          <>
            <p>Importing Building Parameters...</p>
          </>
        )}

        {Boolean(jFloors?.length) && (
          <>
            {syncStatus?.files && (
              <>
                <p>Synchronizing floor : {syncStatus.floorName}</p>
                <p>
                  Loading layers: {syncStatus.file}/{syncStatus.files}
                </p>
                <p>
                  Progress: {syncStatus.floor}/{syncStatus.floors}
                </p>
              </>
            )}
            {!syncStatus?.files && (
              <Form
                onSubmit={handleSave}
                validate={validateForm}
                mutators={{
                  ...arrayMutators,
                }}
                initialValues={initialValues}
                render={formProps => {
                  const { handleSubmit } = formProps

                  const hasSelected = formProps.values.floors.some(
                    floor => floor.sync
                  )

                  return (
                    <form
                      onSubmit={handleSubmit}
                      style={{ textAlign: 'center' }}
                    >
                      <FieldArray name="floors">
                        {({ fields }) => {
                          return (
                            <div>
                              {fields?.map((floor, index) => {
                                return (
                                  <CustomItem key={floor}>
                                    <CheckboxStyled
                                      name={`${floor}.sync`}
                                      label={`${fields.value[index].name} ${
                                        floorsInBuilding?.length &&
                                        !fields.value[index].existFloorId
                                          ? ' (Not synchronized)'
                                          : ''
                                      }`}
                                      inputProps={{
                                        name: 'terms',
                                        type: 'checkbox',
                                      }}
                                    />
                                    <div>{}</div>
                                  </CustomItem>
                                )
                              })}
                            </div>
                          )
                        }}
                      </FieldArray>
                      <ActionWrapper>
                        <Button color="secondary" size="lg" onClick={onClose}>
                          {t('Cancel')}
                        </Button>

                        <Button
                          disabled={!hasSelected}
                          color="primary"
                          size="lg"
                          onClick={handleSubmit}
                        >
                          {t('Synchronize')}
                        </Button>
                      </ActionWrapper>
                    </form>
                  )
                }}
              />
            )}
          </>
        )}
      </AddAppContainer>
    </AddAppWrapper>
  )
}

const AddAppWrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  min-width: 600px;
  background: white;
`
const AddAppContainer = styled.div`
  position: relative;
  z-index: 1;
`

const CheckboxStyled = styled(CheckboxUI)`
  margin: 0;
  label {
    margin-left: 0;
    input {
      margin-right: 10px;
    }
    &:hover,
    input:hover {
      cursor: pointer;
    }
  }
`
const CustomItem = styled.div`
  display: grid;
  justify-items: start;
`
const ActionWrapper = styled.div`
  display: flex;
  gap: 18px;
`
