import React from 'react'
import styled from 'styled-components/macro'
import { Scroller } from 'src/components/Scroller'

const SideBarComponent = ({ children, ...props }) => (
  <SideBarContent {...props}>
    <Scroller left="true" autoHide="false">
      {children}
    </Scroller>
  </SideBarContent>
)

const SideBarContent = styled.div`
  position: relative;
  padding: 0;
  height: 100%;
  overflow: auto;
  z-index: 1;
`

export const SideBar = styled(SideBarComponent)`
  position: relative;
  display: inline-block;
  width: 344px;
  background: #fafbff;
  box-shadow: 0 9.08776px 14.3889px rgba(60, 128, 209, 0.05);
  overflow: hidden;
`
