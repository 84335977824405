import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import {
  addCreditCard,
  loadCreditCard,
  loadCreditCards,
  updateCreditCard,
  removeCreditCard,
} from './asyncThunk'

function isPendingCardAction(action) {
  return (
    action.type.startsWith('creditCard/') && action.type.endsWith('/pending')
  )
}
function isPendingCardsAction(action) {
  return (
    action.type.startsWith('creditCards/') && action.type.endsWith('/pending')
  )
}

// single card
const creditCardSlice = createSlice({
  name: 'creditCard',
  initialState: {
    loading: false,
    signature: '',
    creditCard: [],
  },
  extraReducers: builder => {
    builder.addCase(addCreditCard.fulfilled, (state, action) => {
      state.loading = false
      // Add signature to the state array
      state.signature = action.payload.signature
    })

    builder.addCase(addCreditCard.rejected, state => {
      state.loading = false
      toast.error('Something went wrong')
    })

    builder.addCase(loadCreditCard.fulfilled, (state, action) => {
      state.loading = false
      // Add signature to the state array
      state.creditCard = action.payload
    })

    builder.addCase(updateCreditCard.fulfilled, (state, action) => {
      state.loading = false
      // Add signature to the state array
      state.creditCard = action.payload
    })

    builder.addCase(removeCreditCard.fulfilled, state => {
      state.loading = false
    })
    builder.addMatcher(isPendingCardAction, state => {
      state.loading = true
    })
  },
})

// list of cards
const creditCardsSlice = createSlice({
  name: 'creditCards',
  initialState: {
    loading: false,
    isInitiated: false,
    creditCards: [],
  },
  extraReducers: builder => {
    builder.addCase(loadCreditCards.fulfilled, (state, action) => {
      // Add signature to the state array
      state.creditCards = action.payload

      state.loading = false
      state.isInitiated = true
    })
    builder.addCase(loadCreditCards.rejected, state => {
      state.loading = false
      toast.error('Something went wrong')
    })
    builder.addMatcher(isPendingCardsAction, state => {
      state.loading = true
    })
  },
})

export const creditCardActions = creditCardSlice.actions
export const creditCardReducer = creditCardSlice.reducer

export const creditCardsActions = creditCardsSlice.actions
export const creditCardsReducer = creditCardsSlice.reducer
