import React, { useState, useEffect, useCallback, useMemo } from 'react'
import styled from 'styled-components/macro'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
  getJAccount,
  getJBuildings,
  jibestreamActions,
} from 'src/redux/slicers/jibestream'

import { useBuildings } from 'src/modules/buildings'
import { useFloors } from 'src/modules/floors'
import { Loader, Title } from 'src/ui'
import { useMap } from 'src/modules/map'
import { useAbility } from 'src/hooks/useAbility'
import { Subtitle } from 'src/ui/Title'

import { useJibestream } from 'src/hooks/useJibestream'
import { getJBuilding } from 'src/redux/slicers/jibestream/selectors'
import { readAddress } from 'src/components/MapBox/MapBox'

import {
  ConnectJibestreamAccount,
  ConnectJibestreamBuilding,
  SyncStatus,
} from './components/jibestream'
import { BuildingForm, AddFloor } from './components'

function BuildingComponent(props) {
  const {
    setSearch,
    address,
    setAddress,
    search,
    longitude,
    latitude,
    setShowSearchMarker,
    setShowMarkers,
    flyTo,
    isMapLoaded,
    geocodingClient,
  } = useMap()
  const history = useHistory()
  const params = useParams()
  const buildingId = params?.buildingId && +params.buildingId

  const { getBuilding } = useBuildings()
  const { fetchJBuilding, fetchVenues } = useJibestream()
  const { setJBuilding, setJBuildings, setJAccount, init } = jibestreamActions

  const {
    isLoading: isFloorsLoading,
    get: getFloors,
    setFloors,
    floors,
  } = useFloors()

  const {
    building: { manage, show },
  } = useAbility()

  const jAccount = useSelector(getJAccount)
  const jBuilding = useSelector(getJBuilding)
  const jBuildings = useSelector(getJBuildings)

  const dispatch = useDispatch()

  const [building, setBuilding] = useState({})
  const [showAddFloor, setShowAddFloor] = useState(false)

  const [showConnectJibestram, setShowConnectJibestram] = useState(false)
  const [showAddJBuilding, setShowAddJBuilding] = useState(false)
  const [syncJFloors, setSyncJFloors] = useState(false)
  const [syncStatus, setSyncStatus] = useState({})

  const onCloseAddFloorModal = useCallback(() => setShowAddFloor(false), [])

  const onCloseAddJBuilding = useCallback(() => setShowAddJBuilding(false), [])

  const jBuildingId = building.jibestream_id || jBuilding.jibestream_id

  const jAccountFromBuilding = {
    clientId: building.jibestream_client_id || jBuilding.client_id,
    clientSecret: building.jibestream_client_secret || jBuilding.client_secret,
    customerId: building.jibestream_customer_id || jBuilding.customer_id,
  }

  const loadJBuilding = async () => {
    const fetchedJBuild = await fetchJBuilding(
      jBuildingId,
      jAccountFromBuilding
    )

    await dispatch(setJBuilding(fetchedJBuild))
  }

  const onCloseConnectJibestream = useCallback(
    () => setShowConnectJibestram(false),
    []
  )

  const hasJBuildingConnection = useMemo(
    () => Boolean(jBuilding?.jibestream_id || building?.jibestream_id),
    [building?.jibestream_id, jBuilding?.jibestream_id]
  )

  const initialValues = {
    ...building,
    photo: building?.photo?.medium,
  }

  const loadBuilding = useCallback(async () => {
    if (buildingId) {
      getBuilding(buildingId).then(currentBuilding => {
        if (!currentBuilding && buildingId) {
          history.push('/buildings')
        } else if (currentBuilding?.id) {
          getFloors(buildingId)
          setBuilding(currentBuilding)

          // check existing building in Jibestream
          if (currentBuilding.jibestream_id) {
            dispatch(
              setJAccount({
                clientId: currentBuilding.jibestream_client_id,
                clientSecret: currentBuilding.jibestream_client_secret,
                customerId: currentBuilding.jibestream_customer_id,
              })
            )
          }
        }
      })
    }
  }, [buildingId, dispatch, getBuilding, getFloors, history, setJAccount])

  useEffect(loadBuilding, [loadBuilding, buildingId])

  useEffect(() => {
    if (hasJBuildingConnection && isMapLoaded) {
      const selectedJBuilding = jBuilding?.coordinate
        ? jBuilding
        : jBuildings.find(jbuild => jbuild.id === +jBuildingId)

      if (selectedJBuilding && selectedJBuilding.coordinate) {
        const { coordinate } = selectedJBuilding
        flyTo({
          lng: coordinate.longitude,
          lat: coordinate.latitude,
        })
        if (!buildingId) {
          geocodingClient.geocoderService
            .reverseGeocode({
              query: [coordinate.longitude, coordinate.latitude],
            })
            .send()
            .then(response => {
              setAddress(readAddress(response))
            })
        }
      }
    }
  }, [jBuilding.coordinate, isMapLoaded, hasJBuildingConnection])

  const loadVenues = async () => {
    const { buildings, building: fetchedJBuilding } = await fetchVenues({
      buildingId: jBuildingId,
      jAccount: jAccountFromBuilding,
    })

    dispatch(setJAccount(jAccountFromBuilding))
    dispatch(setJBuildings(buildings))

    if (jBuildingId) {
      dispatch(setJBuilding(fetchedJBuilding))
    }

    if (!building?.id) {
      loadJBuilding()
    }
  }

  useEffect(() => {
    if (
      building.id &&
      jBuilding?.jibestream_id &&
      building?.jibestream_id !== jBuilding.jibestream_id
    ) {
      dispatch(init())
      setFloors([])
    }
    if (building.jibestream_client_secret && !jBuilding?.jibestream_id) {
      loadVenues()
    }
  }, [building?.jibestream_id])

  useEffect(() => {
    if (!buildingId) {
      dispatch(init())
      setFloors([])
    }
  }, [])

  useEffect(() => {
    if (building?.coordinate) {
      setShowMarkers([
        {
          id: building.id,
          title: building.name,
          lat: building.coordinate.latitude,
          lng: building.coordinate.longitude,
        },
      ])
    } else if (!buildingId && !jBuilding.jibestream_id) {
      setShowSearchMarker(true)
    }
  }, [buildingId, building, setShowMarkers, setShowSearchMarker])

  const onJAccountAdded = () => {
    setShowConnectJibestram(false)
    setShowAddJBuilding(true)
  }

  const onBuildingAdded = () => {
    setShowAddJBuilding(false)
  }

  const closeSyncModal = () => {
    setSyncJFloors(false)
  }

  return (
    <Container className={props.className}>
      {((manage && !buildingId) ||
        (show && Boolean(buildingId) && building?.id)) && (
        <BuildingForm
          building={building}
          loadBuilding={loadBuilding}
          setShowAddFloor={setShowAddFloor}
          setShowConnectJibestram={setShowConnectJibestram}
          setShowAddJBuilding={setShowAddJBuilding}
          setSearch={setSearch}
          address={address}
          search={search}
          longitude={longitude}
          latitude={latitude}
          initialValues={initialValues}
          loadJBuilding={loadJBuilding}
          syncJFloors={syncJFloors}
          setSyncJFloors={setSyncJFloors}
          syncStatus={syncStatus}
          setSyncStatus={setSyncStatus}
        />
      )}

      {!buildingId && !manage && (
        <>
          <Title>
            There are no available Building in your current Organization.
          </Title>
          <Subtitle>
            Ask your Organization administrators to create one or give you the
            access.
          </Subtitle>
        </>
      )}
      {showAddFloor && !hasJBuildingConnection && (
        <AddFloor
          onClose={onCloseAddFloorModal}
          floors={building.floors}
          basementFloors={building.basement_floors}
        />
      )}

      {showConnectJibestram && (
        <ConnectJibestreamAccount
          jAccount={jAccount}
          onClose={onCloseConnectJibestream}
          onJAccountAdded={onJAccountAdded}
          fetchJBuilding={fetchJBuilding}
        />
      )}

      {showAddJBuilding && (
        <ConnectJibestreamBuilding
          jAccount={jAccount}
          onClose={onCloseAddJBuilding}
          onBuildingAdded={onBuildingAdded}
          fetchJBuilding={fetchJBuilding}
        />
      )}

      {isFloorsLoading && <LoaderStyled />}

      {syncJFloors && (
        <SyncStatus
          setSyncJFloors={setSyncJFloors}
          syncStatus={syncStatus}
          setSyncStatus={setSyncStatus}
          onClose={closeSyncModal}
          floorsInBuilding={floors}
          buildingId={buildingId}
        />
      )}
    </Container>
  )
}

export const Building = styled(BuildingComponent)``
const LoaderStyled = styled(Loader)`
  z-index: 100;
`

const Container = styled.div`
  position: relative;
`
