export const useRouteNavigationArrows = () => {
  const clearPreviousLayers = props => {
    const { currentMap, matcher } = props
    currentMap.getStyle().layers.forEach(layer => {
      if (layer.id.match(matcher)) {
        currentMap.removeLayer(layer.id)
      }
    })
  }

  const getSegment = (props) => {
    const { point, buildingRoute, skipAngles, start, end, connectionPoints, angle } = props
    let key = point.join(',')

    const virtualPoint = buildingRoute.find(
      item => `${item.longitude},${item.latitude}` === key
    )

    if (!virtualPoint) {
      console.log('missed point id: ', key)
    }

    key += virtualPoint.point_id

    let pointNeighbors
    let lineNeighbor

    if (!virtualPoint?.neighbors) {
      console.log('missing virtual point:', key)
    }

    if (!skipAngles && virtualPoint?.neighbors) {
      pointNeighbors = virtualPoint.neighbors.map(neighborId => {
        return buildingRoute.find(item => item.point_id === neighborId)
      })

      lineNeighbor = pointNeighbors.find(neighbor => {
        return (
          (start[0] === neighbor.longitude &&
            start[1] === neighbor.latitude) ||
          (end[0] === neighbor.longitude && end[1] === neighbor.latitude)
        )
      })
    }

    if (!connectionPoints[key]) {
      connectionPoints[key] = {
        coordinates: point,
        count: 0,
        angles: [],
        id: virtualPoint?.point_id,
      }
    }

    if(angle){
      connectionPoints[key].count++
      if (lineNeighbor && !skipAngles ) {

        connectionPoints[key].angles.push(angle)
      }
    }


    return connectionPoints[key]
  }

  const processCoordinates = (props, layerCallback) => {
    const { coordinates, currentMap, buildingRoute, skipAngles } = props
    const connectionPoints = {}

    coordinates.map(segment => {
      const start = segment[0]
      const end = segment[1]

      if (end?.[0]) {
        const deltaX = end[0] - start[0]
        const deltaY = end[1] - start[1]
        const angle = Math.atan2(deltaY, deltaX) * (-180 / Math.PI)



        segment.map((point, index) => {
          // use props.skipAngle to increase performance and reduce operations

          return getSegment({ point, buildingRoute, skipAngles, start, end, connectionPoints, angle })
        })
      }

      return segment
    })

    // Use the provided callback to handle layer-specific logic
    Object.keys(connectionPoints).forEach(key => {
      const pointData = connectionPoints[key]
      if (pointData.count >= 1) {
        layerCallback(currentMap, pointData, key)
      }
    })
  }

  const showPointsId = props => {
    cleanPointsId({ currentMap: props.currentMap })

    processCoordinates(
      { ...props, skipAngles: true },
      (currentMap, pointData, key) => {
        currentMap.addLayer({
          id: `nearmotion-pointid-layer-${key}-${pointData.id}`,
          type: 'symbol',
          source: 'nearmotion-routesGeoJson',
          layout: {
            'text-field': `${pointData.id}`,
            'text-size': 12,
            'symbol-placement': 'point',
            'text-offset': ['literal', [3.3, 1]],
            'text-allow-overlap': true,
          },
          geometry: {
            type: 'Point',
            coordinates: pointData.coordinates,
          },
          filter: ['all', ['==', ['get', 'pointCoordinates'], key]],
        })
      }
    )
  }

  const calculateOffset = (index, total, distance = 1.5) => {
    // Calculate the offset based on the index and total number of arrows
    const step = distance / total

    return [-1, (index - (total - 1) / 2) * step] // Offset along the y-axis symmetrically
  }

  const loadRouteNavigation = props => {
    cleanRouteNavigation({ currentMap: props.currentMap })

    processCoordinates(props, (currentMap, pointData, key) => {

      pointData.angles.forEach((angle, index) => {
        if (
          currentMap.getLayer(
            `nearmotion-arrows-layer-${key}-${angle}-${index}`
          )
        ) {
          return
        }

        const offset = calculateOffset(index, pointData.angles.length) // Dynamic offset for each arrow

        currentMap.addLayer({
          id: `nearmotion-arrows-layer-${key}-${angle}-${index}`,
          type: 'symbol',
          source: 'nearmotion-routesGeoJson',
          layout: {
            'text-field': `→`,
            'text-rotate': ['literal', angle],
            'text-size': 24,
            'symbol-placement': 'point',
            'text-offset': ['literal', offset],
            'text-allow-overlap': true,
          },
          geometry: {
            type: 'Point',
            coordinates: pointData.coordinates,
          },
          filter: [
            'all',
            ['==', ['get', 'hasNeighbors'], true], // Filter by two-way property
            ['==', ['get', 'pointCoordinates'], key], // Filter by coordinates
          ],
        })
      })
    })
  }

  const cleanRouteNavigation = props => {
    const { currentMap } = props
    clearPreviousLayers({ currentMap, matcher: /^nearmotion-arrows-layer.*/ })
  }

  const cleanPointsId = props => {
    const { currentMap } = props
    clearPreviousLayers({ currentMap, matcher: /^nearmotion-pointid-layer.*/ })
  }

  return {
    loadRouteNavigation,
    cleanPointsId,
    cleanRouteNavigation,
    showPointsId,
  }
}

export default useRouteNavigationArrows
