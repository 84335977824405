import React, { useMemo, useRef } from 'react'
import { Field, Form } from 'react-final-form'
import { toast } from 'react-toastify'
import he from 'he'
import { unwrapResult } from '@reduxjs/toolkit'

import styled from 'styled-components/macro'
import Cleave from 'cleave.js/react'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'src/ui'
import { getCreditCardIcon } from 'src/utils/creditCard'
import { OutlinedInputCss } from 'src/ui/form/TextInput'
import validate from 'src/utils/validate'
import isAmount from 'src/utils/validators/isAmount'

import {
  addCharge,
  getChargesLoading,
  loadCharge,
} from 'src/redux/slicers/subscription'
import { useCompany } from 'src/modules/company'

import { useModalWindow } from 'src/hooks/useModalWindow'
import { TextField } from 'src/components/TextField'

export const ChargeBalanceForm = props => {
  const {
    defaultCard,
    handleShowChargeForm,
    isMadaCard,
    isSubscriptionActive,
    setIsLoading,
    handleCreateSubscription,
    subscription,
  } = props
  const dispatch = useDispatch()
  const { get: getCompany, company: currentCompany } = useCompany()
  const chargeLoading = useSelector(getChargesLoading)

  const { openModalWindow } = useModalWindow({
    url: 'about:blank',
    title: '3Ds',
    w: 600,
    h: 600,
  })
  const formRef = useRef()

  const defaultCharge =
    +currentCompany?.balance_in_currency - +currentCompany?.price_in_currency

  const initialValues = useMemo(() => {
    const values = {
      amount: defaultCharge < 0 ? Math.abs(defaultCharge) : null,
      card_number: defaultCard.info.card_number,

      currency: defaultCard.currency.toUpperCase(),
    }

    if (isMadaCard) {
      values.card_security_code = ''
    }

    return values
  }, [
    defaultCard.currency,
    defaultCard.info.card_number,
    defaultCharge,
    isMadaCard,
  ])

  const validateForm = values => {
    const errors = {}

    errors.amount = validate(`${values?.amount}`, isAmount)

    return errors
  }

  const waitForDddChecked = async (charge, popup) => {
    // Read status response from the popup window
    const receiveMessage = async prop => {
      const { data } = prop
      if (data) {
        if (data.error) {
          toast(he.decode(data.error))
          setIsLoading(false)
        } else if (data.info) {
          toast(he.decode(data.info))
        } else if (data.success) {
          toast(he.decode(data.success))
          await waitForCharge(charge.id)
        }
      }
    }

    if (charge.ddd_url && charge?.status === 'ddd_check') {
      formRef.current.setAttribute('action', charge.ddd_url)
      if (popup) {
        window.addEventListener('message', receiveMessage, false)

        const timer = setInterval(async () => {
          if (popup.closed) {
            window.removeEventListener('message', receiveMessage)
            setIsLoading(false)
            clearInterval(timer)
          }
        }, 500)
      }

      formRef.current.setAttribute('target', '3Ds')
      formRef.current.submit()
    }
  }

  const requestCharge = async (chargeId, interval, popup) => {
    const request = await dispatch(loadCharge({ chargeId }))
    const result = unwrapResult(request)

    if (result.status === 'ddd_check') {
      clearInterval(interval)
      await waitForDddChecked(result, popup)
    } else if (result.status === 'succeed' && result?.settled) {
      clearInterval(interval)
      popup?.close()
      toast(he.decode('Your balance was updated successfully'))
      setIsLoading(false)

      const requestCompany = await getCompany()
      const company = requestCompany?.json?.saas_company || {}
      const paymentNeeded =
        +company.balance_in_currency - +company.price_in_currency
      const activeSubscription = /active|trialing|past_due/.test(
        company?.subscription?.status
      )
      if (!activeSubscription) {
        if (paymentNeeded >= 0) {
          handleCreateSubscription()
        } else {
          toast(
            he.decode(`Insufficient balance to order/renew the subscription.`)
          )
        }
      } else {
        handleShowChargeForm()
      }
    } else if (result.status === 'failed') {
      clearInterval(interval)
      popup?.close()
      setIsLoading(false)
      toast(
        he.decode(
          `Your balance wasn't updated, please check your CVV or card details.`
        )
      )
    }
  }

  const waitForCharge = async (chargeId, popup) => {
    const interval = setInterval(async () => {
      await requestCharge(chargeId, interval, popup)
    }, 3000)
  }

  // run charge process
  const onSubmit = async values => {
    const popup = openModalWindow()

    setIsLoading(true)
    const request = await dispatch(
      addCharge({
        amount: values.amount,
        currency: values.currency.toLowerCase(),
        cvv: values.card_security_code,
      })
    )

    const result = unwrapResult(request)
    if (result?.id) {
      await waitForCharge(result.id, popup)
    } else {
      toast.error(
        result?.json?.error?.message ||
          result.statusText ||
          'Something went wrong'
      )
      popup?.close()
      setIsLoading(false)
    }
  }

  const CleaveFormInput = ({ meta, input, inputProps, ...restOfProps }) => (
    <Cleave options={restOfProps.options} {...input} {...inputProps} />
  )

  const formatPrice = value => {
    return value > 0 && parseFloat(value).toFixed(2)
  }

  return (
    <Container>
      <Form
        onSubmit={() => {}}
        validate={validateForm}
        initialValues={initialValues}
        render={({ handleSubmit, values, errors }) => {
          const cardType =
            values?.card_number && getCreditCardIcon(values?.card_number)

          return (
            <>
              <FormComponent onSubmit={handleSubmit}>
                <NewCard>
                  <div>
                    <b>Card:</b> {values.card_number}
                  </div>
                  {cardType && <InlineDetails>{cardType}</InlineDetails>}
                </NewCard>
                <NewCard>
                  <TextFieldStyled
                    variant="outlined"
                    fullWidth
                    placeholder="Amount"
                    name="amount"
                    type="number"
                    min={0.02}
                    format={formatPrice}
                    formatOnBlur
                  />
                  <InlineDetails>{values.currency}</InlineDetails>
                </NewCard>

                {isMadaCard && (
                  <NewCard>
                    <Field
                      options={{
                        numericOnly: true,
                        blocks: [4],
                      }}
                      inputProps={{
                        placeholder: 'CCV Code',
                      }}
                      name="card_security_code"
                      component={CleaveFormInput}
                    />
                  </NewCard>
                )}

                <Actions>
                  {!(
                    isMadaCard &&
                    defaultCharge > 0 &&
                    !isSubscriptionActive
                  ) && (
                    <Button color="primary" onClick={handleShowChargeForm}>
                      Cancel
                    </Button>
                  )}
                  <Button
                    color="primary"
                    disabled={
                      errors.amount ||
                      !values.amount ||
                      (!values.card_security_code &&
                        defaultCard.brand === 'mada') ||
                      chargeLoading
                    }
                    onClick={() => onSubmit(values)}
                  >
                    {t(subscription ? 'Make Payment' : 'Subscribe')}
                  </Button>
                </Actions>
              </FormComponent>
            </>
          )
        }}
      />
      <form
        method="post"
        action={process.env.REACT_APP_PAYFORT_CHARGE_URL}
        ref={formRef}
        hidden
      >
        <input name="remember_me" defaultValue="YES" />
      </form>
    </Container>
  )
}

const Container = styled.div`
  width: 400px;
`

const FormComponent = styled.form`
  display: grid;
  grid-gap: 10px;
  p {
    font-size: 14px;
  }
`

const InlineDetails = styled.div`
  position: absolute;
  display: grid;
  align-items: center;
  right: 24px;
  top: 1px;
  height: 57px;
`

const Actions = styled.div`
  display: flex;
  justify-content: start;
  column-gap: 24px;
  margin-top: 24px;
`

const TextFieldStyled = styled(TextField)`
  margin: 0;
  > div {
    padding: 0;
  }
`

const NewCard = styled.div`
  display: grid;
  position: relative;
  min-height: 60px;
  align-items: center;
  input {
    ${OutlinedInputCss}
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
`
