import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { toast } from 'react-toastify'
import { css } from 'styled-components'

import { Button } from 'src/ui'
import { DataTable } from 'src/components/DataTable'
import { ConfirmationModal } from 'src/components/ConfirmationModal'

import { ReactComponent as IconTrashBin } from 'src/assets/images/svg/icon-trash-bin.svg'
import { useInvitations } from 'src/hooks/useInvitations'

import { ReactComponent as EditImage } from 'src/assets/images/svg/actions/edit.svg'
import { EditMemberModal } from 'src/pages/Companies/components/EditMemberModal'
import { InviteMemberModal } from 'src/pages/Companies/components/InviteMemberModal'

export function Invitations(props) {
  const {
    get: getInvitations,
    remove: removeInvitations,
    isLoading: isInvitationsLoading,
    add: inviteMember,
    update,
  } = useInvitations()

  const [showAddInvite, setShowAddInvite] = useState()
  const [memberToEdit, setMemberToEdit] = useState()

  const [invitations, setInvitations] = useState([])
  const [search] = useState('')
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(20)
  const [currentPageCount, setCurrentPageCount] = useState()

  const getFiltered = useCallback(async () => {
    const result = await getInvitations({
      search,
      page: currentPageIndex,
      size: currentPageSize,
    })
    await setInvitations(result.json.invitations || [])
    setCurrentPageCount(result.headers.get('X-Pager-Total-Pages') || 0)

    return result
  }, [currentPageIndex, currentPageSize, getInvitations, search])

  const fetchData = useCallback(
    async ({ pageIndex, pageSize }) => {
      setCurrentPageIndex(pageIndex)
      setCurrentPageSize(pageSize)
      const result = await getInvitations({
        search,
        page: pageIndex,
        size: pageSize,
      })

      setCurrentPageCount(result.headers.get('X-Pager-Total-Pages') || 0)
      setInvitations([])
      setInvitations(result.json.invitations || [])
    },
    [getInvitations, search]
  )

  const updateMember = useCallback(
    async (id, data) => {
      const result = await update(id, data)
      if (result.ok) {
        const request = await getFiltered()
        setMemberToEdit(null)
        if (request.ok) {
          setMemberToEdit(
            request?.json?.invitations?.find(admin => admin.id === id)
          )
        }
      }

      return result
    },
    [update, getFiltered]
  )

  const confirmRemoveMember = useCallback(
    async memberId => {
      await removeInvitations(memberId)
      await getFiltered()
    },
    [getFiltered, removeInvitations]
  )
  const handleEditUser = useCallback(member => {
    setMemberToEdit(member)
  }, [])

  const toggleInvitePopup = useCallback(async () => {
    setShowAddInvite(!showAddInvite)
  }, [showAddInvite])

  const closePopup = () => {
    setShowAddInvite(false)
  }

  const submitInvite = useCallback(
    async data => {
      try {
        const result = await inviteMember(data)
        if (result.ok) {
          toast.success('Invitation sent successfully')
          await getFiltered()
          toggleInvitePopup()
        }

        if (result?.json?.error?.validations) {
          return result.json.error.validations
        }
        if (result?.json?.error?.parsedValidations) {
          return result.json.error.parsedValidations
        }
        if (!result.ok) {
          return { error: result.status }
        }
      } catch (error) {
        return { error: t('Something went wrong') }
      }

      return false
    },
    [getFiltered, inviteMember, toggleInvitePopup]
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Invitee Email',
        accessor: 'email',
      },
      {
        Header: 'Invitee Role',
        accessor: 'role',
      },

      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        id: 'actions',
        Header: 'Actions',
        cellStyle: { width: '70px', textAlign: 'center' },
        Cell: ({ row: { original } }) => (
          <TableActionsCell>
            {original.status !== 'accepted' && (
              <>
                <EditImageStyled
                  title="Manage Application user access"
                  onClick={() => handleEditUser(original)}
                />
                <ConfirmationModal
                  closeOnConfirm="true"
                  title="Are you sure you want to delete this invitation?"
                  description="This action cannot be undone"
                  onConfirm={confirmRemoveMember}
                  id={original.id}
                  component={IconTrashBinStyled}
                />
              </>
            )}
          </TableActionsCell>
        ),
      },
    ],
    [confirmRemoveMember, handleEditUser]
  )

  const onClose = useCallback(() => {
    setMemberToEdit(false)
  }, [])

  return (
    <Container className={props?.className}>
      <DataTableStyled
        scrollerAutoHide={false}
        isLoading={isInvitationsLoading}
        pagination
        currentPageIndex={currentPageIndex}
        currentPageSize={currentPageSize}
        pageCount={+currentPageCount}
        fetchData={fetchData}
        columns={columns}
        rows={invitations}
        actions={
          <Button color="primary" onClick={toggleInvitePopup}>
            {t('Invite a new memeber')}
          </Button>
        }
      />

      {showAddInvite && (
        <InviteMemberModal onClose={closePopup} submitInvite={submitInvite} />
      )}
      {memberToEdit && (
        <EditMemberModal
          member={memberToEdit}
          onClose={onClose}
          update={updateMember}
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  height: calc(100vh - 235px);
`

const DataTableStyled = styled(DataTable)`
  padding: 0 16px;
  th {
    text-align: left;
  }
  table {
    padding-right: 10px;
  }
`
const TableActionsCell = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  column-gap: 20px;
`

const svgStyled = css`
  cursor: pointer;
  width: 16px;
  height: 16px;
`

const IconTrashBinStyled = styled(IconTrashBin)`
  ${svgStyled}
`

const EditImageStyled = styled(EditImage)`
  ${svgStyled}
  margin-left: 10px;
`
