import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { toast } from 'react-toastify'

import { api } from 'src/modules/api'
import { useAuth } from 'src/modules/auth'

const OAuthContext = createContext()

export function OAuthProvider(props) {
  const { isAuthenticated } = useAuth()
  const [isOAuthLoading, setIsOAuthLoading] = useState(false)

  const getRedirect = useCallback(async data => {
    const res = await api.post('/api/oauth/v1/authorize', data)

    return res
  }, [])

  const redirect = useCallback(
    async json => {
      const result = await getRedirect(json)
      if (result.ok && result.json.redirect_uri) {
        localStorage.removeItem('oauth_json')
        window.location.href = result.json.redirect_uri
      } else {
        result?.json?.error?.message && toast.error(result.json.error.message)
        setIsOAuthLoading(true)
      }
    },
    [getRedirect]
  )

  useEffect(() => {
    const url = new URL(window.location.href)
    const clientId = url.searchParams.get('client_id')
    let json = {}

    if (clientId) {
      json.client_id = clientId
      ;[
        'state',
        'redirect_uri',
        'response_type',
        'sandbox',
        'customerPathKey',
      ].forEach(key => (json[key] = url.searchParams.get(key)))
      localStorage.setItem('oauth_json', JSON.stringify(json))
    } else {
      json = JSON.parse(localStorage.getItem('oauth_json'))
    }

    if (isAuthenticated) {
      if (json?.client_id) {
        redirect(json)
      } else {
        setIsOAuthLoading(true)
      }
    }
  }, [isAuthenticated, redirect])

  const contextValue = {
    isOAuthLoading,
  }

  return (
    <OAuthContext.Provider value={contextValue}>
      {props.children}
    </OAuthContext.Provider>
  )
}

export function useOAuth() {
  return useContext(OAuthContext)
}
