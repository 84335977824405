import { useCallback, useState } from 'react'

import { api } from 'src/modules/api'

export function useStatus() {
  const [isLoading, setIsLoading] = useState(false)

  const get = useCallback(async () => {
    setIsLoading(true)
    const res = await api.get('/api/status')
    setIsLoading(false)

    return res
  }, [])

  return {
    isLoading,
    get,
  }
}
