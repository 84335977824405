import React from 'react'
import styled from 'styled-components/macro'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import jmap from 'jmap.js'

import { Modal } from 'src/components/Modal'
import { Button } from 'src/ui'
import { TextField } from 'src/components/TextField'
import { Form, FormSpy } from 'react-final-form'
import { Alert } from 'src/components/Alert'

import validate from 'src/utils/validate'

import isRequired from 'src/utils/validators/isRequired'

import { jibestreamActions } from 'src/redux/slicers/jibestream'

export function ConnectJibestreamAccount(props) {
  const { onClose, jAccount = {}, onJAccountAdded } = props

  const { setJAccount, setJBuildings } = jibestreamActions

  const dispatch = useDispatch()

  const onValidate = values => {
    const errors = {}

    errors.clientId = validate(values.clientId, isRequired)
    errors.clientSecret = validate(values.clientSecret, isRequired)
    errors.customerId = validate(values.customerId, isRequired)

    return errors
  }

  const initialValues = {
    ...jAccount,
  }

  const onSubmit = ({ clientId, clientSecret, customerId }) => {
    const core = new jmap.core.JCore({
      host: 'https://api.jibestream.com',
      auth: new jmap.core.Auth(clientId, clientSecret),
      customerId,
      geojson: true, // <-- Flag for receiving geojson instead of svg maps as of v4.4.0
    })

    core.getVenues((error, availableVenues) => {
      if (error) {
        return toast.error(error) // unqoun
      }

      dispatch(
        setJAccount({
          clientId,
          clientSecret,
          customerId,
        })
      )
      dispatch(setJBuildings(availableVenues.getAll().map(item => item._)))
      onJAccountAdded()
    })
  }

  const getSubmitError = () => {}

  return (
    <Modal onClose={onClose}>
      <ModalContainer>
        <Form
          onSubmit={onSubmit}
          validate={onValidate}
          initialValues={initialValues}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} style={{ textAlign: 'center' }}>
              <TextField fullWidth label="Client ID" name="clientId" />
              <TextField fullWidth label="Client Secret" name="clientSecret" />
              <TextField fullWidth label="Customer ID" name="customerId" />

              <FormSpy
                subscription={{
                  submitting: true,
                  pristine: true,
                  valid: true,
                  dirtySinceLastSubmit: true,
                  hasValidationErrors: true,
                  submitErrors: true,
                  error: true,
                  errors: true,
                  submitFailed: true,
                }}
              >
                {({ valid, submitFailed, submitErrors }) => {
                  const submitError = getSubmitError(submitErrors?.error)

                  return (
                    <>
                      <div>
                        {!valid && submitFailed && (
                          <Alert
                            type="error"
                            title={t(submitError.title)}
                            description={t(submitError.description)}
                          />
                        )}
                      </div>

                      <ActionWrapper>
                        <Button color="primary" bold onClick={onClose}>
                          Cancel
                        </Button>

                        <Button
                          color="primary"
                          bold
                          disabled={!valid}
                          onClick={handleSubmit}
                        >
                          {t('Connect')}
                        </Button>
                      </ActionWrapper>
                    </>
                  )
                }}
              </FormSpy>
            </form>
          )}
        />
      </ModalContainer>
    </Modal>
  )
}

const ActionWrapper = styled.div`
  display: flex;

  column-gap: 20px;
  justify-content: space-between;
`
const ModalContainer = styled.div`
  width: 800px;
  max-width: 90vw;
  padding: 30px;
  background-color: white;
  border-radius: 5px;
`
