import { createAsyncThunk } from '@reduxjs/toolkit'
import { api } from 'src/modules/api'

export const loadStats = createAsyncThunk(
  'reports/loadStats',
  async ({ buildingId, floorId }, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `buildings/${buildingId}/floors/${floorId}/stats`
      )
      if (response.ok) {
        return response.json.stats
      }

      return rejectWithValue(response.statusText)
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)
