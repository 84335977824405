import styled from 'styled-components/macro'

export const TextareaInput = styled.textarea`
  resize: none;
  ${props =>
    props.variant === 'old' &&
    `
    background: #ffffff;
    box-shadow: 0px 11.0052px 25.6788px
      rgba(129, 129, 129, 0.04);
    border-radius: 3.66839px;
    font-size: 14px;
    color: #344356;
    padding: 20px;
    border: none;
    box-shadow: 0px 11.0052px 25.6788px
      rgba(129, 129, 129, 0.04);

    &:focus {
      outline: 1px solid #ceceff;
    }

    &::placeholder {
      color: #9aaccf;
    }
  `}

  ${props =>
    props.variant === 'new' &&
    `
    width: 100%;
    border-radius: 4px;
    font-size: 12px;
    line-height: 12px;
    background: #ffffff;
    color: #9aaccf;
    border: none;
    box-shadow: none;

    &:focus {
      outline: none;
    }
    &::placeholder {
      color: #9aaccf;
    }
  `}

  ${props =>
    props.variant === 'outlined' &&
    `
    width: 100%;
    height: 60px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 12px;
    background: #ffffff;
    color: #9aaccf;
    border: 1px solid #CDD6E9;
    padding-left: 15px;
    box-shadow: none;

    &:focus {
      outline: none;
    }
    &::placeholder {
      color: #9aaccf;
    }
  `}

  ${props =>
    props.fullWidth &&
    `
      width: 100%;
  `}

  ${props =>
    props.invalid &&
    `
      border: 1px solid red;
  `}
  ${props =>
    props.size === 'small' &&
    `
    padding: 4px;
  `}
`

TextareaInput.defaultProps = {
  variant: 'old',
}
