import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { useParams } from 'react-router-dom'

import { CreateCustom } from 'src/pages/buildings/Engagements/components/custom/CreateCustom'
import { Engagement } from 'src/pages/buildings/Engagements/components/Engagement'
import { Empty } from 'src/pages/buildings/Engagements/components/Empty'
import { CreateCouponModal } from 'src/pages/buildings/Engagements/components/coupon/CreateCouponModal'
import { CreateUrl } from 'src/pages/buildings/Engagements/components/url/CreateUrl'

function ActionComponent(props) {
  const {
    actionScheme,
    trigger,
    actions,
    triggersHook,
    setTrigger,
    selectedBeacons,
    setSelectedBeacons,
    updateMarkers,
  } = props
  const params = useParams()
  const { triggerId } = params
  const { getTrigger, isLoading: isTriggerLoading } = triggersHook

  const [activeTrigger, setActiveTrigger] = useState()
  const [info, setInfo] = useState(false)
  const [showCreate, setShowCreate] = useState()

  const [currentItem, setCurrentItem] = useState()

  const loadTriggers = useCallback(async () => {
    const triggerResult = await getTrigger(trigger.id)
    if (triggerResult?.trigger) {
      setCurrentItem(triggerResult.trigger)
      selectedBeacons.splice(0, selectedBeacons.length)
      selectedBeacons.push(...triggerResult.trigger.beacon_ids)
      setSelectedBeacons(selectedBeacons)

      setActiveTrigger(triggerResult.trigger.event_type)
      setInfo({
        id: triggerResult.trigger.id,
        name: triggerResult.trigger.activity.name,
      })
      updateMarkers()
    }
  }, [
    getTrigger,
    selectedBeacons,
    setSelectedBeacons,
    trigger.id,
    updateMarkers,
  ])

  useEffect(() => {
    if (
      trigger?.activity?.scheme === actionScheme &&
      trigger?.id === +triggerId
    ) {
      loadTriggers()
    }
  }, [trigger, triggerId, actionScheme])

  const handleShow = () => {
    setShowCreate(!showCreate)
  }

  const handleCreate = currentTrigger => {
    setTrigger(currentTrigger)
    setInfo({
      id: currentTrigger.id,
      name: currentTrigger.activity_attributes.name,
    })
    handleShow()
  }

  return (
    <>
      {!info && !showCreate && (
        <Empty
          label={actionScheme}
          actions={actions}
          create={handleShow}
          isLoading={isTriggerLoading}
        />
      )}
      {!!info && (!showCreate || actionScheme === 'coupon') && (
        <Engagement
          activeTrigger={activeTrigger}
          setActiveTrigger={setActiveTrigger}
          itemType={actionScheme}
          info={info}
          setInfo={setInfo}
          setShowCreate={setShowCreate}
          handleShow={handleShow}
          selectedBeacons={selectedBeacons}
          updateMarkers={updateMarkers}
          currentItem={currentItem}
          setTrigger={setTrigger}
          setCurrentItem={setCurrentItem}
          triggersHook={triggersHook}
        />
      )}

      {showCreate && (
        <>
          {actionScheme === 'custom' && (
            <CreateCustom
              trigger={currentItem}
              onClose={handleShow}
              triggersHook={triggersHook}
              handleCreate={handleCreate}
              setCurrentItem={setCurrentItem}
            />
          )}
          {actionScheme === 'coupon' && (
            <CreateCouponModal
              trigger={currentItem}
              onClose={handleShow}
              triggersHook={triggersHook}
              handleCreate={handleCreate}
              setCurrentItem={setCurrentItem}
            />
          )}
          {actionScheme === 'url' && (
            <CreateUrl
              trigger={currentItem}
              onClose={handleShow}
              handleCreate={handleCreate}
              triggersHook={triggersHook}
              setCurrentItem={setCurrentItem}
            />
          )}
        </>
      )}
    </>
  )
}

export const Action = styled(ActionComponent)``
