import { useCallback, useState } from 'react'

import { api } from 'src/modules/api'
import { useApplications } from 'src/modules/applications'

export function useBeaconsImport() {
  const { applicationId } = useApplications()
  const [isLoading, setIsLoading] = useState(false)

  const get = useCallback(
    async props => {
      const { page = 0, size = 100, search } = props || {}
      setIsLoading(true)

      let requestUrl = `applications/${applicationId}/beacon_imports?page[size]=${size}&page[number]=${
        page + 1
      }`

      if (search) {
        requestUrl = `${requestUrl}&q=${search}`
      }

      const res = await api.get(requestUrl)

      setIsLoading(false)

      return res
    },
    [applicationId]
  )

  const create = useCallback(
    async data => {
      setIsLoading(true)
      const res = await api.post(
        `applications/${applicationId}/beacon_imports`,
        data
      )

      setIsLoading(false)

      return res
    },
    [applicationId]
  )

  const update = useCallback(async (importId, data) => {
    setIsLoading(true)
    const res = await api.patch(`beacon_imports/${importId}`, data)
    setIsLoading(false)

    return res
  }, [])

  const getImport = useCallback(async importId => {
    setIsLoading(true)
    const res = await api.get(`beacon_imports/${importId}`)
    setIsLoading(false)

    return res
  }, [])

  return {
    isLoading,
    get,
    create,
    update,
    getImport,
  }
}
