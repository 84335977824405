import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'

import { api } from 'src/modules/api'
import { parallelRequests } from 'src/utils/api'

export function useTriggers() {
  const { buildingId } = useParams()
  const [isLoading, setIsLoading] = useState(false)

  const get = useCallback(async () => {
    setIsLoading(true)

    const requestUrl = `buildings/${buildingId}/triggers/beacon?page[size]=100`
    const res = await api.get(requestUrl)

    const totalPages = res.headers.get('X-Pager-Total-Pages')

    let result = res.ok ? res?.json.triggers : []

    if (totalPages > 1) {
      const data = await parallelRequests(requestUrl, totalPages - 1)
      result = result.concat(data)
    }

    setIsLoading(false)

    return result
  }, [buildingId])

  const create = useCallback(
    async data => {
      const res = await api.post(
        `buildings/${buildingId}/triggers/beacon`,
        data
      )

      return res?.json || res
    },
    [buildingId]
  )

  const update = useCallback(async (triggerId, data) => {
    setIsLoading(true)
    const res = await api.put(`triggers/${triggerId}`, data)
    setIsLoading(false)

    return res?.json || res
  }, [])

  const getTrigger = useCallback(async triggerId => {
    setIsLoading(true)
    const res = await api.get(`triggers/${triggerId}`)
    setIsLoading(false)

    return res?.json
  }, [])

  const remove = useCallback(async triggerId => {
    setIsLoading(true)
    const res = await api.del(`triggers/${triggerId}`)
    setIsLoading(false)

    return res
  }, [])

  return {
    isLoading,
    get,
    create,
    update,
    remove,
    getTrigger,
  }
}
