import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { AuthPageWrapper, PageContentWrapper } from 'src/pages/PageTemplate'
import { useAuth } from 'src/modules/auth'

export const ConfirmAccount = props => {
  const { confirmAccount } = useAuth()
  const params = useParams()

  useEffect(() => {
    const sendConfirmationRequest = async () => {
      try {
        const res = await confirmAccount(params.verificationCode)

        if (res?.json?.error?.validations) {
          return res.json.error.validations
        }
        window.location.href = '/'
      } catch ({ error }) {
        return { error: t('Something went wrong') }
      }

      return false
    }

    if (/confirm-account/.test(window.location.pathname)) {
      sendConfirmationRequest()
    }
  }, [])

  return (
    <AuthPageWrapper className={props.className}>
      <PageContentWrapper>loading...</PageContentWrapper>
    </AuthPageWrapper>
  )
}
