import React from 'react'
import types from 'creditcards-types'
import styled from 'styled-components'

import Visa from 'src/assets/images/svg/credit-cards/visa.svg'
import VisaInline from 'src/assets/images/svg/credit-cards/inline/visa.svg'

import Mastercard from 'src/assets/images/svg/credit-cards/master-card.svg'
import MastercardInline from 'src/assets/images/svg/credit-cards/inline/mastercard.svg'
import Mada from 'src/assets/images/svg/credit-cards/mada.svg'

const cardTypeIcons = {
  visa: Visa,
  master: Mastercard,
  mastercard: Mastercard,
  mada: Mada,
}

const cardTypeInlineIcons = {
  visa: VisaInline,
  master: MastercardInline,
  mastercard: MastercardInline,
  mada: Mada,
}

function checkIsMada(cardNubmer) {
  return /588845|440647|440795|446404|457865|968208|457997|474491|636120|417633|468540|468541|468542|468543|968201|446393|409201|458456|484783|968205|462220|455708|410621|588848|455036|968203|486094|486095|486096|504300|440533|489318|489319|445564|968211|410685|406996|432328|428671|428672|428673|968206|446672|543357|434107|407197|407395|412565|431361|604906|521076|588850|968202|529415|535825|543085|524130|554180|549760|968209|524514|529741|537767|535989|536023|513213|520058|558563|585265|588983|588982|589005|508160|531095|530906|532013|605141|968204|422817|422818|422819|428331|483010|483011|483012|589206|968207|419593|439954|530060|531196|420132/.test(
    cardNubmer.replace(/\s+/g, '')
  )
}

export function getCreditCardType(creditCard = '') {
  const cardNumber = creditCard.replace(/\*/g, '0')
  const cardType = checkIsMada(cardNumber)
    ? 'mada'
    : types.find(type => type.test(cardNumber, true))?.name?.toLocaleLowerCase()

  return cardTypeIcons?.[cardType] && cardType
}

export function getCreditCardIcon(creditCard) {
  const cardType = getCreditCardType(creditCard)

  return <CreditCardType type={cardType} />
}

export function CreditCardType({ type, inline = false, className }) {
  if (!type) {
    return <></>
  }

  return (
    <CardTypeStyled
      className={className}
      logo={inline ? cardTypeIcons[type] : cardTypeInlineIcons[type]}
    />
  )
}

const CardTypeStyled = styled.div`
  width: 60px;
  height: 58px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center center;
  ${props =>
    props.logo &&
    `
      background-image: url(${props.logo});  
  `}
`
export function parsedExpiryDate(card) {
  return card.info?.expiry_date
    ? card.info?.expiry_date
        .match(/.{1,2}/g)
        .reverse()
        .join('/')
    : '01/01'
}
