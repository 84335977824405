import styled from 'styled-components/macro'

export const Title = styled.div`
  padding: 22px 16px 0 16px;
  font-size: 22px;
  line-height: 22px;
  letter-spacing: 0.25px;
  color: #344356;
`
export const Subtitle = styled(Title)`
  font-size: 18px;
`
