import React from 'react'
import styled from 'styled-components/macro'
import { Modal } from 'src/components/Modal'

export const ModalDialog = ({
  children,
  actions,
  title,
  description,
  onClose,
}) => {
  return (
    <Modal onClose={onClose}>
      <ModalContainer>
        {!!title && <Title>{title}</Title>}
        {!!description && <Description>{description}</Description>}
        {!!actions && <Actions>{actions}</Actions>}
        {children}
      </ModalContainer>
    </Modal>
  )
}

export const Title = styled.div`
  font-weight: 600;
  margin-bottom: 10px;
`

export const Description = styled.div`
  margin-bottom: 33px;
`

export const Actions = styled.div`
  text-align: center;
`

const ModalContainer = styled.div`
  background: white;
  border-radius: 5px;
  padding: 17px 23px;
`
