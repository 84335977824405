import React, { useMemo } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import 'src/assets/scss/main.scss'
import { Beacons } from 'src/pages/Beacons'
import { BuildingIntegration } from 'src/pages/buildings/BuildingIntegration'
import { Applications } from 'src/pages/Applications'
import { MapBoxTemplate } from 'src/components/MapBoxTemplate'
import { MapBox } from 'src/components/MapBox'
import { Buildings } from 'src/pages/buildings'
import { Building } from 'src/pages/buildings/Building'
import { MapStudio } from 'src/pages/MapStudio'
import { MapFloorResizeStudio } from 'src/pages/MapStudio/MapFloorResizeStudio'
import { MapStudioProvider as MapStudioController } from 'src/pages/MapStudio/controllers/MapStudioController'
import { FloorStudio } from 'src/pages/MapStudio/FloorStudio'
import { FloorResize } from 'src/pages/MapStudio/FloorResize'
import { useApplications } from 'src/modules/applications'
import { useCompany } from 'src/modules/company'
import { MapProvider } from 'src/modules/map'
import { SideBar } from 'src/components/SideBar'
import { Engagements } from 'src/pages/buildings/Engagements'
import { Poi } from 'src/pages/Poi'
import { BeaconsImports } from 'src/pages/BeaconsImports'
import { useAuth } from 'src/modules/auth'
import { ErrorPage } from 'src/components/ErrorPage'
import { CompaniesPage } from 'src/pages/Companies'
import { Reports } from 'src/pages/Reports'

export function App() {
  const { user, isLoading: isUserLoading } = useAuth()
  const { isLoading, company } = useCompany()
  const { applicationId, isLoading: isApplicationsLoading } = useApplications()

  const redirectUrl = useMemo(() => {
    if (isLoading || !isApplicationsLoading || isUserLoading) return false

    if (!applicationId) {
      return !user?.saas_company_id || !company?.id
        ? '/companies'
        : '/applications'
    }

    return '/companies'
  }, [
    applicationId,
    company?.id,
    isApplicationsLoading,
    isLoading,
    isUserLoading,
    user?.saas_company_id,
  ])

  function renderBuildings() {
    return (
      <MapProvider>
        <MapBoxTemplate>
          <SideBar>
            <Buildings />
          </SideBar>
          <MapBox />
        </MapBoxTemplate>
      </MapProvider>
    )
  }

  function renderBuilding() {
    return (
      <MapProvider>
        <MapBoxTemplate>
          <SideBar>
            <Building />
          </SideBar>
          <MapBox />
        </MapBoxTemplate>
      </MapProvider>
    )
  }

  function renderEngagements() {
    return (
      <MapProvider>
        <MapBoxTemplate>
          <MapStudioController>
            <SideBar>
              <Engagements />
            </SideBar>
            <MapBox />
          </MapStudioController>
        </MapBoxTemplate>
      </MapProvider>
    )
  }

  function renderBuildingIntegration() {
    return (
      <MapProvider>
        <MapBoxTemplate>
          <SideBar>
            <BuildingIntegration />
          </SideBar>
          <MapBox />
        </MapBoxTemplate>
      </MapProvider>
    )
  }
  function renderReports() {
    return (
      <MapProvider>
        <MapBoxTemplate>
          <MapStudioController>
            <Reports>
              <MapBox />
            </Reports>
          </MapStudioController>
        </MapBoxTemplate>
      </MapProvider>
    )
  }
  function renderFloor() {
    return (
      <MapProvider>
        <MapBoxTemplate>
          <MapStudioController>
            <SideBar>
              <FloorStudio />
            </SideBar>
            <MapStudio />
          </MapStudioController>
        </MapBoxTemplate>
      </MapProvider>
    )
  }
  function renderFloorResize(buildProps) {
    return (
      <MapFloorResizeStudio {...buildProps}>
        <FloorResize {...buildProps} />
      </MapFloorResizeStudio>
    )
  }

  return (
    <Switch>
      <Route exact path="/applications" component={Applications} />

      <Route exact path="/buildings/new" render={renderBuilding} />
      <Route
        exact
        path={['/beacons', '/buildings/:buildingId/beacons']}
        component={Beacons}
      />

      <Route path="/companies" component={CompaniesPage} />

      <Route
        exact
        path={[
          '/beacons/imports',
          '/beacons/imports/:beaconsImportId',
          '/buildings/:buildingId/beacons/imports',
          '/buildings/:buildingId/beacons/imports/:beaconsImportId',
        ]}
        component={BeaconsImports}
      />
      <Route
        exact
        path="/buildings/:buildingId/integration"
        render={renderBuildingIntegration}
      />
      <Route
        exact
        path="/buildings/:buildingId/floors/:floorId/engagements/:triggerId"
        render={renderEngagements}
      />
      <Route
        exact
        path="/buildings/:buildingId/floors/:floorId/engagements/"
        render={renderEngagements}
      />
      <Route
        exact
        path="/buildings/:buildingId/floors/:floorId/reports"
        render={renderReports}
      />
      <Route
        exact
        path="/buildings/:buildingId/floors/:floorId/pois"
        component={Poi}
      />
      <Route
        exact
        path="/buildings/:buildingId/floors/:floorId/edit"
        render={renderFloorResize}
      />
      <Route
        path="/buildings/:buildingId/floors/:floorId"
        render={renderFloor}
      />
      <Route path="/buildings/:buildingId/floors" render={renderBuilding} />
      <Route exact path="/buildings/:buildingId" render={renderBuilding} />

      <Route exact path="/buildings" render={renderBuildings} />

      {!isLoading && redirectUrl && (
        <Redirect from="/" exact to={redirectUrl} />
      )}

      {!isLoading && !redirectUrl && (
        <Route path="*">
          <ErrorPage code="404" />
        </Route>
      )}
    </Switch>
  )
}
