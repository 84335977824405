import React from 'react'
import styled from 'styled-components'

import { Label } from 'src/ui/form/FormField'

export const RadioGroup = props => {
  const { list, name, onChange, defaultValue } = props

  return list.map(item => {
    const key = `${name}${item.value}`

    return (
      <LabelStyled htmlFor={key} key={key}>
        <input
          type="radio"
          id={`${name}${item.value}`}
          name={name}
          value={item.value}
          checked={item.value === defaultValue}
          onChange={onChange}
        />
        {item.name}
      </LabelStyled>
    )
  })
}

const LabelStyled = styled(Label)`
  cursor: pointer;
`
