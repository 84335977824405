export default [
  [email => email, 'Please complete email'],
  [
    email =>
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      ),
    'Provided email is incorrect',
  ],
]
