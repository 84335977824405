import React, { useCallback, useState } from 'react'
import { Form, FormSpy } from 'react-final-form'
import styled from 'styled-components'
import { useCookies } from 'react-cookie'
import { toast } from 'react-toastify'

import { StyledLink } from 'src/components/Link'
import { TextField } from 'src/components/TextField'
import { PasswordField } from 'src/components/PasswordField'
import { Scroller } from 'src/components/Scroller'
import { AuthPageWrapper, PageContentWrapper } from 'src/pages/PageTemplate'

import { Button, Card, InvitationMessage } from 'src/ui'

import { useAuth } from 'src/modules/auth'
import validate from 'src/utils/validate'
import isEmail from 'src/utils/validators/isEmail'
import isTermsRequired from 'src/utils/validators/isTermsRequired'
import { CheckboxUI } from 'src/ui/form/Checkbox'
import { TermsAndPrivacy } from 'src/ui/TermsAndPrivacy'

import { useConfirmInvitation } from 'src/hooks/useConfirmInvitations'
import isPassword from 'src/utils/validators/isPassword'
import { INVITATION_CODE_KEY } from 'src/constants'

import { SuccessMessage } from './SuccessMessage'

const initialValues = {
  email: '',
  first_name: '',
  last_name: '',
  password: '',
  password_confirmation: '',
  terms: false,
}

export const SignUp = () => {
  const { signUp, isLoading } = useAuth()
  const [isSuccess, setIsSuccess] = useState(false)
  const [registerSuccess] = useState(false)
  const [cookies] = useCookies([INVITATION_CODE_KEY])
  const invitationCode = cookies[INVITATION_CODE_KEY]

  const {
    sendConfirmationRequest,
    cleanInvitationCode,
  } = useConfirmInvitation()

  const url = new URL(window.location.href)
  const urlSearch = new URLSearchParams(url.search)
  const userSubscriptionPlan = urlSearch.get('plan')

  const onValidate = useCallback(values => {
    const errors = {}

    errors.email = validate(values.email, isEmail)
    errors.password = validate(values.password, isPassword)
    errors.terms = validate(values.terms, isTermsRequired)

    if (!values.password_confirmation) {
      errors.password_confirmation = 'Please confirm password'
    }

    if (values.password_confirmation !== values.password) {
      errors.password_confirmation = 'Password mismatch'
    }

    return errors
  }, [])

  const onSubmit = async values => {
    try {
      const userData = {
        ...values,
        saas_company_attributes: {},
        attributesFields: {
          saas_company: !!userSubscriptionPlan,
        },
      }
      if (userSubscriptionPlan) {
        userData.saas_company_attributes =
          {
            ...values.saas_company_attributes,
            plan_slug: userSubscriptionPlan,
          } || {}
        userData.attributesField = {
          saas_company: true,
        }
      }

      const res = await signUp(userData)

      setIsSuccess(!!res.ok)
      const errors = { ...res?.json?.error?.parsedValidations }

      if (errors) {
        if (errors.saas_company) {
          errors.saas_company_attributes = errors.saas_company
        }

        return errors
      }
    } catch ({ error }) {
      return { error: t('Something went wrong') }
    }

    return false
  }

  const onSubmitWithInvitation = async values => {
    const result = await sendConfirmationRequest({
      invitationCode,
      data: {
        email: values.email,
        first_name: values.first_name,
        last_name: values.last_name,
        password: values.password,
        password_confirmation: values.password_confirmation,
      },
    })

    if (result.ok) {
      toast.success('Invitation Accepted.')
      setIsSuccess(true)
    } else {
      if (/403/.test(result.status)) {
        toast.error(
          "You can't confirm this invitation. Please use another invitation link."
        )
        cleanInvitationCode()
      }

      return result?.json?.error?.invitee
    }
  }

  if (registerSuccess) return <SuccessMessage />

  return (
    <AuthPageWrapper>
      <Scroller>
        <PageContentWrapper style={{ textAlign: 'center' }}>
          <InvitationMessage />
          <Card
            width="500px"
            style={{
              textAlign: isSuccess ? 'center' : 'left',
            }}
          >
            {isSuccess ? (
              <SuccessMessage />
            ) : (
              <Form
                onSubmit={invitationCode ? onSubmitWithInvitation : onSubmit}
                validate={onValidate}
                initialValues={initialValues}
                render={({
                  handleSubmit,
                  errors,
                  hasValidationErrors: hasErrors,
                }) => (
                  <form onSubmit={handleSubmit}>
                    {userSubscriptionPlan && (
                      <TextField
                        fullWidth
                        label="Company Name"
                        name="saas_company_attributes[name]"
                        maxLength={255}
                      />
                    )}

                    <TextField
                      fullWidth
                      label="Email"
                      name="email"
                      maxLength={255}
                    />
                    <TextField
                      fullWidth
                      label="First Name"
                      name="first_name"
                      maxLength={255}
                    />

                    <TextField
                      fullWidth
                      label="Last Name"
                      name="last_name"
                      maxLength={255}
                    />
                    <PasswordField
                      fullWidth
                      label="Password"
                      name="password"
                      minLength={8}
                      maxLength={128}
                    />
                    <ConformPasswordField
                      fullWidth
                      label="Confirm Password"
                      name="password_confirmation"
                      minLength={8}
                      maxLength={128}
                    />

                    <CheckboxStyled
                      name="terms"
                      label={
                        <>
                          {' '}
                          I agree to the Nearmotion{' '}
                          <a
                            href="https://nearmotion.com/terms-and-conditions/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Terms and Conditions
                          </a>
                          {' and '}
                          <a
                            href="https://nearmotion.com/privacy-policy/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Privacy policy
                          </a>
                        </>
                      }
                      inputProps={{
                        name: 'terms',
                        type: 'checkbox',
                        error: hasErrors && errors.terms,
                      }}
                    />

                    <FormSpy
                      subscription={{
                        submitting: true,
                        pristine: true,
                        valid: true,
                        dirtySinceLastSubmit: true,
                        hasValidationErrors: true,
                        submitFailed: true,
                      }}
                    >
                      {({
                        submitFailed,
                        dirtySinceLastSubmit,
                        hasValidationErrors,
                      }) => (
                        <SignUpButton
                          color="primary"
                          width="100%"
                          bold
                          size="lg"
                          loading={isLoading?.toString()}
                          disabled={
                            hasValidationErrors ||
                            (submitFailed && !dirtySinceLastSubmit)
                          }
                          onClick={handleSubmit}
                        >
                          {t('Sign Up')}
                        </SignUpButton>
                      )}
                    </FormSpy>

                    <StyledLink
                      to="/sign-in"
                      style={{
                        marginTop: 20,
                        width: '100%',
                        textAlign: 'right',
                      }}
                    >
                      {t('I have an account')}
                    </StyledLink>
                    <TermsAndPrivacy />
                  </form>
                )}
              />
            )}
          </Card>
        </PageContentWrapper>
      </Scroller>
    </AuthPageWrapper>
  )
}

const ConformPasswordField = styled(PasswordField)`
  margin-bottom: 12px;
`
const CheckboxStyled = styled(CheckboxUI)`
  margin: 0;
  label {
    margin-left: 0;
  }
`

const SignUpButton = styled(Button)`
  margin-top: 35px;
`
