import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Form, FormSpy } from 'react-final-form'
import styled from 'styled-components/macro'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

import { Button, AccordionItem } from 'src/ui'
import { Link, StyledLink } from 'src/components/Link'
import { TextField } from 'src/components/TextField'
import { ImageUploaderField } from 'src/components/ImageUploaderField'

import { EditFloor } from 'src/pages/buildings/Building/components/EditFloor'
import { useBuildings } from 'src/modules/buildings'
import validate from 'src/utils/validate'
import { Alert } from 'src/components/Alert'
import { useFloors } from 'src/modules/floors'

import { ConfirmationModal } from 'src/components/ConfirmationModal'

import { ReactComponent as LinkImageComponent } from 'src/assets/images/svg/link.svg'
import { ReactComponent as EditImageComponent } from 'src/assets/images/svg/edit.svg'
import { ReactComponent as TrashImageComponent } from 'src/assets/images/svg/icon-trash-bin.svg'
import { AddBeaconsImportsModal } from 'src/components/BeaconsImports'
import { useBeaconsImport } from 'src/hooks/useBeaconsImport'
import { useAbility } from 'src/hooks/useAbility'
import isRequired from 'src/utils/validators/isRequired'

import { getJAccount } from 'src/redux/slicers/jibestream'
import { getJBuilding } from 'src/redux/slicers/jibestream/selectors'
import { useCompany } from 'src/modules/company'

const stopPropagation = e => {
  e.stopPropagation()
  e.preventDefault()
}

function BuildingComponent({
  latitude,
  longitude,
  building = {},
  setShowConnectJibestram,
  setShowAddJBuilding,
  loadBuilding,
  setShowAddFloor,
  initialValues,
  address,
  loadJBuilding,
  setSyncJFloors,
}) {
  const {
    building: { update: canUpdate },
    floor: { manage },
  } = useAbility()

  const params = useParams()
  const { company } = useCompany()

  const { floors: floorsInBuilding, remove: removeFloor } = useFloors()

  const history = useHistory()

  const { create, update } = useBuildings()
  const beaconsImportHook = useBeaconsImport()

  const jAccount = useSelector(getJAccount)
  const jBuilding = useSelector(getJBuilding)

  const buildingId = +params.buildingId
  const isNew = !buildingId

  const jBuildingId = building.jibestream_id || jBuilding.jibestream_id

  const hasJBuildingConnection = Boolean(
    building?.jibestream_id || jBuilding?.jibestream_id
  )

  const canAddJBuilding =
    !hasJBuildingConnection &&
    (jAccount?.clientId || (jAccount?.clientId && !building?.jibestream_id))

  const canSyncJFloors = Boolean(hasJBuildingConnection && buildingId)

  const [formInitials, setFormInitials] = useState(initialValues)
  const [isAddCsv, setIsAddCsv] = useState()

  const canAddJibestream = company?.jibestream && isNew && !jBuildingId

  const onValidate = values => {
    const errors = {}
    errors.name = validate(values.name, isRequired)
    errors.address = validate(values.address, isRequired)
    errors.photo = validate(values.photo, [
      [photo => !!photo || !values.isNew, 'Please add photo'],
    ])

    errors.floors = validate(values.floors, isRequired)

    return errors
  }

  const onSubmit = async values => {
    let res
    if (buildingId) {
      const buildingToSend = {
        ...values,
        coordinate_attributes: {
          latitude: building?.coordinate?.latitude || latitude,
          longitude: building.coordinate?.longitude || longitude,
        },
      }

      if (values.photo) {
        if (values.photo?.match(/^http.*/)) {
          delete buildingToSend.photo
        } else {
          buildingToSend.photo = values.photo
        }
      }

      res = await update(buildingId, buildingToSend)

      if (res.ok) {
        await loadBuilding()
      }
    } else {
      const updatedBuilding = {
        ...values,
      }

      if (!values.basement_floors) {
        delete updatedBuilding.basement_floors
      }

      res = await create({
        ...updatedBuilding,
        coordinate_attributes: jBuilding.jibestream_id
          ? jBuilding.coordinate
          : {
              latitude,
              longitude,
            },
      })
    }

    if (res.ok && !buildingId) {
      history.push(`/buildings/${res.json.building.id}`)
    }

    if (res?.json?.error?.message) {
      return res.json.error.message
    }

    if (res?.json?.error?.parsedValidations) {
      return res.json.error.parsedValidations
    }

    if (!res.ok) {
      return { error: res.status }
    }
  }

  const handleAddFloor = useCallback(
    event => {
      setShowAddFloor(true)
      stopPropagation(event)
    },
    [setShowAddFloor]
  )

  const [editFloorModalState, setEditFloorModalState] = useState(false)
  const closeEditFloorModal = useCallback(
    () => setEditFloorModalState(false),
    []
  )

  const deleteFloor = useCallback(
    async floorId => {
      const result = await removeFloor(floorId)
      if (result.ok) {
        setEditFloorModalState(false)
      } else {
        toast.error(result?.json?.error?.message || 'Something went wrong')
      }

      return result
    },
    [removeFloor]
  )

  const handleFormChange = form => {
    setFormInitials(form.values)
  }

  const importFloors = async event => {
    stopPropagation(event)
    setSyncJFloors(true)
    if (!jBuilding.floors) {
      await loadJBuilding()
    }
  }

  const sortedFloors = useMemo(
    () =>
      hasJBuildingConnection ? floorsInBuilding.reverse() : floorsInBuilding,
    [floorsInBuilding, hasJBuildingConnection]
  )

  useEffect(() => {
    if (address && address !== formInitials.address && isNew) {
      setFormInitials({ ...formInitials, address })
    }
  }, [address, formInitials, isNew])

  useEffect(() => {
    if (
      jBuilding?.jibestream_id &&
      !formInitials.jibestream_id &&
      jBuilding?.floors?.length
    ) {
      const hasBasementFloor = jBuilding.floors.filter(
        item =>
          +item.shortName < 0 || item.name.includes('Lower') || item.level < 0
      )

      // eslint-disable-next-line camelcase
      const basement_floors = hasBasementFloor.length
      // eslint-disable-next-line camelcase
      const floors = jBuilding.floors.length - basement_floors

      const updatedForm = {
        ...formInitials,
        jibestream_id: `${jBuilding.jibestream_id}`,
        coordinate: jBuilding.coordinate,
        basement_floors,
        floors,
      }
      if (jBuilding.name) {
        updatedForm.name = jBuilding.name
      }

      setFormInitials(updatedForm)
    }
  }, [jBuilding?.jibestream_id])

  useEffect(() => {
    if (jAccount.customerId && !formInitials.jibestream_customer_id) {
      const updatedForm = {
        ...formInitials,
        jibestream_customer_id: jAccount.customerId,
        jibestream_client_id: jAccount.clientId,
        jibestream_client_secret: jAccount.clientSecret,
      }

      setFormInitials(updatedForm)
    }
  }, [jAccount?.clientId])

  useEffect(() => {
    if (initialValues.photo && initialValues.photo !== formInitials.photo) {
      setFormInitials({
        ...formInitials,
        photo: initialValues.photo,
      })
    }
  }, [initialValues?.photo])

  return (
    <Container>
      <Form
        onSubmit={onSubmit}
        validate={onValidate}
        initialValues={formInitials}
        render={({ handleSubmit, values }) => {
          let availableFloors
          if (hasJBuildingConnection) {
            availableFloors =
              floorsInBuilding.length !==
              +values.floors + +values.basement_floors
          } else {
            availableFloors = Array.from(
              { length: +values.floors },
              (v, k) => k
            )
              .filter(item => !!item)
              .reverse()
              .concat(['G'])
              .concat(
                Array.from(
                  { length: +values.basement_floors },
                  (v, k) => `-${k + 1}`
                )
              )

            if (floorsInBuilding.length) {
              availableFloors = availableFloors.some(floorName => {
                const isExist = floorsInBuilding.find(floorItem => {
                  return String(floorItem.name) === String(floorName)
                })

                return !isExist
              })
            }
          }

          const canAddNewFloor = isNew || !availableFloors

          const isFloorCountUpdated =
            +values.basement_floors !== +building.basement_floors ||
            +values.floors !== +building.floors

          const isDirty =
            (!isNew && values.jibestream_id !== building.jibestream_id) ||
            (!isNew &&
              values.jibestream_client_id !== building.jibestream_client_id) ||
            isFloorCountUpdated ||
            (values?.photo && values?.photo !== building?.photo?.medium) ||
            (building.name && building.name !== values.name) ||
            building.address !== values.address

          return (
            <form onSubmit={handleSubmit}>
              <AccordionItem
                title={
                  buildingId ? 'Edit your building' : 'Add your new building'
                }
              >
                <TextField
                  variant="old"
                  isNew={isNew}
                  fullWidth
                  label="Building Name"
                  name="name"
                  placeholder="-"
                  maxLength={255}
                />
                <TextField
                  variant="old"
                  isNew={isNew}
                  fullWidth
                  label="Address"
                  name="address"
                  placeholder="-"
                />

                {canAddJibestream && !canAddJBuilding && (
                  <Button
                    color="primary"
                    width="100%"
                    onClick={event => {
                      stopPropagation(event)
                      setShowConnectJibestram(true)
                    }}
                  >
                    Connect Jibestream
                  </Button>
                )}
                {canAddJBuilding && (
                  <Button
                    color="primary"
                    onClick={event => {
                      stopPropagation(event)
                      setShowAddJBuilding(true)
                    }}
                  >
                    Add Jibestream Building ID
                  </Button>
                )}

                {hasJBuildingConnection && (
                  <JibestreamConnected>
                    <div>
                      <JIcon /> Jibestream Venue ID <b>{jBuildingId}</b>
                    </div>
                  </JibestreamConnected>
                )}
              </AccordionItem>
              <AccordionItem title="Building photo">
                <ImageUploaderField
                  name="photo"
                  defaultPhoto={building.photo}
                />
              </AccordionItem>
              {!isNew && (
                <AccordionItem title="Add Beacons">
                  <AddABeacon>Import Beacons</AddABeacon>
                  <Button
                    color="primary"
                    onClick={event => {
                      stopPropagation(event)
                      setIsAddCsv(true)
                    }}
                  >
                    Upload Beacons CSV
                  </Button>

                  <OpenBeacons
                    color="secondary"
                    to={`/${
                      buildingId ? `buildings/${buildingId}/` : ''
                    }beacons`}
                  >
                    Open beacons list
                  </OpenBeacons>
                </AccordionItem>
              )}

              <AccordionItem title="Number of floors">
                <Storeys>
                  <TextField
                    variant="old"
                    isNew={isNew}
                    fullWidth
                    label="Basement floors"
                    min={0}
                    type="number"
                    name="basement_floors"
                    placeholder="0"
                    disabled={hasJBuildingConnection}
                  />
                  <TextField
                    variant="old"
                    isNew={isNew}
                    fullWidth
                    type="number"
                    label="Floors"
                    name="floors"
                    min={1}
                    placeholder="0"
                    disabled={hasJBuildingConnection}
                  />
                </Storeys>
              </AccordionItem>

              {!!floorsInBuilding.length && (
                <AccordionItem title="Floors">
                  {sortedFloors.map(floor => {
                    const link = `/buildings/${buildingId}/floors/${floor.id}${
                      floor.configured ? '' : '/edit'
                    }`

                    return (
                      <FloorLink key={floor.id}>
                        <FloorName>{floor.name}</FloorName>
                        <div>
                          {manage && (
                            <>
                              {hasJBuildingConnection && <JIconFloor />}
                              {!hasJBuildingConnection && (
                                <>
                                  <EditButton
                                    onClick={() =>
                                      setEditFloorModalState(floor.id)
                                    }
                                  />
                                  <ConfirmationModal
                                    title="This will remove the floor from the database. Are you sure?"
                                    iconTitle="This will remove the floor from the database. Are you sure?"
                                    description="This action cannot be undone"
                                    onConfirm={deleteFloor}
                                    id={floor.id}
                                    component={RemoveButton}
                                  />
                                </>
                              )}
                            </>
                          )}

                          <Link to={link}>
                            <LinkImage />
                          </Link>
                        </div>
                      </FloorLink>
                    )
                  })}
                </AccordionItem>
              )}
              <FormSpy onChange={handleFormChange} />
              <FormSpy>
                {({
                  errors,
                  submitFailed,
                  submitErrors,
                  hasValidationErrors,
                }) => {
                  const coordinateError =
                    errors?.coordinate?.[0] ||
                    errors?.coordinate ||
                    submitErrors?.coordinate?.[0] ||
                    submitErrors?.coordinate
                  const baseError =
                    errors?.base?.[0] ||
                    errors?.base ||
                    submitErrors?.base?.[0] ||
                    submitErrors?.base

                  return (
                    <>
                      <div style={{ padding: 10 }}>
                        {!!baseError && (
                          <Alert type="error" title={t(baseError)} />
                        )}
                        {submitErrors && !baseError && !coordinateError && (
                          <Alert type="error" title={t(submitErrors)} />
                        )}
                        {!!coordinateError && (
                          <Alert
                            type="error"
                            title={`${'Coordinate'} ${t(coordinateError)}`}
                          />
                        )}
                      </div>

                      {canSyncJFloors && (
                        <ActionsContainer>
                          <Button
                            width="100%"
                            color="primary"
                            onClick={importFloors}
                          >
                            Synchronize Floors
                          </Button>
                          <Button
                            width={canSyncJFloors ? '100%' : 'auto'}
                            size="large"
                            color="primary"
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={
                              !isDirty || hasValidationErrors || submitFailed
                            }
                          >
                            Save Changes
                          </Button>
                        </ActionsContainer>
                      )}
                      {canUpdate && !canSyncJFloors && (
                        <Actions>
                          <Button
                            disabled={canAddNewFloor}
                            size="large"
                            color="primary"
                            variant="contained"
                            onClick={handleAddFloor}
                          >
                            New Floor
                          </Button>

                          <Button
                            width={canSyncJFloors ? '100%' : 'auto'}
                            size="large"
                            color="primary"
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={
                              !isDirty ||
                              (!hasJBuildingConnection && hasValidationErrors)
                            }
                          >
                            Save Changes
                          </Button>
                        </Actions>
                      )}
                    </>
                  )
                }}
              </FormSpy>
            </form>
          )
        }}
      />

      {isAddCsv && (
        <AddBeaconsImportsModal
          beaconsImportHook={beaconsImportHook}
          onClose={() => {
            setIsAddCsv(false)
          }}
        />
      )}
      {!!editFloorModalState && !hasJBuildingConnection && (
        <EditFloor
          onClose={closeEditFloorModal}
          floorId={editFloorModalState}
        />
      )}
    </Container>
  )
}

export const BuildingForm = styled(BuildingComponent)``

const OpenBeacons = styled(StyledLink)`
  display: block;
  margin-top: 14px;
`

const Container = styled.div`
  padding-bottom: 25px;
  input {
    border: 1px solid #5468ff;
    &:disabled {
      border-color: #34435642;
    }
  }
`
const Storeys = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
`

const EditButton = styled(EditImageComponent)`
  cursor: pointer;
  margin-left: auto;
  margin-right: 17px;
`
const LinkImage = styled(LinkImageComponent)``
const RemoveButton = styled(TrashImageComponent)`
  cursor: pointer;
  margin-right: 12px;
`

const FloorLink = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 22px;
`
const JibestreamConnected = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
`
const FloorName = styled.span``

const Actions = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: space-around;
`

const ActionsContainer = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  gap: 20px;
`

const AddABeacon = styled.p`
  padding: 0;
  font-size: 14px;
  line-height: 14px;
  color: #344356;
`
const JIcon = styled.div`
  position: relative;
  display: inline-block;
  width: 21px;
  height: 18px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFwAAABcCAMAAADUMSJqAAAASFBMVEX////UOTbfUE3/9vXZU1DRHRjzwcDRJiL75OPxysn429rXQD3eaGX87+/urKvpmZfcXlvqlZPhdnTniYfwt7bkfnvfb2zOAACOeZFfAAADJklEQVRoge2Y6a6kIBCFQdBCFlFb+77/m06xud5komAyyXh+2J2281EcqgqUkFevXr36HyXZc2gN/UNo86GU8kdCZ10FT8Hb3qEfgYsxoB+AM1tT+hB8okAfgiezaXnPV7Mrq8vCmVWLHxMxdUl4w1PYqlINYQXhWOsp6qoqCzfzkn4K2RUtB2cd7NCKQjH41mwfNi0Gl+Me7TOmDNwMlB7DLgXv1DlsLYcS8PZsNn5ticiHi+82s6PZ+FECfkq/+K0A3HzPjoRvkA0XyW21dSTUTy5cViezF29y4WbL3qPz4f0h7LXqAXLhH9iHvXpjyQR58LZelnIXNlWYhTIzcr5l7xIxH97UR/biTTac9clwtVoTbYdceHA8WLGzXfkszIN/kikH22P9ZMFD/QTY0fZsuITFFbWyY8Hmwn2jVSnwxRqAEvAxWr4ZAa98GHgBuN66EkeALs4pE874Al+tmdOkMuFmC48jIM1wjhUAufDqCK9oLYj8+RFEAMgA95frR+jfbMEoSYe2Y7dlGL4Sbg7ucnlB+3PkVHsCNh30xgLyOqgMjoX/vPYErbepGPsAaIFbtgaYsB/DSMgMON7g4PoSPLUWtctz4HgacFjp8xJgiGF8LsGbc4V+Z/CyeBvHNETWuJ4MQlVdkTj1FjTadMPQGTcvqDFwNISF9aTiEjzsFVtLVgAeHuFLXJZ/4i5+zXKXDqd+bgVDCasANMY/unz3BRH6wgUtvqztFoBrzd2R5eNHd+777gnXXCGxLy773MxaDVG6DTOrUiVfyxUnuez+7rNxk5nsOA6NDxMfkBTzH9T1hcsKB660S0zbWxPazk1cGLgeOE55Pc1R9JlbafzP0rpKmtnyXHCDHQvJ023neLTqte79SL2zvUt73i3ZQFeYxqzpaVpQ1TcYNhsC295jx/VC+eBEY2etZ9v69YtPeLcM39PhK/e/t+nGeJ+NKRPfUtTKtib8ZFqbHnnr254ETelpDitTuQVV8fCSsj9LYn17s5dvMNlaX4Vs0X122EHYV9SOD0q3JV/2TbM/Kjq/1Tzd6CZ/kZEtSpRw+tWrV6/+Vf0BndEmGznfLrUAAAAASUVORK5CYII=');
  background-size: 100%;
`
const JIconFloor = styled(JIcon)`
  width: 16px;
  height: 15px;
  margin-right: 10px;
`
