import React, { useCallback, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie'

import { useAuth } from 'src/modules/auth'
import { useConfirmInvitation } from 'src/hooks/useConfirmInvitations'
import { toast } from 'react-toastify'
import { INVITATION_CODE_KEY } from 'src/constants'

export const ConfirmInvitation = () => {
  const [, setCookie] = useCookies([INVITATION_CODE_KEY])
  const { sendConfirmationRequest } = useConfirmInvitation()
  const { isAuthenticated, user } = useAuth()
  const { invitationCode } = useParams()
  const history = useHistory()

  const onLoad = useCallback(async () => {
    if (invitationCode) {
      if (isAuthenticated) {
        const result = await sendConfirmationRequest({
          invitationCode,
          data: {
            email: user.email,
            first_name: user.first_name,
            last_name: user.last_name,
          },
        })

        if (!result.ok) {
          if (/403|422/.test(result.status)) {
            toast.error(
              "You can't confirm this invitation, please use another account."
            )
          } else {
            toast.error(result?.json?.error?.message || result?.statusText)
          }
          history.push('/companies')
        } else {
          window.location.href = '/companies'
        }
      } else {
        setCookie(INVITATION_CODE_KEY, invitationCode, {
          path: '/',
          secure: true,
          sameSite: 'lax',
          expires: new Date(+new Date() + 1.8e6),
        })
        history.push('/sign-in')
      }
    }
  }, [
    history,
    setCookie,
    invitationCode,
    isAuthenticated,
    sendConfirmationRequest,
    user?.email,
    user?.first_name,
    user?.last_name,
  ])

  useEffect(onLoad, [onLoad])

  return <div />
}
