import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import 'mapbox-gl/dist/mapbox-gl.css'

import { useMapResizeStudio } from 'src/modules/map-floor-resize-studio'
import { useFloors } from 'src/modules/floors'
import { Button } from 'src/ui'

function MapComponent(props) {
  const {
    currentMap,
    setMapContainer,
    setMapStyle,
    defaultMapStyle,
  } = useMapResizeStudio()

  const mapContainer = useRef()
  const { isLoading: isFloorsLoading } = useFloors()

  const [switchSatellite, setSwitchSatellite] = useState(false)

  const handleSwitchMapStyle = () => {
    setSwitchSatellite(!switchSatellite)

    setMapStyle(
      new URL(
        !switchSatellite ? 'mapbox/satellite-v9' : defaultMapStyle,
        'mapbox://styles/'
      ).href
    )
  }

  useEffect(() => {
    if (mapContainer.current && !currentMap && !isFloorsLoading) {
      setMapContainer(mapContainer.current)
    }
  }, [currentMap, isFloorsLoading, mapContainer, setMapContainer])

  return (
    <Wrapper className={props.className}>
      <Container id="map" className="map-container" ref={mapContainer} />
      <Actions>
        <ViewButton onClick={handleSwitchMapStyle}>
          {switchSatellite ? 'Satellite' : 'Street'} <br /> view
        </ViewButton>
      </Actions>
      <div id="geocoder" className="geocoder" />
    </Wrapper>
  )
}

export const MapResizeStudioBox = styled(MapComponent)``

const Wrapper = styled.div`
  position: fixed;
  left: 344px;
  width: calc(100vw - 344px);
  height: calc(100vh - 72px);

  #map {
    height: 100%;
    width: 100%;
  }
`
const Container = styled.div`
  display: flex;
  position: relative;
  background: white;
  overflow: hidden;
`

const Actions = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 8;
`
const ViewButton = styled(Button)`
  background: white;
  padding: 10px 20px;
  width: 90px;
  height: auto;
`
