import React from 'react'
import { Field } from 'react-final-form'

import { Wrapper, Label, Error } from 'src/ui/form/FormField'

export const CheckboxUI = props => {
  const { name, label, inputProps, error, className } = props

  return (
    <Field name={name} type="checkbox">
      {({ input, meta }) => (
        <Wrapper className={className}>
          {label && (
            <Label>
              <input
                {...input}
                type="checkbox"
                disabled={inputProps.disabled}
              />
              {label}
            </Label>
          )}

          {(meta.error || meta.submitError) &&
            meta.touched &&
            (meta.error || error) && <Error>{inputProps.error || error}</Error>}
        </Wrapper>
      )}
    </Field>
  )
}
