import { useCallback, useEffect, useState } from 'react'

import { useMap } from 'src/modules/map'

import { useBeacons as useBeaconsHook } from 'src/hooks/useBeacons'

export const useBeacons = props => {
  const { selectedBeacons } = props
  const [markersOptions, setMarkersOptions] = useState()
  const [isLoaded, setIsLoaded] = useState(false)

  const { map: currentMap, isMapLoaded } = useMap()
  const { fetchBeacons } = useBeaconsHook()

  const handleMarkerClick = useCallback(
    event => {
      const beaconId = +event.target.dataset.id
      event.target.classList.toggle('active')
      const markerIndex = selectedBeacons.findIndex(item => item === beaconId)
      if (markerIndex === -1) {
        selectedBeacons.push(beaconId)
      } else {
        selectedBeacons.splice(markerIndex, 1)
      }
    },
    [selectedBeacons]
  )

  const createMarker = useCallback(
    beacon => {
      // Create a DOM element for each marker.
      const el = document.createElement('div')
      el.className = 'beacon-marker'
      el.dataset.id = beacon.id

      // Add markers to the map.
      return new window.mapboxgl.Marker(el)
        .setLngLat([beacon.longitude, beacon.latitude])
        .addTo(currentMap)
    },
    [currentMap]
  )

  const loadMarkers = useCallback(async () => {
    const result = await fetchBeacons({ loadAll: true, size: 500 })
    const addMarkers = async beacons => {
      const markers = beacons.map(beacon => {
        const marker = createMarker(beacon)

        return {
          marker,
          beacon,
        }
      })
      await setMarkersOptions(markers)
    }

    addMarkers(result || [])
  }, [createMarker, fetchBeacons])

  useEffect(() => {
    if (isMapLoaded && !markersOptions?.length) {
      loadMarkers()
    }
  }, [isMapLoaded, loadMarkers, markersOptions?.length])

  const updateMarkers = useCallback(() => {
    markersOptions.forEach(item => {
      const element = item.marker.getElement()
      element.classList[
        selectedBeacons && selectedBeacons.some(id => id === item.beacon.id)
          ? 'add'
          : 'remove'
      ]('active')
    })
  }, [markersOptions, selectedBeacons])

  const removeListeners = useCallback(() => {
    markersOptions?.forEach(item => {
      const element = item.marker.getElement()
      element.removeEventListener('click', handleMarkerClick)
    })
  }, [handleMarkerClick, markersOptions])

  useEffect(() => {
    return removeListeners()
  }, [removeListeners])

  const reduceMarkersOptions = useCallback(() => {
    if (markersOptions?.length && !isLoaded) {
      markersOptions.forEach(item => {
        const element = item.marker.getElement()
        // add listener to mark beacon active,
        // for some reason the event listener lost the context if add the listener when the marker element is just created
        element.addEventListener('click', handleMarkerClick, false)
      })
      setIsLoaded(true)
    }
  }, [handleMarkerClick, isLoaded, markersOptions])

  useEffect(() => {
    reduceMarkersOptions()
  }, [
    selectedBeacons,
    markersOptions,
    handleMarkerClick,
    removeListeners,
    reduceMarkersOptions,
  ])

  return {
    markersOptions,
    updateMarkers,
  }
}
