import React from 'react'
import styled from 'styled-components/macro'

// const colors = {
//   info: '#65BBFCFF',
//   success: '#3DBC91FF',
//   warning: '#FDAA4AFF',
//   error: '#EB5954FF',
// }

// const useStyles = makeStyles(theme =>
//   createStyles({
//     container: {
//       padding: '15px 30px 15px 15px',
//       borderRadius: 4,
//     },
//     hasIcon: {
//       paddingLeft: 40,
//     },
//     header: {
//       position: 'relative',
//       marginBottom: 5,
//     },
//     title: {
//       fontWeight: 600,
//     },
//     icon: {
//       position: 'absolute',
//       right: '100%',
//       marginRight: 10,
//     },
//     body: {},
//     black: {
//       color: theme.palette.text.primary,
//     },
//     ...Object.entries(colors).reduce(
//       (acc, color) => ({
//         ...acc,
//         [color[0]]: {
//           backgroundColor: `${hexToRgb(color[1].replace(/FF$/, '0d'))}`,
//           color: `${color[1]}`,
//           border: '1px solid transparent',
//         },
//         [`outlined-${color[0]}`]: {
//           backgroundColor: '#fff',
//           borderColor: color[1],
//         },
//       }),
//       {}
//     ),
//     shadowed: {
//       boxShadow: '0 20px 60px -20px rgba(39, 52, 109, 0.1)',
//       backgroundColor: 'transparent',
//       borderColor: 'transparent',
//     },
//   })
// )

/**
 * @typedef {object} Props
 * @property {(info | success | warning | error)} type
 * @property {string} title
 * @property {string} description
 * @property {boolean} [hasIcon]
 * @property {Symbol} [icon]
 * @property {boolean} [outlined]
 * @property {boolean} [shadowed]
 *
 * @param {Props}
 */
const AlertStyled = ({
  type,
  title,
  description,
  // className,
  hasIcon = true,
  // icon = null,
  // outlined = false,
  // shadowed = false,
  className,
}) => {
  return (
    <AlertComponent type={type} className={className}>
      {title && (
        <Title>
          {hasIcon && (
            <span>{/* {icon || <ExclamationTriangleFillIcon />} */}</span>
          )}
          <div variant="caption">{title}</div>
        </Title>
      )}
      <Description>{description}</Description>
    </AlertComponent>
  )
}

export const Alert = styled(AlertStyled)``

const AlertComponent = styled.div`
  margin-bottom: 20px;
  font-weight: bold;
  padding: 15px;
  border-radius: 5px;

  ${props =>
    props.type === 'error' &&
    `
      background-color: #ef2323;
      color: white;
  `}

  ${props =>
    props.type === 'success' &&
    `
      background-color: #a3ff5d40;
      color: black;
  `}
`

const Title = styled.div`
  font-size: 17px;
`

const Description = styled.div`
  font-size: 13px;
`
