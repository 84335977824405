import styled from 'styled-components/macro'
import { space, layout } from 'styled-system'

export const Wrapper = styled.div`
  margin-bottom: 35px;
  text-align: left;
  ${space}
  ${layout}

  ${props =>
    props.variant === 'new' &&
    `
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
  `}

  ${props =>
    props.variant === 'outlined' &&
    `
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
  `}
`

export const Label = styled.label`
  display: inline-block;
  margin-bottom: 16px;
  margin-left: 22px;
  font-size: 14px;
  letter-spacing: -0.508684px;
  color: black;
  font-weight: 600;

  ${p =>
    p.variant === 'new' &&
    `
    margin-left: 0;
  `}

  ${p =>
    p.variant === 'outlined' &&
    `
    margin-left: 0;
  `}
`

export const Error = styled.div`
  color: red;
  margin-top: 10px;
  margin-left: 10px;
  font-size: 12px;

  &:first-letter {
    text-transform: uppercase;
  }
`
