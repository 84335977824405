import React from 'react'
import styled from 'styled-components/macro'
import { Link as RouterLink } from 'react-router-dom'
import useRouter from 'src/hooks/useRouter'

export const Link = ({
  to,
  name,
  params,
  query,
  ...props
}) => {
  // { name: 'User', params: { userId: 123 }, query: { xxx: 'yyy' } }
  const { getRoutePath } = useRouter()

  return (
    <RouterLink
      {...props}
      to={getRoutePath({ name: name || to, params, query })}
    />
  )
}

export const StyledLink = styled(Link)`
  &,
  &:visited {
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    font-size: 13px;

    :hover {
      text-decoration: underline;
    }
  }
`
