import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import Select from 'react-select'
import { useParams, useHistory } from 'react-router-dom'

import { Button, Loader } from 'src/ui'
import { Modal } from 'src/components/Modal'
import { useFloors } from 'src/modules/floors'
import { customSelectStyles } from 'src/ui/form/SelectInput'

import { useSelector } from 'react-redux'
import { getJBuilding } from 'src/redux/slicers/jibestream/selectors'

function AddJFloorComponent(props) {
  const {
    onClose,
    loadJBuilding,
    isJFloorLoading,
    setIsJFloorLoading,
    buildingId: selectedBuildingId,
  } = props
  const { buildingId } = useParams()
  const history = useHistory()

  const { floors, get, setJFloor } = useFloors()

  const jBuilding = useSelector(getJBuilding)

  const [floor, setFloor] = useState('')
  const [statusText, setStatusText] = useState('')

  const selectedFloor = jBuilding?.floors?.find(item => +item?.id === +floor)

  let options = jBuilding?.floors?.map(floorItem => {
    return {
      label: floorItem.name,
      value: floorItem.id,
    }
  })

  if (floors?.length) {
    options = options?.filter(item => {
      const isExist = floors.find(floorItem => {
        return +floorItem.jibestream_id === item.value
      })

      return !isExist
    })
  }

  async function onSubmit() {
    setIsJFloorLoading(true)
    setStatusText('Importing Floors & Layers...')

    if (floor && selectedFloor.id) {
      const result = await setJFloor({
        floor: selectedFloor,
        buildingId,
      })

      if (result.ok) {
        await get(buildingId)
        history.push(
          `/buildings/${buildingId}/floors/${result.json.building_floor.id}`
        )
        onClose()
      }
      setIsJFloorLoading(false)
    }
  }

  function handleChange({ value }) {
    setFloor(value)
  }

  useEffect(() => {
    if (!isJFloorLoading) {
      setStatusText('')
    }
  }, [isJFloorLoading])

  useEffect(() => {
    setIsJFloorLoading(!jBuilding?.floors)
  }, [jBuilding.floors])

  useEffect(() => {
    if (!jBuilding?.floors?.length || selectedBuildingId !== +buildingId) {
      setStatusText('Importing Building Parameters...')
      loadJBuilding()
    }
  }, [])

  return (
    <AddAppWrapper onClose={onClose} className={props.className}>
      <AddAppContainer style={{ minWidth: '600px' }}>
        Floor number:
        <SelectComponent
          styles={customSelectStyles}
          closeMenuOnSelect="true"
          placeholder="Floor"
          isMulti={false}
          isSearchable={false}
          options={options}
          defaultValue={{ label: floor }}
          onChange={handleChange}
        />
        {statusText && <p>{statusText}</p>}
        <ButtonsContainer triple>
          <Button color="primary" onClick={onSubmit} mt="14px">
            Add floor
          </Button>
          <Button color="primary" onClick={onClose} mt="14px">
            Cancel
          </Button>
        </ButtonsContainer>
      </AddAppContainer>
      {isJFloorLoading && <Loader />}
    </AddAppWrapper>
  )
}

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  grid-gap: 20px;
  ${p =>
    p.triple &&
    `
    grid-template-columns: 1fr 1fr 1fr;
  `}
`

const AddAppWrapper = styled(Modal)``

const AddAppContainer = styled.div`
  padding: 20px;
  margin-top: 43px;
  box-shadow: 0 9.08776px 14.3889px rgba(60, 128, 209, 0.05);
  border-radius: 10px;
  border: 10px solid white;
  background: #fafbff;
  display: flex;
  flex-direction: column;
`

const SelectComponent = styled(Select)`
  position: relative;
  margin: 20px 0;
  z-index: 1001;
`

export const AddJFloor = styled(AddJFloorComponent)``
