import { useCallback, useState } from 'react'
import { api } from 'src/modules/api'
import { useParams } from 'react-router-dom'
import { parallelRequests } from 'src/utils/api'

export const useBeacons = () => {
  const { buildingId, floorId } = useParams()
  const [isLoading, setIsLoading] = useState(false)

  const createBeacon = useCallback(
    async values => {
      return api.post(
        `buildings/${buildingId || values.building_id}/beacons`,
        values
      )
    },
    [buildingId]
  )

  const editBeacon = useCallback(async values => {
    return api.patch(`beacons/${values.id}`, values)
  }, [])

  const deleteBeacon = useCallback(async id => {
    return api.del(`beacons/${id}`)
  }, [])

  const fetchBeacons = useCallback(
    async props => {
      const { page = 0, size = 100, search, loadByBuildId, loadAll } =
        props || {}
      setIsLoading(true)

      let requestUrl = `beacons?page[size]=${size}&page[number]=${page + 1}`

      if (floorId && !loadByBuildId) {
        requestUrl = `buildings/${buildingId}/floors/${floorId}/${requestUrl}`
      } else if (buildingId) {
        requestUrl = `buildings/${buildingId}/${requestUrl}`
      }
      if (search) {
        requestUrl = `${requestUrl}&q=${search}`
      }

      const res = await api.get(requestUrl)

      const totalPages = res.headers.get('X-Pager-Total-Pages')

      let result = res.ok && res.json.beacons
      if (loadAll) {
        if (totalPages > 1) {
          const data = await parallelRequests(
            requestUrl,
            totalPages - 1,
            'beacons'
          )
          result = result.concat(data)
        }
        setIsLoading(false)

        return result
      }

      // res.ok && setBeacons(result)
      setIsLoading(false)

      return res
    },
    [buildingId, floorId]
  )

  return {
    isLoading,
    fetchBeacons,
    createBeacon,
    editBeacon,
    deleteBeacon,
  }
}
