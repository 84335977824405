import { useCallback, useState } from 'react'

import { api } from 'src/modules/api'
import { useParams } from 'react-router-dom'

export function useHeatmapApi() {
  const { buildingId, floorId } = useParams()
  const [isLoading, setIsLoading] = useState(true)

  const get = useCallback(
    async props => {
      const { startDate, endDate } = props
      setIsLoading(true)

      let requestUrl = `buildings/${buildingId}/floors/${floorId}/heatmap`

      if (startDate && endDate) {
        requestUrl += `?from_date=${startDate}&to_date=${endDate}`
      }
      const res = await api.get(requestUrl)

      setIsLoading(false)

      return res?.json
    },
    [buildingId, floorId]
  )

  return {
    isLoading,
    get,
  }
}
