import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { useHistory, useParams } from 'react-router-dom'

import { AccordionItem, Button } from 'src/ui'

import { ReactComponent as MarkerImageComponent } from 'src/assets/images/svg/marker.svg'
import { triggersOptions } from 'src/pages/buildings/Engagements/components/defaults'
import { Alert as AlertComponent } from 'src/components/Alert'
import { ModalDialog } from 'src/components/ModalDialog'
import { useAbility } from 'src/hooks/useAbility'

function EngagementComponent(props) {
  const { buildingId, floorId } = useParams()
  const {
    itemType,
    activeTrigger,
    setActiveTrigger,
    info,
    setShowCreate,
    setInfo,
    selectedBeacons,
    handleShow,
    setTrigger,
    currentItem,
    setCurrentItem,
    triggersHook,
    updateMarkers,
  } = props
  const [formError, setFormError] = useState()
  const [beaconError, setBeaconError] = useState()
  const [triggerError, setTriggerError] = useState()

  const {
    create: createTrigger,
    update: updateTrigger,
    remove: removeTrigger,
  } = triggersHook
  const history = useHistory()
  const [showConfirmation, setShowConfirmation] = useState()

  const {
    engagement: { update: canUpdate, manage },
  } = useAbility()

  function handleCancel() {
    setShowConfirmation(false)
  }

  const unselectTrigger = async () => {
    delete selectedBeacons.splice(0, selectedBeacons.length)
    setTrigger(false)
    setCurrentItem(false)
    setActiveTrigger(false)
    setInfo(false)
    updateMarkers()
    setShowCreate(false)
  }

  const handleDelete = async () => {
    await removeTrigger(info.id)
    await unselectTrigger()
    history.replace(`/buildings/${buildingId}/floors/${floorId}/engagements/`)
  }

  async function handleBack() {
    await unselectTrigger()
    history.push(`/buildings/${buildingId}/floors/${floorId}/engagements`)
  }

  async function handleSubmit() {
    if (!canUpdate) return

    try {
      if (!selectedBeacons.length) {
        return setBeaconError('Please select the beacons on the map')
      }
      if (!activeTrigger) {
        return setTriggerError('Please select the trigger')
      }

      const activity = currentItem.activity_attributes || currentItem.activity
      const coupon = activity.coupon_attributes || activity.coupon
      // eslint-disable-next-line camelcase
      const custom_attributes =
        activity.custom_attributes_attributes || activity.custom_attributes

      const { scheme, name } = activity

      const updatedTrigger = {
        event_type: activeTrigger,
        beacon_ids: selectedBeacons,
        activity_attributes: {
          scheme,
          name,
        },
      }

      if (scheme === 'coupon') {
        updatedTrigger.activity_attributes.coupon_attributes = {
          description: coupon.description,
          name: coupon.name,
          template: coupon.template,
          title: coupon.title,
          button_label: coupon.button_label,
        }
        if (coupon.encoding_type) {
          updatedTrigger.activity_attributes.coupon_attributes.encoding_type =
            coupon.encoding_type
        }
        if (!coupon?.image?.url && coupon?.image) {
          updatedTrigger.activity_attributes.coupon_attributes.image =
            coupon.image
        }

        if (!coupon?.logo?.url && coupon?.logo) {
          updatedTrigger.activity_attributes.coupon_attributes.logo =
            coupon.logo
        }
        if (coupon.unique_identifier_number) {
          updatedTrigger.activity_attributes.coupon_attributes.unique_identifier_number =
            coupon.unique_identifier_number
        }
        if (coupon.identifier_number) {
          updatedTrigger.activity_attributes.coupon_attributes.identifier_number =
            coupon.identifier_number
        }

        if (coupon.button_font_color) {
          updatedTrigger.activity_attributes.coupon_attributes.button_font_color =
            coupon.button_font_color
        }

        if (coupon.button_background_color) {
          updatedTrigger.activity_attributes.coupon_attributes.button_background_color =
            coupon.button_background_color
        }

        if (coupon.button_link) {
          updatedTrigger.activity_attributes.coupon_attributes.button_link =
            coupon.button_link
        }
      } else if (scheme === 'url') {
        // eslint-disable-next-line camelcase
        const { url } = currentItem.activity_attributes || custom_attributes
        updatedTrigger.activity_attributes.url = url
      } else {
        // eslint-disable-next-line camelcase
        updatedTrigger.activity_attributes.custom_attributes_attributes = custom_attributes
      }

      const result = currentItem.id
        ? await updateTrigger(currentItem.id, updatedTrigger)
        : await createTrigger(updatedTrigger)

      if (result?.error) {
        return setFormError(t(result?.json?.error?.message))
      }
      if (!result) {
        return setFormError(t(result.status))
      }

      if (!currentItem.id && result?.trigger?.id) {
        setTrigger(result.trigger)
        setCurrentItem(result.trigger)

        setInfo({
          id: result.trigger.id,
          name: result.trigger.activity.name,
        })
        await setActiveTrigger(result.trigger.event_type)
        history.push(
          `/buildings/${buildingId}/floors/${floorId}/engagements/${result.trigger.id}`
        )
      }

      setFormError(false)
      setTriggerError(false)
      setBeaconError(false)
    } catch (error) {
      return setFormError(t('Something went wrong'))
    }
  }

  return (
    <>
      <AccordionItem title="Engagement">
        <Title>Selected {itemType}s</Title>
        <Items>
          <ItemElement
            size="large"
            color="primary"
            variant="contained"
            onClick={() => handleShow()}
          >
            {info.name}
          </ItemElement>
        </Items>
        {formError && <Alert type="error" title={t(formError)} />}
        <Hr />
        <Title>Based on</Title>
        <Based>
          <Zone>Zone</Zone>
          <Beacon>
            <MarkerImageComponent />
            Beacon
          </Beacon>
        </Based>
        {beaconError && <Alert type="error" title={t(beaconError)} />}
        <Hr />
        <Title>Trigger</Title>
        <Triggers>
          {triggersOptions.map(option => (
            <Trigger
              key={option.value}
              size="large"
              onClick={() => setActiveTrigger(option.value)}
              color={option.value === activeTrigger ? 'primary' : 'secondary'}
            >
              {option.label}
            </Trigger>
          ))}
        </Triggers>
        {triggerError && <Alert type="error" title={t(triggerError)} />}
      </AccordionItem>

      <Actions>
        <Button
          size="large"
          color="primary"
          variant="contained"
          onClick={handleBack}
        >
          Back
        </Button>
        {info.id && (
          <Delete
            disabled={!manage}
            onClick={() => manage && setShowConfirmation(true)}
          >
            Delete
          </Delete>
        )}
        <Button
          disabled={!canUpdate}
          size="large"
          color="primary"
          onClick={handleSubmit}
        >
          Save
        </Button>
      </Actions>

      {!!showConfirmation && (
        <ModalDialog
          title="Remove Trigger"
          description="This action will remove the trigger, please confirm."
          actions={
            <>
              <Button mr="10px" color="secondary" onClick={handleCancel}>
                Cancel
              </Button>
              <Button color="primary" onClick={handleDelete}>
                Confirm
              </Button>
            </>
          }
        />
      )}
    </>
  )
}

export const Engagement = styled(EngagementComponent)``
const Title = styled.div`
  font-family: 'Biko';
  font-size: 14px;
  line-height: 14px;
  color: #344356;
`
const Items = styled.div`
  margin-top: 20px;
`
const ItemElement = styled(Button)`
  position: relative;
  display: block;
  max-width: 150px;
  padding: 0 18px;
  margin-right: 5px;
  margin-top: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const Based = styled.div`
  display: flex;
  margin-top: 20px;
`

const Zone = styled.div`
  display: flex;
  margin-right: 25px;
  align-items: center;
  opacity: 0.2;
  &:before {
    content: '';
    display: inline-block;
    margin-right: 8px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #adb7ff;
    border: 2px solid #5468ff;
  }
`
const Beacon = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }
`
const Triggers = styled.div`
  margin-top: 25px;
`
const Trigger = styled(Button)`
  padding: 0 18px;
  margin-right: 10px;
  margin-bottom: 10px;
`

const Hr = styled.hr`
  border-color: #eaf0f3;
  border-top-width: 0;
  margin: 15px 0;
`

const Actions = styled.div`
  margin: 25px 10px 0;
  display: flex;
  justify-content: space-between;

  button {
    padding: 0 30px;
  }
`
const Delete = styled(Button)`
  color: #ff6355;
`

const Alert = styled(AlertComponent)`
  background: white;
  margin: 0;
  padding: 15px 0 0 0;
  > div:first-of-type {
    font-size: 14px;
    background: white;
    color: #ff6355;
  }
`
