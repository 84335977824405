import isStrengthPassword from './isStrengthPassword'

export default [
  [password => password, 'Please complete password'],
  [
    password => password === password.trim(),
    'Password should not start/end with spaces',
  ],
  ...isStrengthPassword,
]
