import React from 'react'
import { FieldArray } from 'react-final-form-arrays'
import styled from 'styled-components'

import { AddSubtitle as Button } from 'src/ui/form/TagInput/TagsInputField'
import { Label } from 'src/ui/form/FormField'

const weekDay = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sun', 'Sat']

const fixedWeekValues = {
  "--/--": "Closed",
  "00/00": "24/7"
}

const fixedDayValues = {
  "--/--": "closed",
  "00/00": "24 hours a day"
}

export const PreviewWorkingHours = props => {
  const { handleOpenEditHoursModal } = props

  return (
    <Container>
      <FieldArray name="working_hours" {...props}>
        {({ fields }) => {
          return (
            <>
              <SubHeader>
                <Label>Working Hours</Label>
                {Boolean(!props.isView) && (
                  <Button onClick={handleOpenEditHoursModal}>+</Button>
                )}
              </SubHeader>

              {!Array.isArray(fields.value) && <Dummy>{fixedWeekValues[fields.value] || ""}</Dummy>}

              {Array.isArray(fields.value) &&
                fields?.map((hours, index) => {
                  return (
                    <CustomItem key={hours}>
                      <WeekDay>{weekDay[index]}:</WeekDay>

                      {Array.isArray(fields.value[index][0]) && (
                        <>
                          <Period>
                            {fields.value[index][0][0]}/
                            {fields.value[index][0][1]}
                          </Period>

                          {fields.value[index].length > 1 && (
                            <>
                              {fields.value[index].length > 2 ? <>…</> : <>–</>}

                              <Period>
                                {
                                  fields.value[index][
                                    fields.value[index].length - 1
                                  ][0]
                                }
                                /
                                {
                                  fields.value[index][
                                    fields.value[index].length - 1
                                  ][1]
                                }
                              </Period>
                            </>
                          )}
                        </>
                      )}

                      {!Array.isArray(fields.value[index][0]) &&
                        (fixedDayValues[fields.value[index]] || '')}
                    </CustomItem>
                  )
                })}
            </>
          )
        }}
      </FieldArray>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  border-bottom: 1px solid #e2ebffcf;
  padding-bottom: 16px;
  button,
  label {
    margin: 0;
  }

  input[type='time']::-webkit-calendar-picker-indicator {
    background: none;
    pointer-events: none !important;
    outline: none;
  }
`

const Period = styled.div`
  display: flex;
  gap: 16px;
  width: 85px;
`
const WeekDay = styled.div`
  width: 35px;
`
const SubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const CustomItem = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  ${Period} {
    input {
      pointer-events: none;
      border-bottom-color: transparent;
      box-shadow: none;
    }
  }
`

const Dummy = styled.div`
  display: flex;
  gap: 4px;
  font-family: sans-serif;
`
