import React, { useEffect, useState } from 'react'
import styled, { createGlobalStyle } from 'styled-components/macro'
import {
  Tab as TabComponent,
  Tabs,
  TabList as TabListComponent,
  TabPanel,
} from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import { useHistory, useParams } from 'react-router-dom'

import { useBeacons } from 'src/pages/buildings/Engagements/features/Beacons'
import { useTriggers } from 'src/hooks/useTriggers'
import { Loader } from 'src/ui'

import MarkerBeacon from 'src/assets/images/svg/marker-beacon.svg'
import MarkerBeaconActive from 'src/assets/images/svg/marker-beacon-active.svg'
import { useMapStudio } from 'src/pages/MapStudio/controllers/MapStudioController'
import { Action } from 'src/pages/buildings/Engagements/components/Action'

function EngagementComponent() {
  const params = useParams()
  const { triggerId, buildingId, floorId } = params
  const history = useHistory()

  const triggersHook = useTriggers()
  const { get: getTriggers, isLoading: isTriggersLoading } = triggersHook

  const [tabIndex, setTabIndex] = useState(0)
  const [selectedBeacons, setSelectedBeacons] = useState([])

  const [coupon, setCoupon] = useState(false)
  const [urlEngagement, setUrlEngagement] = useState(false)
  const [custom, setCustom] = useState(false)

  const [coupons, setCoupons] = useState()
  const [urlEngagements, setUrlEngagements] = useState()
  const [customs, setCustoms] = useState()

  const { floorAnglesMarkers } = useMapStudio()
  const { markersOptions, updateMarkers } = useBeacons({
    selectedBeacons,
  })

  const returnCoupon = triggerItem => triggerItem.activity.scheme === 'coupon'
  const returnUrl = triggerItem => triggerItem.activity.scheme === 'url'
  const returnCustom = triggerItem => triggerItem.activity.scheme === 'custom'

  const unselectTrigger = async () => {
    setCoupon(false)
    selectedBeacons.splice(0, selectedBeacons.length)
    updateMarkers()
    setUrlEngagement(false)
    setCustom(false)
  }

  useEffect(() => {
    return function cleanup() {
      if (markersOptions?.length) {
        markersOptions.forEach(item => {
          item.marker.remove()
        })
      }
    }
  }, [markersOptions])

  useEffect(() => {
    if (floorAnglesMarkers?.length) {
      floorAnglesMarkers?.forEach(item => item.remove())
    }
  }, [floorAnglesMarkers])

  useEffect(() => {
    const load = async () => {
      let result = await getTriggers()
      if (!result) return false

      // get all triggers according beacons on the floor
      result = result.filter(trigger =>
        trigger.beacon_ids.some(beaconId =>
          markersOptions.some(option => option.beacon.id === beaconId)
        )
      )

      if (result.length) {
        setCoupons(result.filter(returnCoupon))
        setUrlEngagements(result.filter(returnUrl))
        setCustoms(result.filter(returnCustom))
      }

      const filtered = result.filter(
        triggerItem => triggerItem.id === +triggerId
      )
      if (triggerId && filtered?.length) {
        setTabIndex(
          ['coupon', 'url', 'custom'].indexOf(filtered[0].activity.scheme)
        )
        setCoupon(filtered.find(returnCoupon))
        setUrlEngagement(filtered.find(returnUrl))
        setCustom(filtered.find(returnCustom))
      } else {
        setCoupon(false)
        setUrlEngagement(false)
        setCustom(false)
      }
    }
    if (markersOptions?.length) {
      load()
    }
  }, [triggerId, markersOptions, getTriggers])

  const handleTabSelect = async index => {
    await unselectTrigger()
    setTabIndex(index)
    history.push(`/buildings/${buildingId}/floors/${floorId}/engagements`)
  }

  return (
    <>
      <Container>
        <GlobalStyle />
        <Title>Engagement</Title>
        <Tabs selectedIndex={tabIndex} onSelect={handleTabSelect}>
          <TabList>
            <Tab>Coupon</Tab>
            <Tab>URL</Tab>
            <Tab>Custom</Tab>
          </TabList>
          <TabPanel>
            <Action
              actionScheme="coupon"
              trigger={coupon}
              actions={coupons}
              setTrigger={setCoupon}
              triggersHook={triggersHook}
              selectedBeacons={selectedBeacons}
              updateMarkers={updateMarkers}
              setSelectedBeacons={setSelectedBeacons}
            />
          </TabPanel>
          <TabPanel>
            <Action
              actionScheme="url"
              trigger={urlEngagement}
              actions={urlEngagements}
              setTrigger={setCoupon}
              triggersHook={triggersHook}
              updateMarkers={updateMarkers}
              selectedBeacons={selectedBeacons}
              setSelectedBeacons={setSelectedBeacons}
            />
          </TabPanel>
          <TabPanel>
            <Action
              actionScheme="custom"
              trigger={custom}
              actions={customs}
              setTrigger={setCoupon}
              triggersHook={triggersHook}
              updateMarkers={updateMarkers}
              selectedBeacons={selectedBeacons}
              setSelectedBeacons={setSelectedBeacons}
            />
          </TabPanel>
        </Tabs>
      </Container>
      {isTriggersLoading && <Loader />}
    </>
  )
}

export const Engagements = styled(EngagementComponent)``

const Title = styled.div`
  margin: 21px 0 38px 16px;
  font-weight: normal;
  font-size: 22px;
  line-height: 22px;
  color: #344356;
`

const Container = styled.div``
const Tab = styled(TabComponent)`
  padding-bottom: 5px;
  margin-right: 38px;
  font-family: Biko;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  color: #9aaccf;
  cursor: pointer;
  &.react-tabs__tab--selected {
    color: #344356;
    border-bottom: 3px solid #5468ff;
    pointer-events: none;
  }
`
const TabList = styled(TabListComponent)`
  display: flex;
  padding: 0;
  margin: 0 11px;
  justify-content: start;
  list-style: none;
`

const GlobalStyle = createGlobalStyle`
  .beacon-marker{
    width: 30px;
    height: 52px;
    background-size: 100% auto;
    background-image: url(${MarkerBeacon});
    background-repeat: no-repeat;
    background-position: left bottom;
    
  }
  .beacon-marker.active{
    background-image: url(${MarkerBeaconActive});  
  }
`
