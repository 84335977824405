import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'

import { renderFloorImage } from 'src/pages/MapStudio/controllers/renderFloorImage'
import { useBuildings } from 'src/modules/buildings'
import { useFloors } from 'src/modules/floors'

const MapStudioContext = createContext()

export function MapStudioResizeProvider(props) {
  const { building } = useBuildings()
  const { floor } = useFloors()

  const [isLoading] = useState(true)

  const [currentMap, setCurrentMap] = useState()
  const [currentDraw, setCurrentDraw] = useState(null)
  const [mapContainer, setMapContainer] = useState(null)
  const [isMapInited, setIsMapInited] = useState(false)
  const [isMapDrawLoaded, setIsMapDrawLoaded] = useState(false)
  const [buildingAngles, setBuildingAngles] = useState(false)
  const [floorMarkers, setFloorMarkers] = useState(false)
  const [defaultMapStyle] = useState('phpmax/cjgau24wv95cc2sqrbm0ugfak')
  const [mapStyle, setMapStyle] = useState(defaultMapStyle)

  const updatePlanImage = useCallback(
    (markers, map) => {
      if (!floorMarkers) {
        return false
      }

      const coordinates = floorMarkers.map(item => {
        const coords = item.getLngLat()

        return [+coords.lng, +coords.lat]
      })
      const src = (map || currentMap).getSource('nearmotion-floor-plan-image')
      if (!src) {
        renderFloorImage(
          {
            ...floor,
            coordinates: buildingAngles,
          },
          currentMap,
          floorMarkers,
          setFloorMarkers,
          'nearmotion-poly'
        )
      } else {
        src.coordinates = coordinates
        src.prepare()
        src.load()
      }
    },
    [floorMarkers, currentMap, floor, buildingAngles]
  )

  // init Draw for map
  useEffect(() => {
    if (!currentDraw && !isMapDrawLoaded) {
      const Draw = new MapboxDraw({
        displayControlsDefault: false,
        modes: {
          ...MapboxDraw.modes,
        },
        userProperties: true,
        clickBuffer: 10,
        touchBuffer: 10,
      })

      setCurrentDraw(Draw)
      setIsMapDrawLoaded(true)
    }
  }, [currentDraw, isMapDrawLoaded])

  useEffect(() => {
    if (floor?.id && buildingAngles && floorMarkers) {
      const source = currentMap.getSource('nearmotion-floor-plan-image')
      if (!source) {
        renderFloorImage(
          {
            ...floor,
            coordinates: buildingAngles,
          },
          currentMap,
          floorMarkers,
          setFloorMarkers,
          'nearmotion-poly'
        )
      } else {
        updatePlanImage()
      }
    }
  }, [currentMap, floorMarkers])

  // init Draw for map

  useEffect(() => {
    if (isMapInited && currentMap) {
      currentMap.setStyle(new URL(mapStyle, 'mapbox://styles/').href)
    }
  }, [isMapInited, mapStyle, currentMap])

  // start map initialization after all content is loaded
  useEffect(() => {
    if (mapContainer && !isMapInited && isMapDrawLoaded && building) {
      async function setMapInit() {
        await setIsMapInited(true)
        InitMap(building.coordinate)
      }
      setMapInit()
    }
  }, [mapContainer, isMapInited, isMapDrawLoaded, building])

  const InitMap = useCallback(
    async buildingCenter => {
      const { mapboxgl } = window

      let building_marker_lng
      let translatedPoly
      let building_marker_lat

      let building_flag = false
      let floor_Marker_1
      let floor_Marker_2
      let floor_Marker_3
      let floor_Marker_4
      let floor_Marker_rotate1
      let floor_Marker_rotate2
      let floor_Marker_scale1
      let floor_Marker_scale2
      let point1
      let point2
      let point3
      let point4
      let bulding_marker
      let translate

      // -------------------------------------------------- intializing map -----------------------------------------------------------------------

      mapboxgl.accessToken =
        'pk.eyJ1Ijoibm1hY2NvdW50cyIsImEiOiJja2xhazRobjgzbDkxMm9xb2d3YmQ3d2s2In0.wGFavxo8mpa7OI_lEhYUow'

      const map = new mapboxgl.Map({
        container: 'map',
        style: new URL(defaultMapStyle, 'mapbox://styles/').href,
        center: [buildingCenter.longitude, buildingCenter.latitude], // [50.198467337666415, 26.285754323806216],
        zoom: 13,
      })

      map.on('idle', e => {
        updatePlanImage()
      })
      map.on('style.load', e => {
        setFloorMarkers([
          floor_Marker_rotate1,
          floor_Marker_scale1,
          floor_Marker_rotate2,
          floor_Marker_scale2,
        ])
        // updatePlanImage()
      })

      // Address code !!!
      const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl,
        marker: false,
      })
      let a

      document.getElementById('geocoder').appendChild(geocoder.onAdd(map))
      geocoder.on('result', result => {
        a = result
        a.result.center[0]
        bulding_marker.setLngLat([a.result.center[0], a.result.center[1]])
        translate()
        building_marker_lng = bulding_marker.getLngLat().lng
        building_marker_lat = bulding_marker.getLngLat().lat
      })
      // Center of the map
      let center = map.getCenter()
      // initial building lmarker coordinates.
      building_marker_lng = map.getCenter().lng
      building_marker_lat = map.getCenter().lat
      // flag to check if user user select location

      // building marker icon add style.
      const building_marker_icon = document.createElement('div')
      building_marker_icon.style.width = '35px'
      building_marker_icon.style.height = '35px'
      building_marker_icon.style.backgroundSize = '35px 35px'
      building_marker_icon.style.backgroundRepeat = 'no-repeat'
      building_marker_icon.style.backgroundImage =
        "url('data:image/webp;base64,UklGRoAEAABXRUJQVlA4THQEAAAvP8APEJcnO5Jk14qyL1qDLfhvFV9qb60yAlIkSY4kr15F40gd9aMhX3uiAnIkSYokz15mQfa5+ot0T4ZKaLVtq1rWecK793y4uweAXnQiAiWIwU93vfL0FAGIvHtVj6529b6b7Znhok+94HVgEESHEYaEgDhkOFTwNOKPRBqQ8EYmGmgZnmhaYkWGA5MFonQGxQIalqE6MQTEITSmhg14qxQCQUUIVNpSoAjnP1QoBYItKRYpQlsCof+hQBECQRAowX+3JTDwz2DGtzGdONyNehGpaSb3jhpfCGlFfTTLXLNKZu/g4ORS02tt7dJ+Nk76ZJ/x8T8uyICFaSk96dP2F9cXD92x//3sfp0w2Cyc0M//KlbOH/XVWS/n+fafj7/w6/shj9dr8KK6u1q/PDJ/isNUjIWJGdXwWEgmLMiADTQo6H8IoowkNBKNi1HdMWRNxtcwa4h/kzIVWTnixptjxaSketY1oliggnGkyrlkOiPaMOhwI8liuI5BtqFm9MDgITY5llrUcOi+tvHykvlk/efILvliTmeCtcK8L/6XWBdfzSbezL/0/z9qu169OeX26OvqsuUvdxP3OJQfn6f+Z9/y29974+05egUkCABjSFLWm7V5vj3btm1f7e3Ztm3btm3bnrNtfqEq1anqD0T0fwKA/+e+s9cjYnLnjom4fmb/IrD+WHJh1M536ZVdy28hY+ZX9jxNRt4833wtORyF7IXeW3H0PhoMOW7B6iJoNNdOY/uD0fCDA4YWh6Fumer1p6amTq1fvbQOFvhoxCsBNSs0FpozymlgViNrkfbUFQ7reig8aWBRfqrYNOG4yWMq9i/fKSQfNReMzYoSuJ0vkghsIJRpPSaOHDmmf5pCzAwksrEdQrKqUHYfBMo5/RSiMoGvuTYSnjaKsUB7jVO08hAbuFKISkLuBdq9JVGRSGD6H0zUlzos0JvbXqpHhHrxfEC1J1UaDw7HSm0DVPiM5w1RWshDnAyTRCniGM8qopxiipP5irLEEZ49RAXFPycLFeWJXTwniLKKSU7mKcoSK3mOESUUo5yMUBQn3vN8IgJbSz2d9JFaIvmDB8JUWFdKG6o3PE2qQ9wB5utEWUkMmKUzuauQyxIZudYT2EgSnQZTs7sJuSGSa7heFiHKKETH0ZOkeRMGCrltGSL3Dy7IRGB1hRDtOvft26WdUFdD8jKwL6OCaqv0ayP9jg8SCQys5axmEHUVDL7ITSBWaKHXohzSDz6YgE0a6KkynWpa2YOaZ8DorxwaiFiyYo3atWv8KYna0X5mYK0e7w4w7BVvKsXLFCwzlOcwmL9iJgksXBpiIucTG+CciS1g5fdYvvCfdsBuvj1gqddNrltg7aE8PHkO2gMZeL6BxZ9ycuR7aRNs5jgPVi8q6Cz7b7tgr7OdYHuCkxtg/fO8enlf2wcX9NKDCz/n03no7wY4rbMVXPn3NvXV2x1wgloFbs2iugaufVtEKvLFPZBOugguXnIX8d4PN8E2xHXgau/IOB93wYqVYBo=')"
      building_marker_icon.style.cursor = 'pointer'

      // point 1 marker icon add style.
      const points_marker_icon_1 = document.createElement('div')
      points_marker_icon_1.style.width = '20px'
      points_marker_icon_1.style.height = '20px'
      points_marker_icon_1.style.backgroundSize = '20px 20px'
      points_marker_icon_1.style.backgroundRepeat = 'no-repeat'
      points_marker_icon_1.style.backgroundImage =
        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAA3QAAAN0BcFOiBwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAADESURBVFiFzZdBDsMwCASXvjGPdd/WA7kQKWmNbQiQInGyYEYWiQWYGREJoAFgYzaS4ttBRK5GrxD6jYgUeLuqomZgMBsbgA+0OXgUnimgwH9liuFbusAILud5AjN4qsAKPE1gFZ4iYIGHC1jhUnN+tFopvNvnSbhLIBJuFoiGS8/rUFTCD4HLZ1EJXxLIhE8FsuFDgQq4KlAF7wpUwjWBMrgmUAafCaTDV25AS/WPaU2ShuZgZvLUfcdf7Iaenc63B3ZiB8hFlDxcl8HHAAAAAElFTkSuQmCC')"
      points_marker_icon_1.style.cursor = 'pointer'

      // point 2 marker icon add style.
      const points_marker_icon_2 = document.createElement('div')
      points_marker_icon_2.style.width = '20px'
      points_marker_icon_2.style.height = '20px'
      points_marker_icon_2.style.backgroundSize = '20px 20px'
      points_marker_icon_2.style.backgroundRepeat = 'no-repeat'
      points_marker_icon_2.style.backgroundImage =
        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAA6QAAAOkBVJIUSwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAKSSURBVFiF7ZfNa1NBFMV/V02kdFEoDcVWt2qLFqQLA4rETakFIU1TuuhCuqmb4tKN4N9g/xN3QYUqSArZVKgFP7rpItDuCoUu6td1cefFSfryMq9J6MYLh/CSefecmTlzM1dUlZAQkSzwCJgAbgDX3edlYN9hB3gNfFDVn0GJVTURwADwDKgDGohD4BWQ65g/gTgDPAcOUhC34gh4CWS8vGPAXKIAYBCodEHcio/AKDDuVucPkI8VAIwAtR6SR6gDVe95SVW55PtBRK4B7zBztYsq8B746nARuA1MAXcd4mLcIQpzf8vsNxJmUAHuB5h2BtgKWJHFpi0AltsM/AWsdCL28mSBNWCvg4ByQwAwhJ3j1kEnQCmU3OVaD5i9Agu+gHYvraYhd7kKwHGAgJIvYDdmQA2QtAJcvmHgRZtVjTDvC9iM2ffps5DH+GEFK9GtAoq+gAJWtaIf17oljxEzix1xBX4Dk6pqSwwgIjkgD3xS1Tp9ChGZwErzNvBPwHnFhXNl/y/AFyAiU84gfQsRuSoij53hLZwJJ7GjodhRme3DMVyj+aJS8OtAkdOFYgcrItkekE9jxc3Pv+kLmI8REGEfK6vDZyQX4i84u76AUoKACMfRsqUUsNom37ovYCFAQOOlFORl7C89blWHfAHlDsR7zkTBfgCeesZuxXJjnBu8GDD7LWAmgPgB8DYhz4Y/PrqUCp3jDvBGRGqYqbaBz26WNx0eAvcScnwDnjR941QveQqrpOuCQlEDRk6tmBOQx5qFQ+zqPIo1E70irwCDsVvm7d0cMOY9Z7C26qgL4gOsvcu09UyAqXJYo3mYgriONbQDnfIHX0hEJINd3YrALeCKwwlmru/u8wtQUdUfIXn/ApJe6YbfN7AOAAAAAElFTkSuQmCC')"

      points_marker_icon_2.style.cursor = 'pointer'

      // point 3 marker icon add style.
      const points_marker_icon_3 = document.createElement('div')
      points_marker_icon_3.style.width = '20px'
      points_marker_icon_3.style.height = '20px'
      points_marker_icon_3.style.backgroundSize = '20px 20px'
      points_marker_icon_3.style.backgroundRepeat = 'no-repeat'
      points_marker_icon_3.style.backgroundImage =
        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAA3QAAAN0BcFOiBwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAADESURBVFiFzZdBDsMwCASXvjGPdd/WA7kQKWmNbQiQInGyYEYWiQWYGREJoAFgYzaS4ttBRK5GrxD6jYgUeLuqomZgMBsbgA+0OXgUnimgwH9liuFbusAILud5AjN4qsAKPE1gFZ4iYIGHC1jhUnN+tFopvNvnSbhLIBJuFoiGS8/rUFTCD4HLZ1EJXxLIhE8FsuFDgQq4KlAF7wpUwjWBMrgmUAafCaTDV25AS/WPaU2ShuZgZvLUfcdf7Iaenc63B3ZiB8hFlDxcl8HHAAAAAElFTkSuQmCC')"
      points_marker_icon_3.style.cursor = 'pointer'

      // point 4 marker icon add style.
      const points_marker_icon_4 = document.createElement('div')
      points_marker_icon_4.style.width = '20px'
      points_marker_icon_4.style.height = '20px'
      points_marker_icon_4.style.backgroundSize = '20px 20px'
      points_marker_icon_4.style.backgroundRepeat = 'no-repeat'
      points_marker_icon_4.style.backgroundImage =
        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAA6QAAAOkBVJIUSwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAKSSURBVFiF7ZfNa1NBFMV/V02kdFEoDcVWt2qLFqQLA4rETakFIU1TuuhCuqmb4tKN4N9g/xN3QYUqSArZVKgFP7rpItDuCoUu6td1cefFSfryMq9J6MYLh/CSefecmTlzM1dUlZAQkSzwCJgAbgDX3edlYN9hB3gNfFDVn0GJVTURwADwDKgDGohD4BWQ65g/gTgDPAcOUhC34gh4CWS8vGPAXKIAYBCodEHcio/AKDDuVucPkI8VAIwAtR6SR6gDVe95SVW55PtBRK4B7zBztYsq8B746nARuA1MAXcd4mLcIQpzf8vsNxJmUAHuB5h2BtgKWJHFpi0AltsM/AWsdCL28mSBNWCvg4ByQwAwhJ3j1kEnQCmU3OVaD5i9Agu+gHYvraYhd7kKwHGAgJIvYDdmQA2QtAJcvmHgRZtVjTDvC9iM2ffps5DH+GEFK9GtAoq+gAJWtaIf17oljxEzix1xBX4Dk6pqSwwgIjkgD3xS1Tp9ChGZwErzNvBPwHnFhXNl/y/AFyAiU84gfQsRuSoij53hLZwJJ7GjodhRme3DMVyj+aJS8OtAkdOFYgcrItkekE9jxc3Pv+kLmI8REGEfK6vDZyQX4i84u76AUoKACMfRsqUUsNom37ovYCFAQOOlFORl7C89blWHfAHlDsR7zkTBfgCeesZuxXJjnBu8GDD7LWAmgPgB8DYhz4Y/PrqUCp3jDvBGRGqYqbaBz26WNx0eAvcScnwDnjR941QveQqrpOuCQlEDRk6tmBOQx5qFQ+zqPIo1E70irwCDsVvm7d0cMOY9Z7C26qgL4gOsvcu09UyAqXJYo3mYgriONbQDnfIHX0hEJINd3YrALeCKwwlmru/u8wtQUdUfIXn/ApJe6YbfN7AOAAAAAElFTkSuQmCC')"

      points_marker_icon_4.style.cursor = 'pointer'

      const Rotate_hidden_marker1 = document.createElement('div')
      Rotate_hidden_marker1.style.width = '38px'
      Rotate_hidden_marker1.style.height = '55px'
      Rotate_hidden_marker1.style.cursor = 'pointer'

      const Rotate_hidden_marker2 = document.createElement('div')
      Rotate_hidden_marker2.style.width = '38px'
      Rotate_hidden_marker2.style.height = '55px'
      Rotate_hidden_marker2.style.cursor = 'pointer'

      const scale_hidden_marker1 = document.createElement('div')
      scale_hidden_marker1.style.width = '38px'
      scale_hidden_marker1.style.height = '55px'
      scale_hidden_marker1.style.cursor = 'pointer'

      const scale_hidden_marker2 = document.createElement('div')
      scale_hidden_marker2.style.width = '38px'
      scale_hidden_marker2.style.height = '55px'
      scale_hidden_marker2.style.cursor = 'pointer'

      // create popup message
      const Building_marker_popup = new mapboxgl.Popup({
        offset: [0, -50],
      })
        .setLngLat([center.lng, center.lat])
        .setText('Click on the exact location to import the floor plan')

      // create building marker & add div "building_marker_icon"
      bulding_marker = new mapboxgl.Marker(building_marker_icon, {
        anchor: 'bottom',
        draggable: true,
        offset: [0, 5],
      })
        .setLngLat([center.lng, center.lat])
        .setPopup(Building_marker_popup)
        .addTo(map)
      await setCurrentMap(map)

      const onBuildMarkerClick = event => {
        // if there is no building
        if (!building_flag) {
          building_flag = true
          point1 = [
            bulding_marker.getLngLat().lng + 0.00035394366915,
            bulding_marker.getLngLat().lat + 0.000423696311544,
          ]
          point2 = [
            bulding_marker.getLngLat().lng - 0.00017480741806,
            bulding_marker.getLngLat().lat + 0.000509873000709,
          ]
          point3 = [
            bulding_marker.getLngLat().lng - 0.00035227423129,
            bulding_marker.getLngLat().lat - 0.000478640742416,
          ]
          point4 = [
            bulding_marker.getLngLat().lng + 0.00016629123294,
            bulding_marker.getLngLat().lat - 0.000562320085336,
          ]

          floor_Marker_1 = new mapboxgl.Marker(points_marker_icon_1, {})
            .setLngLat(point1)
            .addTo(map)

          floor_Marker_2 = new mapboxgl.Marker(points_marker_icon_2, {})
            .setLngLat(point2)
            .addTo(map)

          floor_Marker_3 = new mapboxgl.Marker(points_marker_icon_3, {})
            .setLngLat(point3)
            .addTo(map)

          floor_Marker_4 = new mapboxgl.Marker(points_marker_icon_4, {})
            .setLngLat(point4)
            .addTo(map)

          floor_Marker_rotate1 = new mapboxgl.Marker(Rotate_hidden_marker1, {
            draggable: true,
          })
            .setLngLat([
              floor_Marker_2.getLngLat().lng,
              floor_Marker_2.getLngLat().lat,
            ])
            .addTo(map)

          floor_Marker_rotate2 = new mapboxgl.Marker(Rotate_hidden_marker2, {
            draggable: true,
          })
            .setLngLat([
              floor_Marker_4.getLngLat().lng,
              floor_Marker_4.getLngLat().lat,
            ])
            .addTo(map)

          floor_Marker_scale1 = new mapboxgl.Marker(scale_hidden_marker1, {
            draggable: true,
          })
            .setLngLat([
              floor_Marker_1.getLngLat().lng,
              floor_Marker_1.getLngLat().lat,
            ])
            .addTo(map)

          floor_Marker_scale2 = new mapboxgl.Marker(scale_hidden_marker2, {
            draggable: true,
          })
            .setLngLat([
              floor_Marker_3.getLngLat().lng,
              floor_Marker_3.getLngLat().lat,
            ])
            .addTo(map)

          floor_Marker_rotate2.on('drag', onRotate2)
          floor_Marker_rotate2.on('dragend', repaintOnMove)

          floor_Marker_rotate1.on('drag', onRotate1)
          floor_Marker_rotate1.on('dragend', repaintOnMove)

          floor_Marker_scale1.on('drag', scale)
          floor_Marker_scale1.on('dragend', repaintOnMove)

          floor_Marker_scale2.on('drag', scale1)
          floor_Marker_scale2.on('dragend', repaintOnMove)

          map.addSource('nearmotion-maine', {
            type: 'geojson',
            data: {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [
                  [
                    [
                      floor_Marker_1.getLngLat().lng,
                      floor_Marker_1.getLngLat().lat,
                    ],
                    [
                      floor_Marker_2.getLngLat().lng,
                      floor_Marker_2.getLngLat().lat,
                    ],
                    [
                      floor_Marker_3.getLngLat().lng,
                      floor_Marker_3.getLngLat().lat,
                    ],
                    [
                      floor_Marker_4.getLngLat().lng,
                      floor_Marker_4.getLngLat().lat,
                    ],
                  ],
                ],
              },
            },
          })
          let layerBefore
          if (map.getLayer('nearmotion-floor-plan-overlay')) {
            layerBefore = 'nearmotion-floor-plan-overlay'
          }
          map.addLayer(
            {
              id: 'nearmotion-poly',
              type: 'fill',
              source: 'nearmotion-maine',
              layout: {},
              paint: {
                'fill-color': '#088',
                'fill-opacity': 0.6,
              },
            },
            layerBefore
          )
        }
      }
      bulding_marker.getElement().addEventListener('click', onBuildMarkerClick)

      translate = () => {
        // if user choose the right location
        if (building_flag) {
          // create poly from 5 points , from 1 to 2 to 3 to 4 & back again to 1 to complete the polygon.
          const poly = turf.polygon([
            [
              [floor_Marker_1.getLngLat().lng, floor_Marker_1.getLngLat().lat],
              [floor_Marker_2.getLngLat().lng, floor_Marker_2.getLngLat().lat],
              [floor_Marker_3.getLngLat().lng, floor_Marker_3.getLngLat().lat],
              [floor_Marker_4.getLngLat().lng, floor_Marker_4.getLngLat().lat],
              [floor_Marker_1.getLngLat().lng, floor_Marker_1.getLngLat().lat],
            ],
          ])
          const from = turf.point([building_marker_lng, building_marker_lat])
          const to = turf.point([
            bulding_marker.getLngLat().lng,
            bulding_marker.getLngLat().lat,
          ])
          const bearing = turf.rhumbBearing(from, to)
          const distance = turf.rhumbDistance(from, to)
          translatedPoly = turf.transformTranslate(poly, distance, bearing)
          // set the translatedPoly coordinates to  markers.
          floor_Marker_1.setLngLat([
            translatedPoly.geometry.coordinates[0][0][0],
            translatedPoly.geometry.coordinates[0][0][1],
          ])
          floor_Marker_2.setLngLat([
            translatedPoly.geometry.coordinates[0][1][0],
            translatedPoly.geometry.coordinates[0][1][1],
          ])
          floor_Marker_3.setLngLat([
            translatedPoly.geometry.coordinates[0][2][0],
            translatedPoly.geometry.coordinates[0][2][1],
          ])
          floor_Marker_4.setLngLat([
            translatedPoly.geometry.coordinates[0][3][0],
            translatedPoly.geometry.coordinates[0][3][1],
          ])
          // update coordinates building_marker;
          building_marker_lng = bulding_marker.getLngLat().lng
          building_marker_lat = bulding_marker.getLngLat().lat

          repaint()
        }
        // if the user drag without select the location. just update coordinates building_marker
        else {
          building_marker_lng = bulding_marker.getLngLat().lng
          building_marker_lat = bulding_marker.getLngLat().lat
        }
        onRotateScaleend()
      }
      // create event listener on user drage building marker.
      bulding_marker.on('drag', translate)
      bulding_marker.on('dragend', repaintOnMove)
      let prev_distance
      let distance_flag = true
      let scaledPoly
      //      var get_most_nearest_distance;
      //        get_most_nearest_distance = getDistanceFromLatLonInKm(building_marker_lat,building_marker_lng,floor_Marker_scale1.getLngLat().lat,floor_Marker_scale1.getLngLat().lng);

      // function to get distance between two coordinates Quiz !!!
      function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
        const R = 6371 // Radius of the earth in km
        const dLat = deg2rad(lat2 - lat1) // deg2rad below
        const dLon = deg2rad(lon2 - lon1)
        const a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(deg2rad(lat1)) *
            Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2)
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
        const d = R * c // Distance in km

        return d * 1000
      }
      function deg2rad(deg) {
        return deg * (Math.PI / 180)
      }

      let scale_factor = 1
      function scale() {
        const poly = turf.polygon([
          [
            [floor_Marker_1.getLngLat().lng, floor_Marker_1.getLngLat().lat],
            [floor_Marker_2.getLngLat().lng, floor_Marker_2.getLngLat().lat],
            [floor_Marker_3.getLngLat().lng, floor_Marker_3.getLngLat().lat],
            [floor_Marker_4.getLngLat().lng, floor_Marker_4.getLngLat().lat],
            [floor_Marker_1.getLngLat().lng, floor_Marker_1.getLngLat().lat],
          ],
        ])
        const distance = getDistanceFromLatLonInKm(
          building_marker_lat,
          building_marker_lng,
          floor_Marker_scale1.getLngLat().lat,
          floor_Marker_scale1.getLngLat().lng
        )
        if (distance_flag) {
          prev_distance = distance
          distance_flag = false
        } else {
          if (distance < prev_distance) {
            scale_factor -= 0.01
            scaledPoly = turf.transformScale(poly, scale_factor)
            prev_distance = distance
            scale_factor = 1
          } else {
            scale_factor += 0.01
            scaledPoly = turf.transformScale(poly, scale_factor)
            prev_distance = distance
            scale_factor = 1
          }

          floor_Marker_1.setLngLat([
            scaledPoly.geometry.coordinates[0][0][0],
            scaledPoly.geometry.coordinates[0][0][1],
          ])
          floor_Marker_2.setLngLat([
            scaledPoly.geometry.coordinates[0][1][0],
            scaledPoly.geometry.coordinates[0][1][1],
          ])
          floor_Marker_3.setLngLat([
            scaledPoly.geometry.coordinates[0][2][0],
            scaledPoly.geometry.coordinates[0][2][1],
          ])
          floor_Marker_4.setLngLat([
            scaledPoly.geometry.coordinates[0][3][0],
            scaledPoly.geometry.coordinates[0][3][1],
          ])
          repaint()
        }
      }

      function scale1() {
        const poly = turf.polygon([
          [
            [floor_Marker_1.getLngLat().lng, floor_Marker_1.getLngLat().lat],
            [floor_Marker_2.getLngLat().lng, floor_Marker_2.getLngLat().lat],
            [floor_Marker_3.getLngLat().lng, floor_Marker_3.getLngLat().lat],
            [floor_Marker_4.getLngLat().lng, floor_Marker_4.getLngLat().lat],
            [floor_Marker_1.getLngLat().lng, floor_Marker_1.getLngLat().lat],
          ],
        ])
        const distance = getDistanceFromLatLonInKm(
          building_marker_lat,
          building_marker_lng,
          floor_Marker_scale2.getLngLat().lat,
          floor_Marker_scale2.getLngLat().lng
        )

        if (distance_flag) {
          prev_distance = distance
          distance_flag = false
        } else {
          if (distance < prev_distance) {
            scale_factor -= 0.01
            scaledPoly = turf.transformScale(poly, scale_factor)
            prev_distance = distance
            scale_factor = 1
          } else {
            scale_factor += 0.01
            scaledPoly = turf.transformScale(poly, scale_factor)
            prev_distance = distance
            scale_factor = 1
          }

          floor_Marker_1.setLngLat([
            scaledPoly.geometry.coordinates[0][0][0],
            scaledPoly.geometry.coordinates[0][0][1],
          ])
          floor_Marker_2.setLngLat([
            scaledPoly.geometry.coordinates[0][1][0],
            scaledPoly.geometry.coordinates[0][1][1],
          ])
          floor_Marker_3.setLngLat([
            scaledPoly.geometry.coordinates[0][2][0],
            scaledPoly.geometry.coordinates[0][2][1],
          ])
          floor_Marker_4.setLngLat([
            scaledPoly.geometry.coordinates[0][3][0],
            scaledPoly.geometry.coordinates[0][3][1],
          ])
          repaint()
        }
      }

      let angle_flag = true

      function repaint() {
        const mapSource = map.getSource('nearmotion-maine')
        if (typeof mapSource !== 'undefined') {
          map.removeLayer('nearmotion-poly')
          map.removeSource('nearmotion-maine')
        }

        const coordinates = [
          [floor_Marker_1.getLngLat().lng, floor_Marker_1.getLngLat().lat],
          [floor_Marker_2.getLngLat().lng, floor_Marker_2.getLngLat().lat],
          [floor_Marker_3.getLngLat().lng, floor_Marker_3.getLngLat().lat],
          [floor_Marker_4.getLngLat().lng, floor_Marker_4.getLngLat().lat],
        ]
        map.addSource('nearmotion-maine', {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [coordinates],
            },
          },
        })
        let layerBefore
        if (map.getLayer('nearmotion-floor-plan-overlay')) {
          layerBefore = 'nearmotion-floor-plan-overlay'
        }
        map.addLayer(
          {
            id: 'nearmotion-poly',
            type: 'fill',
            source: 'nearmotion-maine',
            layout: {},
            paint: {
              'fill-color': '#088',
              'fill-opacity': 0.6,
            },
          },
          layerBefore
        )
        setBuildingAngles(
          [
            floor_Marker_1.getLngLat(),
            floor_Marker_2.getLngLat(),
            floor_Marker_3.getLngLat(),
            floor_Marker_4.getLngLat(),
          ].map(angle => {
            return {
              latitude: angle.lat,
              longitude: angle.lng,
            }
          })
        )
      }

      let prev_angel

      function onRotateScaleend() {
        if (!floor_Marker_rotate1) {
          return false
        }
        floor_Marker_rotate1.setLngLat([
          floor_Marker_2.getLngLat().lng,
          floor_Marker_2.getLngLat().lat,
        ])
        floor_Marker_rotate2.setLngLat([
          floor_Marker_4.getLngLat().lng,
          floor_Marker_4.getLngLat().lat,
        ])
        floor_Marker_scale1.setLngLat([
          floor_Marker_1.getLngLat().lng,
          floor_Marker_1.getLngLat().lat,
        ])
        floor_Marker_scale2.setLngLat([
          floor_Marker_3.getLngLat().lng,
          floor_Marker_3.getLngLat().lat,
        ])

        const poly = turf.polygon([
          [
            [floor_Marker_1.getLngLat().lng, floor_Marker_1.getLngLat().lat],
            [floor_Marker_2.getLngLat().lng, floor_Marker_2.getLngLat().lat],
            [floor_Marker_3.getLngLat().lng, floor_Marker_3.getLngLat().lat],
            [floor_Marker_4.getLngLat().lng, floor_Marker_4.getLngLat().lat],
            [floor_Marker_1.getLngLat().lng, floor_Marker_1.getLngLat().lat],
          ],
        ])
        center = turf.centerOfMass(poly)
        bulding_marker.setLngLat([
          center.geometry.coordinates[0],
          center.geometry.coordinates[1],
        ])
        building_marker_lng = center.geometry.coordinates[0]
        building_marker_lat = center.geometry.coordinates[1]
      }

      function repaintOnMove() {
        if (!floor_Marker_rotate1) {
          return false
        }

        onRotateScaleend()
        setFloorMarkers([
          floor_Marker_rotate1,
          floor_Marker_scale1,
          floor_Marker_rotate2,
          floor_Marker_scale2,
        ])
      }

      function onRotate1() {
        let degree =
          (Math.atan2(
            building_marker_lat - floor_Marker_rotate1.getLngLat().lat,
            building_marker_lng - floor_Marker_rotate1.getLngLat().lng
          ) *
            180) /
          Math.PI
        degree = (degree * Math.PI) / 180.0 / 100

        if (angle_flag) {
          prev_angel = degree
          angle_flag = false
        } else if (degree < prev_angel) {
          prev_angel = degree
          degree = -0.01
        } else {
          prev_angel = degree
          degree = 0.01
        }

        let x =
          Math.cos(degree) *
            (floor_Marker_3.getLngLat().lng - building_marker_lng) -
          Math.sin(degree) *
            (floor_Marker_3.getLngLat().lat - building_marker_lat) +
          building_marker_lng
        let y =
          Math.sin(degree) *
            (floor_Marker_3.getLngLat().lng - building_marker_lng) +
          Math.cos(degree) *
            (floor_Marker_3.getLngLat().lat - building_marker_lat) +
          building_marker_lat
        floor_Marker_3.setLngLat([x, y])

        x =
          Math.cos(degree) *
            (floor_Marker_2.getLngLat().lng - building_marker_lng) -
          Math.sin(degree) *
            (floor_Marker_2.getLngLat().lat - building_marker_lat) +
          building_marker_lng
        y =
          Math.sin(degree) *
            (floor_Marker_2.getLngLat().lng - building_marker_lng) +
          Math.cos(degree) *
            (floor_Marker_2.getLngLat().lat - building_marker_lat) +
          building_marker_lat
        floor_Marker_2.setLngLat([x, y])

        x =
          Math.cos(degree) *
            (floor_Marker_4.getLngLat().lng - building_marker_lng) -
          Math.sin(degree) *
            (floor_Marker_4.getLngLat().lat - building_marker_lat) +
          building_marker_lng
        y =
          Math.sin(degree) *
            (floor_Marker_4.getLngLat().lng - building_marker_lng) +
          Math.cos(degree) *
            (floor_Marker_4.getLngLat().lat - building_marker_lat) +
          building_marker_lat
        floor_Marker_4.setLngLat([x, y])

        x =
          Math.cos(degree) *
            (floor_Marker_1.getLngLat().lng - building_marker_lng) -
          Math.sin(degree) *
            (floor_Marker_1.getLngLat().lat - building_marker_lat) +
          building_marker_lng
        y =
          Math.sin(degree) *
            (floor_Marker_1.getLngLat().lng - building_marker_lng) +
          Math.cos(degree) *
            (floor_Marker_1.getLngLat().lat - building_marker_lat) +
          building_marker_lat
        floor_Marker_1.setLngLat([x, y])

        repaint()
      }

      function onRotate2() {
        let degree =
          (Math.atan2(
            building_marker_lat - floor_Marker_rotate2.getLngLat().lat,
            building_marker_lng - floor_Marker_rotate2.getLngLat().lng
          ) *
            180) /
          Math.PI
        degree = (degree * Math.PI) / 180.0 / 100

        if (angle_flag) {
          prev_angel = degree
          angle_flag = false
        } else if (degree < prev_angel) {
          prev_angel = degree
          degree = -0.01
        } else {
          prev_angel = degree
          degree = 0.01
        }

        let x =
          Math.cos(degree) *
            (floor_Marker_3.getLngLat().lng - building_marker_lng) -
          Math.sin(degree) *
            (floor_Marker_3.getLngLat().lat - building_marker_lat) +
          building_marker_lng
        let y =
          Math.sin(degree) *
            (floor_Marker_3.getLngLat().lng - building_marker_lng) +
          Math.cos(degree) *
            (floor_Marker_3.getLngLat().lat - building_marker_lat) +
          building_marker_lat
        floor_Marker_3.setLngLat([x, y])

        x =
          Math.cos(degree) *
            (floor_Marker_2.getLngLat().lng - building_marker_lng) -
          Math.sin(degree) *
            (floor_Marker_2.getLngLat().lat - building_marker_lat) +
          building_marker_lng
        y =
          Math.sin(degree) *
            (floor_Marker_2.getLngLat().lng - building_marker_lng) +
          Math.cos(degree) *
            (floor_Marker_2.getLngLat().lat - building_marker_lat) +
          building_marker_lat
        floor_Marker_2.setLngLat([x, y])

        x =
          Math.cos(degree) *
            (floor_Marker_4.getLngLat().lng - building_marker_lng) -
          Math.sin(degree) *
            (floor_Marker_4.getLngLat().lat - building_marker_lat) +
          building_marker_lng
        y =
          Math.sin(degree) *
            (floor_Marker_4.getLngLat().lng - building_marker_lng) +
          Math.cos(degree) *
            (floor_Marker_4.getLngLat().lat - building_marker_lat) +
          building_marker_lat
        floor_Marker_4.setLngLat([x, y])

        x =
          Math.cos(degree) *
            (floor_Marker_1.getLngLat().lng - building_marker_lng) -
          Math.sin(degree) *
            (floor_Marker_1.getLngLat().lat - building_marker_lat) +
          building_marker_lng
        y =
          Math.sin(degree) *
            (floor_Marker_1.getLngLat().lng - building_marker_lng) +
          Math.cos(degree) *
            (floor_Marker_1.getLngLat().lat - building_marker_lat) +
          building_marker_lat
        floor_Marker_1.setLngLat([x, y])

        repaint()
      }

      const mapOnClick = async ev => {
        bulding_marker.setLngLat([ev.lngLat.lng, ev.lngLat.lat])
        translate()
        building_marker_lng = bulding_marker.getLngLat().lng
        building_marker_lat = bulding_marker.getLngLat().lat
        if (!floor_Marker_rotate1) {
          return false
        }
        floor_Marker_rotate1.setLngLat([
          floor_Marker_2.getLngLat().lng,
          floor_Marker_2.getLngLat().lat,
        ])
        floor_Marker_rotate2.setLngLat([
          floor_Marker_4.getLngLat().lng,
          floor_Marker_4.getLngLat().lat,
        ])
        floor_Marker_scale1.setLngLat([
          floor_Marker_1.getLngLat().lng,
          floor_Marker_1.getLngLat().lat,
        ])
        floor_Marker_scale2.setLngLat([
          floor_Marker_3.getLngLat().lng,
          floor_Marker_3.getLngLat().lat,
        ])

        await setFloorMarkers([
          floor_Marker_rotate1,
          floor_Marker_scale1,
          floor_Marker_rotate2,
          floor_Marker_scale2,
        ])
      }

      const mapOnLoad = ev => {
        bulding_marker.togglePopup()
      }
      map.on('click', mapOnClick)
      map.on('load', mapOnLoad)
    },
    [defaultMapStyle, updatePlanImage]
  )

  const contextValue = {
    isLoading,
    currentMap,
    mapStyle,
    setMapStyle,
    defaultMapStyle,
    setMapContainer,
    buildingAngles,
    floorMarkers,
  }

  return (
    <MapStudioContext.Provider value={contextValue}>
      {props.children}
    </MapStudioContext.Provider>
  )
}

export function useMapResizeStudio() {
  return useContext(MapStudioContext)
}
