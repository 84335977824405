import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'

import { DataTable } from 'src/components/DataTable'
import { Button, Title } from 'src/ui'
import { Modal } from 'src/components/Modal'
import { useApplications } from 'src/modules/applications'

import { Form, FormSpy } from 'react-final-form'
import { TextField } from 'src/components/TextField'
import { Alert } from 'src/components/Alert'
import validate from 'src/utils/validate'
import isEmail from 'src/utils/validators/isEmail'
import isFieldComplete from 'src/utils/validators/isFieldComplete'
import { ActionWrapper } from 'src/components/BeaconsImports/AddBeaconsImportsModal'

import { SelectField } from 'src/components/SelectField'
import { useKeyPress } from 'src/hooks/useKeyPress'
import { Error } from 'src/ui/form/FormField'

import { Roles } from './model'

const initialValues = {
  email: '',
  role: '',
}

export function InviteMemberModal(props) {
  const { onClose, submitInvite } = props
  const { applications, get: getApplications } = useApplications()

  const [memberRole, setMemberRole] = useState()
  const [applicationIds, setApplicationIds] = useState([])
  const isSuperAdmin = /super_admin/.test(memberRole)

  const validateForm = values => {
    const errors = {}

    errors.email = validate(values.email, isEmail)
    errors.role = validate(
      values.role,
      isFieldComplete('role', 'Please select user role')
    )

    if (!applicationIds.length && values.role && !isSuperAdmin) {
      errors.applications = 'Please select at last one application.'
    }

    return errors
  }

  const escapePressed = useKeyPress('Escape')

  if (escapePressed) {
    onClose()
  }
  const onLoading = useCallback(async () => {
    if (!applications?.length) {
      await getApplications()
    }
  }, [getApplications, applications])

  useEffect(onLoading, [onLoading])

  const updateRole = useCallback(selectedRole => {
    setMemberRole(selectedRole)
  }, [])

  const handleFormChange = useCallback(
    form => {
      updateRole(form?.values.role)
    },
    [updateRole]
  )

  const handleSelectApplication = useCallback(
    (applicationId, checked) => {
      setApplicationIds(
        checked
          ? [...applicationIds, applicationId]
          : [...applicationIds.filter(appId => appId !== applicationId)]
      )
    },
    [applicationIds]
  )
  const handleUpdate = useCallback(
    values => {
      const dataToUpdate = {
        email: values.email,
        role: values.role,
      }

      if (!/super_admin/.test(values.role)) {
        dataToUpdate.application_ids = applicationIds
      }

      return submitInvite(dataToUpdate)
    },
    [applicationIds, submitInvite]
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Application Name',
        accessor: 'name',
      },
      {
        id: 'actions',
        Header: 'Actions',
        cellStyle: { width: '70px', textAlign: 'center' },
        Cell: ({ row: { original } }) => (
          <Input
            disabled={isSuperAdmin}
            type="checkbox"
            defaultChecked={
              isSuperAdmin ||
              applicationIds.some(id => {
                return id === original.id
              })
            }
            onChange={({ target: { checked } }) =>
              handleSelectApplication(original.id, checked)
            }
          />
        ),
      },
    ],
    [applicationIds, handleSelectApplication, isSuperAdmin]
  )

  return (
    <Modal>
      <ModalContainer>
        <Form
          onSubmit={handleUpdate}
          initialValues={initialValues}
          validate={validateForm}
          render={({ handleSubmit }) => (
            <FormStyled onSubmit={handleSubmit} style={{ textAlign: 'center' }}>
              <FormSpy onChange={handleFormChange} />
              <Title>Invite a new member to your company</Title>
              <TextField fullWidth label="Email" name="email" maxLength={255} />
              <SelectField
                label="Role"
                name="role"
                options={Roles}
                isMulti={false}
                isSearchable={false}
                defaultValue="admin"
                inputProps={{
                  defaultValue: 'admin',
                  value: memberRole,
                  onChange: updateRole,
                }}
              />
              <DataTableStyled
                scrollerAutoHide={false}
                columns={columns}
                rows={applications || []}
              />
              <FormSpy
                subscription={{
                  submitting: true,
                  pristine: true,
                  valid: true,
                  dirtySinceLastSubmit: true,
                  hasValidationErrors: true,
                  submitErrors: true,
                  error: true,
                  errors: true,
                  submitFailed: true,
                }}
              >
                {({
                  errors,
                  submitErrors,
                  submitFailed,
                  dirtySinceLastSubmit,
                  hasValidationErrors,
                }) => {
                  const baseError =
                    errors?.base?.[0] ||
                    errors?.base ||
                    submitErrors?.base?.[0] ||
                    submitErrors?.base

                  return (
                    <>
                      {errors.applications && (
                        <Error>{t(errors.applications)}</Error>
                      )}

                      {!!baseError && (
                        <Alert type="error" title={t(baseError)} />
                      )}
                      <ActionWrapperStyled>
                        <Button
                          color="secondary"
                          bold
                          size="lg"
                          onClick={onClose}
                        >
                          {t('Cancel')}
                        </Button>

                        <Button
                          color="primary"
                          bold
                          size="lg"
                          disabled={
                            hasValidationErrors ||
                            (submitFailed && !dirtySinceLastSubmit)
                          }
                          onClick={handleSubmit}
                        >
                          {t('Create')}
                        </Button>
                      </ActionWrapperStyled>
                    </>
                  )
                }}
              </FormSpy>
            </FormStyled>
          )}
        />
      </ModalContainer>
    </Modal>
  )
}

const Input = styled.input`
  cursor: pointer;
`

const FormStyled = styled.form`
  width: 100%;
`
const ActionWrapperStyled = styled(ActionWrapper)`
  display: flex;
  width: 100%;
  column-gap: 16px;
`
const ModalContainer = styled.div`
  width: 800px;
  max-width: 90vw;
  padding: 30px;
  background-color: white;
  border-radius: 5px;
`

const DataTableStyled = styled(DataTable)`
  th,
  td {
    text-align: left;
  }
`
