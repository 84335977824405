import React, { useCallback, useState } from 'react'
import { ModalDialog } from 'src/components/ModalDialog'
import { Button } from 'src/ui'

const preventDefaultAndStopPropagation = e => {
  e?.preventDefault()
  e?.stopPropagation()
}

export const ConfirmationModal = React.memo(
  ({
    component: Component,
    title,
    description,
    closeOnConfirm,
    cancelLabel = 'Cancel',
    confirmationLabel = 'Delete',
    iconTitle = '',
    onConfirm,
    id,
    ...restProps
  }) => {
    const [modalState, setModalState] = useState(false)

    const handleClick = useCallback(e => {
      preventDefaultAndStopPropagation(e)

      setModalState(true)
    }, [])

    const onCancel = useCallback(() => setModalState(false), [])

    const handleConfirmation = useCallback(async () => {
      await onConfirm(id)
      if (closeOnConfirm) onCancel()
    }, [closeOnConfirm, onCancel, onConfirm, id])

    return (
      <>
        <Component title={iconTitle} onClick={handleClick} {...restProps} />
        {!!modalState && (
          <ModalDialog
            title={title}
            description={description}
            actions={
              <>
                <Button mr="10px" color="secondary" onClick={onCancel}>
                  {cancelLabel}
                </Button>
                <Button color="primary" onClick={handleConfirmation}>
                  {confirmationLabel}
                </Button>
              </>
            }
          />
        )}
      </>
    )
  }
)
