import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { addCharge, loadCharge, loadCharges } from './asyncThunk'

function isPendingAction(action) {
  return action.type.startsWith('charges/') && action.type.endsWith('/pending')
}

// single card
const chargesSlice = createSlice({
  name: 'charges',
  initialState: {
    loading: false,
    signature: '',
    charge: {},
    charges: [],
  },
  extraReducers: builder => {
    builder.addCase(addCharge.fulfilled, (state, action) => {
      state.loading = false
      state.charge = action.payload
      // Add signature to the state array
      state.signature = action.payload?.signature
    })

    builder.addCase(addCharge.rejected, state => {
      state.loading = false
      toast.error('Something went wrong')
    })

    builder.addCase(loadCharge.fulfilled, (state, action) => {
      state.loading = false
      // Add signature to the state array
      state.charge = action.payload
    })

    builder.addCase(loadCharges.fulfilled, (state, action) => {
      // Add signature to the state array
      state.charges = action.payload

      state.loading = false
    })

    builder.addCase(loadCharges.rejected, state => {
      state.loading = false
      toast.error('Something went wrong')
    })

    builder.addMatcher(isPendingAction, state => {
      state.loading = true
    })
  },
})

export const chargesActions = chargesSlice.actions
export const chargesReducer = chargesSlice.reducer
