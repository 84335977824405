import { useCallback, useState } from 'react'

import { api } from 'src/modules/api'

export function useCompanyAdmins() {
  const [isLoading, setIsLoading] = useState(false)

  const get = useCallback(async props => {
    const { page = 0, size = 100, search } = props || {}
    setIsLoading(true)
    let requestUrl = `saas_company_admins?page[size]=${size}&page[number]=${
      page + 1
    }`

    if (search) {
      requestUrl = `${requestUrl}&q=${search}`
    }

    const res = await api.get(requestUrl)

    setIsLoading(false)

    return res
  }, [])

  const update = useCallback(
    async (memberId, data) => {
      setIsLoading(true)
      const res = await api.patch(`saas_company_admins/${memberId}`, data)
      res.ok && get()
      setIsLoading(false)

      return res
    },
    [get]
  )

  const remove = useCallback(
    async memberId => {
      setIsLoading(true)
      const res = await api.del(`saas_company_admins/${memberId}`)
      res.ok && get()
      setIsLoading(false)

      return res
    },
    [get]
  )

  return {
    isLoading,
    get,
    update,
    remove,
  }
}
