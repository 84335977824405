import React from 'react'
import styled from 'styled-components/macro'

import { Label, Error } from 'src/ui/form/FormField'
import { Field } from 'react-final-form'
import { ImageUploader } from 'src/components/Uploader'

export const ImageUploaderFieldUI = ({
  label,
  uploaderProps = {},
  error,
  className,
  variant,
  placeholder,
  name,
  type,
  ...props
}) => {
  return (
    <Field
      name={name}
      type={type}
      validate={props?.fieldValidate}
      render={() => (
        <>
          {label && <LabelStyled variant={variant}>{label}</LabelStyled>}
          <ImageUploader {...uploaderProps} />
          {!!error && <Error variant={variant}>{error}</Error>}
        </>
      )}
    />
  )
}

const LabelStyled = styled(Label)`
  width: 100%;
  margin-bottom: 5px !important;
  text-align: left;
`
