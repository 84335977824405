import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { Form, FormSpy } from 'react-final-form'

import { TextField } from 'src/components/TextField'
import { Alert as AlertComponent } from 'src/components/Alert'
import { AccordionItem, Button } from 'src/ui'
import { useAbility } from 'src/hooks/useAbility'

export const CreateUrl = props => {
  const { onClose, trigger, setCurrentItem, handleCreate } = props
  const {
    engagement: { update: canUpdate },
  } = useAbility()

  const [initialValues, setInitialValues] = useState()

  useEffect(() => {
    if (trigger) {
      const activity = trigger.activity || trigger.activity_attributes

      const editTrigger = {
        id: trigger.id,
        url: activity.url,
        action_name: activity.name,
      }
      setInitialValues(editTrigger)
    }
  }, [trigger])

  const handleSave = async values => {
    if (!canUpdate) return

    const newTrigger = {
      id: values.id,
      activity_attributes: {
        url: values.url,
        name: values.action_name,
        scheme: 'url',
      },
    }

    await setCurrentItem(newTrigger)
    handleCreate(newTrigger)
  }

  const validateForm = values => {
    const errors = {}

    if (!values.action_name) {
      errors.action_name = 'Action name Required'
    }
    if (!values?.url?.trim()) {
      errors.url = 'Url Required'
    }

    return errors
  }

  return (
    <Container>
      <AccordionItem title="Engagement">
        <HeaderTitle>Create Url</HeaderTitle>
        <Form
          onSubmit={handleSave}
          validate={validateForm}
          initialValues={initialValues}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} style={{ textAlign: 'center' }}>
              <TextField
                fullWidth
                label="Action name"
                name="action_name"
                placeholder="Starbucks"
                maxLength={255}
              />
              <TextField
                fullWidth
                label="Url"
                name="url"
                placeholder="https://instagram.com/starbucks"
                maxLength={255}
              />
              <FormSpy
                subscription={{
                  submitting: true,
                  pristine: true,
                  valid: true,
                  dirtySinceLastSubmit: true,
                  hasValidationErrors: true,
                  submitErrors: true,
                  error: true,
                  errors: true,
                  submitFailed: true,
                }}
              >
                {spyProps => {
                  const baseError = spyProps.submitErrors?.message

                  return (
                    <>
                      {!!baseError && (
                        <Alert type="error" title={t(baseError)} />
                      )}
                      <ActionWrapper>
                        <Cancel
                          color="secondary"
                          size="lg"
                          onClick={() => onClose()}
                        >
                          {t('Cancel')}
                        </Cancel>

                        <Button
                          disabled={!canUpdate}
                          color="primary"
                          size="lg"
                          onClick={handleSubmit}
                        >
                          {t('Next')}
                        </Button>
                      </ActionWrapper>
                    </>
                  )
                }}
              </FormSpy>
            </form>
          )}
        />
      </AccordionItem>
    </Container>
  )
}

const HeaderTitle = styled.h1`
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.5px;
  color: #344356;
  margin: 20px 20px 40px 0;
`

const Container = styled.div`
  font-family: 'Biko';
  textarea,
  select,
  input {
    width: 340px;
    max-width: 100%;
    padding-left: 0;
    font-size: 12px;
    line-height: 12px;
    border: none;
    border-bottom: 1px solid #e2ebffcf;
    border-radius: 0;
    &:focus {
      outline: none;
      border-bottom-color: #5468ff;
    }
  }
  label {
    margin-bottom: 0;
    margin-left: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    color: #344356;
  }
`
const ActionWrapper = styled.div`
  display: flex;
`

const Cancel = styled(Button)`
  margin-right: 18px;
`

const Alert = styled(AlertComponent)`
  background: white;
  > div:first-of-type {
    background: white;
    color: #ff6355;
  }
`
