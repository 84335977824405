import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { loadStats } from './asyncThunk'

function isPendingAction(action) {
  return action.type.startsWith('reports/') && action.type.endsWith('/pending')
}

const reportsSlice = createSlice({
  name: 'reports',
  initialState: {
    loading: false,
    stats: {},
  },
  extraReducers: builder => {
    builder.addCase(loadStats.fulfilled, (state, action) => {
      state.loading = false
      // Add signature to the state array
      state.stats = action.payload
    })

    builder.addCase(loadStats.rejected, (state, action) => {
      state.loading = false

      toast.error(action.payload || 'Something went wrong')
    })

    builder.addMatcher(isPendingAction, state => {
      state.loading = true
    })
  },
})

export const reportsActions = reportsSlice.actions
export const reportsReducer = reportsSlice.reducer
