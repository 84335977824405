import React from 'react'
import styled from 'styled-components/macro'
import { ReactComponent as SearchImageComponent } from 'src/assets/images/svg/search.svg'

export const SearchInput = ({ disabled, onChange, placeholder }) => {
  return (
    <SearchComponent>
      <input
        type="text"
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
      />
      <SearchImage />
    </SearchComponent>
  )
}

const SearchComponent = styled.div`
  position: relative;
  width: 384px;
  height: 44px;
  box-shadow: none;

  & input {
    padding-left: 53px;
    width: 100% !important;
    height: 44px;
    background: #fafbff;
    border: none;
    border-radius: 14px;
    outline: none;
    ::placeholder {
      color: #9aaccf;
      font-family: 'Biko';
      font-size: 14px;
      line-height: 14px;
    }
  }
`

const SearchImage = styled(SearchImageComponent)`
  position: absolute;
  top: 50%;
  left: 26px;
  width: 16px;
  z-index: 1;
  transform: translateY(-50%);
`
