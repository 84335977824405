import React, { useState, useRef, useEffect, useCallback } from 'react'
import styled from 'styled-components/macro'
import he from 'he'
import { toast } from 'react-toastify'
import { Form, Field } from 'react-final-form'
import Cleave from 'cleave.js/react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'

import { Button as ButtonComponent, Loader } from 'src/ui'
import { Scroller } from 'src/components/Scroller'
import { TextField } from 'src/components/TextField'

import { OutlinedInputCss } from 'src/ui/form/TextInput'
import { useCompany } from 'src/modules/company'
import {
  CreditCardType,
  getCreditCardIcon,
  getCreditCardType,
  parsedExpiryDate,
} from 'src/utils/creditCard'
import { SelectField } from 'src/components/SelectField'
import { ConfirmationModal } from 'src/components/ConfirmationModal'

import {
  getCreditCards,
  loadCreditCards,
  getCreditCardsLoading,
  updateCreditCard,
  addCreditCard,
  removeCreditCard,
  getCreditCardLoading,
} from 'src/redux/slicers/creditCards'

import CardImage from 'src/assets/images/card-purp.png'
import AddCardImage from 'src/assets/images/add-card.png'
import { useModalWindow } from 'src/hooks/useModalWindow'

const initialValues = {
  card_number: '',
  expiry_date: '',
  card_security_code: '',
  card_holder_name: '',
}

const Currencies = [
  {
    label: 'USD',
    value: 'usd',
  },
  {
    label: 'SAR',
    value: 'sar',
  },
]

export const PaymentCards = props => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { openModalWindow } = useModalWindow({
    url: 'about:blank',
    title: '3Ds',
    w: 600,
    h: 600,
  })

  const creditCards = useSelector(getCreditCards)
  const isCardsLoading = useSelector(getCreditCardsLoading)
  const isCardLoading = useSelector(getCreditCardLoading)

  const { company } = useCompany()
  const formRef = useRef()
  const [isLoading, setIsLoading] = useState(false)
  const [activeCard, setActiveCard] = useState(null)

  const updateActiveCard = cards => {
    if (!cards?.length) {
      setActiveCard({})
    } else {
      setActiveCard(cards.find(item => item.default) || cards[0])
    }
    setIsLoading(false)
  }

  const loadCards = useCallback(async () => {
    const result = await dispatch(loadCreditCards())
    const cards = unwrapResult(result)
    updateActiveCard(cards)
  }, [dispatch])

  useEffect(loadCards, [loadCards])

  // Read status response from the popup window
  const receiveMessage = async ({ data }) => {
    if (data) {
      if (data.error) {
        toast(he.decode(data.error))
        setIsLoading(false)
      } else if (data.info) {
        toast(he.decode(data.info))
      } else if (data.success) {
        toast(he.decode(data.success))
        await loadCards()
        if (!company?.subscription) {
          history.push('/payments/subscription')
        }
      }
    }
  }

  const onSubmit = async values => {
    if (Object.keys(initialValues).find(item => !values[item])) {
      return toast.error('Please fill credit card')
    }
    const cardType = getCreditCardType(values?.card_number)

    if (!cardType) {
      toast.dismiss()

      return toast.error('We accept only MADA, VISA and Master Card')
    }

    const popup = openModalWindow()

    setIsLoading(true)

    const response = await dispatch(
      addCreditCard({
        currency: values.currency,
      })
    )

    const result = unwrapResult(response)

    const data = {
      expiry_date: values.expiry_date.split('/').reverse().join(''),
      card_holder_name: values.card_holder_name,
      card_number: values.card_number?.replace(/\s+/g, ''),
      card_security_code: values.card_security_code,
      ...result,
    }
    if (data.signature) {
      formRef.current.innerHTML = ''

      Object.keys(data).forEach(key => {
        const input = document.createElement('INPUT')

        input.setAttribute('name', key)
        input.setAttribute('value', data[key])

        formRef.current.appendChild(input)
      })

      if (popup) {
        window.addEventListener('message', receiveMessage, false)

        const timer = setInterval(async () => {
          if (popup.closed) {
            window.removeEventListener('message', receiveMessage)
            setIsLoading(false)
            clearInterval(timer)
          }
        }, 500)
      }

      formRef.current.setAttribute('target', '3Ds')
      formRef.current.submit()
    }
  }

  function handleSetDefault(cardId) {
    dispatch(
      updateCreditCard({
        cardId,
        data: {
          default: true,
        },
      })
    )
  }

  function handleRemove(cardId) {
    dispatch(
      removeCreditCard({
        cardId,
      })
    )
    loadCards()
  }

  function handleActiveCard(card) {
    setActiveCard(card)
    setIsLoading(false)
  }

  function handleAddNew() {
    setActiveCard({})
  }

  const showAddForm = activeCard && !activeCard?.id

  const CleaveFormInput = ({ meta, input, inputProps, ...restOfProps }) => (
    <Cleave options={restOfProps.options} {...input} {...inputProps} />
  )

  return (
    <Container className={props.className}>
      <SavedCards>
        <Title>Saved Cards</Title>
        <CardsList>
          <Scroller autoHide={false}>
            {creditCards?.map(card => {
              return (
                <CreditCard
                  type={card.card_type}
                  key={card.id}
                  background={CardImage}
                  active={card.id === activeCard?.id}
                  onClick={() => handleActiveCard(card)}
                >
                  <CardType>
                    <CreditCardType type={card.brand} inline="true" />
                  </CardType>
                  {card.currency && (
                    <CardCurrency>{card.currency}</CardCurrency>
                  )}
                  <CardNumber>
                    {card.info?.card_number || '000000******0000'}
                  </CardNumber>
                  <CardDetails>
                    <CardHolder>
                      {card.info?.card_holder_name || 'John Doe'}
                    </CardHolder>
                    <CardExpire>{parsedExpiryDate(card)}</CardExpire>
                  </CardDetails>
                </CreditCard>
              )
            })}
            <AddCreditCard
              active={showAddForm}
              background={AddCardImage}
              onClick={handleAddNew}
            >
              <AddCreditText>{t('Add New Card')}</AddCreditText>
            </AddCreditCard>
          </Scroller>
        </CardsList>
      </SavedCards>
      {showAddForm && (
        <CardOptions>
          <Title>Add New Card</Title>
          <Wrapper>
            <CardScroller autoHide={false}>
              <Form
                onSubmit={() => {}}
                initialValues={initialValues}
                render={({ handleSubmit, values }) => {
                  const cardType =
                    values?.card_number &&
                    getCreditCardIcon(values?.card_number)

                  return (
                    <Wrapper>
                      <FormComponent onSubmit={handleSubmit}>
                        <NewCard>
                          <CardNumberWrapper>
                            <Field
                              inputProps={{ placeholder: 'Card Number' }}
                              options={{ creditCard: true }}
                              component={CleaveFormInput}
                              name="card_number"
                            />
                            {cardType && (
                              <InlineCardType>{cardType}</InlineCardType>
                            )}
                          </CardNumberWrapper>

                          <CardHolderName
                            variant="outlined"
                            fullWidth
                            placeholder="Holder Name"
                            name="card_holder_name"
                            maxLength={255}
                          />
                        </NewCard>
                        <CardCell>
                          <Field
                            options={{ date: true, datePattern: ['m', 'y'] }}
                            inputProps={{ placeholder: 'Expiry Date MM/YY' }}
                            name="expiry_date"
                            component={CleaveFormInput}
                          />
                          <Field
                            options={{
                              numericOnly: true,
                              blocks: [4],
                            }}
                            inputProps={{ placeholder: 'CCV Code' }}
                            name="card_security_code"
                            component={CleaveFormInput}
                          />
                          <Currency
                            name="currency"
                            options={Currencies}
                            isMulti={false}
                            isSearchable={false}
                            defaultValue="admin"
                            inputProps={{
                              defaultValue: 'usd',
                            }}
                          />
                        </CardCell>
                        <p>*We accept MADA, VISA and Master Card</p>
                        <Actions>
                          <Button
                            color="primary"
                            onClick={() => onSubmit(values)}
                          >
                            {t('Add Card')}
                          </Button>
                        </Actions>
                      </FormComponent>
                    </Wrapper>
                  )
                }}
              />
            </CardScroller>
          </Wrapper>
          <form
            method="post"
            action={process.env.REACT_APP_PAYFORT_CHECKOUT_URL}
            ref={formRef}
            hidden
          >
            <input name="remember_me" defaultValue="YES" />
          </form>
          {(isLoading || isCardsLoading || isCardLoading) && <Loader />}
        </CardOptions>
      )}
      {activeCard?.id && (
        <div>
          <Title>Card Actions</Title>
          {!activeCard.default && (
            <CardCover>
              <SetDefault onClick={() => handleSetDefault(activeCard.id)}>
                Set this card as default
              </SetDefault>
              <ConfirmationModal
                title="This will remove the card from the database. Are you sure?"
                iconTitle="This will remove the application from the database. Are you sure?"
                description="This action cannot be undone"
                onConfirm={handleRemove}
                id={activeCard.id}
                component={options => <Remove {...options}>Remove card</Remove>}
              />
            </CardCover>
          )}
        </div>
      )}
    </Container>
  )
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  overflow: hidden;
`
const Container = styled.div`
  position: relative;

  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  @media (max-width: 720px) {
    grid-template-columns: 1fr;
    grid-template-rows: max-content max-content;
  }
`

const SavedCards = styled.div`
  position: relative;
  overflow: hidden;
  max-width: 350px;
  width: 100%;
`

const CardScroller = styled(Scroller)`
  width: 100%;
  height: calc(100% - 62px);
  padding-bottom: 20px;
`

const CardOptions = styled.div`
  position: relative;
  width: 100%;
  max-width: 555px;
  overflow: hidden;
`

const Title = styled.h2`
  margin-top: 0;
  height: 62px;
  font-family: 'Biko';
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.5px;
  color: #344356;
`

const CardsList = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  max-width: 330px;
  flex-flow: column;
  height: calc(100% - 62px);
`

const CardCover = styled.div`
  display: flex;
  width: 100%;
  align-items: top;
  column-gap: 10px;
`

const CreditCard = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 190px;
  margin-bottom: 25px;
  background: url(${props => props.background}) center no-repeat;
  background-size: 100% 100%;
  opacity: 0.5;
  transition-duration: 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
    ${CardCover} {
      display: flex;
    }
  }

  ${props =>
    props.active &&
    `
      opacity: 1;
        &:hover {
          opacity: 1;
        }
    `}
`

const CardHolderName = styled(TextField)`
  margin: 0;
  > div {
    padding: 0;
  }
`

const AddCreditText = styled.div`
  font-family: 'Biko';
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
  z-index: 1;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
`
const AddCreditCard = styled(CreditCard)`
  justify-content: center;
  align-items: center;
  opacity: 1;
  ${AddCreditText} {
    &:before {
      background: rgba(255, 255, 255, ${props => (props.active ? 0.5 : 0.7)});
    }
  }
`

const FormComponent = styled.form`
  display: grid;
  grid-gap: 10px;
  p {
    font-size: 14px;
  }
`
const CardType = styled.div`
  position: absolute;
  left: 20px;
  top: 20px;
  height: 40px;
`

const CardNumberWrapper = styled.div`
  position: relative;
`

const InlineCardType = styled(CardType)`
  left: initial;
  right: 4px;
  top: 1px;
  height: 57px;
  background-color: white;
`
const CardNumber = styled.div`
  margin-left: 20px;
  font-size: 20.2174px;
  line-height: 20px;
  letter-spacing: -0.24px;
  font-family: sans-serif;
  color: #ffffff;
  text-shadow: 1.68478px 2.52717px 2.52717px #191919,
    -1.68478px -0.842391px 1.68478px #696969;
`
const CardDetails = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 20px;
  margin-top: 15px;
`
const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 25px;
`
const CardHolder = styled.div`
  color: white;
  font-style: normal;
  font-weight: 900;
  font-size: 15.163px;
  line-height: 15px;
  letter-spacing: -0.176902px;
`

const CardExpire = styled(CardHolder)``

const CardCurrency = styled(CardHolder)`
  margin: 110px 0 5px 20px;
  font-weight: normal;
  font-size: 15.163px;
  line-height: 15px;
  text-transform: uppercase;
`

const Currency = styled(SelectField)`
  margin: 0;
  margin-right: 1px;
  select {
    height: 60px;
    padding-left: 7px;
    padding-right: 10px;
    outline: 1px solid #ceceff;
  }
`

const NewCard = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  input {
    ${OutlinedInputCss}
  }
`

const CardCell = styled(NewCard)`
  grid-template-columns: 1fr 1fr 1fr;
`
const SetDefault = styled(ButtonComponent)``

const Button = styled(ButtonComponent)`
  width: 148px;
`

const Remove = styled(ButtonComponent)`
  background: #ff6355;
  z-index: 1;
  color: white;
`
