import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Button } from 'src/ui'
import { useCompany } from 'src/modules/company'
import { useAuth } from 'src/modules/auth'
import { DataTable } from 'src/components/DataTable'
import { Modal } from 'src/components/Modal'
import { TextInput } from 'src/ui/form/TextInput'
import { Pricing } from 'src/pages/user/Pricing'
import { Alert } from 'src/components/Alert'

import { ReactComponent as EnterImage } from 'src/assets/images/svg/actions/enter.svg'

export function Companies(props) {
  const history = useHistory()
  const {
    isCompaniesInitiated,
    companies,
    create: createCompany,
    company: selectedCompany,
    getCompanies,
  } = useCompany()
  const [plan, setPlan] = useState()
  const [error, setError] = useState()
  const [showAddCompany, setShowAddCompany] = useState(false)
  const [companyName, setCompanyName] = useState()
  const { updateProfile } = useAuth()

  const handlePlan = useCallback(() => {
    if (plan) {
      setShowAddCompany(plan)
    }
  }, [plan])

  useEffect(handlePlan, [plan, handlePlan])

  const onLoading = useCallback(async () => {
    if (isCompaniesInitiated) {
      await getCompanies()
    }
  }, [getCompanies, isCompaniesInitiated])

  useEffect(onLoading, [onLoading])

  const hasNoOwnedCompany = useMemo(() => {
    return !companies?.some(company => /owner/.test(company.role))
  }, [companies])

  const handleCompany = useCallback(
    async companyId => {
      const request = await updateProfile({
        saas_company_id: companyId,
      })

      if (request.ok) {
        // clean app id
        localStorage.removeItem('applicationId')
        window.location.href = '/companies'
      } else {
        toast.error(request?.json?.error?.message)
      }
    },
    [updateProfile]
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Company Name',
        accessor: 'name',
      },
      {
        Header: 'Role',
        accessor: 'role',
      },
      {
        id: 'actions',
        Header: 'Actions',
        cellStyle: { width: '100px', textAlign: 'center' },

        Cell: ({ row: { original } }) =>
          selectedCompany?.id !== original.id && (
            <EnterImageStyled
              title="Select Company"
              onClick={() => handleCompany(original.id)}
              color="primary"
            />
          ),
      },
    ],
    [handleCompany, selectedCompany]
  )

  const handleCreateCompany = useCallback(async () => {
    if (!companyName || !plan) return false

    const result = await createCompany({
      name: companyName,
      plan_slug: plan,
    })

    if (result.ok) {
      const request = await updateProfile({
        saas_company_id: result.json.saas_company.id,
      })

      if (request.ok) {
        setShowAddCompany(false)
        history.push('/payments/cards')
      }
    } else {
      setError(result?.json?.error?.message || 'Something went wrong.')
    }
  }, [companyName, createCompany, history, plan, updateProfile])

  const handleCreateCompanyOnEnter = useCallback(
    event => {
      if (event.key === 'Enter') {
        handleCreateCompany()
      }
    },
    [handleCreateCompany]
  )

  const onClose = useCallback(() => {
    if (companies?.length > 0) {
      setShowAddCompany(false)
      setPlan(false)
    }
  }, [companies?.length])

  const onBack = useCallback(() => {
    setPlan('')
    setShowAddCompany(true)
  }, [])

  function handleCompanyName({ target: { value } }) {
    setCompanyName(value)
  }
  function handleCompanyNameFocus() {
    setError('')
  }

  const onCompaniesLoad = useCallback(async () => {
    await setShowAddCompany(hasNoOwnedCompany && !companies?.length)
  }, [companies, hasNoOwnedCompany])

  useEffect(() => {
    if (isCompaniesInitiated) {
      onCompaniesLoad()
    }
  }, [companies, isCompaniesInitiated, onCompaniesLoad])

  if (!plan && showAddCompany) {
    return <Pricing setPlan={setPlan} className={props?.className} />
  }

  return (
    <Container className={props?.className}>
      {companies?.length > 0 && (
        <DataTableStyled
          columns={columns}
          rows={companies}
          actions={
            hasNoOwnedCompany && (
              <Button color="primary" onClick={setShowAddCompany}>
                {t('Create Company')}
              </Button>
            )
          }
        />
      )}
      {plan && showAddCompany && (
        <Modal onClose={onClose}>
          <ModalContainer>
            <TextInputStyled
              onFocus={handleCompanyNameFocus}
              onChange={handleCompanyName}
              onKeyDown={handleCreateCompanyOnEnter}
              label="Name"
              name="name"
              placeholder="Your company name"
            />
            {error && <Alert type="error" title={t(error)} />}
            <ActionWrapper>
              {companies?.length > 0 && (
                <Button color="primary" bold size="lg" onClick={onClose}>
                  {t('Cancel')}
                </Button>
              )}
              {!companies?.length && (
                <Button color="primary" bold size="lg" onClick={onBack}>
                  {t('Back')}
                </Button>
              )}

              <Button
                color="primary"
                bold
                size="lg"
                disabled={!companyName}
                onClick={handleCreateCompany}
              >
                {t('Create')}
              </Button>
            </ActionWrapper>
          </ModalContainer>
        </Modal>
      )}
    </Container>
  )
}

const Container = styled.div`
  height: calc(100vh - 210px);
`

const ActionWrapper = styled.div`
  display: flex;
  column-gap: 20px;
  margin-top: 24px;
  justify-content: center;
`

const svgStyled = css`
  cursor: pointer;
  width: 16px;
  height: 16px;
`
const EnterImageStyled = styled(EnterImage)`
  ${svgStyled}
`
const TextInputStyled = styled(TextInput)`
  width: 100%;
`
const DataTableStyled = styled(DataTable)`
  padding: 0 16px;
  th {
    text-align: left;
  }
`

const ModalContainer = styled.div`
  width: 800px;
  padding: 30px;
  background-color: white;
  border-radius: 5px;
`
