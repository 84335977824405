import React from 'react'
import { Field } from 'react-final-form'
import { ImageUploader } from 'src/components/Uploader'
import { createUpload } from 'src/modules/directLoad'

export const ImageUploaderField = ({ name = '1', ...props }) => {
  return (
    <Field
      name={name}
      render={({ input, meta }) => (
        <ImageUploader
          {...props}
          onDrop={async (file, updateProgressCallback, completeCallback) => {
            const upload = await createUpload(
              file,
              updateProgressCallback,
              completeCallback
            )

            input.onChange(upload.signed_id)
          }}
          onRemove={() => input.onChange('')}
          error={
            (meta.error || meta.submitError) &&
            meta.touched &&
            (meta.error || meta.submitError?.[0])
          }
        />
      )}
    />
  )
}
