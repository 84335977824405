import React from 'react'
import styled from 'styled-components/macro'
import { Scroller } from 'src/components/Scroller'

const CardComponent = ({ children, title, ...props }) => (
  <CardContent {...props}>
    {props.scrollable ? (
      <Scroller autoHide={false}>
        {title && <CardHeader>{title}</CardHeader>}
        {children}
      </Scroller>
    ) : (
      <>
        {title && <CardHeader>{title}</CardHeader>}
        {children}
      </>
    )}
  </CardContent>
)

const CardHeader = styled.div`
  padding: 20px 0;
`

const CardContent = styled.div`
  position: relative;
  padding: 0;
  height: 100%;
  overflow: auto;
`

export const Card = styled(CardComponent)`
  position: relative;
  display: inline-block;
  padding: 30px;
  margin: 0 auto;
  background-color: white;
  box-shadow: 0 23.5042px 37.215px rgba(60, 128, 209, 0.05);
  border-radius: 10px;
  overflow: hidden;
  height: auto;

  ${props =>
    props.fullSize &&
    `
    height: 100%;
    width: 100%;
    `}

  ${props =>
    props.height &&
    `
    height: ${props.height};
  `}
  
  ${props =>
    props.width &&
    `
    width: ${props.width};
  `}
`
