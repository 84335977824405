import { useEffect, useCallback, useState } from 'react'

import { useMap } from 'src/modules/map'
import { useHeatmapApi } from 'src/hooks/useHeatmap'

// Where the indoor layers will be inserted.
const beforeLayerId = 'marine-label-sm-ln'

const sevenDaysBefore = new Date(new Date().setDate(new Date().getDate() - 7))

export const useHeatmap = () => {
  const { map, isMapLoaded, mapContainer } = useMap()
  const { get: getHeatmapPoints } = useHeatmapApi()

  const [isLoaded, setIsLoaded] = useState()

  const clearLayers = useCallback(() => {
    if (!mapContainer?.current) return false

    if (map && map.getSource && map.getSource('nearmotion-heatmap')) {
      map.removeLayer('nearmotion-heatmap')
      map.removeLayer('nearmotion-heatmap-circle')
      map.removeSource('nearmotion-heatmap')
    }
  }, [map, mapContainer])

  const reduceHeatmap = useCallback(
    geojson => {
      clearLayers()

      map.addSource('nearmotion-heatmap', {
        type: 'geojson',
        data: geojson,
      })

      map.addLayer({
        id: 'nearmotion-heatmap',
        type: 'heatmap',
        source: 'nearmotion-heatmap',
        paint: {
          // increase intensity as zoom level increases
          'heatmap-intensity': {
            stops: [
              [11, 1],
              [18, 3],
            ],
          },
          'heatmap-opacity': {
            stops: [
              [15.5, 0],
              [16, 1],
              [21, 1],
              [22, 0],
            ],
          },
          // increase radius as zoom increases
          'heatmap-radius': {
            property: 'clusterizedCount',
            type: 'exponential',
            stops: [
              [0, 8],
              [1, 16],
            ],
          },
          // increase weight as diameter breast height increases
          'heatmap-weight': {
            property: 'clusterizedDuration',
            type: 'exponential',
            stops: [
              [1, 0],
              [7, 1],
            ],
          },
          // use sequential color palette to use exponentially as the weight increases
          'heatmap-color': [
            'interpolate',
            ['linear'],
            ['heatmap-density'],
            0,
            'rgba(236,222,239,0)',
            0.1,
            '#73c0ec',
            0.2,
            'rgb(103,169,207)',
            0.3,
            'green',
            0.4,
            'lightgreen',
            0.5,
            'yellow',
            0.7,
            'yellow',
            0.8,
            'orange',
            1,
            '#ff6355',
          ],
        },
      })

      map.addLayer(
        {
          id: 'nearmotion-heatmap-circle',
          type: 'circle',
          source: 'nearmotion-heatmap',

          paint: {
            // increase the radius of the circle as the zoom level and dbh value increases
            'circle-radius': {
              property: 'clusterizedCount',
              type: 'exponential',
              stops: [
                [0, 4],
                [1, 12],
              ],
            },
            'circle-color': {
              property: 'clusterizedDuration',
              type: 'exponential',
              stops: [
                [1, 'white'],
                [2, 'rgb(208,209,230)'],
                [3, 'rgb(166,189,219)'],
                [4, 'rgb(166,189,219)'],
                [5, 'yellow'],
                [6, 'orange'],
                [7, '#ff6355'],
              ],
            },
            'circle-opacity': {
              stops: [
                [21, 0],
                [22, 1],
              ],
            },
          },
        },
        beforeLayerId
      )
    },
    [clearLayers, map]
  )

  const load = useCallback(
    async props => {
      const { startDate: start, endDate: end } = props || {}
      const startDate = Math.round(+(start || sevenDaysBefore) / 1000)
      const endDate = Math.round(+(end || new Date()) / 1000)

      const geojson = await getHeatmapPoints({
        startDate,
        endDate,
      })

      clusterizeDurations(geojson)
      clusterizeCounts(geojson)
      reduceHeatmap(geojson)
      setIsLoaded(true)
    },
    [getHeatmapPoints, reduceHeatmap]
  )

  useEffect(() => {
    if (!isMapLoaded || !map || !mapContainer?.current) return

    load()

    return () => {
      clearLayers()
    }
  }, [
    isMapLoaded,
    getHeatmapPoints,
    reduceHeatmap,
    clearLayers,
    mapContainer,
    map,
    load,
  ])

  return { isLoaded, load, sevenDaysBefore }
}

function clusterizeDurations(geojson) {
  const durations = geojson.features.map(f => f.properties.duration)
  const min = Math.min(...durations)
  const max = Math.max(...durations)
  const base = (max - min) ** (1 / 7)

  geojson.features.forEach(f => {
    f.properties.clusterizedDuration = window.Math.floor(
      window.Math.log(f.properties.duration, base)
    )
  })
}

function clusterizeCounts(geojson) {
  const counts = geojson.features.map(f => f.properties.count)
  const min = Math.min(...counts)
  const max = Math.max(...counts)
  const diff = max - min

  geojson.features.forEach(f => {
    f.properties.clusterizedCount = (f.properties.count - min) / diff
  })
}
