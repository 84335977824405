import React from 'react'
import { Header } from 'src/ui'
import styled from 'styled-components/macro'

const PageWithHeaderComponent = ({ children, className, search }) => {
  return (
    <PageContentWrapper className={className}>
      <Header search={search} />
      {children}
    </PageContentWrapper>
  )
}

export const AuthPageWrapper = ({ children }) => {
  return children
}

export const PageContentWrapper = styled.div`
  padding: 43px;
  height: 100%;
  @media (max-width: 768px) {
    padding: 24px;
  }
`

export const PageWithHeader = styled(PageWithHeaderComponent)`
  padding: 72px 0 0;
`
