import { toast } from 'react-toastify'
import { parseErrorAttributes } from 'src/utils/parseErrorAttributes'

const apiHost = process.env.REACT_APP_API_BASE_URL
const apiUrl = new URL('/api/company/v1/', apiHost).href

const preparePostData = ({ attributesFields = [], ...data }) =>
  Object.entries(data).reduce((res, [fieldName, fieldValue]) => {
    if (attributesFields[fieldName]) {
      res[`${fieldName}_attributes`] = fieldValue
    } else {
      res[fieldName] = fieldValue
    }

    return res
  }, {})

const handleResponse = res => {
  switch (res.status) {
    case 422:
    case 200:
    case 201: {
      return res.json().then(json => {
        res.json = json
        if (json?.error?.message && res.status !== 422) {
          toast.error(json?.error?.message || 'Something went wrong')
        }

        if (json?.error?.validations) {
          const { validations } = json.error

          res.json.error.parsedValidations = parseErrorAttributes(validations)

          if (validations instanceof Object) {
            const resErrors = Object.keys(validations)

            resErrors.forEach(
              error => error && toast.error(Object.keys(error + resErrors[0]))
            )
          }
        }

        return res
      })
    }
    case 401: {
      if (!localStorage.getItem('unauthorized')) {
        toast.error(res.statusText || 'Unauthorized')
        localStorage.setItem('unauthorized', true)
      }

      return res
    }
    default: {
      return res
    }
  }
}

const postFormData = async (url = '', data = {}) => {
  const authHeaders = {}

  if (window.token) {
    authHeaders.Authorization = `Token ${window.token}`
  }

  const res = await fetch(apiUrl + url, {
    method: 'POST',
    cache: 'no-cache',
    body: data,
    headers: {
      ...authHeaders,
    },
  })

  return handleResponse(res)
}

const postFile = (url, file) => {
  const data = new FormData()

  data.append('file', file)

  return postFormData(url, data)
}

const postForm = async (url = '', data = {}, options = {}) => {
  const queryString = Object.keys(data)
    .map(key => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
    })
    .join('&')

  const res = await fetch(url, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: '*/*',
    },
    body: queryString,
    ...options,
  })

  return handleResponse(res)
}

const get = async (path = '', options = {}) => {
  const authHeaders = {}

  if (window.token) {
    authHeaders.Authorization = `Token ${window.token}`
  }

  const res = await fetch(new URL(path, options.apiUrl || apiUrl).href, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...authHeaders,
    },
  })

  return handleResponse(res)
}

const post = async (path = '', data = {}, options = {}) => {
  const authHeaders = {}

  if (window.token) {
    authHeaders.Authorization = `Token ${window.token}`
  }

  const res = await fetch(new URL(path, options.apiUrl || apiUrl).href, {
    method: options.method || 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...authHeaders,
    },
    body: JSON.stringify(preparePostData(data)),
    ...options,
  })

  return handleResponse(res)
}

const put = (path, data) => post(path, data, { method: 'PUT' })
const patch = (path, data) => post(path, data, { method: 'PATCH' })
const del = (path, data) => post(path, data, { method: 'DELETE' })

export const api = {
  del,
  get,
  put,
  post,
  patch,
  postFormData,
  postForm,
  postFile,
}
