import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components/macro'
import { SketchPicker } from 'react-color'

const ColorPickerComponent = props => {
  const [show, setShow] = useState(false)
  const [color, setColor] = useState(props.color || '#ffffff')

  useEffect(() => {
    setColor(props.color || '#ffffff')
  }, [props.color])
  const handleClick = () => {
    setShow(!show)
  }

  const handleClose = () => {
    setShow(false)
  }

  const handleChange = useCallback(
    newColor => {
      setColor(newColor.hex)
      props.onChange(newColor.hex)
    },
    [props]
  )

  return (
    <Wrapper>
      <Swatch onClick={handleClick}>
        <Color color={color} />
      </Swatch>
      {show && (
        <PopOver>
          <Cover onClick={handleClose} />
          <SketchPicker color={color} onChange={handleChange} />
        </PopOver>
      )}
    </Wrapper>
  )
}

export const ColorPicker = styled(ColorPickerComponent)``

const Wrapper = styled.div`
  position: relative;
`

const Color = styled.div`
  width: 36px;
  height: 14px;
  border-radius: 2px;
  background: ${props => props.color};
`

const Swatch = styled.div`
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
`
const Cover = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`
const PopOver = styled.div`
  position: absolute;
  bottom: 100%;
  right: 0;
  z-index: 1000;
`
