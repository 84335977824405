import React from 'react'

import { StyledLink } from 'src/components/Link'
import { TermsAndPrivacy } from 'src/ui/TermsAndPrivacy'

export const SuccessMessage = () => {
  return (
    <div>
      <div>
        <div>
          {t('Verification link has been sent')}
          <br />
          {t('Please check your email')}

          <StyledLink
            to="/sign-in"
            style={{
              marginTop: '20px',
              display: 'block',
              fontSize: 'inherit',
              textAlign: 'center',
            }}
          >
            {t('Go to Sign-In page')}
          </StyledLink>
          <TermsAndPrivacy />
        </div>
      </div>
    </div>
  )
}
