import { createUpload } from 'src/modules/directLoad'

export const jsonToFile = (json, fileName) => {
  const theBlob = new Blob([JSON.stringify(json)])

  return new File([theBlob], fileName, {
    lastModified: new Date().getTime(),
    type: theBlob.type,
  })
}

export const multiFileUpload = async (files, syncStatus, setSyncStatus) => {
  const queries = files.map((file, index) => {
    return new Promise(async (resolve, reject) => {
      const preparedFile = jsonToFile(
        {
          type: 'FeatureCollection',
          features: file.geojson.features,
        },
        `${file.geojson.layerName}.geojson`
      )
      const result = await createUpload(
        preparedFile,
        () => {},
        () => {}
      )

      if (syncStatus) {
        setSyncStatus({
          ...syncStatus,
          file: index,
        })
      }

      if (result.signed_id) {
        resolve({
          ...file,
          file: result,
        })
      } else {
        reject(result)
      }
    })
  })

  return Promise.all(queries)
}
